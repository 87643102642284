import { DB_WEB } from "../Config/DB_Web";
import { GET_PAYMENT_BY_CARD_ID } from "./offlinepayment";

const {
  product_collection,
  card_collecton,
  customer_collection,
  discount_collection,
} = DB_WEB;



export async function GET_CARD_BY_ID_FOR_POST_LIVE(card_id) {
  try {
    const datacard = await card_collecton.get(card_id);       

    if(datacard) {
      return {
        message: "Get data success.",
        status: true,
        data: {
          customer_id: datacard?.customer_id,
          date: datacard?.date,
          discount_total: datacard?.discount_total,
          exchange_rate: datacard?.exchange_rate, 
          items: datacard?.items,
          payment_status: datacard?.payment_status,
          shop_Id: datacard?.shop_Id,
          sub_total: datacard?.sub_total,
          tax_total: datacard?.tax_total,
          total: datacard?.total,
        },
      };
    }

    return {
      message: "Get data not success.",
      status: true, 
    };

  } catch (error) {
    return {
      message: "Get data not success.",
      status: false,
    }
  }
}


export async function GET_CARD_BY_ID(card_id) {
  try {
    const datacard = await card_collecton.get(card_id);
    const datacustomer = await customer_collection.get({
      _id: datacard?.customer_id,
    });

    let rowItems = [];
    if (datacard?.items) {
      datacard?.items?.map(async (item) => {
        const dataProduct = await product_collection.get({
          _id: item?.product_id_setup,
        });
        let objItem = {
          product_id_setup: dataProduct,
          image_src: item?.image_src,
          name: item?.name,
          unit: item?.unit,
          qty: item?.qty,
          qty_in_product: item?.qty_in_product,
          qty_in_product_unit: item?.qty_in_product_unit,
          price: item?.price,
          discount_id: item?.discount_id,
          percentage_discount: item?.percentage_discount,
          amount_discount: item?.amount_discount,
          descriptions: item?.descriptions,
        };
        rowItems.push(objItem);
      });
    }

    let data = {
      _id: datacard?._id,
      date: datacard?.date,
      customer_id: {
        _id: datacustomer?._id,
        name: datacustomer?.name,
        tel: datacustomer?.tel,
      },
      items: rowItems,
      payment_status: datacard?.payment_status,
      sub_total: datacard?.sub_total,
      discount_total: datacard?.discount_total,
      tax_total: datacard?.tax_total,
      total: datacard?.total,
      exchange_rate: datacard?.exchange_rate,
      shop_Id: datacard?.shop_Id,
    };

    return {
      message: "Get data not success.",
      status: true,
      data: data,
    };
  } catch (error) {
    return {
      message: "Get data not success.",
      status: false,
    };
  }
}


export async function GET_ALL_CARD() {
  try {
    const allDataCard = await card_collecton.toArray();
    // console.log("allDataCard:::",allDataCard)

    if (allDataCard?.length > 0) {
      //
      // let allRows = [];
      //
      const allRows = await Promise.all(
        allDataCard?.map(async (rowCard) => {
          //Customer
          const datacustomer = await customer_collection.get({
            _id: rowCard?.customer_id,
          });
          //item
          let rowItems = [];
          rowCard?.items?.map(async (item) => {
            //product detials
            const productDetial = await product_collection.get({
              _id: item?.product_id_setup,
            });
            let objItem = {
              product_id_setup: productDetial,
              image_src: item?.image_src,
              name: item?.name,
              unit: item?.unit,
              qty: item?.qty,
              qty_in_product: item?.qty_in_product,
              qty_in_product_unit: item?.qty_in_product_unit,
              price: item?.price,
              discount_id: item?.discount_id,
              percentage_discount: item?.percentage_discount,
              amount_discount: item?.amount_discount,
            };
            rowItems.push(objItem);
          });

          return {
            _id: rowCard?._id,
            date: rowCard?.date,
            customer_id: {
              _id: datacustomer?._id,
              name: datacustomer?.name,
              tel: datacustomer?.tel,
            },
            items: rowItems,
            payment_status: rowCard?.payment_status,
            sub_total: rowCard?.sub_total,
            discount_total: rowCard?.discount_total,
            tax_total: rowCard?.tax_total,
            total: rowCard?.total,
            exchange_rate: rowCard?.exchange_rate,
            shop_Id: rowCard?.shop_Id,
          };
        })
      );

      return {
        message: "Get data success.",
        status: true,
        data: allRows,
      };
    } else {
      return {
        message: "Get data not success.",
        status: false,
      };
    }
  } catch (error) {
    return {
      message: "Get data not success.",
      status: false,
    };
  }
}


export async function GET_CARD_NO_PAYMENT() {
  try {
    const allDataCard = await card_collecton.toArray(); 

    if (allDataCard?.length > 0) {
      // 
      const allRows = await Promise.all(
        //
        allDataCard?.map(async (rowCard) => { 
          
          //============= Find Payment List ===============
          let listPayment = await GET_PAYMENT_BY_CARD_ID(rowCard?._id); 

          if(listPayment?.data?.length === 0) {
            //Customer
            const datacustomer = await customer_collection.get({
              _id: rowCard?.customer_id,
            });

            return {
                _id: rowCard?._id,
                date: rowCard?.date,
                customer_id: {
                  _id: datacustomer?._id,
                  name: datacustomer?.name,
                  tel: datacustomer?.tel,
                },
                items: rowCard?.items,
                payment_status: rowCard?.payment_status,
                sub_total: rowCard?.sub_total,
                discount_total: rowCard?.discount_total,
                tax_total: rowCard?.tax_total,
                total: rowCard?.total,
                exchange_rate: rowCard?.exchange_rate,
                shop_Id: rowCard?.shop_Id,
            };
            
          }
          
        })
      );

      const newData = allRows?.filter( (e) => e !== undefined); 

      return {
        message: "Get data success.",
        status: true,
        data: newData,
      };
    } else {
      return {
        message: "Get data not success.",
        status: false,
      };
    }
  } catch (error) {
    return {
      message: "Get data not success.",
      status: false,
    };
  }
}


export async function CREATE_CARD(values) {
  try {
    // Use the add() method to add data to the array-based object store
    const card_id = await card_collecton.add(values);

    // Do something with the addedItemId if needed
    if (card_id) {
      const dataCardByID = await GET_CARD_BY_ID(card_id);

      return {
        message: "Create success.",
        status: true,
        data: dataCardByID?.data,
      };
    }
  } catch (error) {
    // Do something
    return {
      message: "Create card not success!",
      status: false,
    };
  }
}


export async function REMOVE_CARD_BY_ID(parameter) {
  try {
    // Use the 
    await card_collecton.delete(parameter); 

    return {
      message: "Card deleted successfully.",
      status: true,
    }; 

  } catch (error) {
    // Do something
    return {
      message: "Remove card not success!",
      status: false,
    };
  }
}