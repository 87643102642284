import "./viewqrcode.scss";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import html2canvas from "html2canvas";
//Icons
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import QrCode from "./QrCode";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

export default function ViewQrCode({ open, handleClose, editRow }) {
  // console.log('rowData',editRow)
  const handleDownloadCard = async () => {
    const element = document.getElementById("card"),
      canvas = await html2canvas(element, {
        logging: true,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
      }),
      data = canvas.toDataURL("image/tif"),
      link = document.createElement("a");
    link.href = data;
    link.download = `card-${editRow?.saleQR}.tif`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="view-qrcode"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">
            Preview Qrcode {editRow?.product_Id?.name}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={3} className="body-container">
          <Stack
            direction="row"
            justifyContent="center"
            id="card"
            className="qrcode-container"            
          >
            <Stack direction="column" justifyContent="center">
              <QrCode productId={editRow?.saleQR} />
            </Stack>
          </Stack>

          <Button
            startIcon={<DownloadOutlinedIcon />}
            onClick={handleDownloadCard}
          >
            Download
          </Button>
          
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
