import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";
import TopRightBar from "../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../Menu/IconMenuResponsive";
import { GET_ALL_SHOPS } from "../../../Schema/setting";
import { GET_CUSTOMER_PAGENATION } from "../../../Schema/customer";
import { useQuery, useMutation } from "@apollo/client";

export default function DefaultCustomer() {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const [customer, setCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState("");

  const { refetch } = useQuery(GET_CUSTOMER_PAGENATION, {
    variables: {
      keyword: '',
      pagination: false,
    },
    onCompleted: ({ getCustomersWithPagination }) => {
      setCustomer(getCustomersWithPagination?.data)
      // console.log(getCustomersWithPagination)
    },
  });

  useEffect(() => {
    const customerId = window.localStorage.getItem("defaultCustomer");
    if (customerId) {
      setSelectCustomer(customerId);
    }
  }, []);

  const handleSelectCustomer = () => {
    if (selectCustomer !== "") {
      // setLoading(true);
      window.localStorage.setItem("defaultCustomer", selectCustomer);
      setAlert(true, "success", "Shop Selected");
    }
  };

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Link style={{ textDecoration: "none" }} to="/setting">
              <Typography className="title-active">Setting</Typography>
            </Link>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Default Customer</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        className="shop"
        mt={10}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography className="text" variant="body">
            Select Default Customer :
          </Typography>
          <FormControl size="small">
            <Select
              className="select"
              value={selectCustomer}
              onChange={(e) => setSelectCustomer(e.target.value)}
            >
              {customer?.map((item, key) => (
                <MenuItem key={key} value={item?._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
            {selectCustomer === "" && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Stack>

        <Stack direction="row">
          {loading ? (
            <Button className="btn-delivery" variant="text">
              loading...
            </Button>
          ) : (
            <Button
              className="btn-delivery"
              variant="text"
              onClick={handleSelectCustomer}
            >
              Set Default Customer
            </Button>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
