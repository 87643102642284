import React, { useState, useContext } from "react";
import "../Style/pagestyle.scss";
import {
  Stack,
  Box,
  Button,
  Table,
  Avatar,
  Grid,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  InputAdornment,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import TopRightBar from "../Menu/TopRightBar";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import moment from "moment";
import { GET_ALL_USERS } from "../Schema/users";
import { useQuery } from "@apollo/client";
import EmptyData from "../../src/Component/Include/EmptyData";
import LoadingPage from "../../src/Component/Include/LoadingPage";
import AddUsers from "../../src/Component/Users/AddUsers/AddUsers";
import UsersAction from "../../src/Component/Users/UsersAction/UsersAction";


export default function Users() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [userData, setUserData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const { refetch } = useQuery(GET_ALL_USERS, {
    variables: {
      keyword: keyword,
    },
    onCompleted: ({ getAllUser }) => {
      setUserData(getAllUser);
      setLoading(false);
      // console.log(getAllUser);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  //modal preview
  const [row, setRow] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const handleOpenPreview = (row) => {
    setOpenPreview(true);
    setRow(row);
  };

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // console.log('userData',userData)

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">User</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid item container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            placeholder="search"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2.6} md={2} lg={3}></Grid>
        <Grid
          item
          xs={12}
          sm={3.4}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            onClick={handleOpen}
            className="btn-add"
            size="small"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
          >
            {t("create")}
          </Button>
          <AddUsers
            t={t}
            open={open}
            handleClose={handleClose}
            dialogTitle={"Create"}
            setRefetch={refetch}
          />
        </Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  {t("no")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("username")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("email")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("role")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("create")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-end"
                      : "header-title-end"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <React.Fragment>
                {userData?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} className="body-cell">
                        <Stack
                          direction="column"
                          height={300}
                          justifyContent="center"
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {userData?.map((row, index) => (
                      <TableRow
                        key={index}
                        className={
                          (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                        }
                      >
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              sx={{ width: 40, height: 40 }}
                              alt={row?.name}
                              src={`${row?.image_src}`}
                            />
                            <Stack
                              direction="column"
                              justifyContent="center"
                            >
                              <Typography>
                                {row?.firsName + "  " + row?.lastName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {row?.role}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {moment(row?.created_At).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell className="body-cell-end" align="right">
                          <UsersAction row={row} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
