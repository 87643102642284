import React, { useContext, useState, useEffect } from "react";
import "./createrefund.scss";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Grid,
  Stack,
  Box,
  TextField,
  Divider,
  IconButton,
  Autocomplete,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import LoopIcon from "@mui/icons-material/Loop";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { GET_CARD_PAGINATION } from "../../Schema/viewpos";
import CreateListItem from "./CreateListItem";
import { AuthContext } from "../../Context/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_REFUND, GET_INVOICE } from "../../Schema/refund";
import GetItemById from "./GetItemById";

export default function CreateRefund({
  open,
  handleClose,
  setRefetch,
  language,
  type,
  data,
}) {

  const shop = JSON.parse(window.localStorage.getItem("shop"));
  // console.log("data", data);

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [invoiceOption, setInvoiceOption] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({ id: "", label: "" });
  const [total, setTotal] = useState(0.0);
  const [refundAmount, setRefundAmount] = useState(0.0);

  const [createReturnProduct] = useMutation(CREATE_REFUND, {
    onCompleted: ({ createReturnProduct }) => {
      if (createReturnProduct?.status) {
        setAlert(true, "success", createReturnProduct?.message);
        setLoading(false);
        resetForm();
        setRefetch();
        setSelectedInvoice({ id: "", label: "" });
        handleClose();
      } else {
        setAlert(true, "error", createReturnProduct?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });
 

  const { refetch: refetchInvoice } = useQuery(GET_INVOICE, {
    variables: {
      fromDate: moment()?.format("YYYY-MM-DD"),
      toDate: moment()?.format("YYYY-MM-DD"),
    },
    onCompleted: ({ getReturnProductByCart }) => {
      // console.log(getReturnProductByCart);
      if (getReturnProductByCart) {
        let rows = [];
        if (type !== "view") {
          getReturnProductByCart?.map((e) => {
            let row = { id: e?._id, label: e?.numbering };
            rows?.push(row);
          });
          setInvoiceOption(rows);
        }
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // ============== list Items ========================
  const [items, setItems] = useState([]);
  const [checkFieldRequried, setCheckFieldRequried] = useState("");

  //== add button
  const addItem = (item) => {
    let newObject = {
      product_id_setup: item?.product_id_setup?._id,
      image_src: item?.image_src,
      name: item?.name,
      unit_Price: item?.product_id_setup?.unit_Price,
      total_amount: item?.product_id_setup?.unit_Price * item?.qty,
      returnQty: 1,
      qty: item?.qty,
      discount: item?.discount_id,
      unit_sale: item?.product_id_setup?.saleName,
    };

    if (items?.length === 0) {
      setItems([...items, newObject]);
    } else {
      const newItem = items;
      if (
        newItem?.filter(
          (e) => e.product_id_setup === item?.product_id_setup?._id
        )?.length !== 0
      ) {
        setItems([...newItem]);
      } else {
        setItems([...items, newObject]);
      }
    }
  };

  // ==Refund amount
  const updateRefundAmount = () => {
    let amount = 0;
    let newI = items;
    newI?.map((e) => {
      amount += e?.returnQty * e?.unit_Price;
    });
    setRefundAmount(amount?.toFixed(2));
  };

  const updateItemQty = (id, value) => {
    let newItem = items;
    newItem.map((i) => {
      if (i?.product_id_setup === id) {
        if (i.qty >= value) {
          i.returnQty = parseInt(value);
        } else {
          i.returnQty = i.qty;
        }
      }
    });
    setItems([...newItem]);
    updateRefundAmount();
  };

  // //== delete button
  const removeItem = (id) => {
    const newItem = items?.filter((e) => e?.product_id_setup !== id);
    setItems([...newItem]);
  };

  //==updateTotalAmount
  const totalAmount = (amount) => {
    setTotal(amount?.toFixed(2));
  };

  
  // ======= fromik ===========
  const addExpend = Yup.object().shape({
    date: Yup.date().required(),
  });

  const formik = useFormik({
    initialValues: {
      date: moment(),
    },

    validationSchema: addExpend,
    onSubmit: async (values) => {
      setLoading(true);
     
      let addEntry = false;
      const BreakException = {};
      try {
        items?.forEach(function (elem) {
          if (elem?.returnQty !== 0) {
            addEntry = true;
            setCheckFieldRequried(false);
          } else {
            setCheckFieldRequried(true);
            addEntry = false;
            throw BreakException;
          }
        });
      } catch (e) {
        addEntry = false;
        if (e !== BreakException) throw e;
      }

      if (addEntry && shop) {        
        createReturnProduct({
          variables: {
            input: {
              items: items?.map((e) => ({
                total_amount: e?.total_amount,
                set_UpSale: e?.product_id_setup,
                qty: e?.returnQty,
                price: e?.unit_Price,
                discount_id: e?.discount?._id,
                unit_sale: e?.unit_sale,
              })),
              cart_Id: selectedInvoice?.id,
              date: values?.date,
              shop_Id: shop?.id
            },
          },
        });
      } else {
        setLoading(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    values,
  } = formik;


  useEffect(() => {
    if (type === "view") {
      setSelectedInvoice({
        id: data?.cart_Id?._id,
        label: data?.cart_Id?.numbering,
      });
      let rows = [];
      data?.items?.map((e) => {
        let newObject = {
          product_id_setup: e?.set_UpSale?.product_Id?._id,
          image_src: e?.set_UpSale?.product_Id?.image_src,
          name: e?.set_UpSale?.product_Id?.name,
          unit_Price: e?.set_UpSale?.unit_Price,
          // total_amount: item?.product_id_setup?.unit_Price * item?.qty,
          returnQty: e?.qty,
          // qty: item?.qty,
          discount: e?.discount_id,
          unit_sale: e?.unit_sale,
        };
        rows?.push(newObject);
      });
      setItems(rows);
    } else {
      refetchInvoice();
    }
  }, [open]);

  useEffect(() => {
    if (selectedInvoice?.id === null || selectedInvoice?.id === undefined) {
      setTotal(0.0);
      setItems([]);
    }else{
      setItems([])
    }
  }, [selectedInvoice?.id]);

  useEffect(() => {
    updateRefundAmount();
  }, [items]);



  const close = () => {
    handleClose();
    setItems([]);
    setSelectedInvoice({
      id: "",
      label: "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      className="create-refund"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" alignItems="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {type && type === "view" ? "Preview" : "Create"} Refund
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={close}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {data?.is_voided ? (
            <Typography className="voided">Voided</Typography>
          ) : null}
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                mt={1}
                mb={1} 
              >
                <Stack direction="row" alignItems="center">
                  <Typography className="invoice">Invoice</Typography>
                  <Autocomplete
                    disabled={type && type === "view"}
                    sx={{ width: "200px", ml: "10px" }}
                    freeSolo
                    clearOnBlur
                    disablePortal
                    fullWidth
                    size="small"
                    options={invoiceOption}
                    value={selectedInvoice}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ""
                    }
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ""
                    }
                    onChange={(event, value) => {
                      setSelectedInvoice(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select invoice" />
                    )}
                  />
                </Stack>

                <Box flexGrow={1}/>

                <Stack direction="row" alignItems="center">
                  <Typography className="invoice" mr={"10px"}>
                    Refund
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled
                      // disabled={type && type === "view"}
                      value={ type && type === "view" ? data?.date : values?.date}
                      onChange={(e) => setFieldValue("date", e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                          {...getFieldProps("date")}
                          error={Boolean(touched.date && errors.date)}
                          helperText={touched.date && errors.date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Stack>
              <TableContainer>
                <Table className="table">
                  <TableHead>
                    <TableRow className="header-row">
                      <TableCell className="header-cell">No</TableCell>
                      <TableCell className="header-cell">Item name</TableCell>
                      <TableCell className="header-cell">Qty</TableCell>
                      <TableCell className="header-cell">Unit price</TableCell>
                      <TableCell className="header-cell">
                        Total Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <GetItemById
                    id={selectedInvoice?.id}
                    addItem={type && type === "view" ? () => {} : addItem}
                    totalAmount={totalAmount}
                  />
                </Table>
              </TableContainer>
              <Typography className="invoice" align="right" mt={1}>
                Total Amount : {"   "}$ {total}
              </Typography>
              <Stack justifyContent="center" alignItems="center">
                <Typography className="return">Return</Typography>
                <LoopIcon sx={{ color: "#0F81C2" }} />
                <Typography className="refund">Refund</Typography>
              </Stack>

              <Grid container spacing={1} className={"return-item"}>
                <Grid item xs={4}>
                  <Typography className="header-cell">Choose Item</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="header-cell" align="center">
                    Qty
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="header-cell" align="center">
                    Unit Price
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="header-cell" align="center">
                    Total Amount
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                {/* List */}
                <CreateListItem
                  type={type && type === "view"}
                  items={items}
                  removeItem={removeItem}
                  updateItemQty={updateItemQty}
                  updateRefundAmount={updateRefundAmount}
                  checkFieldRequried={checkFieldRequried}
                />
              </Grid>
            </Form>
          </FormikProvider>
          <Stack direction="row" justifyContent="end" mt={1} mr={1}>
            <Typography className="invoice">
              Refund Amount : {"   "}${refundAmount}
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box
          className="action"
          sx={{ display: type && type === "view" && "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="center">
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    onClick={handleSubmit}
                    disabled={items?.length === 0}
                  >
                    Create Refund
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
