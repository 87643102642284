import React, { useState, useEffect, useContext } from "react";
import "../Style/pagestyle.scss";
import { Link } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
//Icon
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";

//SRC
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import { useNavigate } from "react-router-dom";
import TopRightBar from "../Menu/TopRightBar";

//Component
import LoadingPage from "../Component/Include/LoadingPage";
import EmptyData from "../Component/Include/EmptyData";
import { FooterPagination } from "../Dymanic/FooterPagination";
import EmptyImage from "../Assets/empty-image.png";
import AdvertiseSetupAction from "../Component/AdvertiseSetup/AdvertiseSetupAction";
import AdvertiseSleepScreen from "../Component/AdvertiseSetup/AdvertiseSleepScreen";

import CreateAdvertise from "../Component/AdvertiseSetup/CreateAdvertise";
import { useQuery } from "@apollo/client";
import { GET_ADVERTISE_WITH_PAGINATION } from "../Schema/advertise";

export default function AdvertiseSetup() {
  // // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  // open create
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // get
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [paginationStatus, setPaginationStatus] = useState(true);

  const { refetch } = useQuery(GET_ADVERTISE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: paginationStatus,
    },
    pollInterval: 500,
    onCompleted: ({ getAdvertisementWithPagination }) => {
      setRowData(getAdvertisementWithPagination?.data);
      setPagination(getAdvertisementWithPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      // console.log(error?.message);
      setLoading(true);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    if (e.target.value === 0) {
      setPaginationStatus(false);
    } else {
      setPaginationStatus(true);
      setPage(1);
    }
  };

  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, limit, keyword]);

  const navigate = useNavigate();
  return (
    <div className="page-container">
    <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Typography
              className="page-title"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/setting")}
            >
              Setting
            </Typography>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Advertise Setup</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>
      <Box sx={{ marginTop: "30px" }}>
        <Grid container mt={"20px"} spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              placeholder="search"
              size="small"
              className="text-field"
              onChange={(e) => {
                setPage(1);
                setKeyword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Button
              onClick={handleOpen}
              className="btn-add"
              fullWidth
              size="small"
              startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            >
              Create
            </Button>
            <CreateAdvertise
              t={t}
              open={open}
              handleClose={handleClose}
              dialogTitle={"Create"}
              setRefetch={refetch}
            />
          </Grid>
        </Grid>
      </Box> 
      <Box className="body-container" marginTop="30px">
        <Box>
          <Grid container spacing={1}>
            {loading ? (
              <Grid item xs={12} bgcolor="#fff">
                <LoadingPage />
              </Grid>
            ) : rowData?.length === 0 ? (
              <Grid item xs={12} bgcolor="#fff">
                <EmptyData />
              </Grid>
            ) : (
              rowData?.map((data, index) => (
                <Grid item xs={6} sm={4} md={3} lg={3} xl={2.4} key={index}>
                  <Stack justifyContent="center" direction="column" spacing={1}> 
                    <Box
                      style={{ position: "relative", bgcolor: "#fff" }}
                      marginTop="17px"
                    >
                      {
                        data?.src_image ?
                          <img
                            src={data?.src_image}
                            srcSet={`${data?.src_image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={data.title}
                            loading="lazy"
                            style={{
                              height: "270px",
                              width: "100%",
                              cursor: "pointer",
                              objectFit: "cover",
                            }} 
                          />
                        :
                          <img
                            src={ EmptyImage }
                            // srcSet={`${EmptyImage}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={data.title}
                            loading="lazy"
                            style={{
                              height: "270px",
                              width: "100%",
                              cursor: "pointer",
                              objectFit: "cover",
                            }} 
                          />
                      }
                      

                      <Box
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      >
                        <AdvertiseSetupAction
                          editData={data}
                          setRefetch={refetch}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box> 
      {/* ==================== Pagination  ============================= */}
   <FooterPagination
        showPage={showPage}
        totalPages={pagination.totalPages}
        limit={limit}
        setPage={setPage}
        handleLimit={handleLimit}
      />
    </div>
  );
}
