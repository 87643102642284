import React, { useState, useContext, useEffect } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TableHead,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import { FooterPagination } from "../Dymanic/FooterPagination";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import TopRightBar from "../Menu/TopRightBar";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
//component
import CreateCustomer from "../Component/Cutomer/CreateCustomer";
import UpdateCustomer from "../Component/Cutomer/UpdateCustomer";
import CustomerAction from "../Component/Cutomer/CustomerAction";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_PAGENATION } from "../Schema/customer";

export default function Customer() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  //open preview
  const [row,setRow] = useState(null)
  const [openPreview,setOpenPreview] = useState(false)
  const handleOpenPreview = (row) =>{
    setRow(row)
    setOpenPreview(true)
  }
  const handleClosePreview = () => setOpenPreview(false)
  

  //open create
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rowData, setRowData] = useState([]);

  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [paginationStatus, setPaginationStatus] = useState(true);


  const { refetch } = useQuery(GET_CUSTOMER_PAGENATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: paginationStatus,
    },
    onCompleted: ({ getCustomersWithPagination }) => {
      setRowData(getCustomersWithPagination?.data);
      setPagination(getCustomersWithPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      // console.log(error?.message);
      setLoading(true);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    if (e.target.value === 0) {
      setPaginationStatus(false);
    } else {
      setPaginationStatus(true);
      setPage(1);
    }
  };

  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, limit, keyword]);

  // console.log(page)

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Customer</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              placeholder="search"
              size="small"
              className="text-field"
              onChange={(e) => {
                setPage(1);
                setKeyword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2.6} md={2} lg={3}></Grid>

          <Grid
            item
            xs={12}
            sm={3.4}
            md={6}
            lg={6}
            sx={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              onClick={handleOpen}
              className="btn-add"
              size="small"
              startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            >
              {t("create")}
            </Button>
            <CreateCustomer
              t={t}
              open={open}
              handleClose={handleClose}
              dialogTitle={"Create"}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="body-container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  {t("no")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("name")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("phone")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("address")}
                </TableCell>

                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  {t("remark")}
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-end"
                      : "header-title-end"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="body">
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {rowData?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  ) : (
                    rowData?.map((data, index) => (
                      <TableRow
                        key={index}
                        className={
                          (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                        }
                      >
                        <TableCell
                        onClick={() =>handleOpenPreview(data)}
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                        onClick={() =>handleOpenPreview(data)}
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              sx={{ width: 40, height: 40 }}
                              src={`${data?.url_src}`}
                            />
                            <Stack direction="column" justifyContent="center">
                              <Typography>{data?.name}</Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell
                        onClick={() =>handleOpenPreview(data)}
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {data?.tel}
                        </TableCell>
                        <TableCell
                        onClick={() =>handleOpenPreview(data)}
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {data?.address}
                        </TableCell>

                        <TableCell
                        onClick={() =>handleOpenPreview(data)}
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {data?.remark}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <CustomerAction editRow={data} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        <UpdateCustomer 
            editRow={row}
            handleClose={handleClosePreview}
            open={openPreview}
            view={true}
            t={t}
            language={language}
        />

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          showPage={showPage}
          totalPages={pagination.totalPages}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
