import React, { useContext, useEffect, useState } from "react";
import "./report.scss";
import { Typography, Stack, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_CLOSE_SALE_BYID } from "../../Schema/report";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";  

export default function CloseMoneyView({ closeSaleID }) { 

  const moneyType = [
    "100,000",
    "50,000",
    "30,000",
    "20,000",
    "15,000",
    "10,000",
    "5,000",
    "2,000",
    "1,000",
    "500",
    "100",
  ];

  
  //
  const [rowData, setRowData] = useState({});
  const { refetch } = useQuery(GET_CLOSE_SALE_BYID, { 
    variables: {
      closeSaleId: closeSaleID,
    },
    onCompleted: ({ getCloseSale }) => {
      console.log("getCloseSale::::",getCloseSale)
      setRowData(getCloseSale);
    },
  });

  useEffect( () => {
    refetch()
  },[closeSaleID])  
  
  return (
    <div className="money-content">

      <Stack container mt="30px" justifyContent="center" alignItems="center">
          <Typography className="report-title">តារាងបិទលុយប្រចាំថ្ងៃ</Typography>
          <Stack direction="row" justifyContent="center" spacing={1}>
              <Typography className="report-date"> 
                {getKhmerFullDate(moment(rowData?.start_date).utc(false))}  {moment(rowData?.start_date)?.utc(false)?.format("HH:mm a")}
              </Typography>
              <Typography className="report-date"> 
                - 
              </Typography>
              <Typography className="report-date"> 
                {getKhmerFullDate(moment(rowData?.close_date))}  {moment(rowData?.close_date)?.utc(false)?.format("HH:mm a")}
              </Typography>
          </Stack> 
      </Stack>
 
      <Grid container spacing={1} mt={"5px"}>
        <Grid item xs={6}>
          <table className="left-table">

            <thead className="thead">
              <tr className="">
                <th className="th" colSpan="3">ប្រតិបត្តិការសាច់ប្រាក់</th>
              </tr>
              <tr className="">
                <th>បរិយាយ</th>
                <th>រៀល</th>
                <th>ដុល្លារ</th>
              </tr>
            </thead>

            <tbody>

              {
                rowData?.cash_transactions?.income_amount?.map( (rowIncome,index) => (
                  <tr className="" key={index}>
                    <td className="td">{rowIncome?.description}</td> 
                    <td className="center">{rowIncome?.amount_kh ? rowIncome?.amount_kh : "0"} ៛</td>
                    <td className="center">{rowIncome?.amount_usd?.toFixed(2)} $</td>
                  </tr>
                ))
              }
            

              <tr className="total-revenue">
                <td>ទឹកប្រាក់នៅសល់</td>
                <td className="blue">{rowData?.cash_transactions?.total_actual_income?.amount_kh} ៛</td>
                <td className="blue">{rowData?.cash_transactions?.total_actual_income?.amount_usd?.toFixed(2)} $ </td>
              </tr> 

            {/* =====================  Repay ======================= */}
              <tr className="total-revenue">
                <td>ទឹកប្រាក់ចូលសរុប​(អតិថិជនសង)</td>
                <td className="blue">{rowData?.cash_transactions?.total_repay?.amount_kh} ៛</td>
                <td className="blue">{rowData?.cash_transactions?.total_repay?.amount_usd?.toFixed(2)} $ </td>
              </tr> 
               
            {
              rowData?.cash_transactions?.repay_amount?.map( (elem,index) => (
                <tr className="" key={index}>
                  <td style={{paddingLeft:"15px"}}>{elem?.description}</td>
                  <td className="center">{elem?.amount_kh}</td>
                  <td className="center">{elem?.amount_usd}</td>
                </tr>
              ))
            }       

            {/* =====================  change Repay ======================= */}
              <tr className="total-revenue">
                <td>ទឹកប្រាក់អាប់ចូលសរុប (អតិថិជនសង)</td>
                <td className="blue">{rowData?.cash_transactions?.total_return_of_repay_amount?.amount_kh} ៛</td>
                <td className="blue">{rowData?.cash_transactions?.total_return_of_repay_amount?.amount_usd?.toFixed(2)} $ </td>
              </tr> 
              


            {/* =================== Owe Amount ====================== */}
            <tr className="total-disbursement">
              <td>ទឹកប្រាក់ជំពាក់សរុប</td>
              <td className="blue">{0} ៛</td>
              <td className="blue">{0.00} $</td>
            </tr>



            {/* =================== expends ====================== */}
              <tr className="total-disbursement">
                <td>ទឹកប្រាក់ចេញសរុប</td>
                <td className="blue">{rowData?.cash_transactions?.total_expense?.amount_kh} ៛</td>
                <td className="blue">{rowData?.cash_transactions?.total_expense?.amount_usd} $</td>
              </tr>

            {
              rowData?.cash_transactions?.expense_amount?.map( (elem,index) => (
                <tr className="" key={index}>
                  <td style={{paddingLeft:"15px"}}>{ elem?.description }</td>
                  <td className="center">{ elem?.amount_kh } ​៛</td>
                  <td className="center">{ elem?.amount_usd } $</td>
                </tr>
              ))              
            }             
             
            {/* <tr className="">
                <td>ដូរលុយ</td>
                <td className="center">40000 ៛</td>
                <td className="center">$(10.00)</td>
              </tr> */}

              <tr className="amount-after-payment">
                <td>ទឹកប្រាក់ក្រោយការទូទាត់</td>
                <td className="center">{rowData?.cash_transactions?.final_amount?.amount_kh} ៛</td>
                <td className="center">{rowData?.cash_transactions?.final_amount?.amount_usd} $</td>
              </tr>
            </tbody>
          </table>

        </Grid>

        <Grid item xs={6}>
          <table className="right-table">
            <thead>
              <tr>
                <th colSpan="6">ក្រដាស់ប្រាក់</th>
              </tr>
              <tr>
                <th>រៀល</th>
                <th>សន្លឹក</th>
                <th>ទឹកប្រាក់សរុប</th>
                <th>ដុល្លារ</th>
                <th>សន្លឹក</th>
                <th>ទឹកប្រាក់សរុប</th>
              </tr>
            </thead>
            <tbody>
              {moneyType?.map((e) => (
                <tr className="center" key={e}>
                  <td>{e}</td>
                  <td></td>
                  <td>៛</td>
                  <td></td>
                  <td></td>
                  <td>$</td>
                </tr>
              ))}
              <tr className="center total">
                <td colSpan="2">សរុប</td>
                <td>970,000 ៛</td>
                <td colSpan="2"></td>
                <td>$955.00</td>
              </tr>
              <tr>
                <td className="right">ម៉ោងបិទបញ្ជី៖</td>
                <td className="center">7:00</td>
              </tr>
              <tr>
                <td className="right">ឈ្មោះអ្នកបិទបញ្ជី៖</td>
                <td className="center">ហួន គឹមលេង</td>
              </tr>
            </tbody>
          </table>
        </Grid>

      </Grid>
    </div>
  );
}
