import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import LogoOnly from '../../../Assets/mart_logo.svg';

const QRCode = new QRCodeStyling({
  width: 260,
  height: 260,
  image: LogoOnly,
  type: "svg",
  margin: 0,
  dotsOptions: {
    color: "#000000",
    type: "dots",
  },
  imageOptions: {
    crossOrigin: "anonymous",
  },
  cornersSquareOptions: {
    type: "extra-rounded",
    color: "#1183C3",
    // radius: 20
  },
});


export default function QrCode({ productId }) {
  // console.log('productId',productId)
  // =============== set info to card ============================
  const ref = useRef(null);

  useEffect(() => {
    QRCode.update({ data: productId });
    QRCode.append(ref.current);
  }, [productId]);

  return <div ref={ref} />;
}
