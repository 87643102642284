import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import { SET_EXCHANGE_RATE } from "../../../Schema/setting";
import { useMutation } from "@apollo/client";

export default function SetExchangeRate({
  t,
  open,
  handleClose,
  refechRate,
  language,
  exChangeRate,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [addExchange] = useMutation(SET_EXCHANGE_RATE, {
    onCompleted: ({ addExchange }) => {
      if (addExchange) {
        setAlert(true, "success", "Set Exchange Success");
        setLoading(false);
        refechRate();
        handleClose();
      } else {
        setLoading(false);
        setAlert(true, "error", "Error!");
      }
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const AddRate = Yup.object().shape({
    rate: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      rate: "",
    },

    validationSchema: AddRate,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log(values, "rate");
      addExchange({
        variables: {
          rate: parseFloat(values?.rate),
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (exChangeRate) {
      setFieldValue("rate", exChangeRate);
    }
  }, [exChangeRate]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="setup-dialog">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems='center'>
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("set-exchange")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="clear-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                      mb={2}
                    >
                      {t("purchase-rate")}{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="rate"
                      {...getFieldProps("rate")}
                      error={Boolean(touched.rate && errors.rate)}
                      helperText={touched.rate && errors.rate}
                    />
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
