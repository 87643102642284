import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { GET_CUSTOMER } from "../../Schema/pos";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";

import { DB_WEB } from "../../Config/DB_Web";  
// @ts-ignore
const { customer_collection } = DB_WEB;


export default function OfflineCustomer() { 

    const { setAlert } = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    //================== GET PRODUCT ====================
    const { data , refetch } = useQuery(GET_CUSTOMER, {
        variables: { 
          keyword: "", 
        },
        onCompleted: ({ getCustomers }) => {
        //   console.log(getCustomers) 
        },
    });

    
    useEffect( () => {
        refetch();
    },[loading])


    //================= CLEAR PRODUCT COLLECTION =================
    const handleClareCustomer = () => {
        customer_collection.clear();
    }
 

    //================= ADD PRODUCT COLLECTION =================
    const handleGetCustomer = () => { 

        if(data?.getCustomers?.data?.length > 0) {
            //=============== CLEARE OLD DATA ==============
            handleClareCustomer();

            //=============== SET NEW DATA ================
            let rows = [];   
            data?.getCustomers?.data?.map( (row) => {  
                let allRow = {
                    _id: row?._id,
                    name: row?.name, 
                    tel: row?.tel, 
                } 
                rows.push(allRow);  
            }) 
            customer_collection.bulkAdd(rows);

            setTimeout( () => {
                setLoading(false);
                setAlert(true , "success" , "Customer is set.");
            },500)
            
        } else {
            setTimeout( () => {
                setLoading(false);
                setAlert(true , "error" , "No customer to set!");
            },500)
        }
        
    }
        
    //========================== GET FROM DB OFFLINE ==================
    const allItems = useLiveQuery( () => customer_collection.toArray(), []); 


    return(
        <Grid item xs={6}>
            <Stack
                direction="column" 
                sx={{
                    backgroundColor: "white", 
                    padding: "15px",
                    borderRadius: "10px",
                    height: "150px",
                }}
            >
                <Typography sx={{ fontWeight: "bold" }}>
                    Customer Offline
                </Typography>
                <Typography variant="body2">
                    Set and clear customer offline.
                </Typography>
                <Box flexGrow={1} />
                <Stack direction="row" justifyContent="center" spacing={2}>
                    
                    <Button 
                        sx={{
                            backgroundColor: 'green', 
                            color: "white", 
                            width: "150px",
                            ":hover": {
                                backgroundColor: 'green',
                            }
                        }} 
                        onClick={ () => { 
                            refetch(); 
                            setLoading(true);
                            setTimeout( () => {
                                handleGetCustomer();  
                            },2000) 
                        }}
                    >
                        { loading ? "...Loading" : "Set" }
                    </Button>
                    <Box flexGrow={1} />
                    <Stack direction="column" justifyContent="center" height="100%">
                        <Typography>
                            Items: {allItems ? allItems?.length : 0} record
                        </Typography>
                    </Stack> 
                </Stack> 
            </Stack>
            
        </Grid>
    )
}