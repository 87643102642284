import { useLazyQuery, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { GET_PRODUCT_FOR_SALE_PAGINIATION } from "../../Schema/pos";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";


import { DB_WEB } from "../../Config/DB_Web";
// @ts-ignore
const { product_collection } = DB_WEB;


export default function OfflineProduct() {

    const { setAlert } = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    //================== GET PRODUCT ====================
    const [getProductForSalePagination,{ data , refetch }] = useLazyQuery(GET_PRODUCT_FOR_SALE_PAGINIATION); 
    useEffect( () => {
        refetch();
    },[loading])


    //================= CLEAR PRODUCT COLLECTION =================
    const handleClareProduct = () => {
        product_collection.clear();
    }
 

    //================= ADD PRODUCT COLLECTION =================
    const handleGetProduct = async () => { 

        try {
            await getProductForSalePagination({
                variables: {
                    category: "",
                    type: "",
                    keyword: "",
                    saleQr: "",
                    pagination: false,
                },
                onCompleted: ({getProductForSalePagination}) => {
                    // console.log("getProductForSalePagination::" , getProductForSalePagination)
                    //=============== CLEARE OLD DATA ==============
                    handleClareProduct();
                        
                    //=============== SET NEW DATA ================
                    let rows = [];  
                    getProductForSalePagination?.data?.map( (row) => {  
                        let allRow = {
                            _id: row?._id,
                            type: row?.type, 
                            unit: row?.unit,
                            name: row?.name,
                            image: row?.image,
                            qty: row?.qty,
                            qty_inUnit: row?.product_Id?.qty_inUnit,
                            saleQR: row?.saleQR,
                            category: row?.category,
                            total_inShop: row?.total_inShop,  
                            unit_Price: row?.unit_Price,
                            descriptions: row?.descriptions,
                        } 
                        rows.push(allRow);  
                    }) 
                    product_collection.bulkAdd(rows); 

                    setTimeout( () => {
                        setLoading(false);
                        setAlert(true , "success" , "Product is set.");
                    },500)

                }
            })
        } catch (error) { 
            setTimeout( () => {
                setLoading(false);
                setAlert(true , "error" , error);
            },500)
        } 
  
    }
        
    //========================== GET FROM DB OFFLINE ==================
    const allItems = useLiveQuery( () => product_collection.toArray(), []); 


    return(
        <Grid item xs={6}>
            <Stack
                direction="column" 
                sx={{
                    backgroundColor: "white", 
                    padding: "15px",
                    borderRadius: "10px",
                    height: "150px",
                }}
            >
                <Typography sx={{ fontWeight: "bold" }}>
                    Product Offline
                </Typography>
                <Typography variant="body2">
                    Set and clear product offline for sale.
                </Typography>
                <Box flexGrow={1} />
                <Stack direction="row" justifyContent="center" spacing={2}> 
                    <Button 
                        sx={{
                            backgroundColor: 'green', 
                            color: "white", 
                            width: "150px",
                            ":hover": {
                                backgroundColor: 'green',
                            }
                        }} 
                        onClick={() => {  
                            setLoading(true);
                            setTimeout( () => {
                                handleGetProduct(); 
                            },1000)   
                        }}
                    >
                        { loading ? "...Loading" : "Set" }
                    </Button>
                    <Box flexGrow={1} />
                    <Stack direction="column" justifyContent="center" height="100%">
                        <Typography>
                            Items: {allItems ? allItems?.length : 0} record
                        </Typography>
                    </Stack> 
                </Stack> 
            </Stack>
            
        </Grid>
    )
}