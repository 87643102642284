import React, { useState, useContext } from "react";
import "../../../Style/pagestyle.scss";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";
import TopRightBar from "../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../Menu/IconMenuResponsive";
import SetExchangeRate from "./SetExchangeRate";
import { GET_EXCHANGE_RATE, SET_EXCHANGE_RATE } from "../../../Schema/setting";
import { useQuery, useMutation } from "@apollo/client";

export default function ExchangeRate({ refechRate }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addExchange] = useMutation(SET_EXCHANGE_RATE, {
    onCompleted: ({ addExchange }) => {
      refechRate(); 
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleGetNBC = () => {
    addExchange({
      variables: {
        rate: 0,
      },
    });
  };


  return (
    <div className="page-container">
      <Stack direction="row" spacing={2}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Link style={{ textDecoration: "none" }} to="/setting">
              <Typography className="title-active">Setting</Typography>
            </Link>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Exchange Rate</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        className="border-mash"
        mt={10}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography
            className="text"
            variant="body"
            sx={{ placeItems: "center" }}
          >
            Officail Exchange Rate:
            <b className="bold-tage"> {exChangeRate} KHR/ USD</b>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            <Button className="btn-add" variant="text" onClick={handleGetNBC}>
              Get From NBC
            </Button>

            <Button className="btn-add" variant="text" onClick={handleOpen}>
              Set Exchange
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <SetExchangeRate
        t={t}
        exChangeRate={exChangeRate}
        open={open}
        refechRate={refechRate}
        handleClose={handleClose}
        dialogTitle={"save"}
      />
    </div>
  );
}
