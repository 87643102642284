import { gql } from "@apollo/client";



export const CREATE_EXPENSE = gql`
 mutation CreateExpenses($input: Input!) {
    createExpenses(input: $input) {
      status
      message
    }
  }
`;

export const GET_EXPENSE = gql`
query GetExpenses($startDate: DataTime, $endDate: DataTime) {
  getExpenses(start_date: $startDate, end_date: $endDate) {
    _id
    is_voided
    titles
    items {
      item_name
      qty
      unit_price
    }
    expense_amount {
      type_currency
      total_amount
      from
    }
    date
    created_At
  }
}
`;

export const UPDATE_EXPENSE = gql`
mutation UpdateExpenses($updateExpensesId: ID!, $input: Input!) {
  updateExpenses(id: $updateExpensesId, input: $input) {
    status
    message
  }
}
`;
export const VOID_EXPENSE = gql`
mutation VoidExpenses($voidExpensesId: ID) {
  voidExpenses(id: $voidExpensesId) {
    status
    message
  }
}
`;