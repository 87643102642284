import { gql } from "@apollo/client";


export const GET_PAYMENT = gql`
  query GetPayment {
    getPayment {
      card_id {
        _id
        create_by {
          _id
          firsName
          lastName
          role
        }
        customer_id {
          _id
          name
          tel
          address
          remark
          image_src
          image_name
          created_at
        }
        date
        isDiscount
        items {
          product_id_setup {
            _id
            unit_on_sale {
              _id
              name
            }
            product_id {
              _id
              bar_Code
              category
              cost
              name
              type
              unit
            }
            type_on_sale
            unit_price_on_sale
            qty_on_sale
            qr_code
            qty_in_product
            created_At
          }
          discount_id {
            _id
            amount_discount
            percentage
            status
            events
          }
          price
          qty
        }
        numbering
        status
      }
      sub_total
      tax_total
      discount_total
      total
      date
      is_owe_money
      payment {
        type_payment_method
        receive_money
        return_money
        owe_money
      }
    }
  }
`;


export const GET_CARD_PAGINATION = gql`
  query GetCartsPagination(
    $page: Int, 
    $limit: Int
    $from: String!
    $to: String!
    $keyword: String
    $pagination: Boolean
    $customer: String
    $product: String
  ) {
    getCartsPagination(
      page: $page
      limit: $limit
      from: $from
      to: $to
      keyword: $keyword
      pagination: $pagination
      customer: $customer
      product: $product
    ) {
      data {
        _id
        customer_id {
          _id
          address
          image_src
          name
          tel
        }
        shop_Id {
          _id
          address
          name
          email
          phone
        }
        create_by {
          _id
          email
          firsName
          lastName
          image_src
        }
        status
        numbering
        isDiscount
        is_voided
        date
        payment_status
        discount_total
        sub_total
        tax_total
        exchange_rate
        total
        items {
          _id
          product_id_setup {
            _id
            saleName
            saleQty
            saleType
            saleQR
            product_Id {
              name
              cost
              category
              image_src
              remark
              type
              feature
              unit
              qty_inUnit
              bar_Code
              _id
            }
            unit_Price
          }
          discount_id {
            _id
            amount_discount
            end_date
            events
            percentage
            start_date
            status
          }
          price
          qty
          percentage_discount
          amount_discount
          qty_in_product          
          qty_in_shop
          unit
          name
          image_src          
        }
        created_at
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;


export const GET_PAYMENT_BY_CARDID = gql`
  query GetPaymentByCartId($cardId: ID) {
    getPaymentByCartId(cardId: $cardId) {
      _id
      card_id {
        _id
      }
      receive_amount {
        type_currency
        amount
        from
      }
      return_amount {
        type_currency
        amount
        from
      }
      amount_owe {
        type_currency
        amount
        from
      }
      paid_amount
      date
      is_voided
      payment_method
      repayment_status
    }
  }
`;


export const VOID_PAYMENT_BY_ID = gql`
  mutation VoidedPaymentById($voidedPaymentByIdId: ID) {
    voidedPaymentById(id: $voidedPaymentByIdId) {
      message
      status
    }
  }
`;


export const VOID_CARD_BY_ID = gql`
  mutation VoidedCartById($voidedCartByIdId: ID) {
    voidedCartById(id: $voidedCartByIdId) {
      status
      message
    }
  }
`;
