import * as React from "react";
import "./customeraction.scss";
import { IconButton, Typography, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import UpdateCustomer from "./UpdateCustomer";
import DeleteCustomer from "./DeleteCustomer";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Dymanic/Translate";

export default function CustomerAction({ editRow, setRefetch}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="three-point" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {handleOpenEdit(); handleClose()}}>
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="icon-menu-update" />
            <Typography  className={language === "kh" ? "text-menuKh" : "text-menuEn"}> {t("update")} </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="icon-menu-delete" />
            <Typography  className={language === "kh" ? "text-menuKh" : "text-menuEn"}>{t("delete")} </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <UpdateCustomer
        t={t}
        open={openEdit}
        editRow={editRow}
        language={language}
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />

      <DeleteCustomer
        t={t}
        open={openDel}
        editRow={editRow}
        setRefetch={setRefetch}
        handleClose={handleCloseDel}
      />
    </div>
  );
}
