import React, { useEffect, useState, useContext } from "react";
import "./productitem.scss";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Button,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { AuthContext } from "../../../Context/AuthContext";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuery } from "@apollo/client";
import { GET_DISCOUNT } from "../../../Schema/discount";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";

export default function PosPage({
  typeSell,
  productItems,
  handleUpdateAddQtyProductCard,
  handleUpdateRemoveQtyProductCard,
  handleUpdateAddQtyByInputNumber,
  handleDeleteItem,
  updateDiscount,
  handleDeleteOutStock,
}) {

  const { setAlert } = useContext(AuthContext);
  const [option, setOption] = useState([]);
  const [amountDis, setAmountDis] = useState(0);
  // console.log("productItems", productItems);

  const [openTextField,setOpenTextField] = useState(false);

  // ==================== get discount ====================
  const { refetch } = useQuery(GET_DISCOUNT, {
    variables: {
      keyword: "",
    },
    onCompleted: ({ getDiscount }) => {
      // console.log(getDiscount)
      if (getDiscount?.length !== 0) {
        let rows = [];
        getDiscount?.map((e) => {
          const row = {
            id: e?._id,
            label:
              e?.percentage !== 0
                ? e?.percentage + "% - " + e?.events
                : "$" + e?.amount_discount + " - " + e?.events,
            amount: e?.amount_discount,
            percent: e?.percentage,
          };
          if (e?.status) rows.push(row);
        });
        setOption(rows);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [typeSell]);

  // console.log("productItems", productItems);

  return (
    <Box
      className="box-order-card"
      sx={{ display: productItems?.length === 0 ? "none" : "block" }}
    >
      <Grid container spacing={2}>
        {productItems?.slice()?.reverse()?.map((d, index) => {
          return (
            <Grid  xs={12}  item container  className="order-item-container"  key={index}  >
              <Grid item xs={8} className="grid-1">
                <Accordion className="accordion" sx={{width: "100%"}}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Stack direction="row" ml={1}>

                      <Stack
                        direction="column"
                        justifyContent="center"
                        className="img-container"
                      >
                        { d?.image_src === "" || d?.image_src === null ? (
                          <HideImageOutlinedIcon  className="product-img"  sx={{ color: "#757575" }} />
                        ) : (
                          <img  className="product-img" alt="Image" src={d?.image_src} />
                        )}
                      </Stack>

                      <Stack
                        ml={2}
                        direction="column"
                        justifyContent="center"
                        className="img-container"
                        spacing={1}
                      >
                        
                        <Typography className="product-name" sx={{ fontSize: "15px" }}>
                          {d?.name} {d?.descriptions}
                        </Typography>

                        <Stack direction="row" spacing={1}>
                            <Typography className="product-price" sx={{ fontSize: "15px" }}>  
                              $ {d?.price} - { d?.unit }  
                            </Typography>

                            { d?.amount_discount !== 0 && d?.amount_discount !== undefined ? (
                              <Typography color="red" sx={{ fontSize: "15px" }}>
                                {"( $ " + d?.amount_discount + " )"}
                              </Typography>
                            ) : d?.percentage_discount !== 0 && d?.percentage_discount !== undefined ? (
                              <Typography color="red" sx={{ fontSize: "15px" }}>
                                {"($" + ( (d?.percentage_discount * (d?.price * d?.qty)) / 100 )?.toFixed(2) + ")"}
                              </Typography>
                            ) : null}  
                        </Stack>

                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      freeSolo
                      clearOnBlur
                      disablePortal
                      size="small"
                      options={option}
                      value={{
                        id: d?.discount_id,
                        label: d?.discount_label,
                        amount: d?.amount_discount,
                        percent: d?.percentage_discount,
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id || value === ""
                      }
                      getOptionLabel={(option) =>
                        option?.label ? option?.label : ""
                      }
                      onChange={(event, value) => {
                        updateDiscount(d?.product_id_setup, value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Discount" />
                      )}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              
              <Grid item xs={3} className="grid-2">
                <Stack direction="column" justifyContent="center" spacing={1} sx={{ width: "100%" ,height: "100%" , paddingRight: "20px" }}>
                  <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center" sx={{ width: "100%" }} >  
                    <IconButton
                      size="small"
                      className="descrease-container"
                      onClick={() => {
                        if (d?.qty !== 1) {
                            handleDeleteOutStock(d?.product_id_setup);
                            handleUpdateRemoveQtyProductCard(productItems, d?.product_id_setup);
                        } else {
                            handleDeleteItem(d?.product_id_setup);
                            handleDeleteOutStock(d?.product_id_setup);
                        }
                      }}
                    >
                      <RemoveIcon className="descrease-icon" />
                    </IconButton>

                    <Stack direction="column" justifyContent="center" width="55px">
                        <Stack direction="row" justifyContent="center" width="55px"> 
                        {
                          openTextField === false ?
                            <Button fullWidth onClick={ () => setOpenTextField(true) } className='qty-product-sale'>
                                <Typography className="product-qty">
                                    {d?.qty}
                                </Typography>
                            </Button>
                        :
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                value={d?.qty}
                                onFocus={ e => e.target.select() }
                                onBlur={() => setOpenTextField(false)}
                                onChange={ (e) => {
                                  handleUpdateAddQtyByInputNumber(productItems, d?.product_id_setup , parseFloat(e.target.value))
                                  // console.log(e.target.value)
                                }}
                            />
                        }                           
                        </Stack>
                    </Stack>

                    <IconButton
                      size="small"
                      className="increase-container"
                      onClick={ () => {
                          if (typeSell === "Retail") {
                              handleUpdateAddQtyProductCard( productItems, d?.product_id_setup);
                          } else {
                              handleUpdateAddQtyProductCard( productItems, d?.product_id_setup );
                          }
                      }}
                    >
                      <AddOutlinedIcon className="increase-icon" />
                    </IconButton>  

                  </Stack>
                  <Stack direction="row" justifyContent="center">                    
                      <Typography sx={{ fontSize: "16px" , marginTop: "-10px" }}>              
                        $ {(d?.price * d?.qty).toFixed(2)}   
                      </Typography>                   
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={1} className="grid-2">
                  <Stack direction="column" justifyContent="center" height="100%"> 
                      <Stack direction="row" justifyContent="right"> 
                        <IconButton onClick={() => handleDeleteItem(d?.product_id_setup)} > 
                          <DeleteForeverOutlinedIcon className="delete-btn" />
                        </IconButton>
                      </Stack>
                  </Stack>
              </Grid>

            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
