import { gql } from "@apollo/client";


export const REPORT_SHOP_RECIEVE_PRODUCT = gql`
  query GetShopStockReceiveReport($shopId: String, $fromDate: String, $toDate: String) {
    getShopStockReceiveReport(shop_id: $shopId, fromDate: $fromDate, toDate: $toDate) {
      product_name
      unit
      qty
      bar_code
      category
      created_at
      qty_inUnit
      unit_of_qty_inUnit
      created_by {
        _id
        email
        lastName
        role
        firsName
      }
    }
  }
`


export const REPORT_DAILY_SALE = gql`
  query GetDailySalesReport($customerType: ID, $startDate: DataTime, $endDate: DataTime, $paymentStatus: String) {
    getDailySalesReport(customer_type: $customerType, start_date: $startDate, end_date: $endDate, payment_status: $paymentStatus) {
      date
      invoice_number
      customer_type
      payment_status
      total_sale_amount
      money_paid
      discount
      remain_amount
    }
  }
`;

export const REPORT_ACCOUNT_RECEIVABLE = gql`
  query GetReportAccountsReceivable($startDate: DataTime, $endDate: DataTime, $repaymentStatus: String) {
    getReportAccountsReceivable(start_date: $startDate, end_date: $endDate, repaymentStatus: $repaymentStatus) {
      date
      invoice_number
      customer_type
      customer_name
      repayment_amount
      amount_owe
      pay_by
    }
  }
` 



export const GET_CLOSE_SHIFT_REPORT = gql`
  query GetCloseSale($closeSaleId: String) {
    getCloseSale(closeSaleId: $closeSaleId) {
      _id
      close_date
      close_by {
        firsName
        lastName
        _id
      }
      cash_transactions {
        expense_amount {
          amount_usd
          amount_kh
          description
        }
        final_amount {
          amount_usd
          amount_kh
        }
        income_amount {
          description
          amount_usd
          amount_kh
        }
        repay_amount {
          description
          amount_usd
          amount_kh
        }
        total_actual_income {
          amount_usd
          amount_kh
        }
        total_expense {
          amount_usd
          amount_kh
        }
        total_repay {
          amount_usd
          amount_kh
        }
        total_return_of_repay_amount {
          amount_kh
          amount_usd
        }
      }
      banknote {
        dollars {
          banknote_type
          number_of_banknote
          total_amount
        }
        riels {
          banknote_type
          number_of_banknote
          total_amount
        }
      }
      created_at
    }
  }
`;



export const CREATE_CLOSE_SHIFT = gql`
  mutation CreateCloseSale($input: CloseSaleInput) {
    createCloseSale(input: $input) {
      message
      status
    }
  }
`;




export const GET_CLOSE_SALE_BY_DATE = gql`
  query GetListOfCloseSaleDate($fromDate: String, $toDate: String) {
    getListOfCloseSaleDate(fromDate: $fromDate, toDate: $toDate) {
      _id
      close_date
      start_date
    }
  }
`


export const GET_CLOSE_SALE_BYID = gql`
query GetCloseSale($closeSaleId: String) {
  getCloseSale(closeSaleId: $closeSaleId) {
    _id
    close_date
    close_by {
      _id
      firsName
      lastName
      image_name
      image_src
      email
      role
      created_At
    }
    cash_transactions {
      income_amount {
        description
        amount_kh
        amount_usd
      }
      total_actual_income {
        amount_kh
        amount_usd
      }
      repay_amount {
        description
        amount_kh
        amount_usd
      }
      total_return_of_repay_amount {
        amount_kh
        amount_usd
      }
      total_repay {
        amount_kh
        amount_usd
      }
      expense_amount {
        description
        amount_kh
        amount_usd
      }
      total_expense {
        amount_kh
        amount_usd
      }
      final_amount {
        amount_kh
        amount_usd
      }
    }
    banknote {
      riels {
        banknote_type
        number_of_banknote
        total_amount
      }
      dollars {
        banknote_type
        number_of_banknote
        total_amount
      }
    }
    created_at
  }
}
`



export const REPORT_GET_CURRENT_SHIFT = gql`
  query GetCurrentShift {
    getCurrentShift {
      close_date
      start_date
      income_amount {
        description
        amount_kh
        amount_usd
      }
      total_actual_income {
        amount_kh
        amount_usd
      }
      repay_amount {
        description
        amount_kh
        amount_usd
      }
      total_return_of_repay_amount {
        amount_kh
        amount_usd
      }
      total_repay {
        amount_kh
        amount_usd
      }
      expense_amount {
        description
        amount_kh
        amount_usd
      }
      total_expense {
        amount_kh
        amount_usd
      }
      final_amount {
        amount_kh
        amount_usd
      }
    }
  }
`;


export const GET_LAST_CLOSE_SHIFT = gql`
  query Query {
    getLastCloseSale
  }
`;


export const REPORT_SALE_BY_PRODUCT = gql`
  query GetSalesReportByProduct($category: String, $keyword: String, $fromDate: DateTime, $toDate: DataTime) {
    getSalesReportByProduct(category: $category, keyword: $keyword, fromDate: $fromDate, toDate: $toDate ) {
      sale_name
      qty_sale
      profit
      product_name
      discount
      category_name
      bar_code
      Cost_of_goods_sale
      Cost_of_goods
    }
  }
`;

export const REPRORT_ACCOUNT_REPAYMENT = gql`
  query GetReportAccountsRepayment($date: DataTime) {
    getReportAccountsRepayment(date: $date) {
      date
      invoice_number
      customer_type
      customer_name
      repayment_amount
      amount_owe
      pay_by
    }
  }
`



export const REPRORT_INVENTORY_BY_DAY = gql`
  query GetInventoryByDayReport($date: String, $keyword: String, $category: String, $isHasQty: Boolean) {
    getInventoryByDayReport(date: $date, keyword: $keyword, category: $category, isHasQty: $isHasQty) {
      bar_code
      product_name
      unit
      qty_inUnit
      unit_of_qty_inUnit
      early_qty
      stock_in_qty
      sold_qty
      lost_qty
      spoiled_qty
      confuse_qty
      remain_qty
      remain_qty_cost
    }
  }
`

