import React, { useEffect, useState } from 'react';
import '../../Pos/Suspend/suspend.scss'
import { Button, Stack, Typography, Divider, Badge } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_CARD_NO_PAYMENT } from '../../../Schema/offlinecard';

export default function SuspendOffline({ cardSuspend , setCardSuspend }) {

    const navigate = useNavigate();
    const backToPOS = (cardId) => {
        // navigate("/pos/update", { state: { cardId } });
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    // =========== get data ===============
    const [rowData, setRowData] = useState([]);

    useEffect( () => {
        GET_CARD_NO_PAYMENT().then( (res) => { 
            setCardSuspend(false);
            if(res?.status) {
                setRowData(res?.data)
            } else {
                setRowData([])
            }
        })
    },[cardSuspend]);

    console.log(rowData)

    return (
        <div className='suspend'>
            {
                rowData?.length > 0 ?
                <Badge badgeContent={rowData?.length}  color="error" max={10} sx={{width: "100%"}}>
                    <Button
                        className='suspend-btn'
                        fullWidth
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                    >
                        Suspended
                    </Button>
                </Badge>
            : 
                <Button
                    className='suspend-btn'
                    fullWidth
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                >
                    Suspended
                </Button> 
            }
            


            <Menu
                sx={{ padding: '0px !important' }}
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {rowData?.length === 0 || rowData === null? (
                    <MenuItem >No card today</MenuItem>
                ) : (
                    rowData?.map((data, index) => (
                        <MenuItem className={index % 2 !== 0 ? 'no-active' : 'active'}
                            onClick={() => { 
                                handleClose(); 
                                backToPOS(data?._id) 
                            }}
                            key={index}
                            sx={{ width: '235px' }}
                        >
                            <Stack direction='column' width='100%'>
                                <Stack direction='row' justifyContent={'space-between'} >
                                    <Typography className='title' ml={6}>No :</Typography>
                                    <Typography className='title'> {data?.numbering ? data?.numbering : "---:---"}</Typography>
                                </Stack>
                                <Stack direction='row' justifyContent={'space-between'} width='100%'>
                                    <Typography className='title'>Customer :</Typography>
                                    <Typography className='title'>{data?.customer_id?.name}</Typography>
                                </Stack>
                            </Stack>
                            <Divider />
                        </MenuItem>
                    ))
                )}
            </Menu>
        </div>
    );
}