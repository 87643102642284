import { useLiveQuery } from "dexie-react-hooks";
import { DB_WEB } from "../Config/DB_Web";

const { product_collection } = DB_WEB; 

// const allItems = useLiveQuery(() => product_collection.toArray(), []);

export function GET_ALL_PRODUCT() {
  return  product_collection.toArray()
}

export function GET_PRODUCT_PAGINATION(typeSell ,keyword, category , currentPage , limit , saleQR) {
  const offset = (currentPage - 1) * limit; 
  // Use Dexie's offset() and limit() to fetch a specific page 
  
  if( category?.title === "All menu" && keyword === "" && saleQR === "" ) {
    return product_collection
    .filter( (e) =>  e.type === typeSell)
    .offset(offset)
    .limit(limit)
    .toArray();
  }

  if( category?.title !== "All menu" && keyword === "" && saleQR === "" ) {
    return product_collection
    .filter( (e) =>  e.type === typeSell && e.category === category?.title)
    .offset(offset)
    .limit(limit)
    .toArray();
  }

  if( category?.title === "All menu" && keyword !== "" && saleQR === "" ) {
    return product_collection
    .filter( (e) =>  e.type === typeSell && e.name.includes(keyword))
    .offset(offset)
    .limit(limit)
    .toArray();
  }

  if( category?.title !== "All menu" && keyword !== "" && saleQR === "" ) {
    return product_collection
    .filter( (e) =>  e.type === typeSell && e.category === category?.title && e.name.includes(keyword))
    .offset(offset)
    .limit(limit)
    .toArray();
  }


  if( category?.title === "All menu" && keyword === "" && saleQR !== "" ) {
    return product_collection
    .filter( (e) =>  e.type === typeSell && e.saleQR.includes(saleQR))
    .offset(offset)
    .limit(limit)
    .toArray();
  }

  if( category?.title !== "All menu" && keyword === "" && saleQR !== "" ) {   
    return product_collection
    .filter( (e) =>  e.type === typeSell && e.category === category?.title && e.saleQR.includes(saleQR))
    .offset(offset)
    .limit(limit)
    .toArray();
  }

}