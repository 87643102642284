import React, { useState, useContext, useEffect } from "react";
import "./paymentmethod.scss";
import {
  Stack,
  Box,
  Button,
  Divider,
  Typography,
  Grid,
  Fade,
  Paper,
  Popper
} from "@mui/material";
//ICON
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
//SRC
import Cash from "./Cash";
import Split from "./Split";
import External from "./External";
import Bank from "./Bank";
import VoidPayment from "./VoidPayment";
import { useQuery } from "@apollo/client";
import { GET_TOTAL_PAID_AMOUNT_CARD } from "../../../Schema/pos";


export default function PaymentMethod({
  type,
  receipt,
  setReceiveAmount,
  setReturnAmount,
  setPayment_method,
  setAmountOwe,
  setPaidAmount,
  setRepaymentStatus,
  listPayment,
  setStateCreatePaymentSuccess,
  stateCreatePaymentSuccess,
  setRequireField,
  findPayment
}) {

  const exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));

  // ======== open Cash Pay ==========
  const [openCash, setOpenCash] = useState(false);
  const handleCash = () => setOpenCash(true);
  const closeCash = () => {
    setOpenCash(false);
    setRequireField(false)
  }

  // ======== open Bank Pay ==========
  const [openBank, setOpenBank] = useState(false);
  const handleBank = () => setOpenBank(true);
  const closeBank = () => {
    setRequireField(false)
    setOpenBank(false);
  }

  // ============= get all receipt amount from invoice =============
  const [totalReceiveAmount,setTotalReceiveAmount] = useState(0);
  const handleGetTotalReceiveAmount = () => {
      let totalReceiveAmount = 0;      
      if(listPayment?.length !== 0) {
          listPayment?.forEach( (element) => {
              if(element?.receive_amount?.length !== 0 && element?.is_voided === false) {               
                totalReceiveAmount += element?.receive_amount[0]?.amount+(element?.receive_amount[1]?.amount/receipt?.exchange_rate)
              }        
          })
      }      
      setTotalReceiveAmount(totalReceiveAmount)
  }

  // console.log('listPayment', listPayment)

  useEffect( () => {   

    if(listPayment?.length !== 0) {
        setRepaymentStatus(true);
    } else {
        setRepaymentStatus(false);
    }

    if (openCash) {
      setPayment_method("Cash");
    } else if (openBank) {
      setPayment_method("Bank");
    } else {
      setPayment_method("");
    }

    handleGetTotalReceiveAmount()
  }, [openCash, openBank]);


  useEffect( () => {    
      if(listPayment?.length !== 0) {
          setRepaymentStatus(true);
      } else {
          setRepaymentStatus(false);
      }

      handleGetTotalReceiveAmount()
      // ======= when create payment success back to select payment again ==========
      if(stateCreatePaymentSuccess) {          
          closeCash(); 
          closeBank();
          setStateCreatePaymentSuccess(false);
      }
  },[stateCreatePaymentSuccess])


  return (
    <>

      { openCash && (
        <Cash
          closeCash={closeCash}
          totalAmount={receipt?.total-totalReceiveAmount >= 0 ? receipt?.total-totalReceiveAmount : 0}
          exChangeRate={exChangeRate}
          setReceiveAmount={setReceiveAmount}
          setReturnAmount={setReturnAmount}
          setAmountOwe={setAmountOwe}
          setPaidAmount={setPaidAmount}   
          setRequireField={setRequireField}     
        />
      )}


      { openBank && (
        <Bank
          closeBank={closeBank}
          totalAmount={receipt?.total-totalReceiveAmount >= 0 ? receipt?.total-totalReceiveAmount : 0}
          exChangeRate={exChangeRate}
          setReceiveAmount={setReceiveAmount}
          setReturnAmount={setReturnAmount}
          setAmountOwe={setAmountOwe}
          setPaidAmount={setPaidAmount}   
          setRequireField={setRequireField}     
        />
      )}


      <Stack
        sx={{
          display:
            openCash || openBank
              ? "none"
              : "block",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={4}>
            <Button
              variant="contained"
              fullWidth
              className="btn-payment"
              onClick={handleCash}
            >
              <Stack>
                <Stack direction="row" justifyContent="center">
                  <LocalAtmRoundedIcon className="icon" />
                </Stack>
                <Typography>Cash</Typography>
              </Stack>
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} lg={4}>
            <Button
              variant="contained"
              fullWidth
              className="btn-payment"
              onClick={handleBank}
            >
              <Stack>
                <Stack direction="row" justifyContent="center">
                  <AccountBalanceWalletOutlinedIcon className="icon" />
                </Stack>
                <Typography>Bank</Typography>
              </Stack>
            </Button>
          </Grid>
 

          <Grid item xs={12}>
            <Divider sx={{ m: "10px" }} />
            <Typography align="center" className="recent-payment">
              Recent Payments
            </Typography>
            <Grid container spacing={1} mt={1}>
              { listPayment?.map((data, index) => (             
                <Grid item xs={12}  key={index} >      
                    <Stack direction="row" className="list-payment">
                        <Typography align="left" className='pay-method'  sx={{bgcolor:data?.is_voided && '#80808099!important'}}>
                            {data?.payment_method === "" ? "Not Paid" : data?.payment_method+" Paid"}
                        </Typography> 
                        
                        <Stack direction="column" justifyContent="center" width="40%">                        
                            <Typography align="right">
                                $ {data?.payment_method === "" ? (receipt?.total)?.toFixed(2) : (data?.paid_amount)?.toFixed(2) }
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="right" width="40%" alignItems='center'>
                            <VoidPayment data={data} findPayment={findPayment}/>
                        </Stack>
                    </Stack>                                                          
                </Grid>                
              )) }
            </Grid>
          </Grid>
        </Grid>
       
      </Stack>
     
    </>
  );
}
