import React, { useContext, useEffect, useState } from "react";
import "./addsale.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import CropImageFile from "../../CropImage/CropImageFile";
import {
  UPDATE_SALE_SETUP_PRODUCT,
  // GET_UNIT,
  GET_ALL_PRODUCT,
} from "../../../Schema/setting";
import { useMutation, useQuery } from "@apollo/client";

export default function UpdateSale({
  editRow,
  t,
  open,
  language,
  handleClose,
  setRefetch,
}) {
  // console.log('editRow',editRow)
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorQtyInUnit, setErrorQtyInUnit] = useState(false);
  // const [selectUnit, setSelectUnit] = useState({ id: "", label: "" });
  // const [unitVal, setUnitVal] = useState("");
  const [productId, setProductId] = useState({
    label: "",
    id: "",
    unitPrice: 0,
    unit: 0,
    qty_inUnit: 0,
  });

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [updateSaleSetUpProduct] = useMutation(UPDATE_SALE_SETUP_PRODUCT, {
    onCompleted: ({ updateSaleSetUpProduct }) => {
      if (updateSaleSetUpProduct?.status) {
        setAlert(true, "success", updateSaleSetUpProduct?.message);
        setLoading(false);
        setRefetch();
        handleClose();
        resetForm();
      } else {
        setLoading(false);
        setAlert(true, "error", updateSaleSetUpProduct?.message);
      }
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //product
  const [allProduct, setAllProduct] = useState([]);
  const { data: dataAllProduct, refetch } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct)
      if (getAllProduct?.length !== 0) {
        let rows = [];
        getAllProduct?.map((element) => {
          const allrow = {
            label: element?.name,
            id: element?._id,
            unitPrice: element?.cost,
            unit: element?.unit,
            qty_inUnit: element?.qty_inUnit,
          };
          rows.push(allrow);
        });
        setAllProduct(rows);
      } else {
        setAllProduct([]);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  //fromik
  const addSale = Yup.object().shape({
    product_Id: Yup.string().required("require product!"),
    saleName: Yup.string().required("require sale name!"),
    saleType: Yup.string().required("require type!"),
    saleQR: Yup.string(),
    descriptions: Yup.string(),
    saleQty: Yup.number().required("require qty!"),
    unit_Price: Yup.number().required("require unitprice!"),
    other: Yup.string(),
    expireDate: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      product_Id: "",
      saleName: "",
      saleType: "Retail",
      saleQR: "",
      descriptions: "",
      saleQty: 0,
      unit_Price: 0,
      other: "",
      expireDate: "",
    },

    validationSchema: addSale,
    onSubmit: async (values) => {
      setLoading(true);
      if (productId?.qty_inUnit === 0) {
        setAlert(true, "error", "Need qty in unit in inventory");
        setErrorQtyInUnit(true);
      } else {
        setLoading(true);
        console.log("SUBMIT VALUE", values);
        updateSaleSetUpProduct({
          variables: {
            updateSaleSetUpProductId: editRow?._id,
            input: {
              product_Id: values?.product_Id,
              saleName: values?.saleName,
              saleQR: values?.saleQR,
              saleQty: values?.saleQty,
              saleType: values?.saleType,
              unit_Price: values?.unit_Price,
              image: profileHook,
              descriptions: values?.descriptions,
              other: values?.other,
              expireDate: values?.expireDate,
            },
          },
        });
      }
    },
  });

  
  const { errors, touched, handleSubmit, getFieldProps,  setFieldValue, values, resetForm, } = formik;

  useEffect( () => {
    if (editRow) {
      console.log(editRow)
      setFieldValue("descriptions" , editRow?.descriptions ? editRow?.descriptions : "")
      setFieldValue("product_Id", editRow?.product_Id?._id);
      setFieldValue("saleName", editRow?.saleName);
      setFieldValue("saleType", editRow?.saleType);
      setFieldValue("saleQty", editRow?.product_Id?.qty_inUnit ? editRow?.saleQty*editRow?.product_Id?.qty_inUnit : 0);
      setFieldValue("saleName", editRow?.saleName);
      setFieldValue("unit_Price", editRow?.unit_Price);
      setFieldValue("saleQR", editRow?.saleQR);
      setFieldValue("other", editRow?.other)
      setFieldValue("expireDate", editRow?.expireDate ? editRow?.expireDate : "")
      setProductId({
        id: editRow?.product_Id?._id,
        label: editRow?.product_Id?.name,
        unitPrice: editRow?.product_Id.cost,
        unit: editRow?.product_Id.unit,
        qty_inUnit: editRow?.product_Id?.qty_inUnit,
      });
      if(editRow?.image !== null && editRow?.image !== "") {
        setProfileHook(editRow?.image);
      } else {
        setProfileHook(editRow?.product_Id?.image_src);
      }
      
      // setFieldValue("unit_on_sale", editRow?.unit_on_sale?._id);
      // setUnitVal({
      //   id: editRow?.unit_on_sale?._id,
      //   label: editRow?.unit_on_sale?.name,
      // });
    }
  }, [editRow,open]);

  console.log(profileHook);
  
  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="create-sale">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems='center'>
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("sale-set")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Product Name
                    </Typography>

                    <Autocomplete
                      disablePortal
                      disabled
                      value={productId}
                      options={allProduct}
                      getOptionLabel={(option) =>
                        option && option?.label !== undefined
                          ? option?.label
                          : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, newValue) => {
                        setFieldValue("product_Id", newValue?.id);
                        setProductId(newValue);
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField placeholder="Product" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item xs={!openCrop ? 4 : 12} className="grid-profile">
                    {!openCrop ? (
                      <Box>
                        <Stack
                          className="profile"
                          direction="row"
                          justifycontent="center"
                        >
                          <Button component="label" sx={{ p: "0px" }}>
                            <TextField
                              type="file"
                              id="image"
                              sx={{ display: "none" }}
                              onChange={handleUploadImage}
                            />
                            <Avatar
                              className="avater-image"
                              variant="square"
                              src={
                                imageFile
                                  ? URL.createObjectURL(imageFile)
                                  : profileHook
                              }
                            >
                              <PhotoSizeSelectActualOutlinedIcon
                                sx={{ fontSize: "70px" }}
                              />
                            </Avatar>
                            <input type="file" hidden />
                          </Button>
                        </Stack>
                      </Box>
                    ) : (
                      <CropImageFile
                        setImageFile={setImageFile}
                        photoURL={photoURL}
                        setOpenCrop={setOpenCrop}
                        setPhotoURL={setPhotoURL}
                        setUploadHook={setProfileHook}
                      />
                    )}
                  </Grid>

                  <Grid item xs={8} mt={5}>
                    <Stack direction="column">
                      <Box
                        sx={{
                          ml: "10px",
                          color: "blue",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        {productId.label}
                      </Box>
                      <Box sx={{ ml: "10px", display: "flex" }}>
                        <Typography variant="h6">
                          Costing:$ {productId.unitPrice}/
                          <b>{productId.unit}</b>
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("choose-sale-type")}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        IconComponent={(props) => (
                          <KeyboardArrowDownOutlinedIcon {...props} />
                        )}
                        {...getFieldProps("saleType")}
                        error={Boolean(touched.saleType && errors.saleType)}
                        helpertext={touched.saleType && errors.saleType}
                      >
                        <MenuItem value="Retail">
                          <Typography>Retail</Typography>
                        </MenuItem>
                        <MenuItem value="Wholesale">
                          <Typography>Wholesale</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {/* {t("qty-on-sale")} */}
                      Sale Qty
                    </Typography>

                    <TextField
                      onFocus={(e) => e.target.select()}
                      InputProps={{
                        inputProps: {
                          type: "number",
                          min: 1,
                        },
                      }}                    
                      // disabled 
                      fullWidth
                      size="small"
                      placeholder="Input Qty"
                      {...getFieldProps("saleQty")}
                      error={Boolean(touched.saleQty && errors.saleQty)}
                      helpertext={touched.saleQty && errors.saleQty}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "100%" }}
                    >
                      <Typography sx={{ fontSize: "30px", mt: "20px" }}>
                        /
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Qty In Unit
                    </Typography>
                    <TextField
                      error={errorQtyInUnit}
                      value={productId?.qty_inUnit ? productId?.qty_inUnit : 0}
                      fullWidth
                      size="small"
                      placeholder="Qty In Unit"
                      aria-readonly
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Unit
                    </Typography>
                    <TextField
                      value={
                        values?.saleQty && productId?.qty_inUnit !== 0
                          ? (values?.saleQty / productId?.qty_inUnit)?.toFixed(
                            3
                          )
                          : 0
                      }
                      fullWidth
                      size="small"
                      placeholder="Unit"
                      aria-readonly
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>{productId?.unit}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Sale Name
                    </Typography>

                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Sale Name"
                      {...getFieldProps("saleName")}
                      error={Boolean(touched.saleName && errors.saleName)}
                      helpertext={touched.saleName && errors.saleName}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("unit-price")}
                    </Typography>

                    <TextField
                      onFocus={(e) => e.target.select()}
                      fullWidth
                      size="small"
                      type="number"
                      placeholder="Unit Price"
                      {...getFieldProps("unit_Price")}
                      error={Boolean(touched.unit_Price && errors.unit_Price)}
                      helpertext={touched.unit_Price && errors.unit_Price}
                      InputProps={{
                        startAdornment: (
                          <Typography marginRight={1}>
                              $
                          </Typography> 
                        ),
                      }} 
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("dis-note")}
                    </Typography>

                    <TextField
                      onFocus={(e) => e.target.select()}
                      fullWidth
                      size="small"
                      placeholder="discount note"
                      {...getFieldProps("descriptions")}
                      error={Boolean(touched.descriptions && errors.descriptions)}
                      helpertext={touched.descriptions && errors.descriptions}
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("expire_date")}
                    </Typography> 
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd-MMM-yyyy"
                          value={values?.expireDate}
                          onChange={(e) => setFieldValue("expireDate" , e ? e : "" )}
                          renderInput={(params) => (
                            <TextField
                              {...params} 
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>


                  </Grid>


                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("other")}
                    </Typography>

                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder={t("other")}
                      {...getFieldProps("other")}
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("barcode")}
                    </Typography>

                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder="Type Barcode"
                      {...getFieldProps("saleQR")}
                    />
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
