import React, { useState, useEffect } from "react";
import "./wholesaleproduct.scss";
import { Link } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
//icons
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { TbPlaylistAdd } from "react-icons/tb";
//src
import EmptyData from "../../../Include/EmptyData";
import LoadingPage from "../../../Include/LoadingPage";
import TopRightBar from "../../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../../Menu/IconMenuResponsive";
import CreateWholesale from "../Create/CreateWholesale";
import WholesaleAction from "../Action/WholesaleAction";
import PreviewWholesale from "../Preview/PreviewWholesale";


const tableData = [
  {
    id:1,
    name:"ដំណាប់ស្វាយ",
    image_src:"",
    unit:"កេស",
    retail_product_number:25,
    retail_product_id:{
      unit:{
        name:"កញ្ចប់",
      }
    },
    unit_price:30.00
},

{
  id:2,
  name:"នំគ្រាប់ស្វាយ",
  image_src:"https://storage-server.go-globalschool.com/client/storage:Cashew/folder:image/fileName:ff9d8f3f-5304-4b67-bf04-4e64eff308b90222023jpeg.png/user:63c753cb620903b7f6c2f57b/key:OGV5bzlHAOQeP2gp8Ixr0ZKY6qnZX2aSUQt6KMtowIW",
  unit:"កេស",
  retail_product_number:15,
  retail_product_id:{
    unit:{
      name:"គ្រាប់",
    }
  },
  unit_price:60.00
},

{
  id:3,
  name:"សូកូឡាគ្រប់រសជាតិ",
  image_src:"https://storage-server.go-globalschool.com/client/storage:Cashew/folder:image/fileName:f52ea8ab-c031-4303-80b2-35cce2ae71290232023jpeg.png/user:63c753cb620903b7f6c2f57b/key:OGV5bzlHAOQeP2gp8Ixr0ZKY6qnZX2aSUQt6KMtowIW",
  unit:"កេស",
  retail_product_number:30,
  retail_product_id:{
    unit:{
      name:"កញ្ចប់",
    }
  },
  unit_price:30.00
},

]


export default function WholeSaleProductPage() {
  const [loading, setLoading] = useState(true);

  //open preview 
  const [row, setRow] = useState(null)
  const [openPreview, setOpenPreview] = useState(false)
  const handleOpenPreview = (row) => {
    setOpenPreview(true)
    setRow(row)
  }
  const handleClosePreview = () => setOpenPreview(false)

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //paginator
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState('');
  const [paginator, setPaginator] = useState({});

//   const [tableData, setTableData] = useState([]);

  // console.log('tableDataWholesale', tableData)

  useEffect(() => {
    setShowPage(page)
  }, [keyword, limit, page])

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  useEffect(() =>{
    setLoading(false)
  },[])


  return (
    <div className="wholesale-page">
      <Stack direction="row">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box className="slash" />
          <Stack direction="column" justifyContent="center">
            <Stack direction="row" spacing={1}>
              <Link style={{ textDecoration: "none" }} to="/setting">
                <Typography className="page-title">Setting</Typography>
              </Link>
              <Typography className="page-title">/</Typography>
              <Typography className="page-title">Wholesale Products</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={6} className="search-container">
          <Box className="box-search">
            <Stack direction="column" sx={{ width: "300px" }}>
              <TextField
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder="Search"
                size="small"
                onChange={(e) => setKeyword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} className="add-container">
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleOpen}
              className="btn-add"
              startIcon={<TbPlaylistAdd className="icon-add" />}
            >
              Create
            </Button>
          </Stack>
          <CreateWholesale open={open} handleClose={handleClose}/>
        </Grid>
      </Grid>

      <Box className="box-table-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" align="left">N°</TableCell>
                <TableCell className="header-title">Name</TableCell>
                <TableCell className="header-title">Unit</TableCell>
                <TableCell className="header-title">Quantity per box</TableCell>
                <TableCell className="header-title">Wholesale price</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <React.Fragment>
                {tableData?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} className="body-cell" >
                        <Stack direction="column" height={300} justifyContent='center'>
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {tableData?.map((row, index) => (
                      <TableRow className="body-row" key={index}>
                        <TableCell className="body-cell" onClick={() => handleOpenPreview(row)}>
                          {/* {index + paginator?.slNo} */}
                          {index+1}
                          </TableCell>
                        <TableCell className="body-cell" onClick={() => handleOpenPreview(row)}>
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              sx={{ width: 40, height: 40 }}
                              alt={row?.name}
                              src={`${row?.image_src}`}
                            ><PhotoSizeSelectActualOutlinedIcon /></Avatar>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              spacing={2}
                            >
                              <Typography>{row?.name}</Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell className="body-cell" onClick={() => handleOpenPreview(row)}>{row?.unit}</TableCell>
                        <TableCell className="body-cell" onClick={() => handleOpenPreview(row)}>{row?.retail_product_number} {row?.retail_product_id?.unit?.name}</TableCell>
                        <TableCell className="body-cell" onClick={() => handleOpenPreview(row)}>$ {(row?.unit_price)?.toFixed(2)}</TableCell>
                        <TableCell className="body-cell" align="right">
                           <WholesaleAction editData={row}/>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>


        <PreviewWholesale
          open={openPreview}
          handleClose={handleClosePreview}
          data={row}
        /> 

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }} alignItems='center' spacing={2}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            count={paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
          <Select
            sx={{ width: '105px' }}
            size='small'
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={e => setLimit(e.target.value)}
            defaultValue={limit}
          >
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={16}>16/Page</MenuItem>
            <MenuItem value={20}>20/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
