import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Axios from "axios";
import imageCompression from "browser-image-compression";
import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
//ICONS
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmptyImage from "../../Assets/empty-image.png";
//SRC
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Dymanic/Translate";
import { useMutation } from "@apollo/client";

//component
import { CREATE_ADVERTISE } from "../../Schema/advertise";
import UploadImage from "../CropImage/UploadImage";
import "./advertisesetup.scss";

export default function CreateAdvertise({
  open,
  handleClose,
  setRefetch,
  t,
  language,
}) {
  const { setAlert } = useContext(AuthContext);

  //hiden and show vibility
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [photoURL, setPhotoURL] = useState();


  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    console.log("imageFile", imageFile);
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
    }
  };

  const [createAdvertisement] = useMutation(CREATE_ADVERTISE, {
    onCompleted: ({ createAdvertisement }) => {
      console.log("createAdvertisement", createAdvertisement);
      if (createAdvertisement?.status) {
        setAlert(true, "success", createAdvertisement?.message);
        setRefetch();
        setLoading(false);
        resetForm();
        handleClose();
        setProfileHook();
      } else {
        setAlert(true, "error", createAdvertisement?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
    }, 

  });

  //formik
  const CheckFieldsValidation = Yup.object().shape({
    src_image: Yup.string(),
    title: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      src_image: "",
      title: "",
    },

    validationSchema: CheckFieldsValidation,
    onSubmit: async (values) => {
      console.log("values====>", values);
      setLoading(true);
      const URL = await UploadImage(imageFile); 
      console.log(" url ", URL);
      createAdvertisement({
        variables: {
          input: {
            src_image: URL,
            title: values?.title,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;
  
  useEffect(() =>{
    setImageFile()
    resetForm()
  },[open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} sx={{width: "100%"}} className="advertise-setup-dialog">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("add-advertise")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="clear-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid iteme xs={12}>
                    <Box>
                      <Stack direction="row" justifyContent="center">
                        <Button component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img 
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : EmptyImage
                            }
                          />
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("firstname")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder="title"
                      {...getFieldProps("title")}
                      //   error={Boolean(touched.firsName && errors.firsName)}
                      //   helperText={touched.firsName && errors.firsName}
                    />
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
