import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  TextField
} from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";
import TopRightBar from "../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../Menu/IconMenuResponsive";
import { GET_ALL_SHOPS } from "../../../Schema/setting";
import { useQuery, useMutation } from "@apollo/client";

export default function Shop() {

  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectShopOption, setSelectShopOption] = useState([]);
  const [selectedShop, setSelectedShop] = useState({})

  const { refetch , data } = useQuery(GET_ALL_SHOPS, {
    variables: {
      keyword: "",
    },
    onCompleted: ({ getAllShops }) => {

      if (getAllShops) {
        let rows = [];
        getAllShops?.forEach((element, index) => {
          const allRow = {
            id: element?._id,
            label: element?.name,
            label_kh: element?.name_kh,
            email: element?.email,
            phone: element?.phone,
            address: element?.address,
          }
          rows?.push(allRow);
        })
        setSelectShopOption(rows)
      }

    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect( () => {
    refetch()
  },[])

  useEffect(() => {
    const shopId = JSON.parse(window.localStorage.getItem("shop")); 
    if (shopId) {
      setSelectedShop(shopId);
    }
  }, [data?.getAllShops]);

  const handleSelectShop = () => {
    if (selectedShop !== null) { 
      window.localStorage.setItem("shop", JSON.stringify(selectedShop));
      setAlert(true, "success", "Shop Selected");
    }
  }; 

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Link style={{ textDecoration: "none" }} to="/setting">
              <Typography className="title-active">Setting</Typography>
            </Link>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Shop</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        className="shop"
        mt={10}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography className="text" variant="body">
            Select shop to delivery :
          </Typography>
          
          {/* <FormControl size="small">
            <Select
              // defaultValue={""}
              className="select"
              value={selectShop}
              onChange={(e) => setSelectShop(e.target.value)}
            >
              {shop?.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
            {selectShop === null && <FormHelperText>Required</FormHelperText>}
          </FormControl> */}

          <Box width={200}>
            <Autocomplete
              fullWidth
              freeSolo
              clearOnBlur
              disablePortal
              size="small"
              options={selectShopOption}
              value={selectedShop}
              isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
              getOptionLabel={(option) => option?.label ? option?.label : ""}
              onChange={(event, value) => setSelectedShop(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Shop" />
              )}
            />
          </Box>
        </Stack>

        <Stack direction="row">
          {loading ? (
            <Button className="btn-delivery" variant="text">
              loading...
            </Button>
          ) : (
            <Button
              className="btn-delivery"
              variant="text"
              onClick={handleSelectShop}
            >
              Select Shop
            </Button>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
