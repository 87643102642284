import React, { useEffect, useState } from "react";
import "./report.scss";
import { Typography, Stack } from "@mui/material";
import { useQuery } from "@apollo/client";
import { REPORT_SALE_BY_PRODUCT } from "../../Schema/report";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";

export default function SellingByItem({ fromDate , toDate, category, keyword }) {

  // console.log(date)
  const [rowData, setRowData] = useState([]);
  const [loading,setLoading] = useState(true);

  
  const { refetch } = useQuery(REPORT_SALE_BY_PRODUCT, {
    variables: {
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        keyword: keyword,
        category: category,
    },
    onCompleted: ({ getSalesReportByProduct }) => {
        setRowData(getSalesReportByProduct);
        setLoading(false);
        console.log(getSalesReportByProduct)
    },
    onError: (error) => {
        console.log(error?.message)
    }
  });

  useEffect( () => {
    refetch();
    setLoading(true);
  },[keyword,category,toDate,fromDate])


  const [totalAll,setTotalAll] = useState({
    totalProfit : 0,
    totalDiscount : 0,
    totalAmount : 0,
    totalCosting : 0,
    totalQty : 0,
  })  
  
  useEffect( () => {
    if(rowData?.length > 0) {
      let totalProfit = 0;
      let totalDiscount = 0;
      let totalAmount = 0;
      let totalCosting = 0;
      let totalQty = 0;
  
      rowData?.map( (i) => {
        totalProfit    += parseFloat(i?.profit?.toFixed(2));
        totalDiscount  += parseFloat((i?.discount)?.toFixed(4));
        totalAmount  += parseFloat((i?.Cost_of_goods_sale)?.toFixed(4));
        totalCosting  += parseFloat((i?.Cost_of_goods)?.toFixed(4));
        totalQty  += parseFloat(i?.qty_sale?.toFixed(2));
      })

      setTotalAll({
        totalProfit : totalProfit,
        totalDiscount : totalDiscount,
        totalAmount : totalAmount,
        totalCosting : totalCosting,
        totalQty : totalQty,
      })
    }    
  },[rowData])
  

  return (
    <>
      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">
          របាយការណ៍លក់តាមមុខទំនិញ
        </Typography>
        <Typography className="report-date">
          {getKhmerFullDate(moment(fromDate))} ដល់ {getKhmerFullDate(moment(toDate))}
        </Typography>
      </Stack>
      <Stack className="report-table-container">
        
      <table className="daily-report-print">
        <thead className="thead-sticky">
          <tr>
            <th className="thead-title-in-report">ល.រ</th>
            <th className="thead-title-in-report">បារកូដ</th>
            <th className="thead-title-in-report">ឈ្មោះទំនិញ</th>
            <th className="thead-title-in-report">ប្រភេទទំនិញ</th>
            <th className="thead-title-in-report">ចំនួនលក់</th>
            <th className="thead-title-in-report">ថ្លៃដើម</th>
            <th className="thead-title-in-report">ថ្លៃលក់</th>
            <th className="thead-title-in-report">បញ្ចុះតម្លៃ</th>
            <th className="thead-title-in-report">ប្រាក់ចំណេញ</th>
          </tr>
        </thead>
        <tbody>
          { loading ? 
            <tr className="center" rowSpan={4}>
              <td  className="tbody-title-in-report" colSpan={9}>Loading...</td>
            </tr>
          :
            <>
              {
                rowData?.map((e, i) => (
                  <tr className="center" key={i}>
                    <td  className="tbody-title-in-report">{i + 1}</td>
                    <td  className="tbody-title-in-report" align="center">{e?.bar_code}</td>
                    <td  className="tbody-title-in-report" align="left">{e?.product_name+" "}({e?.sale_name})</td>
                    <td  className="tbody-title-in-report">{e?.category_name}</td>
                    <td  className="tbody-title-in-report">{(e?.qty_sale)?.toFixed(2)}</td>
                    <td  className="tbody-title-in-report">{(e?.Cost_of_goods)?.toFixed(4)} $</td>
                    <td  className="tbody-title-in-report">{(e?.Cost_of_goods_sale)?.toFixed(2)} $</td>
                    <td  className="tbody-title-in-report">{(e?.discount)?.toFixed(4)} $</td>
                    <td  className="tbody-title-in-report">{e?.profit?.toFixed(2)} $</td>
                  </tr>
                )) 
              } 
              <tr className="center">
                <td  className="tbody-title-in-report" align="right" colSpan={4}>សរុប {" "}</td> 
                <td  className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>{(totalAll?.totalQty)?.toFixed(2)}</td>
                <td  className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>{(totalAll?.totalCosting)?.toFixed(4)} $</td>
                <td  className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>{(totalAll?.totalAmount)?.toFixed(2)} $</td>
                <td  className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>{(totalAll?.totalDiscount)?.toFixed(4)} $</td>
                <td  className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>{(totalAll?.totalProfit)?.toFixed(2)} $</td>
              </tr> 
            </> 
          } 
          

        </tbody>
      </table>
      </Stack>
    </>
  );
}
