import { useEffect, useState } from "react";
import { GET_PAYMENT_BY_CARD_ID } from "../../../Schema/offlinepayment";
import { Typography } from "@mui/material";

export default function PosStatusOffline({editRow}) {

    const [status,setStatus] = useState("Unpaid");

    useEffect( () => {
        async function fetchData() {
            const paymentList = await GET_PAYMENT_BY_CARD_ID(editRow?._id);

            console.log("editRow:::",editRow);
            console.log("paymentList::", paymentList?.data);

            if(paymentList?.data?.length === 0) { 
                setStatus("Unpaid");
            } else {
                let total = 0;
                paymentList?.data?.map( (rowList) => {
                    total += rowList?.paid_amount;
                }) 

                if(total < editRow?.total) {
                    setStatus("Owe");
                } else {
                    setStatus("Paid");
                } 
            }
             
        }
        fetchData();
    },[editRow])
    

    return (
        <>
            <Typography sx={{ color: status === "Unpaid" ? "red" : status === "Paid" ? "green" : "orange"}}>
                {status}
            </Typography>
        </>
    )
}