import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import TopRightBar from "../Menu/TopRightBar";
import { Link } from "react-router-dom"; 
import OfflineProduct from "../Component/OfflineSetup/OfflineProduct";
import OfflineCustomer from "../Component/OfflineSetup/OfflineCustomer";
import OfflineDiscount from "../Component/OfflineSetup/OfflineDiscount";
import { useEffect } from "react";



export default function OfflineSetup() {
 
    useEffect( () => {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            navigator.storage.estimate().then(function(estimate) {
                console.log('Current usage: ' + estimate.usage*0.000001 + ' MB');
                console.log('Quota: ' + estimate.quota*0.000001 + ' MB');
            });
        } else {
            console.log('StorageManager API not supported in this browser');
        }          
    },[])
   


    return(
        <div className="page-container">
            <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                    <IconMenuResponsive />
                </Stack>       
                <Stack direction="column" justifyContent="center">
                    <Box className="slash" />
                </Stack>         
                <Stack direction="column" justifyContent="center">
                    <Stack direction="row" spacing={1}>
                        <Link style={{ textDecoration: "none" }} to="/setting">
                            <Typography className="title-active">Setting</Typography>
                        </Link>
                        <Typography className="page-title">/</Typography>
                        <Typography className="page-title">Offline Setup</Typography>
                    </Stack>
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                <TopRightBar /> 
            </Stack>
            <Grid container spacing={2} sx={{ marginTop: "20px" }}> 
                <OfflineProduct />
                <OfflineCustomer />
                <OfflineDiscount />
            </Grid>
        </div>
    )
}