import React, { useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Button,
  Typography,
  IconButton,
  Box,
  Grid,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function External({ closeExternal, totalAmount,exChangeRate }) {
  const [loading, setLoading] = useState(false);

  //fromik
  const addExternal = Yup.object().shape({
    card_number: Yup.string().required(),
    expire_date: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      card_number: "",
      expire_date: new Date(),
    },

    validationSchema: addExternal,
    onSubmit: async (values) => {
      setLoading(true);
      console.log(values, "card_number");
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const handleClearCard = () => {
    setFieldValue("card_number", "");
  };

  const handleClearDate = () => {
    setFieldValue("expire_date", "");
  };

  return (
    <div className="create-container">
      <Stack direction="row" spacing={2} sx={{ placeItems: "center" }}>
        <IconButton onClick={closeExternal}>
          <ArrowCircleLeftOutlinedIcon />
        </IconButton>
        <Typography className="field-title">External Payment</Typography>
      </Stack>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Stack direction="column" justifyContent="center">
            <Box sx={{ justifyContent: "center", display: "center" }}>
              Amount
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography mt={2}>$ </Typography>
              <Typography variant="h4"> {totalAmount}</Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={10}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <TextField
                    // className="text-field"
                    fullWidth
                    size="small"
                    placeholder="Card Number"
                    {...getFieldProps("card_number")}
                    error={Boolean(touched.card_number && errors.card_number)}
                    helperText={touched.card_number && errors.card_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearCard} sx={{color:"red"}}>
                            <CancelOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    // className="text-field"
                    fullWidth
                    size="small"
                    placeholder="Expired Date"
                    {...getFieldProps("expired date")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearDate} sx={{color:"red"}}>
                            <CancelOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Box>
            </Form>
          </FormikProvider>
        </Grid>

        <Grid item xs={10}>
          <Box className="action">
            <Grid container justifyContent="center">
              <Grid item xs={2}>
                {loading ? (
                  <Button className={"btn-actionEn"} fullWidth>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={"btn-actionEn"}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {/* {t("save")} */}
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
