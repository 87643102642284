import React, { useEffect, useState } from "react";
import "./layout.scss";
import { styled } from "@mui/material/styles";
import { Box, Backdrop, Stack, Typography, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useLocation } from "react-router-dom";
import MenuNavbar from "../Menu/MenuNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
//Srcs
import logo from "../Assets/mart_logo.svg";
import TopRightBar from "../Menu/TopRightBar";
import { GET_USER_LOGIN } from "../Schema/users";
import { useQuery } from "@apollo/client";
import AdvertiseSleepScreen from "../Component/AdvertiseSetup/AdvertiseSleepScreen";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Layout() {
  let location = useLocation();

  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const [openMobile] = useState(false);

  // Resize width
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const user = JSON.parse(window.localStorage.getItem("userLogin"));
  // Sleep Screen ============================================================================
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [iconReload, setIconReload] = React.useState(false);

  const handleCloseBackdrop = () => {
    setIconReload(true);
    setTimeout(() => {
      setOpenBackdrop(false);
      setIconReload(false);
    }, 2000);
    window.location.reload(location.pathname);
  };

  const { data: dataUserLogin } = useQuery(GET_USER_LOGIN, {
    pollInterval: 10000,
    onCompleted: ({ getuserLogin }) => {
      // console.log(getuserLogin);
    },
    onError: (error) => {
      console.log(error?.message);
      let result = error.message.includes("expired");
      // console.log(result)
      if (result) {
        setOpenBackdrop(true);
      }
    },
  });
  // Sleep Screen ================================================================================================

  if (openBackdrop) {
    return (
      <Box className="backdrop-container">
        <Backdrop
          className="backdrop-style"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          transitionDuration={{ enter: 500, exit: 1000 }}
          open={openBackdrop}
        >
          <Stack direction="column" alignItems="center" spacing={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={logo} alt="logo" width="45%" />
              <Typography className="system-title">POS MANAGEMENT</Typography>
              {/* <Button
                  variant="contained"
                  endIcon={
                    iconReload ? (
                      <CircularProgress sx={{ color: "white" }} size="25px" />
                    ) : (
                      <ReplayIcon />
                    )
                  }
                 
                >
                  Back to Homepage
                </Button> */}
            </Stack>
            <Stack justifyContent="left" onClick={handleCloseBackdrop}>
              <AdvertiseSleepScreen />
            </Stack>

            <Typography className="power-title">
              Power By: GO GLOBAL IT
            </Typography>
          </Stack>
        </Backdrop>
      </Box>
    );
  }

  return (
    <Box className="main">
      {width > 1400 ? (
        <Box className="layout-container">
          <CssBaseline />
          <MenuNavbar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
          <Box
            component="main"
            sx={{ flexGrow: 1, padding: "20px 30px" }}
            className="outlet-container"
          >
            <Outlet />
          </Box>
        </Box>
      ) : (
        <Box className="layout-container">
          <CssBaseline />
          {/* <TopRightBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          /> */}
          <Box
            component="main"
            sx={{ flexGrow: 1, padding: "20px" }}
            className="outlet-container"
          >
            <Outlet />
          </Box>
        </Box>
      )}
    </Box>
  );
}
