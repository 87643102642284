import { gql } from "@apollo/client";


export const ADJUST_QTY_IN_SHOP = gql`
    mutation AdjustProductInShop($input: AdjustProductInShopInput!) {
        adjustProductInShop(input: $input) {
            message
            status
        }
    }
`;


export const REMOVE_QTY_PRODUCT = gql`
    mutation DecreaseProductInShop($input: DecreaseProInShop) {
        decreaseProductInShop(input: $input) {
            message
            status
        }
    }
`;


export const GET_PRODUCT_IN_SHOP = gql`
    query GetProductInShopByProductId($productId: ID!) {
        getProductInShopByProductId(product_Id: $productId) {
            _id
            product_Id {
                _id
                name
                qty_inUnit
                unit
                unit_of_qty_inUnit
            }
            sale_Status
            unit_Price
            qty
            created_At
            instock_At
            expire_At
        }
    }
`
