import { gql } from "@apollo/client";

export const GET_ADVERTISE_WITH_PAGINATION = gql`
query GetAdvertisementWithPagination($keyword: String, $page: Int, $limit: Int, $pagination: Boolean) {
  getAdvertisementWithPagination(keyword: $keyword, page: $page, limit: $limit, pagination: $pagination) {
    data {
      _id
      title
      src_image
      created_At
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const CREATE_ADVERTISE = gql`
mutation CreateAdvertisement($input: AdvertisementInput) {
    createAdvertisement(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_ADVERTISE = gql`
mutation UpdateAdvertisement($id: ID, $input: AdvertisementInput) {
    updateAdvertisement(_id: $id, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_ADVERTISE = gql`
mutation DeleteAdvertisement($id: ID) {
  deleteAdvertisement(_id: $id) {
    message
    status
  }
}
`;