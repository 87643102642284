import React, { useContext, useState, useEffect } from "react";
import "./createexpense.scss";
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography,
    Grid,
    Stack,
    Box,
    TextField,
    Divider,
    MenuItem,
    FormControl,
    Select,
    InputAdornment,
    IconButton
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CreateListExpense from "./CreateListExpense";
import { AuthContext } from "../../Context/AuthContext";
import { useMutation } from "@apollo/client";
import { UPDATE_EXPENSE } from "../../Schema/expense";

export default function UpdateExpense({
    open,
    handleClose,
    setRefetch,
    // t,
    language,
    editRow
}) {
    const { setAlert } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [cash, setCash] = useState({ from: 'Cash', type_currency: 'USD', total_amount: 0.00 });
    const [bank, setBank] = useState({ from: 'Bank', type_currency: 'USD', total_amount: 0.00 });
    const [requireBoth, setRequireBoth] = useState(false);

    const [updateExpenses] = useMutation(UPDATE_EXPENSE, {
        onCompleted: ({ updateExpenses }) => {
            if (updateExpenses?.status) {
                setAlert(true, "success", updateExpenses?.message);
                setLoading(false);
                setRefetch()
                handleClose();
            } else {
                setAlert(true, "error", updateExpenses?.message);
                setLoading(false);
            }
        },
        onError: (error) => {
            setAlert(true, "error", updateExpenses?.error);
            setLoading(false);
        },
    });

    const onChangeCash = (type, total) => {
        setCash({ from: 'Cash', type_currency: type, total_amount: total })
    }

    const onChangeBank = (type, total) => {
        setBank({ from: 'Bank', type_currency: type, total_amount: total })
    }

    const [touchedRequireBoth, setTouchedRequireBoth] = useState(false);
    const handleTouchRequireBoth = () => setTouchedRequireBoth(false);
    // ============== list Items ========================
    const [checkFieldRequired, setCheckFieldRequried] = useState(false);
    const [items, setItems] = useState([]);
    const [currentItem, setCurrentItem] = useState({
        item_name: "",
        qty: 0.01,
        unit_price: 0.01,
        key: "",
    });

    //== add button
    const addItem = () => {
        setCurrentItem({
            item_name: "",
            qty: 0.01,
            unit_price: 0.01,
            key: Date.now()
        })
        setItems([...items, currentItem])
    };

    //== delete button
    const removeItem = (key) => {
        const newItem = items?.filter(e => e?.key !== key)
        setItems([...newItem])
    }

    //== update item_name
    const updateItemName = (key, value) => {
        // console.log('value',value)
        const filterItem = items
        filterItem?.map(e => {
            if (e.key === key) {
                e.item_name = value
            }
        })
        setItems([...filterItem])
    }

    //== update item-qty
    const updateItemQty = (key, value) => {
        const filterItem = items
        filterItem?.map(e => {
            if (e.key === key)
                e.qty = parseFloat(value)
        })
        setItems([...filterItem])
    }

    //== update item-price
    const updateItemPrice = (key, value) => {
        const filterItem = items
        filterItem?.map(e => {
            if (e.key === key)
                e.unit_price = parseFloat(value)
        })
        setItems([...filterItem])
    }

    // ======= fromik
    const addExpend = Yup.object().shape({
        title: Yup.string().required("require!"),
        date: Yup.date().required("required date!"),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            date: moment(),

        },


        validationSchema: addExpend,
        onSubmit: async (values) => {
            // console.log("values:::", values);
            let addEntry = false;
            const BreakException = {};
            try {
                items?.forEach(function (elem) {
                    if (elem?.item_name !== "") {
                        addEntry = true;
                        setCheckFieldRequried(false);
                    } else {
                        setCheckFieldRequried(true);
                        addEntry = false;
                        throw BreakException;
                    }
                });
                if (cash?.total_amount === 0 && bank?.total_amount === 0) {
                    setRequireBoth(true)
                    addEntry = false;
                }
            } catch (e) {
                addEntry = true;
                if (e !== BreakException) throw e;
            }

            if (addEntry) {
                setLoading(true);
                updateExpenses({
                    variables: {
                        updateExpensesId: editRow?._id,
                        input: {
                            titles: values?.title,
                            items: items?.map(e => ({
                                unit_price: e.unit_price,
                                qty: e.qty,
                                item_name: e.item_name
                            })),
                            expense_amount: [cash, bank],
                            date: values?.date
                        },
                    },
                });
            }
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
    } = formik;

    useEffect(() => {
        if (items?.length === 0)
            addItem()
    }, [items?.length])


    useEffect(() => {
        if (editRow) {
            setFieldValue('title', editRow?.titles)
            setFieldValue('date', editRow?.date)
            editRow?.expense_amount?.map(e => {
                if (e?.from === 'Bank')
                    setBank({ from: 'Bank', type_currency: e?.type_currency, total_amount: e?.total_amount })
                else {
                    setCash({ from: 'Cash', type_currency: e?.type_currency, total_amount: e.total_amount })
                }
            })
            let allItems = []
            editRow?.items?.map((e, key) => {
                let item = {}
                item = { item_name: e?.item_name, qty: e?.qty, unit_price: e?.unit_price, key: key + 1 }
                allItems?.push(item)
            })
            setItems(allItems)
        }
    }, [editRow,open])


    return (
        <div>
            <Dialog open={open} onClose={handleClose} className="setup-expense">
                <DialogTitle className="dialog-title">
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                            {/* {t("add-customer")} */}
                            Update Expense
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleClose}>
                            <CloseIcon className="clear-icon" />
                        </IconButton>
                    </Stack>
                    <Divider />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <FormikProvider value={formik}>
                            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Grid container rowSpacing={2} columnSpacing={3}>
                                    <Grid item xs={6}>
                                        <Typography className={language === "kh" ? "subKh" : "subEn"} >
                                            Title
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            multiline
                                            placeholder="expense on"
                                            {...getFieldProps("title")}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={language === "kh" ? "subKh" : "subEn"}>Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={values?.date}
                                                onChange={(e) => setFieldValue("date", e)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="text-field"
                                                        size="small"
                                                        fullWidth
                                                        // onFocus={setRequireBoth(false)}
                                                        {...getFieldProps("date")}
                                                        error={Boolean(
                                                            touched.date && errors.date
                                                        )}
                                                        helperText={touched.date && errors.date}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Stack direction="column" justifyContent="center" height="100%">
                                            <Typography >
                                                Expense on
                                            </Typography>
                                        </Stack>

                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack direction="column" justifyContent="center" height="100%">
                                            <Typography >
                                                Qty
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack direction="column" justifyContent="center" height="100%">
                                            <Typography >
                                                Unit Price
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Stack direction="column" justifyContent="center" width="100%">
                                            <Stack direction="row" justifyContent="right" width="100%">
                                                <IconButton onClick={addItem}>
                                                    < AddCircleOutlinedIcon color="success" />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Grid>

                                    {/* List */}
                                    <CreateListExpense
                                        items={items}
                                        removeItem={removeItem}
                                        updateItemName={updateItemName}
                                        updateItemQty={updateItemQty}
                                        updateItemPrice={updateItemPrice}
                                        checkFieldRequired={checkFieldRequired}
                                    />

                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={language === "kh" ? "subKh" : "subEn"}
                                            >
                                                Expense Amount
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Stack direction='column' justifyContent='center' height={'100%'}>
                                                <Typography>
                                                    Cash
                                                </Typography>
                                            </Stack>

                                        </Grid>
                                        <Grid item xs={3}>

                                            <Stack direction='column' justifyContent='center' height={'100%'}>
                                                <FormControl fullWidth size="small">
                                                    <Select
                                                        value={cash?.type_currency}
                                                        onChange={e => onChangeCash(e.target.value, cash?.total_amount)}
                                                    >
                                                        <MenuItem value={'USD'}>$</MenuItem>
                                                        <MenuItem value={'Riel'}>៛</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                placeholder="total"
                                                onClick={e => e.target.select()}
                                                type={'number'}
                                                onChange={e => onChangeCash(cash?.type_currency, e.target.value ? parseFloat(e.target.value) : 0.00)}
                                                inputProps={{
                                                    min: 0.00,
                                                }}
                                                value={cash?.total_amount}
                                                error={requireBoth}
                                                helperText={requireBoth && 'At least 1 payment'}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack direction='column' justifyContent='center' height={'100%'}>
                                                <Typography>
                                                    Bank
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack direction='column' justifyContent='center' height={'100%'}>
                                                <FormControl fullWidth size="small">
                                                    <Select
                                                        value={bank?.type_currency}
                                                        onChange={e => onChangeBank(e.target.value, bank?.total_amount)}
                                                    >
                                                        <MenuItem value={'USD'}>$</MenuItem>
                                                        <MenuItem value={'Riel'}>៛</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction='column' justifyContent='center' height={'100%'}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    placeholder="total"
                                                    type={'number'}
                                                    onClick={e => e.target.select()}
                                                    onChange={e => onChangeBank(bank?.type_currency, e.target.value ? parseFloat(e.target.value) : 0.00)}
                                                    inputProps={{
                                                        min: 0.00,
                                                    }}
                                                    value={bank?.total_amount}
                                                    error={requireBoth}
                                                    helperText={requireBoth && 'At least 1 payment'}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Box className="action">
                        <Grid container>
                            <Grid item xs={12}>
                                {loading ? (
                                    <Button
                                        className={
                                            language === "kh" ? "btn-actionKh" : "btn-actionEn"
                                        }
                                        fullWidth
                                    >
                                        Loading...
                                    </Button>
                                ) : (
                                    <Button
                                        className={
                                            language === "kh" ? "btn-actionKh" : "btn-actionEn"
                                        }
                                        fullWidth
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
