import "./previewholesale.scss";
import {
    Stack,
    Box,
    Typography,
    IconButton,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
} from "@mui/material";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
// import moment from "moment";
export default function PreviewWholesale({
    open,
    handleClose,
    data,
}) {

    const title = [
        { title: 'Name', data: data?.retail_product_id?.name },
        { title: 'Unit', data: data?.unit?.name },
        { title: 'Quantity per box', data: data?.retail_product_number },
        { title: 'Retail price', data: (data?.retail_product_id?.unit_price)?.toFixed(2) },
        { title: 'Wholesale Price', data: (data?.unit_price)?.toFixed(2) },
        { title: 'Remark', data: data?.remark },
    ]
    return (
        <Dialog open={open} onClose={handleClose} className="preview-wholesale" fullWidth maxWidth='sm'>
            <DialogTitle className="dialog-title">
                <Stack direction="row" spacing={2}>
                    <Typography className="title">Preview Wholesale Products</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClose}>
                        <CloseIcon className="clear-icon" />
                    </IconButton>
                </Stack>
                <Divider />
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Stack direction="row" spacing={6} className='content' alignItems={'center'}>
                        <Box>
                            {data?.image_src === '' ? (
                                <ImageNotSupportedOutlinedIcon sx={{ width: '150px', height: '150px' }} />
                            ) : (
                                <img src={data?.image_src} alt="" className="image" />
                            )}
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableBody className="body">
                                    {title?.map((e, i) => (
                                        <TableRow key={i}>
                                            <TableCell className="body-cell" width={'40%'}>
                                                {e?.title}
                                            </TableCell>
                                            <TableCell className="body-cell" width={'10%'}>
                                                :
                                            </TableCell>
                                            <TableCell className="data" >
                                                {e?.title === 'Wholesale Price' && '$ '}
                                                {e?.title === 'Retail price' && '$ '}
                                                {e?.data}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
}
