import * as React from "react"; 
import { Typography, Stack , Button } from "@mui/material";  
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom"; 
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate"; 
import { useMutation } from "@apollo/client"; 
import { CREATE_CARD_AND_PAYMENT } from "../../../Schema/pos";
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import { GET_CARD_BY_ID_FOR_POST_LIVE, REMOVE_CARD_BY_ID } from "../../../Schema/offlinecard";
import { DELETE_PAYMENT_BY_CARD_ID, GET_PAYMENT_BY_CARD_ID_POST_LIVE } from "../../../Schema/offlinepayment";


export default function ViewPosActionOffline({ editRow , setRefetchCardOffiline }) {

  // Change Language
  const { language , setAlert } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const navigate = useNavigate();
  const backToPOS = (cardId) => {
    navigate("/pos/update", { state: { cardId } });
  };

  const [loading,setLoading] = React.useState(false);
  const [loadingRemove,setloadingRemove] = React.useState(false);



  const hanldeRemoveCard = async (cardId) => {
      setloadingRemove(true); 
      const res = await REMOVE_CARD_BY_ID(cardId); 
      const resPayment = await DELETE_PAYMENT_BY_CARD_ID(cardId);

      setRefetchCardOffiline(true);
      if(res?.status) {
        setloadingRemove(false);
        setAlert(true,"success",res?.message); 
      } else {
        setloadingRemove(false)
        setAlert(true,"error",res?.message); 
      }

  }


  //======================== CREATE SALE ===================
  const [saleWithPayment] = useMutation(CREATE_CARD_AND_PAYMENT,{
    onCompleted: ({saleWithPayment}) => { 
        setLoading(false)
        if(saleWithPayment?.status) {
          setAlert(true,"success",saleWithPayment?.message) 

          // remove card and payment from database local
          hanldeRemoveCard(editRow?._id);  

        } else {
          setAlert(true,"error",saleWithPayment?.message)
        }
    },
    onError: (error) => { 
        setAlert(true,"error",error?.message);
        setLoading(false)
    }
  });



  const handlePostLive = async (cardID) => {
      // console.log(cardID); 
      
      setLoading(true);

      const saleCreateData = await Promise.all([
        (async () => {
            const inputCart = await GET_CARD_BY_ID_FOR_POST_LIVE(cardID); 
            const inputPayment = await GET_PAYMENT_BY_CARD_ID_POST_LIVE(cardID);
          
            return { 
              inputCart:  inputCart?.data, 
              inputPayment: inputPayment?.data
            };

        })()
      ]);

      // console.log(saleCreateData[0]) 

      saleWithPayment({
        variables: {
          inputPayment: saleCreateData[0].inputPayment,
          inputCart: saleCreateData[0]?.inputCart
        },
      })

  }



  return (
    <div style={{ width: "100%"}}>

      <Stack direction="row" spacing={2} justifyContent="right">

        {
          loadingRemove ? 
          <Button>
              <Stack direction="row" spacing={1}  className="action">
                  <DeleteForeverOutlinedIcon className="icon-menu-delete" />
                  <Typography className="void-card">Loading...</Typography>
              </Stack>
          </Button>
        :
          <Button
            onClick={() => {
              hanldeRemoveCard(editRow?._id)
            }}        
          >
              <Stack direction="row" spacing={1}  className="action">
                  <DeleteForeverOutlinedIcon className="icon-menu-delete" />
                  <Typography className="void-card">Delete</Typography>
              </Stack>
          </Button>
        }
        

        {
          loading ?
          <Button>
              <Stack direction="row" spacing={1}  className="action">
                  <MoveUpOutlinedIcon className="icon-menu-post" />
                  <Typography className="void-card">Loading...</Typography>
              </Stack>
          </Button>
        :
          <Button
            onClick={ () => {
              handlePostLive(editRow?._id);
            }}        
          >
              <Stack direction="row" spacing={1}  className="action">
                  <MoveUpOutlinedIcon className="icon-menu-post" />
                  <Typography className="void-card">Post</Typography>
              </Stack>
          </Button>
        }
        
      </Stack> 

    </div>
  );
}
