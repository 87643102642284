import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Avatar,
} from "@mui/material";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import app from "../../src/firebase";
import "./login.scss";
import logoImage from "../Assets/mart_logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../Context/AuthContext";

export default function Login() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [iconReload, setIconReload] = useState(false);

  const auth = getAuth(app);

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email!").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password must be 6 characters!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // console.log("values::", values);
      setIconReload(true);
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("userCredential::", user); 
          // window.localStorage.setItem('token',user?.accessToken)
          setAlert(true, "success", "Login Successfull!");
          setTimeout(() => {
            setIconReload(true);
            navigate("/");
          }, 1200);
        })
        .catch((error) => {
          console.log("error::", error);
          setIconReload(false);
          setAlert(true, "error", "Invalid Email/Passwork!");
        });

      //get User Date after login Success
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // const uid = user.uid;
          console.log(user, "user");
        } else {
          // User is signed out
        }
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="login-page">
            <Box className="background-image" />
            <Box className="container">
              <Box className="form-login">
                <Box className="box-logo">
                  <Avatar
                    sx={{ width: 100, height: 100 }}
                    variant="square"
                    alt="logo"
                    src={logoImage}
                  />
                </Box>

                <Box className="box-text" sx={{ mt: 1 }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    spacing={1}
                    className="title-login"
                  >
                    <Typography className="title">
                      Welcome to POS System Mart
                    </Typography>
                    <Typography
                      className="sub-title"
                      variant="body2"
                      align="center"
                    >
                      Sign In to continue
                    </Typography>
                  </Stack>
                </Box>

                <Box className="box-login" sx={{ mt: 3 }}>
                  <Stack direction="column" justifyContent="center" spacing={2}>
                    <TextField
                      className="text-field"
                      size="small"
                      placeholder="example@company.com"
                      fullWidth
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      className="text-field"
                      type={show ? "text" : "password"}
                      size="small"
                      placeholder="password"
                      {...getFieldProps("password")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),

                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={handleClick}
                          >
                            {show ? (
                              <VisibilityIcon className="icon" />
                            ) : (
                              <VisibilityOffIcon className="icon" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Link
                      to="/forgotpassword"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="#000"
                        fontWeight="bold"
                      >
                        Forgot password?
                      </Typography>
                    </Link>

                    <Button
                      className="btb-sign-in"
                      type="submit"
                      size="small"
                      variant="elevated"
                      endIcon={
                        iconReload ? (
                          <CircularProgress
                            sx={{ color: "#fff" }}
                            size="20px"
                          />
                        ) : null
                      }
                    >
                      Sign In
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="body2"
              align="center"
              color="#000"
              sx={{ mb: 3, letterSpacing: "2px" }}
            >
              @Copyright 2023, Go Global IT
            </Typography>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
