import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";
// srcs
import { REPRORT_ACCOUNT_REPAYMENT } from "../../Schema/report";
export default function AccountRepayment({ date }) {
  const [rowData, setRowData] = useState();

  const { data, refetch } = useQuery(REPRORT_ACCOUNT_REPAYMENT, {
    variables: {
      date: moment(date).format("YYYY-MM-DD"),      
    },
    onCompleted: ({ getReportAccountsRepayment }) => {
      // console.log("getReportAccountsRepayment::", getReportAccountsRepayment)
      setRowData(getReportAccountsRepayment);
    },
    onError: (error) => {
      console.log("error getReportAccountsRepayment", error.message);
    },
  });
  useEffect(() => {
    refetch();
  }, data);

  return (
    <div>
      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">របាយការណ៍គណនីសង</Typography>
        <Typography className="report-date">
          {getKhmerFullDate(moment(date))}
        </Typography>
      </Stack>
      <Stack className="report-table-container">
      <table className="daily-report-print">
        <thead className="thead-sticky">
          <tr>
            <th className="thead-title-in-report">ល.រ</th>
            <th className="thead-title-in-report">ថ្ងៃជំពាក់</th>
            <th className="thead-title-in-report">អតិថិជន</th>
            <th className="thead-title-in-report">បរិយាយ</th>
            <th className="thead-title-in-report">លេខវិក្កយបត្រ</th>
            <th className="thead-title-in-report">ទឹកប្រាក់ជំពាក់</th>
            <th className="thead-title-in-report">ទឹកប្រាក់សង</th>
            <th className="thead-title-in-report">តាមរយៈ</th>
          </tr>
        </thead>
        <tbody>
          {rowData?.map((e, i) => (
            <tr className="center" key={i}>
              <td className="tbody-title-in-report">{i + 1}</td>
              <td className="tbody-title-in-report">{moment(e?.date).format("MM/DD/YYYY hh:mm A")}</td>
              <td className="tbody-title-in-report">{e?.customer_name}</td>
              <td className="tbody-title-in-report">{e?.customer_type}</td>
              <td className="tbody-title-in-report">{e?.invoice_number}</td>
              <td className="tbody-title-in-report">{e?.amount_owe.toFixed(2)}</td>
              <td className="tbody-title-in-report">{e?.repayment_amount?.toFixed(2)}</td>
              <td className="tbody-title-in-report">{e?.pay_by}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </Stack>
    </div>
  );
}
