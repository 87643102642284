import React, { useEffect, useState, useContext, useRef } from "react";
import "./pos.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Pagination,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
// import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
import PrintProductItemOffline from "../Component/PosOffline/PrintProductItemOffline/PrintProductItemOffline";
import TopRightBar from "../Menu/TopRightBar";
import ProductItemOffline from "../Component/PosOffline/ProductItemOffline/ProductItemOffline";
import Suspend from "../Component/Pos/Suspend/Suspend";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { DB_WEB } from "../Config/DB_Web"; 
import { GET_ALL_PRODUCT, GET_PRODUCT_PAGINATION } from "../Schema/offlineproduct";
import { GET_ALL_CUSTOMER } from "../Schema/offlinecustomer";
import { CREATE_CARD } from "../Schema/offlinecard";
import { NumericFormat } from "react-number-format";
import SuspendOffline from "../Component/PosOffline/SuspendOffline/SuspendOffline";


export default function PosOfflinePage() {

  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);
  const [loadingCreateCard, setLoadingCreateCard] = useState(false);
  const [loadingSuspended,setLoadingSuspended] = useState(false); 
  const [refetchCardSuspend,setRefetchCardSuspend] = useState(false);

  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  const defaultCustomer = window.localStorage.getItem("defaultCustomer");
  let exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));
  const shop = JSON.parse(window.localStorage.getItem("shop"));

  //LINK TO VIEWPOS
  const navigate = useNavigate();
  const viewPos = () => navigate("/pos/view-pos");


  //SELECT CUSTOMER
  const [typeSell, setTypeSell] = useState("Retail");
  const [productQtyByIdData, setProductQtyByIdData] = useState(0);
  const [productItems, setProductItems] = useState([]);
  const [productIdScan, setProductIdScan] = useState("");
  const [category, setCategory] = useState({ title: "All menu" });
  const [totalDiscount, setTotalDiscount] = useState(0.0);
  const [customer, setCustomer] = useState([{ id: "", label: "" }]);
  const [selectedCustomer, setSelectedCustomer] = useState({ id: "", label: "" });

  const [receiptData, setReceiptData] = useState(null);

  //RECEIVE
  const [checkRequire, setCheckRequire] = useState(false);
  const [receiptDate, setReceiptDate] = useState(moment());
  const [totalAmount, setTotalAmount] = useState(0);


  //PAGINATOR
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(16);
  const [keyword, setKeyword] = useState("");
  const [saleQR,setSaleQR] = useState(""); 
  const [tableData, setTableData] = useState([]); 
  const [productForScan,setProductForScan] = useState([]);


  // ======================== QUERY PRODUCT SCAN  =======================
  useEffect( () => {   
    GET_ALL_PRODUCT().then( allItems => {
      setProductForScan(allItems); 
      setLoading(false);
    }) 
  },[]) 


  // ======================== QUERY PRODUCT SHOW  =======================
  useEffect( () => {
    GET_PRODUCT_PAGINATION(typeSell ,keyword, category , page , limit , saleQR)?.then((e) => { 
      setTableData(e);
    });
  },[typeSell ,keyword, category , limit , page , saleQR]) 


  //=========================== QUERY CUSTOMER ===================
  useEffect( () => {
    GET_ALL_CUSTOMER().then( getCustomers => {
      if (getCustomers) {
        let rows = [];
        getCustomers?.map( (e) => {
            let row = { id: e?._id, label: e?.name };
            rows.push(row); 
            if (e?._id === defaultCustomer) {
              setSelectedCustomer({ id: e?._id, label: e?.name });
            }
        });
        setCustomer(rows);
      } else {
        setCustomer([]);
      }
    });
  },[])


  //================= CLEAR BUTTON TOP RIGHT ======================
  const handleClear = () => {
    setProductItems([]);
    setProductIdScan(""); 
  };


  // ==================  CHANGE TYPESELL ====================
  const handleChangeTypeSell = (e) => { 
    setPage(1);
    setKeyword("");
    setSaleQR("");
    setLimit(limit);
    setTypeSell(e.target.value);
  };


  // ============= OPEN DIALOG PAYMENT ==============
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleClear();
  }
 


  // ================== DELETE ITEM =======================
  const handleDeleteItem = (product_id_setup) => {
    let filteredItems = productItems?.filter( (t) => t.product_id_setup !== product_id_setup ); 
    setProductItems(filteredItems);
  };


  // =================== UPDATE PRICE DISCOUNT ==================
  const handleTotalDiscount = () => {
    let amount = 0;
    productItems?.map((e) => {
        if ( e?.percentage_discount !== 0 && e?.percentage_discount !== undefined ) {
          amount += e?.percentage_discount * ((e?.price * e?.qty) / 100);
        }

        if (e?.amount_discount !== 0 && e?.amount_discount !== undefined) {
          amount += e?.amount_discount;
        }
    });
    setTotalDiscount(amount?.toFixed(2));
  };


  // ===================== UPDATE ITEM QTY =========================
  const handleUpdateAddQtyProductCard = (arrayItem, itemid) => {
    const items = arrayItem;     
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty += 1;
      }
    });
    setProductItems([...items]);  
    handleTotalDiscount();
  };

  const handleUpdateAddQtyByInputNumber = async (arrayItem,itemid,newQty) => { 
    const items = arrayItem;  
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty = newQty;
      }
    });
    setProductItems([...items]);
       
    handleTotalDiscount(); 
  };

  // =================== UPDATE REMOVE QTY ============================
  const handleUpdateRemoveQtyProductCard = (arrayItem, itemid) => {
    const items = arrayItem;
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty -= 1;
      }
    });
    setProductItems([...items]);
    handleTotalDiscount();
  };

  // ===================== CLICK ON CART TO ADD =====================
  const handleAddProductItem = (e) => {
    // console.log("e", e);
    setKeyword("");
    setSaleQR("");
    
    let newObject = {
      product_id_setup: e?._id,
      image_src: e?.image,
      name: e?.name,
      unit: e?.unit,
      qty: 1,
      qty_in_product: e?.qty,
      qty_in_product_unit: e?.qty_inUnit,
      price: e?.unit_Price,
      discount_id: null,
      percentage_discount: 0,
      amount_discount: 0,
      descriptions: e?.descriptions,
    };
  
    const newItem = productItems;

    if (newItem?.length !== 0) {
      let addArray = false;
      const BreakException = {};
      try {
        if (typeSell === "Retail") {
            // ====================== Retiail Product ============================
            newItem?.map( (elem) => {
              if (elem?.product_id_setup !== newObject?.product_id_setup) {
                addArray = true;
              } else {
                addArray = false;
                handleUpdateAddQtyProductCard( newItem , newObject?.product_id_setup);
                throw BreakException;
              }
            });
        } else {
            // ====================== Whole Product ============================
            newItem?.map( (elem) => {
              if (elem?.product_id_setup !== newObject?.product_id_setup) {
                addArray = true;
              } else {
                addArray = false;
                handleUpdateAddQtyProductCard(newItem , newObject?.product_id_setup);
                throw BreakException;
              }
            });
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      // ========== if true push into array ==========
      if (addArray) {
        newItem.push(newObject);
      }

    } else {
      newItem.push(newObject);
    }
    setProductItems([...newItem]);
    handleTotalDiscount();

  };


  // ================= UPDATE DISCOUNT =====================
  const updateDiscount = (itemId, valueDiscount) => {
    const items = productItems;
    items?.map((i) => {
      if (i.product_id_setup == itemId) {
        if (valueDiscount?.percent !== 0) {
          i.percentage_discount = valueDiscount?.percent;
          i.discount_id = valueDiscount?.id;
          i.amount_discount = 0;
        } else {
          i.amount_discount = valueDiscount?.amount;
          i.discount_id = valueDiscount?.id;
          i.percentage_discount = 0;
        }
      }
    });
    setProductItems([...items]);
    handleTotalDiscount();
  };

  // ============================== TOTAL AMOUNT ============================
  let subTotal = productItems?.reduce(function (a, b) {
    return a + b.price * b.qty;
  }, 0);

  useEffect(() => {
    if (totalDiscount !== 0) {
      setTotalAmount(subTotal - totalDiscount);
    } else {
      setTotalAmount(subTotal);
    }
  }, [subTotal, totalDiscount]);


  useEffect(() => {
    if (typeSell !== "Retail") {
      if (selectedCustomer?.id === null || selectedCustomer?.id === undefined) {
        setCheckRequire(true);
      } else {
        setCheckRequire(false);
      }
    }
  }, [selectedCustomer?.id]);


  useEffect( () => {
    setShowPage(page); 
  }, [typeSell, page, keyword, saleQR , limit, category?.title]);


  //=================== SCAN Product =============================
  const handleAddProScan = () => { 
      if (productForScan?.filter( (e) => e?.saleQR === productIdScan)?.length > 0) {
          let productScan = productForScan?.filter( (e) => e?.saleQR === productIdScan); 
          if (productScan) {
            handleAddProductItem(productScan[0]);
            setProductIdScan("");              
          }
      }
  };


  useEffect( () => {    
    if(productIdScan !== "" && productIdScan !== undefined) {
      handleAddProScan();
    }
  }, [productIdScan]);
  

  //============================ CREATE CARD ==========================
  
  const handleCreateCard = (suspended) => {

    const newValue = { 
      _id: Date.now(),    
      date: moment(receiptDate).format(),
      customer_id: selectedCustomer?.id,
      items: productItems,
      payment_status: "Unpaid",     
      sub_total: parseFloat((subTotal)?.toFixed(2)),
      discount_total: parseFloat(totalDiscount),
      tax_total: 0,
      total: parseFloat((totalAmount)?.toFixed(2)),
      exchange_rate: parseFloat(exChangeRate),
      shop_Id: shop?.id,
    };

    console.log("submit-value", newValue);
    
    //================== CREATE CARD =================
    CREATE_CARD(newValue).then( (res) => {
        if(res.status) {
            setAlert(true, "success" , res.message)
            setLoadingCreateCard(false);   
            setLoadingSuspended(false);
            setRefetchCardSuspend(true); 

            if(suspended === false) {
              setReceiptData(res?.data); 
              setTimeout( () => {
                handleOpen();
              }, 300);              
            } else {
              handleClear();
            }
            
        } else {
            setAlert(true, "error" , res.message)
            setLoadingCreateCard(false); 
            setLoadingSuspended(false);
        }
    });  
  };
 

  const scroll = useRef(null);
  const next = () => {
    scroll.current.scrollLeft += 180;
  };
  const previous = () => {
    scroll.current.scrollLeft -= 180;
  };

  useEffect( () => {
    setPage(1);
  },[keyword])

  return (
    <div className="pos-page">
      <Grid container spacing={4}>

        {/* LEFT SIDE */}
        <Grid item xs={12} sm={12} md={6} lg={5} xl={7}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="column" justifyContent="center">
              <IconMenuResponsive />
            </Stack>

            <Stack direction="column" justifyContent="center">
              <Box className="slash" />
            </Stack>

            <Stack direction="column" justifyContent="center">
              <Typography className="page-title">POS</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <TopRightBar />
          </Stack>

          {/* MENU BAR */}
          <Grid container spacing={3} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} className="search-container">
              <Grid container spacing={2}>     

                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search" width="100%">
                    <FormControl  fullWidth  className="text-field">
                      <Select
                        value={typeSell}
                        size="small"
                        onChange={handleChangeTypeSell}
                      >
                        <MenuItem value={"Retail"}>Retail</MenuItem>
                        <MenuItem value={"Wholesale"}>Wholesale</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>


                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search">
                    <Stack direction="column" width="100%">
                      <TextField
                        value={keyword}
                        className="text-field"                        
                        id="input-with-sx"
                        placeholder="Search"
                        size="small"
                        onChange={ (e) => setKeyword(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 1 }}>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search">
                    <Stack direction="column" width="100%">
                      <TextField
                        value={saleQR}
                        className="text-field"                        
                        id="input-with-sx"
                        placeholder="Barcode"
                        size="small"
                        onChange={ (e) => setSaleQR(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 1 }}>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}  className="add-container">
                  <Stack direction='row' justifyContent='end' width="100%">
                    <Button          
                      fullWidth            
                      onClick={viewPos}
                      className="btn-add"
                      startIcon={<VisibilityOutlinedIcon className="icon-add" />}                      
                    >
                      ViewPos
                    </Button>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}  className="add-container">
                  <SuspendOffline cardSuspend={refetchCardSuspend} setCardSuspend={setRefetchCardSuspend}/>
                </Grid>

              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography className="category">Category</Typography>
              <Stack direction={"row"} justifyContent="end" mt={"5px"}>
                <Stack direction={"row"} spacing={2}>                  
                  <IconButton onClick={previous}>
                    <NavigateBeforeIcon />
                  </IconButton>
                  <IconButton onClick={next}>
                    <NavigateNextIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <Box className="category-style">
              <Stack className="scroll-container" ref={scroll}>
                { listCategory?.map( (e, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      setCategory({ title: e?.label });
                      setPage(1);
                    }}
                    className="item-box"
                  >
                    <Stack direction="column" width="100%">
                      <Stack direction="row" justifyContent="center">
                        <Typography className="menu-icon">{e?.icon}</Typography>
                      </Stack>
                      <Stack
                        className={ category?.title === e?.label ? "active" : "no-active" }
                      >
                        <Typography variant="body2" mt={"3px"}>{e?.label}</Typography>
                      </Stack>
                    </Stack>
                  </Button>
                ))}
              </Stack>
            </Box>

            <Box className="category">
              Product
            </Box>


            <Box className="tabs-container"> 
          
              { loading ? (
                <Stack direction="column" justifyContent="center" height={400}>
                  <LoadingPage />
                </Stack>
              ) : (
                <>
                  { tableData?.length === 0 ? (
                    <Stack  direction="column"  justifyContent="center"  height={300}>
                      <EmptyData />
                    </Stack>
                  ) : (
                    <Grid container spacing={2}>
                      { tableData?.map((data, index) => (
                          <Grid item  xs={3} sm={3} md={3} lg={3} xl={2} key={index}>
                              <Button
                                className="btn-pos"                                    
                                onClick={ () => handleAddProductItem(data,data?.total_inShop) }
                              >                                    
                                <Stack direction="column" width="100%">
                                  <Stack  direction="row" justifyContent="center" className="img-container">
                                    {data?.image === "" || data?.image === null ? (
                                      <HideImageOutlinedIcon
                                        className="product-img"
                                        sx={{ color: "#757575" }}
                                      />
                                    ) : (
                                      <img
                                        className="product-img"
                                        alt="Image"
                                        src={data?.image ? data?.image : "" }
                                      />
                                    )}
                                  </Stack>

                                  <Stack
                                    className="card-body"                                        
                                    direction="column"
                                    justifyContent="top"
                                  >

                                    <Typography className="product-name">
                                      {data?.name}
                                    </Typography>

                                    { data?.descriptions ?
                                      <Typography className="product-name">
                                        <span style={{ color:"white" , backgroundColor: "red" , padding: 3 , borderRadius: "5px"}}>
                                          {data?.descriptions}
                                        </span>
                                      </Typography>
                                    : null }

                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Typography className="product-price">
                                        ${data?.unit_Price}     
                                      </Typography>
                                      <Typography className="unit-name"> 
                                        {data?.unit}
                                      </Typography>
                                    </Stack>

                                  </Stack>
                                </Stack>
                              </Button>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </>
              )} 

            </Box> 


            <Stack
              mt={2}
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={2}
            >              
              <Stack
                direction="row"
                sx={{ bgcolor: "#fff", padding: "5px", borderRadius: "10px" }}
                alignItems="center"
                spacing={2}
              >
                <Pagination                  
                  page={showPage}
                  hideNextButton={false}
                  hidePrevButton={false}
                  count={parseInt(productForScan?.length/limit)}
                  color="primary"
                  variant="outlined"                                  
                  onChange={(event, pageNum) => setPage(parseInt(pageNum)) }
                />

                <Select
                  sx={{ width: "105px" }}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  size="small"
                  value={limit} 
                  onChange={(e) => {
                    setLimit(e.target.value)
                    setPage(1)
                  }}
                >
                  <MenuItem value={6}>6/Page</MenuItem>
                  <MenuItem value={8}>8/Page</MenuItem>
                  <MenuItem value={16}>16/Page</MenuItem>
                  <MenuItem value={20}>20/Page</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          {/* END LEFT GRID */}
        </Grid>


        {/* RIGHT SIDE 2 */}
        <Grid item xs={12} sm={12} md={6} lg={7} xl={5}>
          <Box className="order-container">
            <Button
              onClick={handleClear}
              className="clear-btn"              
            >
              Clear
            </Button>
            <Stack direction="column">
              <Typography className="order-title">Current Order</Typography>
              <Box mb={2}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={6} xl={6}>
                    <Typography className="order-subtitle">
                      Receipt Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={receiptDate}
                        onChange={(e) => setReceiptDate(e)}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="text-field"
                            size="small"
                            
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="order-subtitle">
                      Select Customer                    
                    </Typography>
                    <Autocomplete
                      id="customer-pos"
                      freeSolo
                      clearOnBlur
                      disablePortal
                      size="small"
                      options={customer}
                      value={selectedCustomer}
                      isOptionEqualToValue={ (option, value) => option.id === value.id || value === "" }
                      getOptionLabel={ (option) => option?.label ? option?.label : "" }
                      onChange={(event, value) => setSelectedCustomer(value)}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Customer"
                          error={selectedCustomer === null}
                          helperText={selectedCustomer === null && 'require'}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="order-subtitle">
                      Enter product:
                    </Typography>
                    <TextField
                      autoFocus={true}
                      autoComplete="off"
                      placeholder="cursor here to scan"
                      fullWidth
                      size="small"
                      value={productIdScan}                      
                      onChange={ (e) => {
                        setSaleQR(e?.target?.value)
                        setProductIdScan(e?.target?.value) 
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>


              {/* =============== product item card ============ */}
              <ProductItemOffline
                productItems={productItems}
                handleUpdateAddQtyProductCard={handleUpdateAddQtyProductCard}
                handleDeleteItem={handleDeleteItem}
                productQtyByIdData={productQtyByIdData}
                handleUpdateRemoveQtyProductCard={handleUpdateRemoveQtyProductCard}
                handleUpdateAddQtyByInputNumber={handleUpdateAddQtyByInputNumber}
                typeSell={typeSell}
                updateDiscount={updateDiscount}
              />

              <Stack direction="column" spacing={1} className="total-container">
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="sub-total">Subtotal</Typography>
                  <Typography className="sub-total">
                    $ {subTotal?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="sub-total">Discount</Typography>
                  </Stack>
                  <Typography className="sub-total">
                    $ {totalDiscount}
                  </Typography>
                </Stack>
                <Box className="underline" />
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="total">Total</Typography>
                  <Typography className="total">
                    $ {totalAmount?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="right">
                  <Typography className="total-kh">
                    <NumericFormat 
                        value={(totalAmount * exChangeRate)?.toFixed(0)} 
                        thousandSeparator={true} 
                        displayType={'text'} 
                        renderText={(value, props) => <div {...props}>៛ {value}</div>}
                    /> 
                  </Typography>
                </Stack>
              </Stack>



              <Stack direction="row" justifyContent="center" spacing={2} mt={1}>

                  <Box sx={{width: "40%"}}>
                    {
                      loadingSuspended ?
                        <Button 
                          fullWidth 
                          className="btn-payment"
                          startIcon={<AddShoppingCartOutlinedIcon />}
                        >
                          Loading...
                        </Button>
                      :
                        <Button
                          className="btn-payment"
                          fullWidth
                          disabled={ productItems?.length === 0 || selectedCustomer === null ? true : false}
                          onClick={ () => {  
                            setLoadingSuspended(true)
                            setTimeout( () => {
                              handleCreateCard(true);
                            },300)                            
                          }}
                          startIcon={<AddShoppingCartOutlinedIcon />}
                        >
                            Suspended
                        </Button>
                    } 
                  </Box>


                  { loadingCreateCard ? (
                    <Button 
                      fullWidth 
                      className="btn-payment"
                      startIcon={<PaidOutlinedIcon />}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      className="btn-payment"
                      disabled={productItems?.length === 0 || selectedCustomer === null ? true : false}
                      onClick={() => {
                        handleCreateCard(false);
                        setLoadingCreateCard(true); 
                      }}
                      startIcon={<PaidOutlinedIcon />}
                    >
                      Continue to Payment
                    </Button>
                  )}
              
              </Stack>



              <PrintProductItemOffline
                open={open}
                receipt={receiptData}
                handleClose={handleClose}
                type={"createPos"}                 
              />

            </Stack>
          </Box>
        </Grid>
      
      </Grid>
    </div>
  );
}
//category list
const listCategory = [
  { label: "All menu", icon: "📦" },
  { label: "Food", icon: "🍔" },
  { label: "Meat", icon: "🍖" },
  { label: "Drink", icon: "🍸" },
  { label: "Vegetables", icon: "🥒" },
  { label: "Fruit", icon: "🍎" },
  { label: "Cosmetic", icon: "🎒" },
  { label: "Snacks", icon: "🍱" },
  { label: "Spices", icon: "🔥" },
  { label: "Soap", icon: "🍵" },
  { label: "Ice Cream", icon: "🍦" },
  { label: "Preserved Food", icon: "🍣" },
  { label: "Daily Use", icon: "🛍" },
  { label: "Rice", icon: "🍚" },
  { label: "Other", icon: "..." },
];
