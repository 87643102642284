import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Modal,
  TextField,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";

import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import { FooterPagination } from "../../../Dymanic/FooterPagination";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";
import TopRightBar from "../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../Menu/IconMenuResponsive";
import {
  GET_PRODUCT_SETUP_WITH_PAGINATION,
  GET_ALL_CATEGORY,
} from "../../../Schema/setting";
import { useQuery } from "@apollo/client";
import LoadingPage from "../../../Component/Include/LoadingPage";
// import ViewQrCode from './ViewQrCode'

//component
import AddSale from "./AddSale";
import PreviewSale from "./PreviewSale";
import SubTableSale from "./SubTableSale";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={2} square {...props} />
))(({ theme }) => ({
  borderBottom: `0.2px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "block",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", marginRight: "5px" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginRight: theme.spacing(4),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "0.2px solid rgba(0, 0, 0, .125)",
}));


export default function TableSale() {

  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [proId, setProId] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [productFromTable,setProductFromTable] = useState({
    id: "",
    label: "",
    unitPrice: "",
    unit: "",
    qty_inUnit: "",
    image: ""
  })

  //open preview
  const [row, setRow] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const handleOpenPreview = (row) => {
    setOpenPreview(true);
    setRow(row);
  };
  const handleClosePreview = () => setOpenPreview(false);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);

  //page
  const [showPage, setShowPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [rowData, setRowData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [paginationStatus,setPaginationStatus] = useState(true);
  const { data: allCategory } = useQuery(GET_ALL_CATEGORY);

  //MAIN PRODUCT
  const { data, refetch } = useQuery(GET_PRODUCT_SETUP_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: paginationStatus,
      category: category,
    },
    onCompleted: ({ getProductSetUpWithPagination }) => {
      setRowData(getProductSetUpWithPagination?.data);
      setPagination(getProductSetUpWithPagination?.paginator);
      // console.log(getProductSetUpWithPagination?.data?.length);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    if(e.target.value === 0) {
      setPaginationStatus(true)
      setPage(1)
    } else {
      setPaginationStatus(true)
      setPage(1)
    }
  };

  useEffect(() => {
    refetch();
    setShowPage(page);
    setExpanded("");
  }, [page, keyword, category, limit]);

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel, rowData) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setProId(rowData?._id);
    // console.log(rowData)
    setProductFromTable({
      id: rowData?._id,
      label: rowData?.name,
      unitPrice: rowData?.cost,
      unit: rowData?.unit,
      qty_inUnit: rowData?.qty_inUnit,
      image: rowData?.image_src,
    })
  };


  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // console.log('rowData',rowData)

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Link style={{ textDecoration: "none" }} to="/setting">
              <Typography className="title-active">Setting</Typography>
            </Link>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Sale</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              placeholder="search"
              size="small"
              className="text-field"
              onChange={(e) => {
                setKeyword(e.target.value);
                setPage(1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Box className="box-search">
              <FormControl fullWidth className="text-field">
                <InputLabel id="demo-controlled-open-select-label">
                  {" "}
                  All
                </InputLabel>
                <Select
                  value={category}
                  size="small"
                  label="All"
                  onChange={(event) => {
                    setCategory(event.target.value);
                    setPage(1);
                  }}
                  IconComponent={(props) => (
                    <KeyboardArrowDownOutlinedIcon {...props} />
                  )}
                >
                  <MenuItem value="">
                    <em>all</em>
                  </MenuItem>
                  {allCategory?.getCategorise?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={4}
            lg={3}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
              },
            }}
          ></Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={7}
            sx={{
              display: { lg: "flex", md: "flex" },
              justifyContent: "right",
            }}
          >
            {/* <Button
              onClick={handleOpen}
              className="btn-add"
              startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            >
              Add Sale
            </Button> */}

            <Modal open={open} onClose={handleClose}>
              <AddSale
                t={t}
                language={language}
                handleClose={handleClose}
                open={open}
                setRefetch={refetch}
                productFromTable={productFromTable}
              />
            </Modal>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell width="5%" className="header-title">
                  {t("no")}
                </TableCell>
                <TableCell width="25%" className="header-title">
                  {t("product")}
                </TableCell>
                <TableCell width="13%" className="header-title">
                  {t("category")}
                </TableCell>

                <TableCell width="13%" className="header-title"> 
                  Unit Price
                </TableCell>

                <TableCell width="12%" className="header-title">
                  {t("sale-type")}
                </TableCell>

                <TableCell width="10%" className="header-title">
                  {t("other")}
                </TableCell>

                <TableCell width="5%" className="header-title">
                  {/* {t("product-status")} */}
                </TableCell>

                <TableCell align="right" className="header-title">
                  {t("sale-set-up")}
                </TableCell>               

              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {rowData?.map((row, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`, row)}
              >
                <AccordionSummary
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}
                >
                  <Stack direction="row" width="100%">
                    <Typography>{row?.name}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                      sx={{
                        color: row?.set_sale_status === "yes" ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {row?.set_sale_status}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <SubTableSale id={proId} handleOpen={handleOpen}/>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        <PreviewSale
          open={openPreview}
          handleClose={handleClosePreview}
          data={row}
        />

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          showPage={showPage}
          totalPages={pagination?.totalPages}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          componentName={"tableSetupSale"}
        />
      </Box>
    </div>
  );
}
