import React, { useState, useEffect } from "react";
import "./toprightbar.scss";
import {
  Avatar,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Skeleton,
  Button,
} from "@mui/material";
import { HiOutlineUserCircle } from "react-icons/hi";
//Srcs
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import CamFlag from "../Assets/Cambodia.png";
import EngFlag from "../Assets/United_Kingdom.png";
import { useQuery } from "@apollo/client";
import { GET_USER_LOGIN } from "../Schema/users";
import shiftIcon from "../Assets/shift.png";

export default function TopRightBar() {
  
  // Change Language
  const { language , changeLanguage } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  // ================= Change Language =======================
  const { connectMode } = React.useContext(AuthContext);

  const [langauge, setLanguage] = useState("en");
  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    setLanguage(lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    let getLaung = window.localStorage.getItem("language");
    if (getLaung) {
      setLanguage(getLaung);
      changeLanguage(getLaung);
      return;
    } else {
      setLanguage("en");
      changeLanguage("en");
      window.localStorage.setItem("language", "en");
      return;
    }
  }, []);


  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      window.localStorage.setItem("userLogin", JSON.stringify(getUserLogin));
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  let mode = JSON.parse(window.localStorage.getItem("onlineMode"))

  return (
    <>
        
        <Stack direction="column" justifyContent="center" spacing={2} className="top-bar">
          <Button  
            sx={{
              background: mode === true ? "green" : "orange", 
              borderRadius: 3, 
              height: "70%",
              paddingRight: 2,
              paddingLeft: 2,
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={ () => {
              window.localStorage.setItem("onlineMode" , !mode)
              window.location.reload();
            }}
          > 
              <Avatar
                src={shiftIcon}
                sx={{ width: "25px", height: "25px"}}
              /> 
              <Typography sx={{fontWeight: "bold" , color: "white" , marginLeft: 1}}>
                { mode ? "Online" : "Offline" }
              </Typography>
          </Button>
        </Stack> 


        
      { loading ? (
        <Stack direction="row" spacing={2} className="top-bar">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} className="top-bar">
          <Stack direction="column" justifyContent="center">
            {langauge === "en" ? (
              <Tooltip title="Change to Khmer" placement="left">
                  <IconButton onClick={() => handleChangeLanguage("kh")}>
                      <Avatar
                        src={EngFlag}
                        sx={{ width: "25px", height: "25px" }}
                      />
                  </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Change to English" placement="left">
                  <IconButton onClick={() => handleChangeLanguage("en")}>
                      <Avatar
                        src={CamFlag}
                        sx={{ width: "25px", height: "25px" }}
                      />
                  </IconButton>
              </Tooltip>
            )}
          </Stack>

          <Stack direction="column" justifyContent="center">
              <Avatar
                src={`${data?.getUserLogin?.image_src}`}
                alt={data?.getUserLogin?.firsName}
              />
          </Stack>

          <Stack direction="column" justifyContent="center">
            <Typography className="user-name">             
              {data?.getUserLogin?.firsName}
            </Typography>
            <Typography className="user-role">              
              {data?.getUserLogin?.lastName}
            </Typography>
          </Stack>

        </Stack>
      )}
    </>
  );
}
