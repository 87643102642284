import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  Avatar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//components
import "./forgotpassword.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../Context/AuthContext";
import app from "../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { GrUnlock } from "react-icons/gr";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);

  const [iconReload, setIconReload] = useState(false);

  const auth = getAuth(app);
  // console.log("auth::", auth)

  const forgotSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email!").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          setAlert(true, "success", "Please check message in your email!");
          setTimeout(() => {
            setIconReload(false);
            navigate("/");
          }, 1200);
        })
        .catch((error) => {
          setAlert(true, "error", "Invalid Email/Password!");
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="forgot-page">
            <Box className="background-image" />
            <Stack direction="row" sx={{ ml: 10 }} className="back-btn">
              <Stack direction="column" justifyContent="center">
                <IconButton id="back-button" onClick={() => navigate("/login")}>
                  <ArrowBackIcon className="icon-arrow" />
                </IconButton>
              </Stack>

              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                className="text-back"
              >
                <label for="back-button">
                  <Typography className="title-back" align="center">
                    Back
                  </Typography>
                </label>
              </Stack>
            </Stack>

            <Box className="container">
              <Box className="form-forgot">
                <Box className="box-logo">
                  {/* <Avatar
                      sx={{ width: 100, height: 100 }}
                      variant="square"
                      alt="logo"
                      src={"ad"} />*/}
                  <LockOpenOutlinedIcon className="icon-lock"/>
                 
                </Box>

                <Box className="box-text" sx={{ mt: 1 }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    spacing={1}
                    className="title-login"
                  >
                    <Typography className="title">
                      Welcome to POS System Mart
                    </Typography>
                    <Typography className="sub-title" variant="body2">
                      Please input your email to recieve a link in order to
                      change password!
                    </Typography>
                  </Stack>
                </Box>

                <Box className="box-login" sx={{ mt: 3 }}>
                  <Stack direction="column" justifyContent="center" spacing={2}>
                    <TextField
                      className="text-field"
                      id="outlined-size-small"
                      size="small"
                      placeholder="example@company.com"
                      fullWidth
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      className="btb-sign-in"
                      type="submit"
                      endIcon={
                        iconReload ? (
                          <CircularProgress
                            sx={{ color: "#0f81c2" }}
                            size="20px"
                          />
                        ) : null
                      }
                    >
                      send
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="body2"
              align="center"
              color="#000"
              sx={{ letterSpacing: "2px" }}
            >
              @Copyright 2023, Go Global IT
            </Typography>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
