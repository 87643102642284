import React, { useContext, useEffect, useState } from "react";
import "../Create/createwholesale.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
    Grid,
    Stack,
    Box,
    Button,
    TextField,
    Divider,
    InputAdornment,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Avatar,
    FormHelperText,
} from "@mui/material";
//Icons
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
//src
import { AuthContext } from "../../../../Context/AuthContext";
import CropImageFile from "../../../CropImage/CropImageFile";
//Schema
import {SelectProduct,SelectUnit} from "../../../../Dymanic/DynamicComponents";

export default function DeleteWholesale({ open, handleClose, setRefetch, editData }) {

    // console.log('editData', editData)

    const { setAlert } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [productVal, setProductVal] = useState({ id: "", title: "" });
    const [unitVal, setUnitVal] = useState({ id: "", title: "" });


    // upload Imagesss
    const [profileHook, setProfileHook] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [photoURL, setPhotoURL] = useState(null);
    const [openCrop, setOpenCrop] = useState(false);

    const handleUploadImage = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setImageFile(imageFile);
            setPhotoURL(URL.createObjectURL(imageFile));
            setOpenCrop(true);
        }
    };

    //fromik
    const AddCategory = Yup.object().shape({
        // name: Yup.string().required("require!"),
        unit: Yup.string().required("require!"),
        retail_product_id: Yup.string().required("require!"),
        unit_price: Yup.number().min(1).required("require!"),
        retail_product_number: Yup.number().min(1).required("require!"),
        remark: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            // name: "",
            remark: "",
            retail_product_id: "",
            unit_price: 1,
            unit: "",
            retail_product_number: 1,
        },

        validationSchema: AddCategory,
        onSubmit: async (fieldValues) => {
            setLoading(true);
            // console.log("fieldValues::", fieldValues);

          
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        // values,
    } = formik;

    useEffect(() => {
        if (unitVal?.id !== undefined) {
            setFieldValue("unit", unitVal?.id);
        } else {
            setFieldValue("unit", "");
        }
        if (productVal?.id !== undefined) {
            setFieldValue("retail_product_id", productVal?.id);
        } else {
            setFieldValue("retail_product_id", "");
        }
    }, [unitVal?.id, productVal?.id]);

    useEffect(() => {
        setFieldValue('retail_product_number', editData?.retail_product_number)
        // setFieldValue('name', editData?.name)
        setFieldValue('remark', editData?.remark)
        setFieldValue('unit_price', editData?.unit_price)
        setProfileHook(editData?.image_src)
        setProductVal({ id: editData?.retail_product_id?._id, title: editData?.retail_product_id?.name })
        setUnitVal({ id: editData?.unit?._id, title: editData?.unit?.name })
    }, [editData]);

    return (
        <Dialog open={open} onClose={handleClose} className="create-category">
            <DialogTitle className="dialog-title">
                <Stack direction="row" spacing={2}>
                    <Typography className="title">Update Wholesell</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClose}>
                        <CloseIcon className="clear-icon" />
                    </IconButton>
                </Stack>
                <Divider />
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <FormikProvider value={formik}>
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container rowSpacing={2} columnSpacing={3}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {!openCrop ? (
                                            <Box>
                                                <Stack
                                                    className="profile"
                                                    direction="row"
                                                    justifycontent="center"
                                                >
                                                    <IconButton component="label">
                                                        <TextField
                                                            type="file"
                                                            id="image"
                                                            sx={{ display: "none" }}
                                                            onChange={handleUploadImage}
                                                        />
                                                        <Avatar
                                                            className="avater-image"
                                                            src={
                                                                imageFile ? URL.createObjectURL(imageFile) : profileHook
                                                            }
                                                        > <PhotoSizeSelectActualOutlinedIcon
                                                                sx={{ fontSize: "70px" }}
                                                            /></Avatar>
                                                        <input type="file" hidden />
                                                    </IconButton>
                                                </Stack>

                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    sx={{ mt: 1 }}
                                                >
                                                    <Typography className="field-title">Profile</Typography>
                                                </Stack>
                                            </Box>
                                        ) : (
                                            <CropImageFile
                                                setImageFile={setImageFile}
                                                photoURL={photoURL}
                                                setOpenCrop={setOpenCrop}
                                                setPhotoURL={setPhotoURL}
                                                setUploadHook={setProfileHook}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Typography className="field-title">Name</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        multiline
                                        placeholder="name"
                                        {...getFieldProps("name")}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid> */}


                                <Grid item xs={6}>
                                    <Typography className="field-title">Product Name</Typography>
                                    <SelectProduct
                                        selectedValue={productVal}
                                        setValues={setProductVal}
                                    />
                                    {!!errors.retail_product_id && touched.retail_product_id && (
                                        <FormHelperText error id="outlined-adornment-password">
                                            {errors.retail_product_id}
                                        </FormHelperText>
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography className="field-title">Wholesale price</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Price"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                type: "number",
                                                min: 1,
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalAtmOutlinedIcon
                                                        sx={{ fontSize: "2xl", color: "green" }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...getFieldProps("unit_price")}
                                        error={Boolean(touched.unit_price && errors.unit_price)}
                                        helperText={touched.unit_price && errors.unit_price}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography className="field-title">Unit</Typography>
                                    <SelectUnit selectedValue={unitVal} setValues={setUnitVal} />
                                    {!!errors.unit && touched.unit && (
                                        <FormHelperText error id="outlined-adornment-password">
                                            {errors.unit}
                                        </FormHelperText>
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography className="field-title">Quantity per box</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            inputProps: {
                                                type: "number",
                                                min: 1,
                                            },
                                        }}
                                        placeholder=""
                                        {...getFieldProps("retail_product_number")}
                                        error={Boolean(touched.retail_product_number && errors.retail_product_number)}
                                        helperText={touched.retail_product_number && errors.retail_product_number}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography className="field-title">Remark</Typography>
                                    <Stack>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            multiline
                                            placeholder="remark"
                                            {...getFieldProps("remark")}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                {loading ? (
                    <Button fullWidth className="btn-action">
                        Loading...
                    </Button>
                ) : (
                    <Button fullWidth className="btn-action" onClick={handleSubmit}>
                        Update
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
