import React, { useState, useContext } from "react";
import "../../src/Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import { BsBoxSeam } from "react-icons/bs";
import TopRightBar from "../Menu/TopRightBar";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import { useNavigate } from "react-router-dom";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";

export default function Setting() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const navigate = useNavigate();

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Setting</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container spacing={4} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={() => navigate("/setting/exchang-rate")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <CurrencyExchangeOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Exchange Rate
                </Typography>
                <Typography variant="capitle">Set Exchange rate</Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={() => navigate("/setting/sale")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <InventoryOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  SetUp Sale
                </Typography>
                <Typography variant="capitle">
                  You're can update & delect information sale.
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={() => navigate("/setting/discount")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <DiscountOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Discount
                </Typography>
                <Typography variant="capitle">
                  Let's make our customer day
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={() => navigate("/setting/shop")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <StoreOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Setup Shop
                </Typography>
                <Typography variant="capitle">Setup and edit shop</Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={() => navigate("/setting/defalutcustomer")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <PersonOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Setup default Customer
                </Typography>
                <Typography variant="capitle">
                  Choose your default Customer
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={ () => navigate("/setting/adjustqty")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <StoreOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Warehouse Mart
                </Typography>
                <Typography variant="capitle">Adjust qty product</Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={ () => navigate("/setting/offline_setup")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <OfflineBoltOutlinedIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Offline Setup
                </Typography>
                <Typography variant="capitle">Get and Create offline.</Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            className="btn-set"
            size="small"
            onClick={ () => navigate("/setting/advertise_setup")}
          >
            <Stack className="stack-content" direction={"row"} spacing={2}>
              <Stack className="icon-box">
                <CampaignIcon className="icon" />
              </Stack>
              <Stack className="title">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  Advertise Setup
                </Typography>
                <Typography variant="capitle">Get and Create advertise.</Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>


      </Grid>
    </div>
  );
}
