import React, { useContext, useEffect, useState } from "react";
import "./report.scss";
import { Typography, Stack, Grid } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_CLOSE_SHIFT, GET_CLOSE_SHIFT_REPORT, GET_LAST_CLOSE_SHIFT, REPORT_CASH_TRANSITION_BY_DAY, REPORT_GET_CURRENT_SHIFT } from "../../Schema/report";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";
import { AuthContext } from "../../Context/AuthContext";


export default function CloseMoney({ setCloseShiftData , loading }) {

  
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const moneyType = [
    "100,000",
    "50,000",
    "30,000",
    "20,000",
    "15,000",
    "10,000",
    "5,000",
    "2,000",
    "1,000",
    "500",
    "100",
  ];


  //=================== GET LAST CLOSE SHIFT =======================
  const [fromData,setFromDate] = useState("");
  const { refetch: refetchLastShift } = useQuery(GET_LAST_CLOSE_SHIFT, {
    onCompleted: ({getLastCloseSale}) => {
        console.log("getLastCloseSale:::",getLastCloseSale)
        if(getLastCloseSale) {  
          setFromDate(getLastCloseSale)
        } else { 
          setFromDate("")
        } 
    },
    onError: (error) => {
      console.log(error.message)
    }
  })

  useEffect( () => {
    refetchLastShift()
  },[loading])


  //
  const [rowData, setRowData] = useState({});
  const { refetch } = useQuery(REPORT_GET_CURRENT_SHIFT, { 
    onCompleted: ({ getCurrentShift }) => {
      console.log("getCurrentShift::::",getCurrentShift)
      setRowData(getCurrentShift);
    },
  });

  useEffect( () => {
    refetch()
  },[fromData]) 

  
  useEffect( () => {

    let transactions = {
        income_amount: rowData?.income_amount?.map( (rowInc) => { 
          return {
            amount_kh: rowInc?.amount_kh,
            amount_usd: rowInc?.amount_usd,
            description: rowInc?.description
          }
        }),
        final_amount: {
          amount_kh: rowData?.final_amount?.amount_kh,
          amount_usd: rowData?.final_amount?.amount_usd,
        },
        expense_amount: rowData?.expense_amount?.map( (rowExp) => { 
          return {
            amount_kh: rowExp?.amount_kh,
            amount_usd: rowExp?.amount_usd,
            description: rowExp?.description
          }
        }),
        repay_amount: rowData?.repay_amount?.map( (rowRew) => { 
          return {
            amount_kh: rowRew?.amount_kh,
            amount_usd: rowRew?.amount_usd,
            description: rowRew?.description
          }
        }),
        total_actual_income: {
          amount_usd: rowData?.total_actual_income?.amount_usd,
          amount_kh: rowData?.total_actual_income?.amount_kh,
        },
        total_expense: {
          amount_kh: rowData?.total_expense?.amount_kh,
          amount_usd: rowData?.total_expense?.amount_usd,
        },
        total_repay: {
          amount_kh: rowData?.total_repay?.amount_kh,
          amount_usd: rowData?.total_repay?.amount_usd,
        },
        total_return_of_repay_amount: {
          amount_kh: rowData?.total_return_of_repay_amount?.amount_kh,
          amount_usd: rowData?.total_return_of_repay_amount?.amount_usd
        }
    }
    
    setCloseShiftData({
        banknote: {
          riels: [],
          dollars: [],
        },
        cash_transactions: transactions,
        close_by: userLogin?._id, 
        close_date: rowData?.close_date,
        start_date: rowData?.start_date,
    })

  },[rowData]) 
   

  
  return (
    <div className="money-content">

      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">តារាងបិទលុយប្រចាំថ្ងៃ</Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography className="report-date"> 
              {getKhmerFullDate(moment(rowData?.start_date).utc(false))}  {moment(rowData?.start_date)?.utc(false)?.format("HH:mm a")}
            </Typography>
            <Typography className="report-date"> 
              - 
            </Typography>
            <Typography className="report-date"> 
              {getKhmerFullDate(moment(rowData?.close_date))}  {moment(rowData?.close_date)?.utc(false)?.format("HH:mm a")}
            </Typography>
        </Stack> 
      </Stack>
 
      <Grid container spacing={1} mt={"5px"}>
        <Grid item xs={6}>
          <table className="left-table">

            <thead className="thead">
              <tr className="">
                <th className="th" colSpan="3">ប្រតិបត្តិការសាច់ប្រាក់</th>
              </tr>
              <tr className="">
                <th>បរិយាយ</th>
                <th>រៀល</th>
                <th>ដុល្លារ</th>
              </tr>
            </thead>

            <tbody>

              {
                rowData?.income_amount?.map( (rowIncome,index) => (
                  <tr className="" key={index}>
                    <td className="td">{rowIncome?.description}</td> 
                    <td className="center">{rowIncome?.amount_kh ? rowIncome?.amount_kh : "0"} ៛</td>
                    <td className="center">{rowIncome?.amount_usd?.toFixed(2)} $</td>
                  </tr>
                ))
              }
            

              <tr className="total-revenue">
                <td>ទឹកប្រាក់នៅសល់</td>
                <td className="blue">{rowData?.total_actual_income?.amount_kh} ៛</td>
                <td className="blue">{rowData?.total_actual_income?.amount_usd?.toFixed(2)} $ </td>
              </tr> 

            {/* =====================  Repay ======================= */}
              <tr className="total-revenue">
                <td>ទឹកប្រាក់ចូលសរុប​​(អតិថិជនសង)</td>
                <td className="blue">{rowData?.total_repay?.amount_kh} ៛</td>
                <td className="blue">{rowData?.total_repay?.amount_usd?.toFixed(2)} $ </td>
              </tr> 
               
            {
              rowData?.repay_amount?.map( (elem,index) => (
                <tr className="" key={index}>
                  <td style={{paddingLeft:"15px"}}>{elem?.description}</td>
                  <td className="center">{elem?.amount_kh}</td>
                  <td className="center">{elem?.amount_usd}</td>
                </tr>
              ))
            }       

            {/* =====================  change Repay ======================= */}
              <tr className="total-revenue">
                <td>ទឹកប្រាក់អាប់ចូលសរុប​(អតិថិជនសង)</td>
                <td className="blue">{rowData?.total_return_of_repay_amount?.amount_kh} ៛</td>
                <td className="blue">{rowData?.total_return_of_repay_amount?.amount_usd?.toFixed(2)} $ </td>
              </tr> 
              

            {/* =================== Owe Amount ====================== */}
            <tr className="total-disbursement">
              <td>ទឹកប្រាក់ជំពាក់សរុប</td>
              <td className="blue">{0} ៛</td>
              <td className="blue">{0.00} $</td>
            </tr>


            {/* =================== expends ====================== */}
              <tr className="total-disbursement">
                <td>ទឹកប្រាក់ចេញសរុប</td>
                <td className="blue">{rowData?.total_expense?.amount_kh} ៛</td>
                <td className="blue">{rowData?.total_expense?.amount_usd} $</td>
              </tr>

            {
              rowData?.expense_amount?.map( (elem,index) => (
                <tr className="" key={index}>
                  <td style={{paddingLeft:"15px"}}>{ elem?.description }</td>
                  <td className="center">{ elem?.amount_kh } ​៛</td>
                  <td className="center">{ elem?.amount_usd } $</td>
                </tr>
              ))              
            }             
             
            {/* <tr className="">
                <td>ដូរលុយ</td>
                <td className="center">40000 ៛</td>
                <td className="center">$(10.00)</td>
              </tr> */}

              <tr className="amount-after-payment">
                <td>ទឹកប្រាក់ក្រោយការទូទាត់</td>
                <td className="center">{rowData?.final_amount?.amount_kh} ៛</td>
                <td className="center">{rowData?.final_amount?.amount_usd} $</td>
              </tr>
            </tbody>
          </table>

        </Grid>

        <Grid item xs={6}>
          <table className="right-table">
            <thead>
              <tr>
                <th colSpan="6">ក្រដាស់ប្រាក់</th>
              </tr>
              <tr>
                <th>រៀល</th>
                <th>សន្លឹក</th>
                <th>ទឹកប្រាក់សរុប</th>
                <th>ដុល្លារ</th>
                <th>សន្លឹក</th>
                <th>ទឹកប្រាក់សរុប</th>
              </tr>
            </thead>
            <tbody>
              {moneyType?.map((e) => (
                <tr className="center" key={e}>
                  <td>{e}</td>
                  <td></td>
                  <td>៛</td>
                  <td></td>
                  <td></td>
                  <td>$</td>
                </tr>
              ))}
              <tr className="center total">
                <td colSpan="2">សរុប</td>
                <td>970,000 ៛</td>
                <td colSpan="2"></td>
                <td>$955.00</td>
              </tr>
              <tr>
                <td className="right">ម៉ោងបិទបញ្ជី៖</td>
                <td className="center">7:00</td>
              </tr>
              <tr>
                <td className="right">ឈ្មោះអ្នកបិទបញ្ជី៖</td>
                <td className="center">ហួន គឹមលេង</td>
              </tr>
            </tbody>
          </table>
        </Grid>

      </Grid>
    </div>
  );
}
