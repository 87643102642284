import moment from "moment";
export function getKhmerNumber(number) {
  let numArr = number?.toString()?.split("");

  let numberKh = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
  let newArr = [];

  for (let i = 0; i < numArr?.length; i++) {
    if (isNaN(parseFloat(numArr[i]))) {
      newArr.push(numArr[i]);
      continue;
    }
    newArr.push(numberKh[numArr[i]]);
  }
  return newArr?.join("");
}

export function getKhmerMonth(month) {
  
  let khMonth;
  switch (month) {
    case "1":
      khMonth = "មករា";
      break;
    case "2":
      khMonth = "កុម្ភៈ";
      break;
    case "3":
      khMonth = "មីនា";
      break;
    case "4":
      khMonth = "មេសា";
      break;
    case "5":
      khMonth = "ឧសភា";
      break;
    case "6":
      khMonth = "មិថុនា";
      break;
    case "7":
      khMonth = "កក្កដា";
      break;
    case "8":
      khMonth = "សីហា";
      break;
    case "9":
      khMonth = "កញ្ញា";
      break;
    case "10":
      khMonth = "តុលា";
      break;
    case "11":
      khMonth = "វិច្ឆិកា";
      break;
    case "12":
      khMonth = "ធ្នូ";
  }
  return khMonth;
}

export function getKhmerDay(day) {
  let khDay;
  switch (day) {
    case "Monday":
      khDay = "ចន្ទ";
      break;
    case "Tuesday":
      khDay = "អង្គារ";
      break;
    case "Wednesday":
      khDay = "ពុធ";
      break;
    case "Thursday":
      khDay = "ព្រហស្បតិ៍";
      break;
    case "Friday":
      khDay = "សុក្រ";
      break;
    case "Saturday":
      khDay = "សៅរ៍";
      break;
    case "Sunday":
      khDay = "អាទិត្យ";
  }
  return khDay;
}

export function getKhmerFullDate(date) {
  const numDay = getKhmerNumber(parseInt(moment(date).format("DD")));
  const month = getKhmerMonth(moment(date).format("M"));
  const letterDay = getKhmerDay(moment(date).format("dddd"));
  const year = getKhmerNumber(parseInt(moment(date).format("YYYY")));
  const fullDay = `ថ្ងៃ${letterDay} ទី${numDay} ខែ${month} ឆ្នាំ${year}`;
  return fullDay;
}
