import React, { useEffect, useState } from "react";
// import "./report.scss";
import { Typography, Stack } from "@mui/material";
import moment from "moment";
import { REPRORT_INVENTORY_BY_DAY } from "../../Schema/report";
import { getKhmerFullDate } from "../Function/khmerDate";
import { useQuery } from "@apollo/client";


export default function Inventory({ date, keyword, category , times }) {

  const [rowData, setRowData] = useState([]);
  const [loading,setLoading] = useState(true);

  // console.log(moment(date).format().split('T')[0]+"T"+moment(times).format().split('T')[1]);

  const { data , refetch } = useQuery(REPRORT_INVENTORY_BY_DAY, {
    variables: {
      date: date & times ? moment(date)?.format()?.split('T')[0]+"T"+moment(times)?.format()?.split('T')[1] : moment()?.format()?.split('T')[0]+"T"+moment()?.format()?.split('T')[1],
      keyword: keyword ? keyword  : "",
      category: category ? category : "",
      isHasQty: true,
    },
    onCompleted: ({ getInventoryByDayReport }) => {
      console.log("getInventoryByDayReport",getInventoryByDayReport);
      setRowData(getInventoryByDayReport);

      setTimeout(() => {
        setLoading(false);
      }, 500);

    },
    onError: (error) => {
      console.log(error.message);
    },
  });


  useEffect( () => {
    refetch()
    setLoading(true);
  },[keyword,category,date,times]) 



  const handleGetTotalOpenQty = () => {
    let earlyQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        earlyQty += parseFloat((rowQ?.early_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return earlyQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }


  
  const handleGetTotalstockInQty = () => {
    let stockInQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        stockInQty += parseFloat((rowQ?.stock_in_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return stockInQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }

  const handleGetTotalSaleQty = () => {
    let saleQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        saleQty += parseFloat((rowQ?.sold_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return saleQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }

  const handleGetTotalSpoiledQty = () => {
    let spoiledQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        spoiledQty += parseFloat((rowQ?.spoiled_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return spoiledQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }

  const handleGetTotalRemainQty = () => {
    let remainQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        remainQty += parseFloat((rowQ?.remain_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return remainQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }
  

  const handleGetTotalConfuseQty = () => {
    let confuseQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        confuseQty += parseFloat((rowQ?.confuse_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return confuseQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }

  const handleGetTotalLostQty = () => {
    let lostQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        lostQty += parseFloat((rowQ?.lost_qty*rowQ?.qty_inUnit)?.toFixed(2))
      })
    }
    return lostQty?.toFixed(2)+" "+rowData[0]?.unit_of_qty_inUnit;
  }

  
  const handleGetTotalRemainAmount = () => {
    let remainAmountQty = 0;
    if(rowData) {
      rowData?.map( (rowQ) => {
        remainAmountQty += parseFloat((rowQ?.remain_qty_cost*rowQ?.remain_qty)?.toFixed(2))
      })
    }
    return remainAmountQty?.toFixed(2);
  }


  return (
    <>
      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">របាយការណ៍សន្និធិ</Typography>
        <Typography className="report-date">{getKhmerFullDate(moment(date))}</Typography>
        <Typography className="report-date"> 00:01 AM - {moment(times)?.format("hh:mm A")}</Typography>
      </Stack>
      <Stack className="report-table-container">
      <table className="daily-report-print">
        <thead className="thead-sticky">
          <tr className="throw-head">
            <th className="thead-title-in-report">ល.រ</th>
            <th className="thead-title-in-report">បារកូដ</th>
            <th className="thead-title-in-report">ឈ្មោះទំនិញ</th>  
            <th className="thead-title-in-report">ដើមគ្រា</th>
            <th className="thead-title-in-report">ដាក់ចូល</th>
            <th className="thead-title-in-report">លក់</th>
            <th className="thead-title-in-report">បាត់</th>
            <th className="thead-title-in-report">ច្រលំ</th>
            <th className="thead-title-in-report">ខូច</th>
            <th className="thead-title-in-report">នៅសល់</th>    
            <th className="thead-title-in-report">ថ្លៃដើម</th>   
            <th className="thead-title-in-report">សរុបថ្លៃដើម</th> 
          </tr>
        </thead>
         
        <tbody >

        {
          loading ?
            <tr className="center">
                <td colSpan={12}>loading...</td>                
            </tr>
        :  
          <>
            <tr className="center">
                    <td className="tbody-title-in-report"> </td>
                    <td className="tbody-title-in-report" align="center"></td>
                    <td className="tbody-title-in-report" align="left"></td>
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalOpenQty()}</td>
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalstockInQty()}</td> 
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalSaleQty()}</td> 
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalLostQty()}</td> 
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalConfuseQty()}</td>
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalSpoiledQty()}</td>  
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalRemainQty()}</td>
                    <td className="tbody-title-in-report"></td>
                    <td className="tbody-title-in-report" style={{ fontWeight: "bold"}}>{handleGetTotalRemainAmount()} $</td>
            </tr>

            { rowData?.map((e, i) => ( 
              <tr className="center" key={i}>
                    <td className="tbody-title-in-report">{i + 1}</td>
                    <td className="tbody-title-in-report" align="center">{e?.bar_code}</td>
                    <td className="tbody-title-in-report" align="left">{e?.product_name}</td>
                    <td className="tbody-title-in-report">{(e?.early_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td>
                    <td className="tbody-title-in-report">{(e?.stock_in_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td> 
                    <td className="tbody-title-in-report">{(e?.sold_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td> 
                    <td className="tbody-title-in-report">{(e?.lost_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td> 
                    <td className="tbody-title-in-report">{(e?.confuse_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td>
                    <td className="tbody-title-in-report">{(e?.spoiled_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td>  
                    <td className="tbody-title-in-report">{(e?.remain_qty*e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}</td>
                    <td className="tbody-title-in-report">{(e?.remain_qty_cost)?.toFixed(2)} $ - {e?.unit}</td>
                    <td className="tbody-title-in-report">{(e?.remain_qty_cost*e?.remain_qty)?.toFixed(2)} $</td>
              </tr> 
            ))}
          </>
        }


        {/* {
          loading ?
            <tr className="center">
                <td colSpan={8}>loading...</td>                
            </tr>
        :        
          [1,2,3,4,5,6,7,2,5,7,8,6,78,7,78,7,78,7,78,7]?.map((e, i) => (
            <tr className="center" key={i}>
                <td className="tbody-title-in-report">{i + 1}</td>
                <td className="tbody-title-in-report" align="center">{e?.bar_code}</td>
                <td className="tbody-title-in-report" align="left">{e?.product_name}</td>
                <td className="tbody-title-in-report">{(e?.early_qty*e?.qty_inUnit)?.toFixed(0)} {e?.unit_of_qty_inUnit}</td>
                <td className="tbody-title-in-report">{(e?.stock_in_qty*e?.qty_inUnit)?.toFixed(0)} {e?.unit_of_qty_inUnit}</td>     
                <td className="tbody-title-in-report">{(e?.sold_qty*e?.qty_inUnit)?.toFixed(0)} {e?.unit_of_qty_inUnit}</td>    
                <td className="tbody-title-in-report">{(e?.remain_qty*e?.qty_inUnit)?.toFixed(0)} {e?.unit_of_qty_inUnit}</td>
                <td className="tbody-title-in-report">{(e?.remain_qty_cost)?.toFixed(2)} $ - {e?.unit}</td>
            </tr>
          ))
        } */}
        

        </tbody>


        

      </table>
      </Stack>
    </>
  );
}
