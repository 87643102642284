import React, { useEffect, useState } from "react";
import "./report.scss";
import { Typography, Stack } from "@mui/material";
import { useQuery } from "@apollo/client";
import { REPORT_DAILY_SALE } from "../../Schema/report";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";

export default function DailySale({ fromDate , toDate, customerType }) {

  const [loading,setLoading] = useState(true);
  const [rowData, setRowData] = useState([]);

  // console.log(moment(date).format("YYYY-MM-DD"));

  const { refetch } = useQuery(REPORT_DAILY_SALE, {
    variables: {
      customerType: customerType?.id ? customerType?.id : null,
      startDate: moment(fromDate).format("YYYY-MM-DD"),
      endDate: moment(toDate).format("YYYY-MM-DD"),
      paymentStatus: "",
    },
    onCompleted: ({ getDailySalesReport }) => {
      setRowData(getDailySalesReport);
      setLoading(false)
      // console.log(getDailySalesReport);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  
  useEffect( () => {
    refetch();
    setLoading(true);
  },[ customerType?.id , fromDate , toDate ])


  const handleTotalAmount = () => {
    let total = 0;
    rowData?.map( (i) => {
      total += i?.total_sale_amount+i?.discount
    })
    return total?.toFixed(2)
  }

  const handleTotalPaidAmount = () => {
    let total = 0;
    rowData?.map( (i) => {
      total += i?.money_paid
    })
    return total?.toFixed(2)
  }

  const handleTotalDisAmount = () => {
    let total = 0;
    rowData?.map( (i) => {
      total += i?.discount
    })
    return total?.toFixed(2)
  }

  const handleTotalOweAmount = () => {
    let total = 0;
    rowData?.map( (i) => {
      total += i?.remain_amount
    })
    return (total)?.toFixed(2)
  }

  return (
    <>
      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">របាយការណ៍លក់ប្រចាំថ្ងៃ</Typography>
        <Typography className="report-date">
        {getKhmerFullDate(moment(fromDate))} ដល់​​ {getKhmerFullDate(moment(toDate))}
        </Typography>
      </Stack>
      <Stack  className="report-table-container">
        <table style={{marginRight: "15px"}} className="daily-report-print">
          
          <thead className="thead-sticky">
            <tr className="throw-head">
              <th className="thead-title-in-report">ល.រ</th>
              <th className="thead-title-in-report">កាលបរិច្ឆេទ</th>
              <th className="thead-title-in-report">លេខវិក្កយបត្រ</th>
              <th className="thead-title-in-report">អតិថិជន</th>
              <th className="thead-title-in-report">ការទូទាត់</th>
              <th className="thead-title-in-report">ប្រាក់លក់សរុប</th>
              <th className="thead-title-in-report">ប្រាក់បានបង់</th>
              <th className="thead-title-in-report">បញ្ចុះតម្លៃ</th>
              <th className="thead-title-in-report"> ប្រាក់នៅខ្វះ</th>
            </tr>
          </thead>


          <tbody>
            {
              loading ?
                <tr className="center"> 
                  <td className="tbody-title-in-report" colSpan={9}> Loading...</td> 
                </tr>
              :
                <>
                  { rowData?.map((e, i) => (
                      <tr className="center" key={i}>
                        <td className="tbody-title-in-report">{i + 1}</td>
                        <td className="tbody-title-in-report">{moment(e?.date)?.utc(false)?.format("DD/MMM/YY h:mm A")}</td>
                        <td className="tbody-title-in-report">{e?.invoice_number}</td>
                        <td className="tbody-title-in-report">{e?.customer_type}</td>
                        <td className="tbody-title-in-report">{e?.payment_status}</td>
                        <td className="tbody-title-in-report">$ {(e?.total_sale_amount+e?.discount)?.toFixed(2)}</td>
                        <td className="tbody-title-in-report">$ {e?.money_paid?.toFixed(2)}</td>
                        <td className="tbody-title-in-report">$ {e?.discount?.toFixed(2)}</td>
                        <td className="tbody-title-in-report">$ {e?.remain_amount?.toFixed(2)}</td>
                      </tr>
                  ))}

                    <tr className="center"> 
                      <td className="tbody-title-in-report" colSpan={4}></td>
                      <td className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>សរុប</td>
                      <td className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>$ {handleTotalAmount()}</td>
                      <td className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>$ {handleTotalPaidAmount()}</td>
                      <td className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>$ {handleTotalDisAmount()}</td>
                      <td className="tbody-title-in-report" style={{backgroundColor:"#cfe1f8", color: 'black'}}>$ {handleTotalOweAmount()}</td>
                    </tr>
                </> 
            }
            

          </tbody>
        </table>
      </Stack>
    </>
  );
}
