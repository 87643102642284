
import { gql } from "@apollo/client";
export const GET_CARD_NO_PAYMENT = gql`
query GetCartNotPayment {
    getCartNotPayment {
        _id
        numbering
        customer_id {
            name
        }
    }
}
`;