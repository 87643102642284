import React, { useState, useEffect, useContext } from "react";
import "../../../../Style/pagestyle.scss";
import { Link } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
//ICONS
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { TbPlaylistAdd } from "react-icons/tb";
//SRC
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Dymanic/Translate";
import EmptyData from "../../../Include/EmptyData";
import LoadingPage from "../../../Include/LoadingPage";
import TopRightBar from "../../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../../Menu/IconMenuResponsive";
import moment from "moment";
// import CreateDiscount from "../Create/CreateDiscount";
import AdjustQtyAction from "../Action/AdjustQtyAction";
// import PreviewDiscount from "../Preview/PreviewDiscount";
import { useQuery } from "@apollo/client";
import { GET_DISCOUNT } from "../../../../Schema/discount";
import { useNavigate } from "react-router-dom";
import DialogAdjustQtyProduct from "../DialogAdjustQtyProduct/DialogAdjustQtyProduct";
import DialogCreateWarehouse from "../DialogAdjustQtyProduct/DialogCreateWarehouse";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function TableViewQtyProduct() {

  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { language , setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  //===========
  const [openAdjust, setOpenAdjust] = React.useState(false);
  const handleOpenAdjust = () => setOpenAdjust(true);
  const handleCloseAdjust = () => setOpenAdjust(false);

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //paginator
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [paginator, setPaginator] = useState({});

  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_DISCOUNT, {
    variables: {
      keyword: keyword,
    },
    onCompleted: ({ getDiscount }) => {
      setTableData(getDiscount);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  useEffect(() => {
    setShowPage(page);
  }, [keyword, limit, page]);

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  // console.log("tableDiscount ::", tableData);
  console.log(userLogin?.role);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Typography
              className="page-title"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/setting")}
            >
              Setting
            </Typography>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Warehouse Mart</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={6} className="search-container">
          <Box className="box-search">
            <Stack direction="column" sx={{ width: "300px" }}>
              <TextField
                sx={{ bgcolor: "#fff !important" }}
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder="Search"
                size="small"
                onChange={(e) => setKeyword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={6} className="add-container">
          <Stack direction="row" spacing={2} justifyContent="end">

            <Button
              onClick={ () => {
                if(userLogin?.role !== "cashier" || userLogin?.role !== "stock_controller" ) {
                  handleOpenAdjust();
                } else {
                  setAlert(true , "error" , "You are not allowed to adjust.")
                }
              }}
              className="btn-add"
              startIcon={<TbPlaylistAdd className="icon-add" />}
            >
              Adjust Warehouse 
            </Button>

            <Button
              onClick={() => {
                  if(userLogin?.role !== "cashier" || userLogin?.role !== "stock_controller" ) {
                    handleOpen();
                  } else {
                    setAlert(true , "error" , "You are not allowed to adjust.")
                  }
              }}
              className="btn-add"
              startIcon={<TbPlaylistAdd className="icon-add" />}
            >
              Create Warehouse 
            </Button> 
          </Stack> 

          <DialogCreateWarehouse
            open={open}
            handleClose={handleClose}
            setRefetch={refetch}
            t={t}
          />
 
          <DialogAdjustQtyProduct
            open={openAdjust}
            handleClose={handleCloseAdjust}
            setRefetch={refetch}
            t={t}
          />

        </Grid>

      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell width="5%" className="header-title">
                  {t("no")}
                </TableCell>
                <TableCell width="25%" className="header-title">
                  {t("product")}
                </TableCell>
                <TableCell width="25%" className="header-title">
                  {t("category")}
                </TableCell>
                <TableCell width="15%" className="header-title">
                  {/* {t("cost")} */}
                  Unit Price
                </TableCell>
                <TableCell width="15%" className="header-title">
                  {t("sale-type")}
                </TableCell>

                <TableCell width="9%" className="header-title">
                  {t("product-status")}
                </TableCell>

                <TableCell align="right" className="header-title">
                  {t("sale-set-up")}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        {loading ? (
          <LoadingPage />
        ) : (
          <>
            { [1,2,3,4,5].map( (row, index) => ( 
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                key={index}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Stack direction="row" width="100%">
                    <Typography>coca</Typography>
                    <Box sx={{ flexGrow: 1}}/>
                    <Typography sx={{color: "coca" === "yes" ? "green" : "red", fontWeight: "bold"}}>
                    </Typography> 
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet,
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet,
                  </Typography>
                </AccordionDetails>
              </Accordion>  
            ))} 
          </>
        )}
      
      </Box>
    </div>
  );
}
