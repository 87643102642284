import React, { useState, useContext ,useEffect } from "react";
import {
  Stack,
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Paper,
  Fade,
  Popper,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { VOID_PAYMENT_BY_ID } from "../../../Schema/viewpos";
import { AuthContext } from "../../../Context/AuthContext";
import moment from "moment";

export default function VoidPayment({ data , findPayment }) {

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [voidedPaymentById] = useMutation(VOID_PAYMENT_BY_ID, {
    onCompleted: ({ voidedPaymentById }) => {
      if (voidedPaymentById?.status) {
        setAlert(true, "success", voidedPaymentById?.message);
        setOpen(false);
        setLoading(false);
      } else {
        setAlert(true, "error", voidedPaymentById?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log(error.message);
    },
  });

  const handleVoidPayment = () => {
    // console.log(id)
    setLoading(true);
    voidedPaymentById({
      variables: {
        voidedPaymentByIdId: data?._id,
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement) => (event) => {    
    // setOpen((prev) => placement !== newPlacement || !prev);
    setOpen(!open)
    setAnchorEl(event.currentTarget);       
    setPlacement(newPlacement);
  };

  // console.log("listPayment", data);

  return (
    <div>
      <Button
        sx={{ color: data?.is_voided && "gray !important" }}
        className={ data?.is_voided || findPayment === 1 || moment()?.format('DD-MM-YYYY') !== moment(data?.date).format('DD-MM-YYYY') ? "btn-void-payment-card-void" : "btn-void-payment-card" }
        onClick={handleClick(`bottom`)}       
        disabled={ data?.is_voided || findPayment === 1 ||  moment()?.format('DD-MM-YYYY') !== moment(data?.date).format('DD-MM-YYYY')}
      >
        {data?.is_voided ? "voided" : "void"}
      </Button>

      <Popper        
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={{ zIndex: "1400" }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 1 }}>
              <Typography align="center">Are you sure?</Typography>
              <Stack direction="row" spacing={1}>
                {loading ? (
                  <Button disabled>Loading...</Button>
                ) : (
                  <Button onClick={handleVoidPayment} sx={{color:'green'}}>Yes</Button>
                )}
                <Button onClick={() => setOpen(false)} sx={{color:'red'}}>No</Button>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
    // <div>
    //   {listPayment?.length === 0 ? (
    //     <Stack
    //       direction="row"
    //       justifyContent="center"
    //       sx={{ height: "50vh" }}
    //       alignItems="center"
    //     >
    //       <Typography sx={{ fontSize: "30px" }}>Unpaid</Typography>
    //     </Stack>
    //   ) : (
    //     <Typography align="center" mb={3} variant="h4">
    //       Payment
    //     </Typography>
    //   )}
    // </div>
  );
}
