import React, { useState, useEffect } from "react";
import "./createrefund.scss";
import {
  Typography,
  Stack,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { GET_CARD_BY_ID, UPDATE_CARD } from "../../Schema/posupdate";
import { useQuery } from "@apollo/client";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";

function GetItemById({ id, addItem, totalAmount }) {
  const [itemData, setItemData] = useState([]);
  const { data } = useQuery(GET_CARD_BY_ID, {
    variables: {
      getCartByIdId: id,
    },
    onCompleted: ({ getCartById }) => {
      if (getCartById) {
        totalAmount(getCartById?.total);
        setItemData(getCartById);
      }
    },
    onError: (error) => {
      //   console.log(error?.message);
    },
  });

  if (itemData?.length === 0 || id === undefined) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            <Stack
              sx={{ height: "200px" }}
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <FeedbackOutlinedIcon />
              <Typography>Invoice not selected yet</Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {itemData?.items?.map((row, index) => (
        <TableRow key={index} className="body-row-refund">
          <TableCell className="body-cell" onClick={() => addItem(row)}>
            {index + 1}
          </TableCell>
          <TableCell className="body-cell" onClick={() => addItem(row)}>
            <Stack direction="row" spacing={1} alignItems="center">
              <img className="image" src={row?.image_src} alt={row?.name} />
              <Typography>{row?.name}</Typography>
            </Stack>
          </TableCell>
          <TableCell className="body-cell" onClick={() => addItem(row)}>
            {row?.qty + " x " + row?.unit}
          </TableCell>
          <TableCell className="body-cell" onClick={() => addItem(row)}>
            $ {row?.product_id_setup?.unit_Price}
          </TableCell>
          <TableCell className="body-cell" onClick={() => addItem(row)}>
            $ {row?.price * row?.qty}
            <br />
            {row?.discount_id
              ? row?.discount_id?.amount_discount !== 0
                ? `($ ${row?.discount_id?.amount_discount})`
                : null
              : null}
            {row?.discount_id
              ? row?.discount_id?.percentage !== 0
                ? `($ ${(
                    row?.price *
                    row?.qty *
                    (row?.discount_id?.percentage / 100)
                  )?.toFixed(2)})`
                : null
              : null}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

export default GetItemById;
