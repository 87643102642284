import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: ID, $input: UserUpdate) {
    updateUser(user_Id: $userId, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(user_id: $userId) {
      message
      status
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUser($keyword: String) {
    getAllUser(keyword: $keyword) {
      role
      lastName
      image_src
      image_name
      firsName
      email
      created_At
      _id
    }
  }
`;

export const GET_USER_WITH_PAGINATION = gql`
  query GetUserByPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getUserByPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        firsName
        lastName
        email
        role
        image_src
        image_name
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      _id
      firsName
      lastName
      image_name
      image_src
      email
      role
      created_At
    }
  }
`;
