import { gql } from "@apollo/client";

export const GET_CARD_BY_ID = gql`
  query GetCartById($getCartByIdId: ID) {
    getCartById(id: $getCartByIdId) {
      items {
        qty
        price
        discount_id {
          _id
          status
          start_date
          percentage
          end_date
          events
          amount_discount
        }        
        amount_discount
        image_src
        name
        percentage_discount
        qty_in_product
        qty_in_shop        
        unit
        product_id_setup {
          _id
          saleName
          image
          saleQty
          saleType
          descriptions
          saleQR
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            qty_inUnit
            bar_Code
            created_At
          }
          unit_Price
          created_At
        }
      }
      _id
      create_by {
        _id
        email
        firsName
        lastName
        image_src
      }
      created_at
      customer_id {
        _id
        created_at
        image_src
        name
        tel
      }
      date
      discount_total
      exchange_rate
      isDiscount
      is_voided
      numbering
      payment_status
      shop_Id {
        _id
        name
        phone
        email
        address
      }
      status
      sub_total
      tax_total
      total
    }
  }
`;
export const UPDATE_CARD = gql`
  mutation UpdateCart($input: inputCart!, $updateCartId: ID) {
    updateCart(input: $input, id: $updateCartId) {
      data {
        _id
        customer_id {
          _id
          name
          tel
          address
          image_src
        }
        shop_Id {
          _id
          name
          email
          phone
          address
        }
        create_by {
          _id
          firsName
          lastName
          image_src
          email
        }
        status
        numbering
        isDiscount
        is_voided
        date
        payment_status
        discount_total
        sub_total
        tax_total
        exchange_rate
        total
        items {
          _id
          product_id_setup {
            _id
            saleName
            descriptions
            saleQty
            saleType
            saleQR
            product_Id {
              _id
              name
              cost
              category
              image_src
              type
              feature
              unit
              qty_inUnit
              bar_Code
            }
            unit_Price
          }
          discount_id {
            _id
            amount_discount
            percentage
            events
            status
            start_date
            end_date
          }
          price
          qty
          percentage_discount
          amount_discount
          qty_in_product          
          qty_in_shop
          unit
          name
          image_src          
        }
        created_at
      }
      message
      status
    }
  }
`;
