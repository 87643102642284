import React, { useContext, useState ,useEffect} from "react";
import "./createcustomer.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
} from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";
import CropImageFile from "../CropImage/CropImageFile";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "../../Schema/customer";

export default function CreateCustomer({
  open,
  handleClose,
  setRefetch,
  t,
  language,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: ({ createCustomer }) => {
      // console.log("user", createCustomer);
      if (createCustomer?.status) {
        setAlert(true, "success", createCustomer?.message);
        setLoading(false);
        resetForm();
        handleClose();
      } else {
        setAlert(true, "error", createCustomer?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error, "mgs");
      setAlert(true, "error", createCustomer?.message);
      setLoading(false);
    },
  });

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
 
  //fromik
  const addCustomer = Yup.object().shape({
    name: Yup.string().required("require!"),
    address: Yup.string().required("required address!"),
    tel: Yup.string(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      address: "",
      name: "",
      tel: "",
      remark: "",
    },

    validationSchema: addCustomer,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("values:::", values);
      createCustomer({
        variables: {
          input: {
            ...values,
            // image_src: "",
            // image_name: "",
          },
        },
      });
      // }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    values,
  } = formik;

  useEffect(() =>{
    resetForm()
  },[open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="setup-dialog">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems='center'>
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("add-customer")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="clear-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} className="grid-profile">
                    {!openCrop ? (
                      <Box>
                        <Stack
                          className="profile"
                          direction="row"
                          justifycontent="center"
                        >
                          <IconButton component="label">
                            <TextField
                              type="file"
                              id="image"
                              sx={{ display: "none" }}
                              onChange={handleUploadImage}
                            />
                            <Avatar
                              className="avater-image"
                              src={
                                imageFile
                                  ? URL.createObjectURL(imageFile)
                                  : null
                              }
                            />
                            <input type="file" hidden />
                          </IconButton>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          sx={{ mt: 1 }}
                        >
                          <Typography
                            className={
                              language === "kh" ? "text-Kh" : "text-En"
                            }
                          >
                            {t("profile")}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : (
                      <CropImageFile
                        setImageFile={setImageFile}
                        photoURL={photoURL}
                        setOpenCrop={setOpenCrop}
                        setPhotoURL={setPhotoURL}
                        setUploadHook={setProfileHook}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("customer")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder="name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("phone")}
                    </Typography>
                    <Stack>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="tel"
                        {...getFieldProps("tel")}
                        error={Boolean(touched.tel && errors.tel)}
                        helperText={touched.tel && errors.tel}
                      />
                    </Stack>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography className="order-subtitle">
                      Customer Type:
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={customerType}
                        onChange={(e) => setCustomerType(e.target.value)}
                      >
                        <MenuItem value={"WalkInCustomer"}>
                          Walk in customer
                        </MenuItem>
                        <MenuItem value={"SpecialCustomer"}>
                          Special customer
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("address")}
                    </Typography>
                    <Stack>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="address"
                        {...getFieldProps("address")}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("remark")}
                    </Typography>
                    <Stack>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="remark"
                        {...getFieldProps("remark")}
                        error={Boolean(touched.remark && errors.remark)}
                        helperText={touched.remark && errors.remark}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
