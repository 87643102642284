import React, { useState, useRef, useContext, useEffect } from "react";
import "../Style/pagestyle.scss";
import "./report.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";

//SRC
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

//
import TopRightBar from "../Menu/TopRightBar";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import SearchIcon from "@mui/icons-material/Search";
import { useReactToPrint } from "react-to-print";
import wingdingImage from "../Assets/tacteing-header.PNG";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import logo from "../Assets/logo_report_mart.jpg";
import CloseMoney from "../Component/Report/CloseMoney";
import DailySale from "../Component/Report/DailySale";
import RequestStock from "../Component/Report/RequestStock";
import Inventory from "../Component/Report/Inventory";
import SellingByItem from "../Component/Report/SellingByItem";
import AR from "../Component/Report/AR";
import AccountRepayment from "../Component/Report/AccountRepayment";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_CATEGORY } from "../Schema/setting";
import { GET_CUSTOMER_PAGENATION } from "../Schema/customer";
import { CREATE_CLOSE_SHIFT, GET_CLOSE_SALE_BY_DATE } from "../Schema/report";
import { AuthContext } from "../Context/AuthContext";
import CloseMoneyView from "../Component/Report/CloseMoneyView";
import { useFetcher } from "react-router-dom";

export default function Report() {
  
  const [selectReport, setSelectReport] = useState("លក់ប្រចាំថ្ងៃ");
  const [date, setDate] = useState(moment());
  const [times, setTimes] = useState(moment());
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [fromDate,setFromDate] = useState(moment());
  const [toDate,setToDate] = useState(moment());

  const { data: categoryOption } = useQuery(GET_ALL_CATEGORY);

  const [shiftState, setShiftState] = useState("");



  //==============  Get Close Sale =======================
  const [closeSaleSelected,setCloseSaleSelected] = useState({ id: "" , label: ""});
  const [optionCloseSale,setOptionCloseSale] = useState([]);

  const { refetch: refetchDataCloseSale } = useQuery(GET_CLOSE_SALE_BY_DATE,{
    variables: {
      fromDate: fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : moment()?.format("YYYY-MM-DD"),
      toDate: fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : moment()?.format("YYYY-MM-DD"),
    },
    onCompleted: ({getListOfCloseSaleDate}) => {
        console.log("getListOfCloseSaleDate",getListOfCloseSaleDate) 
        if(getListOfCloseSaleDate?.length > 0) {
            let rows = [];
            getListOfCloseSaleDate?.map( (elem,index) => {
                const allRow = {
                  id: elem?._id,
                  label:  moment(elem?.start_date)?.utc(false).format("DD-MM-YYYY")+" "+
                          moment(elem?.start_date)?.utc(false)?.format("HH:mm a")+" - "+
                          moment(elem?.close_date)?.utc(false).format("DD-MM-YYYY")+" "+
                          moment(elem?.close_date)?.utc(false)?.format("HH:mm a")
                } 
                rows.push(allRow);
            }) 

            setOptionCloseSale([...rows]);
        }
    },
    onError: (error) => {
        console.log(error.message)
    }
  })

 
  useEffect( () => {
    refetchDataCloseSale()
  },[fromDate])

  // ============ get customer =============
  const [customerType, setCustomerType] = useState({ id: "", label: "" });
  const [customerOption, setCustomerOption] = useState([]);
  const { refetch } = useQuery(GET_CUSTOMER_PAGENATION, {
    variables: {
      keyword: "",
      pagination: true,
      limit: 100,
      page: 1,
    },
    onCompleted: ({ getCustomersWithPagination }) => {
      if (getCustomersWithPagination?.data) {
        let rows = [];
        getCustomersWithPagination?.data?.forEach((elem, row) => {
          const allRow = {
            id: elem?._id,
            label: elem?.name,
          };
          rows.push(allRow);
        });
        setCustomerOption(rows);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  
  useEffect( () => {
    refetch();
  },[])

  //PRINT
  const compnentRef = useRef();
  const handleToPrint = useReactToPrint({
    content: () => compnentRef.current,
  });



  const { setAlert } = useContext(AuthContext);
  const [loading,setLoading] = useState(false);
  const [closeShiftData,setCloseShiftData] = useState();


  const [createCloseSale] = useMutation(CREATE_CLOSE_SHIFT,{
    onCompleted: ({createCloseSale}) => {
      if(createCloseSale?.status) {
        setAlert(true,"success",createCloseSale?.message);
        setLoading(false);
      } else {
        setAlert(true,"error",createCloseSale?.message)
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error.message)
    }
  })



  const handleCloseShiftReport = () => {
    setLoading(true)

    console.log("closeShiftData",closeShiftData)

    createCloseSale({
      variables: {
        input: closeShiftData
      } 
    })

  }


  useEffect( () => {
    setShiftState("");
  },[selectReport])

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Report</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container mt={"30px"} mb={"30px"} spacing={2}>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
          <Stack direction="column" justifyContent="center">
            <FormControl size="small" className="text-field" fullWidth>
              <Select
                value={selectReport}
                onChange={(e) => setSelectReport(e.target.value)}
              >
                <MenuItem value={"លក់ប្រចាំថ្ងៃ"}>លក់ប្រចាំថ្ងៃ</MenuItem>
                <MenuItem value={"លក់តាមមុខទំនិញ"}>លក់តាមមុខទំនិញ</MenuItem>
                <MenuItem value={"គណនីត្រូវទទួល"}>គណនីត្រូវទទួល</MenuItem>
                <MenuItem value={"គណនីសង"}>គណនីសង</MenuItem>
                <MenuItem value={"សន្និធិ"}>សន្និធិ</MenuItem>
                <MenuItem value={"បិទលុយ"}>បិទលុយ</MenuItem>
                <MenuItem value={"ស្នើសុំទំនិញចូលម៉ាត"}>
                  ស្នើសុំទំនិញចូលម៉ាត
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Grid>

      {
        selectReport === "បិទលុយ" ?
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <Stack direction="column" justifyContent="center">
              <FormControl size="small" className="text-field" fullWidth>
                <Select
                  value={shiftState}
                  onChange={(e) => setShiftState(e.target.value)}
                > 
                  <MenuItem value={"current shift"}>Current Shift</MenuItem>
                  <MenuItem value={"last shift"}>Last Shift</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        :
          null
      }
        



        {selectReport === "លក់តាមមុខទំនិញ" ||
        selectReport === "គណនីសង" ||
        selectReport === "សន្និធិ" ||
        selectReport === "ស្នើសុំទំនិញចូលម៉ាត" ||
        selectReport === "គណនីត្រូវទទួល" ||
        selectReport === "បិទលុយ" ? null : (
          <Grid item xs={6} sm={4} md={3} lg={2.5} xl={2}>
            <Autocomplete
              freeSolo
              clearOnBlur
              className="text-field"
              size="small"
              disablePortal
              options={customerOption}
              value={customerType}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => setCustomerType(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Customer type" />
              )}
            />
          </Grid>
        )}

        {selectReport === "លក់ប្រចាំថ្ងៃ" ||
        selectReport === "គណនីសង" ||
        selectReport === "គណនីត្រូវទទួល" ||
        selectReport === "បិទលុយ" ? null : (
          <Grid item xs={6} sm={6} md={3} lg={2.5} xl={2}>
            <Autocomplete
              className="text-field"
              size="small"
              disablePortal
              options={
                categoryOption?.getCategorise
                  ? categoryOption?.getCategorise
                  : [{ label: "" }]
              }
              onChange={(e) => {
                e.target.innerText === undefined
                  ? setCategory("")
                  : setCategory(e.target.innerText);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Category" />
              )}
            />
          </Grid>
        )}

        {selectReport === "លក់ប្រចាំថ្ងៃ" ||
        selectReport === "គណនីសង" ||
        selectReport === "គណនីត្រូវទទួល" ||
        selectReport === "បិទលុយ" ? null : (
          <Grid item xs={6} sm={4} md={3} lg={2.5} xl={2}>
            <TextField
              onChange={(e) => setKeyword(e.target.value)}
              size="small"
              fullWidth
              className="text-field"
              placeholder="Product Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}


        {
          shiftState !== "current shift" ?
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={fromDate}
                      onChange={(e) => setFromDate(e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                </LocalizationProvider>
            </Grid>
          : null
        } 

        {
          selectReport === "បិទលុយ" && shiftState === "last shift" ? 
            <Grid item xs={6} sm={4} md={3} lg={3} xl={2}> 
              <Autocomplete
                freeSolo
                clearOnBlur
                className="text-field"
                size="small"
                disablePortal
                options={optionCloseSale}
                value={closeSaleSelected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => setCloseSaleSelected(value)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Close Sale" />
                )}
              />
            </Grid>
          :
            null
        }

          { selectReport === "លក់ប្រចាំថ្ងៃ" || selectReport === "លក់តាមមុខទំនិញ" ? (
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={toDate}
                      onChange={(e) => setToDate(e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                </LocalizationProvider>
            </Grid>
          ) : null}


          { selectReport === "សន្និធិ" ? (
            <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  className="text-field"
                  inputFormat="hh:mm a"
                  value={times}
                  onChange={(newValue) => setTimes(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          ) : null}

          {/* ========== Print =============== */}
          <Box flexGrow={1} />

          {
            selectReport === "បិទលុយ" && shiftState === "current shift" ?
              <Grid item xs={2} sm={2} md={1.5} lg={1.5}>
                <Stack direction="column" justifyContent="center">
                  {
                    loading ?
                      <Button
                        className="print-btn" 
                      >
                        Loading...
                      </Button>
                  :
                      <Button
                        className="print-btn"
                        onClick={handleCloseShiftReport} 
                      >
                        Close Shift
                      </Button>
                  }                  
                </Stack>
              </Grid>
          :
            null
          }

          


          <Grid item xs={2} sm={2} md={1.5} lg={1.5}>
            <Stack direction="column" justifyContent="center">
              <Button
                className="print-btn"
                onClick={handleToPrint}
                startIcon={<PrintOutlinedIcon className="icon-add" />}
              >
                Print
              </Button>
            </Stack>
          </Grid>


      </Grid>

      <Box className="report-container" ref={compnentRef}>
        <Stack className="report-intro" spacing={1}>
          <img src={logo} alt="logo" className="logo" />
          <Typography className="nation">ព្រះរាជាណាចក្រកម្ពុជា</Typography>
          <Typography className="nation">ជាតិ សាសនា ព្រះមហាក្សត្រ</Typography>
          <img
            src={wingdingImage}
            alt="wingdingImage"
            className="wingdingImage"
          />


        {
          selectReport !== "ស្នើសុំទំនិញចូលម៉ាត" &&
          selectReport !== "សន្និធិ" ?
            <Box
              sx={{ 
                width: "100%",
                justifyContent: "left !important",  
              }}
            > 
              <input 
                type="text"  
                style={{
                  color:"black",
                  padding: "10px",
                  margin: 0,
                  textAlign: "right",
                  border: "none",
                  float: "right",
                  marginTop: "-55px"
                }} 
                placeholder="PO00/00" 
              />  
            </Box> 
          : null
        }

          

        </Stack>
           

        { selectReport === "លក់ប្រចាំថ្ងៃ" ? (
          <DailySale fromDate={fromDate} toDate={toDate} customerType={customerType} />
        ) : selectReport === "លក់តាមមុខទំនិញ" ? (
          <SellingByItem fromDate={fromDate} toDate={toDate} category={category} keyword={keyword} />
        ) : selectReport === "គណនីត្រូវទទួល" ? (
          <AR customerType={customerType} date={fromDate} />
        ) : selectReport === "សន្និធិ" ? (
          <Inventory
            date={fromDate}
            keyword={keyword}
            category={category}
            times={times}
          />
        ) : selectReport === "ស្នើសុំទំនិញចូលម៉ាត" ? (
          <RequestStock date={fromDate} />
        ) : selectReport === "គណនីសង" ? (
          <AccountRepayment />
        ) : (
            shiftState === "current shift"  ?
              <CloseMoney setCloseShiftData={setCloseShiftData} loading={loading}/>
          :
              <CloseMoneyView closeSaleID={closeSaleSelected?.id}/> 
        )}

      </Box>
    </div>
  );
}
