import React, { useState , useEffect, useRef } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Button,
  Typography,
  IconButton,
  Box,
  Grid,
  Stack,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { NumericFormat } from 'react-number-format';


export default function Bank({ 
  closeBank, 
  totalAmount, 
  exChangeRate,
  setReceiveAmount,
  setReturnAmount,
  setAmountOwe,
  setPaidAmount,
  setRequireField,
}) {

  let textInputUSD = useRef(null);
  let textInputKHR = useRef(null);

  //change money state
  const [changeUsd, setChangeUsd] = useState(0);
  const [changeKhr, setChangeKhr] = useState(0);

  // const [loading, setLoading] = useState(false);
  const [selectBank, setSelectBank] = useState("ABA");
  const [amountRiel,setAmountRiel] = useState(0);
  const [amountUSD,setAmountUSD] = useState(0);
  
  // ================= total Amount Recieve ======================
  const [totalReceiveAmount,setTotalReceiveAmount] = useState(0)
  const handleReceiveAmount = () => {
    setTotalReceiveAmount(parseFloat(amountUSD)+parseFloat(amountRiel/exChangeRate))
  }

  const autoSetAmountRiel = () => {
    setAmountRiel(parseFloat(totalAmount * exChangeRate));
  };

  const autoSetAmountUSD = () => {
    setAmountUSD(parseFloat(totalAmount));
  };
  // console.log(totalReceiveAmount)

  useEffect( () => {
    handleReceiveAmount()
    setChangeUsd(0)
    setChangeKhr(0)
  },[amountUSD,amountRiel])

  // ============================== check change amount to customer =======================
  const updateUsdChange = (value) => {
    let remain = parseFloat((totalReceiveAmount - totalAmount - value)?.toFixed(2) );
    setChangeUsd(parseFloat(value));
    setChangeKhr(parseFloat(remain * exChangeRate)?.toFixed(2));
  };

  const updateKhrChange = (value) => {
    let remain = parseFloat( (totalReceiveAmount - totalAmount - value / exChangeRate)?.toFixed(2));
    setChangeKhr(parseFloat(value));
    setChangeUsd(parseFloat(remain)?.toFixed(2));
  };


  // ================================ check require field =====================
  useEffect( () => {
    if(totalAmount !== 0) {
        if(totalReceiveAmount === 0) {
            setRequireField(true)
        } else if(totalReceiveAmount - totalAmount > 0) {
            setRequireField(true)
        } else {
            if(selectBank !== "" && selectBank !== undefined) {
              setRequireField(false)
            } else {
              setRequireField(true);
            }
        }
    } else {
      setRequireField(true);
    }  
  },[totalReceiveAmount,selectBank])


  useEffect( () => {
    if(totalAmount !== 0) {
        if(changeUsd !== 0 || changeKhr !== 0 ) {
            if(selectBank !== "" && selectBank !== undefined) {
                setRequireField(false);
            } else {
                setRequireField(true);
            }
        } else {
            if(totalReceiveAmount - totalAmount > 0) {
                setRequireField(true);
            } else if( selectBank === "" || selectBank === undefined ) {
                setRequireField(true);
            } else {
              setRequireField(false);
            }
        }
    } else {
      setRequireField(true);
    }
  },[changeUsd,changeKhr,selectBank])


  // ============================== set Amount ==========================================
  const listBank = ["ABA", "Credit Card" , "ACELIDA"];

  useEffect( () => {    

      setReceiveAmount([
        { amount: amountUSD ,  from: selectBank,  type_currency: "USD"  },
        { amount: amountRiel ,  from: selectBank,  type_currency: "RIEL"  }
      ]) 

      setReturnAmount([
        { amount: parseFloat(changeUsd) ,  from: "Cash" ,  type_currency: "USD"  },
        { amount: parseFloat(changeKhr) ,  from: "Cash" ,  type_currency: "RIEL"  }
      ])

      if(amountUSD === 0 &&  amountRiel === 0 ) {
          setAmountOwe([
            { amount: parseFloat((totalAmount)?.toFixed(2)) , from: "Cash" ,  type_currency: "USD"  },
            { amount: 0 , from: "Cash" ,  type_currency: "RIEL"  }
          ])      
          setPaidAmount(0)
      } else if( amountUSD !== 0 &&  amountRiel === 0 ) { 
          setAmountOwe([
            { amount: totalAmount-totalReceiveAmount > 0 ? parseFloat((totalAmount-totalReceiveAmount)?.toFixed(2)) : 0 , 
              from: "Cash",  
              type_currency: "USD"  
            },
            { amount: 0 , from: "Cash" ,  type_currency: "RIEL"  }
          ])      
          setPaidAmount(totalAmount-totalReceiveAmount > 0 ? parseFloat((totalReceiveAmount)?.toFixed(2)) : parseFloat((totalAmount)?.toFixed(2)) )
      } else if(amountUSD === 0 &&  amountRiel !== 0) {
          setAmountOwe([
            { amount: 0 , from: "Cash" ,  type_currency: "USD"  },
            { amount: totalAmount-totalReceiveAmount > 0 ? parseFloat(((totalAmount-totalReceiveAmount)* exChangeRate)?.toFixed(2))  : 0 , 
              from: "Cash" ,  
              type_currency: "RIEL"
            }
          ])      
          setPaidAmount(totalAmount-totalReceiveAmount > 0 ? parseFloat((totalReceiveAmount)?.toFixed(2)) : parseFloat((totalAmount)?.toFixed(2)) )
      } else if(amountUSD !== 0 &&  amountRiel !== 0) {
        setAmountOwe([
          { amount: totalAmount-totalReceiveAmount > 0 ? parseFloat((totalReceiveAmount)?.toFixed(2)) : 0 , 
            from: "Cash",  
            type_currency: "USD"  
          },
          { amount: 0 , from: "Cash" ,  type_currency: "RIEL" }
        ])  
        setPaidAmount(totalAmount-totalReceiveAmount > 0 ? parseFloat((totalReceiveAmount)?.toFixed(2)) : parseFloat((totalAmount)?.toFixed(2)) )
      } else {
        console.log("no payment process!")
      }      
          
  },[ selectBank , amountRiel, amountUSD, totalReceiveAmount , changeUsd , changeKhr ])

  return (
    <div className="create-container">
      <Stack direction="row" spacing={2} sx={{ placeItems: "center" }}>
        <IconButton onClick={closeBank}>
          <ArrowCircleLeftOutlinedIcon />
        </IconButton>
        <Typography className="field-title">Bank Payment</Typography>
      </Stack>
      
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={10}>
          <Stack direction="column" justifyContent="center">

            <Box sx={{ justifyContent: "center", display: "center" }}>
              Amount
            </Box>

            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography mt={2}>$ </Typography>
              <Typography variant="h4"> {totalAmount?.toFixed(2)}</Typography>
            </Box> */}

            <Stack direction="row" mb={2} justifyContent="Center" spacing={2} sx={{backgroundColor: "#0F81C2" , borderRadius: "5px" , padding: "10px" , color: "#fff"}}>             
              <Typography variant="h4">$ {(totalAmount)?.toFixed(2)}</Typography>
              <Typography variant="h4">=</Typography>
              <Typography variant="h4">          
                <NumericFormat 
                    value={(totalAmount * exChangeRate)?.toFixed(0)} 
                    thousandSeparator={true} 
                    displayType={'text'} 
                    renderText={(value, props) => <div {...props}> {value}</div>}
                /> 
              </Typography>
              <Typography variant="h4">៛</Typography>
            </Stack>



          </Stack>
        </Grid>


        <Grid item xs={3}>  
            <Stack direction="row" justifyContent="right"  height="100%">
                <Stack direction="column" justifyContent="center" height="100%">
                    <Typography sx={{color:"#0F81C2" , fontWeight: 'bold'}}>Bank</Typography>
                </Stack> 
            </Stack>
        </Grid>

        <Grid item xs={9}> 
            <FormControl fullWidth size="small">
              <Select 
                value={selectBank}
                onChange={ (e) => setSelectBank(e.target.value) }
                placeholder="Bank Name"                 
              >
                { listBank?.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>                  
        </Grid>
        
        <Grid item xs={3}>
            <Stack direction="row" justifyContent="right"  height="100%">
                <Stack direction="column" justifyContent="center" height="100%">
                    <Typography sx={{color:"#0F81C2" , fontWeight: 'bold'}}>Open Balance</Typography>
                </Stack> 
            </Stack>          
        </Grid>

        <Grid item xs={4.5}>
            <TextField
                fullWidth
                size="small"
                type="number"
                value={amountUSD}                   
                onFocus={ e => e.target.select()}
                onChange={ (e) => {
                    if(e.target.value !== undefined && e.target.value !== "") {
                      setAmountUSD(parseFloat(e.target.value)) 
                    } else {
                      setAmountUSD(0) 
                    }                      
                }}
                inputRef={textInputUSD}
                InputProps={{
                  startAdornment: ( 
                      <IconButton 
                          className="btn-pay-usd-pos"   
                          onClick={() => { 
                            textInputUSD.current.focus(); 
                            autoSetAmountUSD();
                          }}  
                      >
                            USD
                      </IconButton> 
                  ),
                }}
            />                 
        </Grid>


        <Grid item xs={4.5}>          
            <TextField
                fullWidth
                size="small"
                type="number"
                value={amountRiel}
                onFocus={ e => e.target.select()}
                onChange={ (e) => {
                    if(e.target.value !== undefined && e.target.value !== "") {
                      setAmountRiel(parseFloat(e.target.value)) 
                    } else {
                      setAmountRiel(0) 
                    }                      
                }}
                inputRef={textInputKHR}
                InputProps={{
                  startAdornment: ( 
                      <IconButton 
                          className="btn-pay-usd-pos"   
                          onClick={() => { 
                            textInputKHR.current.focus(); 
                            autoSetAmountRiel()
                          }}  
                      >
                            KHR
                      </IconButton> 
                  ),
                }}
            />
        </Grid>

        

        <Grid item xs={10}>
          <Stack direction="row" justifyContent="end" mt={2}>
            <Grid container rowSpacing={3}>
              <Grid item xs={6}>
                <Stack direction="row" justifyContent="end" mt={0.6}>
                  <Typography>Exchange Rate : 1$ = {exChangeRate}៛</Typography>
                </Stack>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end" pr={2} mt={0.6}>
                    <Typography sx={{color:"#0F81C2"}}>Receive</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end">
                    <TextField                    
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      className={ totalReceiveAmount < totalAmount ? "less" : "more" }
                      variant="standard"
                      focused
                      aria-readonly
                      value={totalReceiveAmount?.toFixed(2)}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid item xs={6} />
              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end" pr={2} mt={0.6}>
                    <Typography sx={{color:"#0F81C2"}}>Change</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end">
                    <TextField
                      // disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      focused
                      aria-readonly
                      value={ totalReceiveAmount - totalAmount < 0  ? 0.0 : (totalReceiveAmount - totalAmount)?.toFixed(2) }
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="end" mt={1} mb={2}>
                    <TextField
                      // disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">៛</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      focused
                      aria-readonly
                      value={ 
                        (totalReceiveAmount - totalAmount) * exChangeRate < 0  ? 
                          0
                        : 
                          ((totalReceiveAmount - totalAmount)*exChangeRate)?.toFixed(0)
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>


        <Grid item xs={10}>
            { totalReceiveAmount - totalAmount > 0 && (
              <Grid item container xs={12} spacing={2}>
                  <Grid item xs={2}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      sx={{ height: "100%" }}
                    >
                      <Typography sx={{color:"#0F81C2" , fontWeight: 'bold'}}>Change</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Stack direction="row">
                      <TextField
                        onFocus={(e) => e.target.select()}
                        value={changeUsd}
                        onChange={(e) => {
                          if (e.target.value !== undefined && e.target.value !== "") {
                            
                            if((totalReceiveAmount - totalAmount) > parseFloat(e.target.value)) {
                                updateUsdChange(parseFloat(e.target.value));
                            } else {
                                updateUsdChange(totalReceiveAmount - totalAmount);
                            }  
                            
                          } else {
                            updateUsdChange(0);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <IconButton
                                className="btn-pay-usd-pos"  
                                onClick={() => { 
                                  if(totalReceiveAmount - totalAmount < 0){
                                    updateUsdChange(0)
                                  } else {
                                    updateUsdChange(totalReceiveAmount - totalAmount)
                                  }
                                }} 
                            >
                                  USD
                            </IconButton>
                          ),
                        }}
                        placeholder="USD"
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Stack direction="row">
                      <TextField
                        onFocus={(e) => e.target.select()}
                        value={(changeKhr)?.toFixed(0)}
                        onChange={(e) => {
                          if (e.target.value !== undefined && e.target.value !== "") {

                              if((totalReceiveAmount - totalAmount)* exChangeRate > parseFloat(e.target.value)) {
                                  updateKhrChange(parseFloat(e.target.value));
                              } else {
                                  updateKhrChange((totalReceiveAmount - totalAmount)* exChangeRate);
                              }    

                          } else {
                            updateKhrChange(0);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                              <IconButton
                                  className="btn-pay-usd-pos"   
                                  onClick={() => {
                                    if((totalReceiveAmount - totalAmount) * exChangeRate < 0 ){
                                      updateKhrChange(0)
                                    } else {
                                      updateKhrChange((totalReceiveAmount-totalAmount)*exChangeRate)
                                    }
                                  }}
                                >
                                    KHR
                              </IconButton>
                          ),
                        }}
                        placeholder="KHR"
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </Stack>
                  </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
    </div>
  );
}
