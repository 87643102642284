import React, { useState, useEffect, useMemo } from "react";
import "./paymentmethod.scss";
import {
  Stack,
  Box,
  Button,
  Typography,
  IconButton,
  MenuItem,
  TextField,
  FormControl,
  InputAdornment,
  Select,
  Grid,
} from "@mui/material";
//ICON
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function Split({ closeSplit, totalAmount, exChangeRate }) {
  //   ================================= item ========================
  const [payItem, setPayItem] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [receiveKh, setReceiveKh] = useState(0);
  const [receiveUsd, setReceiveUsd] = useState(0);
  //HANDLE CHANGE RECEIVE
  const handleChangeReceiveKh = (value) => {
    setReceiveKh(value);
    setReceiveUsd((totalAmount - value / exChangeRate)?.toFixed(2));
  };
  const handleChangeReceiveUsd = (value) => {
    setReceiveUsd(value);
    setReceiveKh(
      (totalAmount * exChangeRate - value * exChangeRate)?.toFixed(2)
    );
  };

  //  effect after change usd or khr in lish payment
  const handleChangeAmount = async () => {
    const filterItem = payItem;
    let newPayList = [];
    if (filterItem?.length !== 0) {
      filterItem?.map((elem) => {
        const newRow = {
          type: elem?.type,
          USD: (totalAmount / payItem?.length)?.toFixed(2),
          KHR: (totalAmount / payItem?.length) * exChangeRate,
          key: elem?.key,
        };
        newPayList.push(newRow);
      });
      setPayItem(newPayList);
    }
  };

  const handleModiyAmount = (key, value) => {
    const filterItem = payItem;
    let summarytotal = 0;

    let paymentListModify = filterItem?.filter((e) => e?.key === key);
    let paymentListOld = filterItem?.filter((e) => e?.key !== key);
    summarytotal += paymentListModify[0]?.USD;
    let remainTotal = totalAmount - summarytotal;

    paymentListOld?.map((e) => {
      e.USD = (remainTotal / paymentListOld?.length)?.toFixed(2);
      e.KHR = ((remainTotal / paymentListOld?.length) * exChangeRate)?.toFixed(
        2
      );
    });

    setPayItem(paymentListModify.concat(paymentListOld));
  };

  useEffect(() => {
    if (payItem?.length !== 0) {
      handleChangeAmount();
    }
  }, [payItem?.length]);

  const addNewPayItem = async () => {
    setCurrentItem({ type: "Cash", USD: 0, KHR: 0, key: Date.now() });
  };

  //create
  const increment = async () => {
    const newPay = currentItem;
    if (newPay?.key !== "") {
      const items = [...payItem, newPay];
      setPayItem([...items]);
      setCurrentItem({ type: "Cash", USD: 0, KHR: 0, key: "" });
    }
  };

  useEffect(() => {
    if (currentItem?.key !== "") {
      increment();
    }
  }, [currentItem]);

  useMemo(() => {
    let arrayLoop = [];
    for (var i = 0; i < 1; i++) {
      const rowLoop = { type: "Cash", USD: 0, KHR: 0, key: Date.now() };
      arrayLoop.push(rowLoop);
    }
    setPayItem(arrayLoop);
  }, []);

  //update
  const updateType = (key, value) => {
    const filterItem = payItem;
    filterItem?.map((e) => {
      if (e?.key === key) {
        e.type = value;
      }
    });
    setPayItem([...filterItem]);
  };

  const updateUSD = (key, value) => {
    if (value > totalAmount || value < 0) {
      handleChangeAmount();
    } else {
      const filterItem = payItem;
      filterItem?.map((e) => {
        if (e?.key === key) {
          e.USD = value ? parseFloat(value) : 0;
          e.KHR = value ? parseFloat(value) * exChangeRate : 0;
        }
      });
      setPayItem([...filterItem]);
      handleModiyAmount(key, value);
    }
  };
  const updateKHR = (key, value) => {
    if (value > totalAmount * exChangeRate || value < 0) {
      handleChangeAmount();
    } else {
      const filterItem = payItem;
      filterItem?.map((e) => {
        if (e?.key === key) {
          e.KHR = value ? parseFloat(value) : 0;
          e.USD = value ? (parseFloat(value) / exChangeRate)?.toFixed(2) : 0;
        }
      });
      setPayItem([...filterItem]);
      handleModiyAmount(key, value);
    }
  };

  //delete
  const decrease = (key) => {
    const filterItem = payItem?.filter((e) => e?.key !== key);
    setPayItem(filterItem);
  };

  // console.log("payItem", payItem);

  return (
    <>
      <Grid container className="split">
        <Grid item xs={3}>
          <Stack direction="row">
            <IconButton onClick={closeSplit}>
              <ArrowCircleLeftOutlinedIcon />
            </IconButton>
            <Typography mt={1} ml={2} className="title">
              Split Pay
            </Typography>
          </Stack>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="Center">
              <Typography className="amount">Amount</Typography>
            </Stack>
            <Stack direction="row" mb={2} justifyContent="Center" spacing={2}>
              <Typography></Typography>
              <h3>$</h3>
              <Typography className="money"> {totalAmount}</Typography>
              <Typography className="money">=</Typography>
              <Typography className="money">{totalAmount * 4100}</Typography>
              <h3>៛</h3>
            </Stack>
          </Grid>
        </Grid>
        {/* RECEIVE FIELD */}
        <Grid item container xs={11} spacing={1}>
          <Grid item xs={2}>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography align="start">Receive</Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack direction="row">
              <TextField
                value={receiveUsd}
                onFocus={(e) => e.target.select()}
                placeholder="USD"
                size="small"
                type="number"
                fullWidth
                onChange={(e) => {
                  e.target.value === "" || e.target.value === undefined
                    ? handleChangeReceiveUsd(0)
                    : handleChangeReceiveUsd(parseFloat(e.target.value));
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack direction="row">
              <TextField
                value={receiveKh}
                onFocus={(e) => e.target.select()}
                placeholder="KHR"
                size="small"
                type="number"
                fullWidth
                onChange={(e) => {
                  e.target.value === "" || e.target.value === undefined
                    ? handleChangeReceiveKh(0)
                    : handleChangeReceiveKh(parseFloat(e.target.value));
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* Add Btn */}
        <Grid item container xs={11} mt={2}>
          <Grid item xs={12} className="payment">
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Stack direction="column" justifyContent="center">
                <Typography>Payments</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center">
                <Stack direction="row">
                  <Button disabled className="disable">
                    {payItem?.length}
                  </Button>
                  <Button className="btn" onClick={addNewPayItem}>
                    <AddOutlinedIcon />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          {payItem?.map((item) => {
            return (
              <Grid item container xs={12} spacing={1} key={item?.key}>
                <Grid item xs={5}>
                  <FormControl size="small" fullWidth>
                    <Select
                      defaultValue={"Cash"}
                      onChange={(e) => updateType(item?.key, e?.target?.value)}
                    >
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="CreditCard">Credit Card</MenuItem>
                      <MenuItem value="ExtenalPayment">
                        Extenal Payment
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" justifyContent="center">
                    <Stack direction="row" justifyContent="end">
                      <TextField
                        size="small"
                        type="number"
                        placeholder="USD"
                        value={item?.USD}
                        onChange={(e) => updateUSD(item?.key, e?.target?.value)}
                        onFocus={(e) => e.target.select()}
                        sx={{ marginRight: "5px" }}
                      />
                      <TextField
                        size="small"
                        type="number"
                        placeholder="KHR"
                        value={item?.KHR}
                        onChange={(e) => updateKHR(item?.key, e?.target?.value)}
                        sx={{ marginRight: "5px" }}
                      />

                      <Button className="btn" variant="contained">
                        Charge
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    sx={{ color: "red" }}
                    variant="contained"
                    onClick={() => decrease(item?.key)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}
