import React, { useContext, useEffect, useState } from "react";
import "./addsale.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import CropImageFile from "../../CropImage/CropImageFile";
import {
  CREATE_SETUP_SALE_PRODUCT,
  // GET_UNIT,
  GET_ALL_PRODUCT,
} from "../../../Schema/setting";
import { useMutation, useQuery } from "@apollo/client";

export default function AddSale({
  t,
  open,
  language,
  handleClose,
  setRefetch,
  productFromTable
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorQtyInUnit, setErrorQtyInUnit] = useState(false);
  // const [selectUnit, setSelectUnit] = useState({ id: "", label: "" });
  // const [unitVal, setUnitVal] = useState("");
  const [productId, setProductId] = useState({
    id: "",
    label: "",
    unitPrice: "",
    unit: "",
    qty_inUnit: "",
  });

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [setUpSaleProduct] = useMutation(CREATE_SETUP_SALE_PRODUCT, {
    onCompleted: ({ setUpSaleProduct }) => {
      if (setUpSaleProduct?.status) {
        setAlert(true, "success", setUpSaleProduct?.message);
        setLoading(false);
        setRefetch();
        handleClose();
      } else {
        setLoading(false);
        setAlert(true, "error", setUpSaleProduct?.message);
      }
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });


  //product
  const [allProduct, setAllProduct] = useState([]);
  const { data: dataAllProduct, refetch } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct);
      if (getAllProduct?.length !== 0) {
        let rows = [];
        getAllProduct?.map((element, index) => {
          const allrow = {
            label: index + 1 + " - " + element?.name,
            id: element?._id,
            unitPrice: element?.cost,
            unit: element?.unit,
            qty_inUnit: element?.qty_inUnit,
          };
          rows.push(allrow);
        });
        setAllProduct(rows);
      } else {
        setAllProduct([]);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  //fromik
  const addSale = Yup.object().shape({
    product_Id: Yup.string().required("require product!"),
    saleName: Yup.string().required("require sale name!"),
    saleType: Yup.string().required("require type!"),
    saleQR: Yup.string(),
    descriptions: Yup.string(),
    saleQty: Yup.number().required("require qty!"),
    unit_Price: Yup.number().required("require unit price!"),
    other: Yup.string(),
    expireDate: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      product_Id: "",
      saleName: "",
      saleType: "Retail",
      descriptions: "",
      saleQR: "",
      saleQty: "",
      unit_Price: "",
      other: "",
      expireDate: "",
    },

    validationSchema: addSale,
    onSubmit: async (values) => {
      if (productId?.qty_inUnit === 0) {
        setAlert(true, "error", "Need qty in unit in inventory");
        setErrorQtyInUnit(true);
      } else {
        setLoading(true);
        // console.log(values, "SUBMIT VALUE");
        setUpSaleProduct({
          variables: {
            input: {
              product_Id: values?.product_Id,
              saleName: values?.saleName,
              saleQR: values?.saleQR,
              saleQty: values?.saleQty,
              saleType: values?.saleType,
              unit_Price: values?.unit_Price,
              image: profileHook,
              descriptions: values?.descriptions,
              other: values?.other,
              expireDate: values?.expireDate,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue,  values, } = formik;

  useEffect( () => {
      if(productFromTable) {
          console.log(productFromTable);
          setProductId(productFromTable);
          setFieldValue("product_Id" , productFromTable?.id );
          setProfileHook(productFromTable?.image);         
      }
  },[productFromTable])


  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="create-sale">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems='center'>
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("sale-set")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Product Name
                    </Typography>

                    <Autocomplete
                      freeSolo
                      clearOnBlur
                      disablePortal
                      value={productId}
                      options={allProduct}
                      getOptionLabel={(option) => option?.label ? option?.label : "" }
                      isOptionEqualToValue={(option, value) => option.id === value.id || value === "" }
                      onChange={(e, newValue) => {
                        setFieldValue( "product_Id", newValue?.id ? newValue?.id : "" );
                        setProductId(newValue);
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          placeholder="Product"
                          {...params}
                          error={Boolean( touched.product_Id && errors.product_Id )}
                          helperText={touched.product_Id && errors.product_Id}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={!openCrop ? 4 : 12} className="grid-profile">
                    {!openCrop ? (
                      <Box>
                        <Stack
                          className="profile"
                          direction="row"
                          justifycontent="center"
                        >
                          <Button component="label" sx={{ p: "0px" }}>
                            <TextField
                              type="file"
                              id="image"
                              sx={{ display: "none" }}
                              onChange={handleUploadImage}
                            />
                            <Avatar
                              className="avater-image"
                              variant="square"
                              src={
                                imageFile
                                  ? URL.createObjectURL(imageFile)
                                  : profileHook
                              }
                            >
                              <PhotoSizeSelectActualOutlinedIcon
                                sx={{ fontSize: "70px" }}
                              />
                            </Avatar>
                            <input type="file" hidden />
                          </Button>
                        </Stack>
                      </Box>
                    ) : (
                      <CropImageFile
                        setImageFile={setImageFile}
                        photoURL={photoURL}
                        setOpenCrop={setOpenCrop}
                        setPhotoURL={setPhotoURL}
                        setUploadHook={setProfileHook}
                      />
                    )}
                  </Grid>

                  <Grid item xs={8} mt={5}>
                    <Stack direction="column">
                      <Box
                        sx={{
                          ml: "10px",
                          color: "blue",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        {productId?.label}
                      </Box>
                      {productId?.length !== 0 ? (
                        <Box sx={{ ml: "10px", display: "flex" }}>
                          <Typography variant="h6">
                            Costing:$
                            {productId?.unitPrice}/<b>{productId?.unit}</b>
                          </Typography>
                        </Box>
                      ) : null}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("choose-sale-type")}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        displayEmpty
                        {...getFieldProps("saleType")}
                        inputProps={{ "aria-label": "Without label" }}
                        error={Boolean(touched.saleType && errors.saleType)}
                        helperText={touched.saleType && errors.saleType}
                      >
                        <MenuItem value="Retail">Retail</MenuItem>
                        <MenuItem value="Wholesale">Wholesale</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    > 
                      Sale Qty
                    </Typography>

                    <TextField
                      onClick={(e) => e.target.select()}
                      InputProps={{
                        inputProps: {
                          type: "number",
                          min: 1,
                        },
                      }}
                      fullWidth
                      size="small"
                      placeholder="Input Qty"
                      {...getFieldProps("saleQty")}
                      error={Boolean(touched.saleQty && errors.saleQty)}
                      helperText={touched.saleQty && errors.saleQty}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "100%" }}
                    >
                      <Typography sx={{ fontSize: "30px", mt: "20px" }}>
                        /
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Qty in unit
                    </Typography>
                    <TextField
                      error={errorQtyInUnit}
                      value={productId?.qty_inUnit ? productId?.qty_inUnit : 0}
                      fullWidth
                      size="small"
                      placeholder="Qty In Unit"
                      aria-readonly
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Unit
                    </Typography>
                    <TextField
                      value={
                        values?.saleQty && productId?.qty_inUnit !== 0
                          ? (values?.saleQty / productId?.qty_inUnit)?.toFixed(
                              3
                            )
                          : 0
                      }
                      disabled
                      fullWidth
                      size="small"
                      placeholder="Unit"
                      aria-readonly
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>{productId?.unit}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Sale Name
                    </Typography> 
                    {/* <TextField
                      fullWidth
                      size="small"
                      placeholder="Sale Name"
                      {...getFieldProps("saleName")}
                      error={Boolean(touched.saleName && errors.saleName)}
                      helperText={touched.saleName && errors.saleName}
                    /> */} 
                    <FormControl size="small" fullWidth>
                      <Select
                        inputProps={{ "aria-label": "Without label" }}
                        {...getFieldProps("saleName")}
                        error={Boolean(touched.saleName && errors.saleName)}
                        helperText={touched.saleName && errors.saleName}
                        placeholder="Sale Name"
                      >
                        <MenuItem value={"កំប៉ុង/Can"}>កំប៉ុង/Can</MenuItem>
                        <MenuItem value={"ដប/Bottle"}>ដប/Bottle</MenuItem>                                 
                        <MenuItem value={"យួរ/"}>យួរ</MenuItem>
                        <MenuItem value={"កេស/Case"}>កេស/Case</MenuItem>                 
                        <MenuItem value={"កញ្ចប់/Pac"}>កញ្ចប់/Pac</MenuItem>    
                        <MenuItem value={"គីឡូ/kg"}>គីឡូ/kg</MenuItem>    
                        <MenuItem value={"ខាំ/Hg"}>ខាំ/Hg</MenuItem> 
                        <MenuItem value={"ប្រអប់/Box"}>ប្រអប់/Box</MenuItem>    
                        <MenuItem value={"ដុំ/Piece"}>ដុំ/Piece</MenuItem>               
                        <MenuItem value={"ដើម"}>ដើម</MenuItem>
                        <MenuItem value={"នំ"}>នំ</MenuItem>
                        <MenuItem value={"បន្ទះ"}>បន្ទះ</MenuItem>
                        <MenuItem value={"លីត្រ"}>លីត្រ/Lite</MenuItem>
                        <MenuItem value={"ឈុត/Set"}>ឈុត/Set</MenuItem>
                        <MenuItem value={"កែវ/Glass"}>កែវ/Glass</MenuItem>
                      </Select>
                    </FormControl>  
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("unit-price")}
                    </Typography>

                    <TextField
                      onFocus={(e) => e.target.select()}
                      fullWidth
                      size="small"
                      type="number"
                      placeholder="Unit Price"
                      {...getFieldProps("unit_Price")}
                      error={Boolean(touched.unit_Price && errors.unit_Price)}
                      helperText={touched.unit_Price && errors.unit_Price}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("dis-note")}
                    </Typography>

                    <TextField
                      onFocus={(e) => e.target.select()}
                      fullWidth
                      size="small"
                      placeholder="discount note"
                      {...getFieldProps("descriptions")}
                      error={Boolean(touched.descriptions && errors.descriptions)}
                      helpertext={touched.descriptions && errors.descriptions}
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("expire_date")}
                    </Typography> 
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd-MMM-yyyy"
                          value={values?.expireDate}
                          onChange={(e) => setFieldValue("expireDate" , e ? e : "")}
                          renderInput={(params) => (
                            <TextField
                              {...params} 
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>


                  </Grid>


                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("other")}
                    </Typography> 

                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder={t("other")}
                      {...getFieldProps("other")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("barcode")}
                    </Typography>

                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder="Type Barcode"
                      {...getFieldProps("saleQR")}
                    />
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
