import React, { useState, useContext, useEffect } from "react";
import "../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField } from "@mui/material";
import { VOID_EXPENSE } from "../../Schema/expense";
import { useMutation } from "@apollo/client";
//Schema
//Components
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Dymanic/Translate";

export default function DeleteExpense({
    open,
    handleClose,
    editRow,
    setRefetch,
}) {
    // Change Language
    const { language } = React.useContext(AuthContext);
    const { t } = translateLauguage(language);
    const [confirmVal, setConfirmVal] = React.useState("");
    const [loading, setLoading] = useState(false);
    const { setAlert } = useContext(AuthContext);

    //mutation Delete data
    const [voidExpenses] = useMutation(VOID_EXPENSE, {
        onCompleted: ({ voidExpenses }) => {
            if (voidExpenses?.status) {
                setAlert(true, "success", voidExpenses?.message);
                setLoading(false);
                setConfirmVal("")
                setRefetch();
                handleClose();
            } else {
                setAlert(true, "error", voidExpenses?.message);
                setLoading(false);
            }
        },
        onError: (error) => {
            setAlert(true, "error", error?.message);
            setLoading(false);
        },
    });

    const handleVoid = () => {
        setLoading(true)
        voidExpenses({
            variables: {
                voidExpensesId: editRow?._id,
            },
        });
    };

    useEffect(() => {
        setLoading(false)
        setConfirmVal('')
    }, [handleClose])

    return (
        <Dialog open={open} onClose={handleClose} className="delete-container">
            <DialogTitle className="dialog-title">
                <Stack direction="row" spacing={2}>
                    <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                        Void Expense
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClose}>
                        <CloseIcon className="close-icon" />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography
                        variant="subtitle1"
                        className={language === "kh" ? "simple-textKh" : "simple-textEn"}
                    >
                        {t("do-you-want-to-delete")}
                    </Typography>

                    <Stack direction="row" spacing={1}>
                        <Typography
                            variant="subtitle1"
                            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
                        >
                            {t("please-input")}

                        </Typography>
                        <Typography className="body-text" variant="subtitle1">
                            EXPENSE
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
                        >
                            {/* {t("to-delete")} */}
                            To Void
                        </Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="month"
                                fullWidth
                                size="small"
                                type="text"
                                onChange={(e) => setConfirmVal(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box className="action">
                            {confirmVal === "EXPENSE" ? (
                                <Button
                                    sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
                                    className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    loading={loading}
                                    onClick={handleVoid}
                                    disabled={loading}
                                >
                                    {/* {loading ? "Loading..." : t("delete-now")} */}
                                    {loading ? 'loading...' : 'Void'}
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={language === "kh" ? "btn-deleteKh" : "btn-deleteEn"}
                                    fullWidth
                                    disabled
                                >
                                    {/* {t("delete")} */}
                                    Void
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
