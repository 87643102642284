import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TableHead,
  Grid,
  TextField,
  InputAdornment,
  Tab,
  Autocomplete,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import { FooterPagination } from "../../../Dymanic/FooterPagination";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";
import TopRightBar from "../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../Menu/IconMenuResponsive";
import { useNavigate } from "react-router-dom";
import ViewPosActionOffline from "./ViewPosActionOffline";
import ViewPosAction from "./ViewPosAction";
import moment from "moment";
import PrintProductItem from "../PrintProductitem/PrintProductItem";
import PrintProductItemOffline from "../../PosOffline/PrintProductItemOffline/PrintProductItemOffline";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//component
import { useQuery } from "@apollo/client";
import { GET_CARD_PAGINATION } from "../../../Schema/viewpos";
import { GET_CUSTOMER_PAGENATION } from "../../../Schema/customer";
import { GET_PRODUCT_SETUP_WITH_PAGINATION } from "../../../Schema/setting";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { GET_ALL_CARD } from "../../../Schema/offlinecard";
import { GET_PRODUCT_FOR_SALE_PAGINIATION } from "../../../Schema/pos";
import { GET_PAYMENT_BY_CARD_ID } from "../../../Schema/offlinepayment";
import PosStatusOffline from "./posStatusOffline";

export default function ViewPos() {

  // ================= Change Language =================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true); 
 
  const [date,setDate] = useState(moment())


  // ============ get customer =============
  const [customerType, setCustomerType] = useState({ id: "", label: "" });
  const [customerOption, setCustomerOption] = useState([]);

  const { refetch: refechCustomer } = useQuery(GET_CUSTOMER_PAGENATION, {
    variables: {
      keyword: "",
      page: 1,
      limit: 50,
      pagination: true,
    },
    onCompleted: ({ getCustomersWithPagination }) => {
      if (getCustomersWithPagination?.data) {

        console.log(getCustomersWithPagination);

        let rows = [];
        getCustomersWithPagination?.data?.forEach((elem, row) => {
          const allRow = {
            id: elem?._id,
            label: elem?.name,
          };
          rows.push(allRow);
        });
        setCustomerOption(rows);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect( () => {
    refechCustomer();
  },[])
    

  //============================ Get Product ========================
  const [productSelected, setProductSelected] = useState({ id: "", label: "" });
  const [productOption,setProductOption] = useState([]);

  const { refetch: refetchProduct } = useQuery(GET_PRODUCT_FOR_SALE_PAGINIATION, {
    variables: {
      page: 1,
      limit: 10,
      category: "",
      type: "",
      keyword: "",
      saleQr: "",
      pagination: false,
    },
    onCompleted: ({ getProductForSalePagination }) => {
      // console.log(getProductForSalePagination)
      if(getProductForSalePagination?.data) {
          let rows = [];
          getProductForSalePagination?.data?.map( (item,index) => {
              const allRow = {
                id: item?._id, 
                label: item?.descriptions ? 
                    index+1+"-"+item?.name+item?.descriptions+" :"+item?.type : 
                    index+1+"-"+item?.name+" :"+item?.type
              }
              rows.push(allRow)
          })
          setProductOption(rows)
      }       
    },
    onError: (error) => {
      console.log(error?.message); 
    }, 
  });

  useEffect( () => {
    refetchProduct();
  },[])


  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState(""); 
  const [pagination, setPagination] = useState({});
  const [paginationStatus, setPaginationStatus] = useState(true);


  //================== Get Card Pagination ======================
  const [tableData, setTableData] = useState([]);
  const { refetch } = useQuery(GET_CARD_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      from: date ? moment(date).format("YYYY-MM-DD") : "",
      to: date ? moment(date).format("YYYY-MM-DD") : "",
      pagination: paginationStatus,
      keyword: keyword,
      customer: customerType?.id ? customerType?.id : "",
      product: productSelected?.id ? productSelected?.id : "",
    },
    onCompleted: ({ getCartsPagination }) => {
      setTableData(getCartsPagination?.data);
      setPagination(getCartsPagination?.paginator);
      setLoading(false);
      // console.log(getCartsPagination);
    },
    onError: (error) => {
      console.log(error.message);
      setLoading(true);
    },
  });

  useEffect( () => {
    refetch();
  },[customerType?.id, productSelected?.id , date , page , paginationStatus , keyword])
 

  //print
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const handleClosePreview = () => setOpenPreview(false);
  const handleOpenPreview = (row) => {
    setPreviewData(row);
    setOpenPreview(true);
  };


  //print
  const [openPreviewOffline, setOpenPreviewOffline] = useState(false);
  const [previewDataOffline, setPreviewDataOffline] = useState(null);
  const handleClosePreviewOffline = () => setOpenPreviewOffline(false);
  const handleOpenPreviewOffline = (row) => {
    setPreviewDataOffline(row);
    setOpenPreviewOffline(true);
  };



  //NAVIGATE BACK TO POS
  const navigate = useNavigate();
  const pos = () => {
    navigate("/pos");
  };

  const handleLimit = (e) => {
    setLimit(e.target.value);
    if (e.target.value === 0) {
      setPaginationStatus(false);
      setPage(1);
    } else {
      setPaginationStatus(true);
      setPage(1);
    }
  };

  useEffect(() => {
    setShowPage(page);
  }, [page, limit, keyword]);


  // values tap
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
 
  //======================= GET CARD OFFLINE ======================
  const [refetchCardOffiline,setRefetchCardOffiline] = useState(true);
  const [cardDataOffline,setCardDataOffline] = useState([]); 

  useEffect( () => {
    async function fetchDataOffline() {  
      await GET_ALL_CARD().then( (res) => {
          if(res.status) { 
            setCardDataOffline(res?.data);
            setRefetchCardOffiline(false);
          } else {
            setCardDataOffline([]);
            setRefetchCardOffiline(false);
          }
      })  
    }
    fetchDataOffline();
    
  },[value,refetchCardOffiline])
 

    
  return (
    <div className="page-container">

      <PrintProductItem
          setRefetch={refetch}
          open={openPreview}
          handleClose={handleClosePreview}
          type="viewPos"
          receipt={previewData}
      /> 

      <PrintProductItemOffline 
          open={openPreviewOffline}
          handleClose={handleClosePreviewOffline}
          type="viewPos"
          receipt={previewDataOffline}
      /> 

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              className="page-title"
              onClick={() => pos()}
              sx={{ cursor: "pointer" }}
            >
              POS
            </Typography>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">View POS</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextField
            placeholder="search invoice N°"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>


        <Grid item xs={12} sm={4} md={3} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                onChange={(e) => setDate(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
        </Grid>


        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Autocomplete
              freeSolo
              clearOnBlur
              className="text-field"
              size="small"
              disablePortal
              options={customerOption}
              value={customerType}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => setCustomerType(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Customer" />
              )}
          />
        </Grid>  


        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Autocomplete
              freeSolo
              clearOnBlur
              className="text-field"
              size="small"
              disablePortal
              options={productOption}
              value={productSelected}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => setProductSelected(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Product" />
              )}
          />
        </Grid>


      </Grid>


      <Box sx={{ width: '100%' ,mt:2}}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Online" value="1" sx={{fontWeight: "bold", fontSize: "17px" , textTransform: "none"}}/>
              <Tab label="Offline" value="2" sx={{fontWeight: "bold", fontSize: "17px" , textTransform: "none"}}/> 
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0}}> 

            <Box className="body-container">
              <TableContainer>
                <Table className="table" aria-label="simple table">
                  <TableHead className="header-row ">
                    <TableRow>
                      <TableCell
                        className= {
                          language === "kh"
                            ? "header-titleKh-start"
                            : "header-title-start"
                        }
                      >
                        Invoice N°
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      >
                        Date
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      >
                        Customer
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      >
                        Sale By
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className={
                          language === "kh" ? "header-titleKh" : "header-title"
                        }
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <LoadingPage />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <React.Fragment>
                        {tableData?.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <EmptyData />
                            </TableCell>
                          </TableRow>
                        ) : (
                          tableData.map((data, index) => {
                            return (
                              <TableRow
                                sx={{ bgcolor: data?.is_voided && "#fff0f0" }}
                                key={index}
                                className={ data?.is_voided ? "body-row void" : 'body-row'}
                              >
                                <TableCell
                                  className={
                                    language === "kh"
                                      ? "body-cellKh-start"
                                      : "body-cell-start"
                                  }
                                  onClick={() => handleOpenPreview(data)}
                                >
                                  {data?.numbering}
                                </TableCell>
                                <TableCell
                                  className={
                                    language === "kh"
                                      ? "body-cellKh-start"
                                      : "body-cell-start"
                                  }
                                  onClick={() => handleOpenPreview(data)}
                                >
                                  {moment(data?.date)?.utc(false)?.format("MMM Do YY hh:mm a")}
                                </TableCell>
                                <TableCell
                                  className={
                                    language === "kh"
                                      ? "body-cellKh-start"
                                      : "body-cell-start"
                                  }
                                  onClick={() => handleOpenPreview(data)}
                                >
                                  {data?.customer_id?.name}
                                </TableCell>
                                <TableCell
                                  className={
                                    language === "kh"
                                      ? "body-cellKh-start"
                                      : "body-cell-start"
                                  }
                                  onClick={() => handleOpenPreview(data)}
                                >
                                  { data?.create_by ? data?.create_by?.firsName+" "+data?.create_by?.lastName : "" }
                                </TableCell>
                                <TableCell
                                  className={ language === "kh" ? "body-cellKh-start" : "body-cell-start" }
                                  onClick={() => handleOpenPreview(data)}
                                >
                                  $ {data?.total?.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleOpenPreview(data)}
                                  className={ language === "kh" ? "body-cellKh-start" : "body-cell-start" }
                                  sx={{ 
                                    fontWeight: "bold",
                                    color: data?.payment_status === "Unpaid" ? "red": 
                                    data?.payment_status === "Owe" ? "Orange" : 
                                    "green" 
                                  }}
                                >
                                  {data?.payment_status}
                                </TableCell>
                                <TableCell className="body-cell-end" align="right">
                                  <ViewPosAction
                                    editRow={data}
                                    setRefetch={refetch}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              </TableContainer> 
              
            </Box> 
          
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0}}>
           
           
            <Box className="body-container">
                <TableContainer>
                  <Table className="table" aria-label="simple table">
                    <TableHead className="header-row ">
                      <TableRow>
                        <TableCell
                          className={
                            language === "kh"
                              ? "header-titleKh-start"
                              : "header-title-start"
                          }
                        >
                          Invoice N°
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        >
                          Customer
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        >
                          Sale By
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "header-titleKh" : "header-title"
                          }
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                       
                      {
                        refetchCardOffiline ? 
                        <React.Fragment> 
                            <TableRow>
                              <TableCell colSpan={7}>
                                  <LoadingPage />
                              </TableCell>
                            </TableRow> 
                        </React.Fragment> 
                      : 
                        <React.Fragment>
                          {cardDataOffline?.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={7}>
                                <EmptyData />
                              </TableCell>
                            </TableRow>
                          ) : (
                            cardDataOffline?.map((data, index) => {
                              return (
                                <TableRow
                                  sx={{ bgcolor: data?.is_voided && "#fff0f0" }}
                                  key={index}
                                  className={ data?.is_voided ? "body-row void" : 'body-row'}
                                >
                                  <TableCell
                                    className={
                                      language === "kh"
                                        ? "body-cellKh-start"
                                        : "body-cell-start"
                                    }
                                    onClick={ () => handleOpenPreviewOffline(data)}
                                  >
                                    ---:---
                                  </TableCell>
                                  <TableCell
                                    className={
                                      language === "kh"
                                        ? "body-cellKh-start"
                                        : "body-cell-start"
                                    }
                                    onClick={() => handleOpenPreviewOffline(data)}
                                  >
                                    {moment(data?.date).format("MMM Do YY")}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      language === "kh"
                                        ? "body-cellKh-start"
                                        : "body-cell-start"
                                    }
                                    onClick={() => handleOpenPreviewOffline(data)}
                                  >
                                    {data?.customer_id?.name}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      language === "kh"
                                        ? "body-cellKh-start"
                                        : "body-cell-start"
                                    }
                                    onClick={() => handleOpenPreviewOffline(data)}
                                  >
                                    { data?.create_by ? data?.create_by?.firsName+" "+data?.create_by?.lastName : "" }
                                  </TableCell>
                                  <TableCell
                                    className={ language === "kh" ? "body-cellKh-start" : "body-cell-start" }
                                    onClick={() => handleOpenPreviewOffline(data)}
                                  >
                                    $ {data?.total?.toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => handleOpenPreviewOffline(data)}
                                    className={ language === "kh" ? "body-cellKh-start" : "body-cell-start" } 
                                  >
                                    <PosStatusOffline editRow={data}/>
                                  </TableCell>
                                  <TableCell className="body-cell-end" align="right" width="10%">
                                    <ViewPosActionOffline
                                      editRow={data}
                                      setRefetchCardOffiline={setRefetchCardOffiline}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          )}
                        </React.Fragment> 
                      }

                    </TableBody>
                  </Table>
                </TableContainer> 
              
              </Box> 
          

          </TabPanel> 
        </TabContext>
      </Box>


      {/* ==================== Pagination  ============================= */}
      <FooterPagination
        showPage={showPage}
        totalPages={pagination?.totalPages}
        limit={limit}
        setPage={setPage}
        handleLimit={handleLimit}
      />

    </div>
  );
}
