import { DB_WEB } from "../Config/DB_Web";

const { payment_collection } = DB_WEB; 


export async function GET_PAYMENT_BY_CARD_ID (card_id) {
    try {

        if(card_id) {
            const paymentData = await payment_collection
                .filter( e => e?.card_id === card_id )
                .toArray();

            if(paymentData) {
                return {
                    message: "Get payment success!", 
                    status: true,  
                    data: paymentData 
                }; 
            }  

        } else {
            return {
                message: "Get payment not success!", 
                status: false,  
            };
        } 

    } catch (error) {
        // Do something 
        return {
            message: "Get payment not success!", 
            status: false, 
        }; 
    }
}



export async function GET_PAYMENT_BY_CARD_ID_POST_LIVE (card_id) {
    try {

        if(card_id) {
            const paymentData = await payment_collection
            .filter( e => e?.card_id === card_id )
            .toArray();

            const dataPayLive = await Promise.all(
                paymentData?.map( (rowPay) => {
                    return {
                        amount_owe:  rowPay?.amount_owe,
                        date: rowPay?.date,
                        is_voided: rowPay?.is_voided,
                        paid_amount: rowPay?.paid_amount,
                        payment_method: rowPay?.payment_method,
                        receive_amount: rowPay?.receive_amount,
                        repayment_status: rowPay?.repayment_status,
                        return_amount: rowPay?.return_amount,
                    }
                })
            )

            if(dataPayLive) {
                return {
                    message: "Get payment success!", 
                    status: true,  
                    data: dataPayLive 
                }; 
            }  
            
        } else {
            return {
                message: "Get payment not success!", 
                status: false,  
            };
        }
        

    } catch (error) {
        // Do something 
        return {
            message: "Get payment not success!", 
            status: false, 
        }; 
    }
}



export async function CREATE_PAYMENT_BY_CARD_ID (values) { 
    try {
        // Use the add() method to add data to the array-based object store
        const payment_id = await payment_collection.add(values); 

        // Do something with the addedItemId if needed
        if(payment_id) {  
            return { 
                message: "Create success.", 
                status: true, 
                data: payment_id,
            };
        }  

    } catch (error) {
        // Do something 
        return {
            message: "Create payment not success!", 
            status: false, 
        };
    } 
}


export async function DELETE_PAYMENT_BY_CARD_ID (parameter) {

    try {

        await payment_collection
            .where("card_id")
            .equals(parameter)
            .delete();

        return {
            message: "Delete payment success!", 
            status: true, 
        };

    } catch (error) {
        // Do something 
        return {
            message: "Delete payment not success!", 
            status: false, 
        };
    }
}