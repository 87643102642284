import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: PaymentInput!) {
    createPayment(input: $input) {
      message
      status
    }
  }
`;

export const GET_TOTAL_PAID_AMOUNT_CARD = gql`
  query Query($cardId: ID) {
    getPaidAmountByCardId(cardId: $cardId)
  }
`;


export const CREATE_CARD_AND_PAYMENT = gql`
  mutation SaleWithPayment($inputPayment: [PaymentSaleInput], $inputCart: inputCart) {
    saleWithPayment(inputPayment: $inputPayment, inputCart: $inputCart) {
      message
      status
    }
  }
`

export const CREATE_CARD = gql`
  mutation CreateCart($input: inputCart!) {
    createCart(input: $input) {
      data {
        _id
        customer_id {
          _id
          name
          tel
          address
          remark
          image_src
          image_name
          created_at
        }
        create_by {
          _id
          firsName
          lastName
        }
        status
        numbering
        isDiscount
        is_voided
        date
        payment_status
        discount_total
        sub_total
        tax_total
        exchange_rate
        total
        items {
          _id
          product_id_setup {
            _id
            saleName
            descriptions
            saleQty
            saleType
            saleQR
            product_Id {
              _id
              name
              cost
              category
              image_src
              type
              feature
              unit
              qty_inUnit
              bar_Code
            }
            unit_Price
          }
          discount_id {
            _id
            amount_discount
            end_date
            events
            percentage
            start_date
            status
          }
          price
          qty
          percentage_discount
          amount_discount
          qty_in_product          
          qty_in_shop
          unit
          name
          image_src          
        }
        created_at
      }
      message
      status
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomers($keyword: String!) {
    getCustomers(keyword: $keyword) {
      data {
        _id
        name
        tel
        image_src
      }
    }
  }
`;

export const GET_ALL_CARD = gql`
  query GetAllCart {
    getAllCart {
      _id
      customer_id {
        _id
        address
        name
        tel
      }
      create_by {
        _id
        firsName
        lastName
      }
      created_at
      status
      numbering
      payment_status
      sub_total
      exchange_rate
      total
      date
      isDiscount
      items {
        discount_id {
          _id
          amount_discount
          events
          percentage
        }
        product_id_setup {
          _id
          product_id {
            _id
            category
            name
            unit
            image_src
          }
          qr_code
          qty_in_product
          qty_on_sale
          type_on_sale
          unit_on_sale {
            _id
            name
          }
          unit_price_on_sale
        }
      }
    }
  }
`;


export const GET_PRODUCT_FOR_SALE_PAGINIATION = gql`
query GetProductForSalePagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean, $category: String, $type: String, $saleQr: String) {
  getProductForSalePagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination, category: $category, type: $type, saleQR: $saleQr) {
      data {
        _id
        type
        qty
        unit
        name
        image
        saleQR
        descriptions
        category
        total_inShop
        product_Id {
          _id
          name
          cost
          category
          image_src
          remark
          type
          feature
          unit
          qty_inUnit
          bar_Code
          created_At
        }
        image_src
        unit_Price
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
