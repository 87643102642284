import Dexie from 'dexie';

//------------------ DB NAME ----------------------------
const DB_WEB = new Dexie('posOfflineApp');


//------------------ Define data collection -------------
DB_WEB.version(1).stores({
    product_collection: '_id,type,unit,name,image,saleQR,category,total_inShop,unit_Price,descriptions', 
    customer_collection: '_id,name,tel',
    card_collecton: '_id,date,customer_id,*items,payment_status,sub_total,discount_total,tax_total,total,exchange_rate,shop_Id',
    discount_collection: "_id,amount_discount,end_date,events,percentage,remark,start_date,status",
    payment_collection: "_id,amount_owe,card_id,date,is_voided,payment_method,repayment_status,receive_amount,return_amount,paid_amount",
    
})

DB_WEB.open();

export { DB_WEB };