import "../../../Style/previewstyle.scss";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import moment from "moment";
export default function PreviewSale({ open, handleClose, data }) {
  // console.log("data", data);
  const cost = () => {
    let price = 0;
    data?.raw_materials?.map((e) => {
      price += e?.raw_material?.cost * e?.qty;
    });
    return price;
  };

  const title = [
    { title: "Product Name", data: data?.product_id?.name },
    { title: "Unit", data: data?.unit_on_sale?.name },
    { title: "Category", data: data?.product_id?.category },
    { title: "Cost", data: cost(data?.unit_price_on_sale) },
    { title: "Bar Code", data: data?.qr_code !== "" ? data?.qr_code : "null" },
    { title: "Sale Type", data: data?.type_on_sale },
    { title: "Sale Set Up", data: "Yes" },
    { title: "Sale Veiw", data: "" },
  ];
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="preview"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">Preview Sale</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Stack
            direction="row"
            spacing={6}
            className="content"
            alignItems={"center"}
          >
            <Box>
              {data?.product_id?.image_src === "" ? (
                <ImageNotSupportedOutlinedIcon
                  sx={{ width: "150px", height: "150px" }}
                />
              ) : (
                <img
                  src={data?.product_id?.image_src}
                  alt=""
                  className="image"
                />
              )}
            </Box>

            <TableContainer>
              <Table>
                <TableBody className="body">
                  {title?.map((e, i) =>
                    e?.title !== "Sale Veiw" ? (
                      <TableRow key={i}>
                        <TableCell className="body-cell" width={"40%"}>
                          {e?.title}
                        </TableCell>
                        <TableCell
                          className="body-cell"
                          width={"10%"}
                        ></TableCell>
                        :
                        <TableCell className="data">
                          {/* {e?.title === "Unit" && "$ "} */}
                          {e?.title === "Cost" && "$ "}
                          {e?.data}
                        </TableCell>
                      </TableRow>
                    ) : null
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
