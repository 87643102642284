import React, { useReducer, createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
// reducer
const firebaseReducer = (state, action) => {
  // console.log("state::", state);
  switch (action.type) {
    case "LOGGED_IN_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

// state
const initialState = {
  user: null,
};

// create context
const AuthContext = createContext();

// context provider
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(firebaseReducer, initialState);
  const [role, setRole] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // console.log(user?.auth?.currentUser.displayName, "user");
        user?.auth?.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // console.log(idToken);
            // console.log( "role" , user?.auth?.currentUser?.displayName);
            setRole(user?.auth?.currentUser?.displayName);
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: user?.email,
                token: idToken,
              },
            });
          })
          .catch(function (error) {
            // Handle error
          });
      } else {
        dispatch({
          type: "LOGGED_IN_USER",
          payload: null,
        });
      }
    });

    // console.log(
    //   "noneUserAccesse : ",

    // );
    // let my = noneUserAccesse(["admin", "employee"]);
    // console.log(my);
    // cleanup
    return () => unsubscribe();
  }, []);

  // Check role and Permissions
  // this function call when we need to disable the component
  const noneUserAccesse = (roles) => {
    const getRole = roles.filter((rol) => rol === role);
    if (getRole.length > 0) {
      //no access
      return false;
    } else {
      //can access
      return true;
    }
  };

  // change language
  const [language, setLanguage] = useState("en");
  const changeLanguage = (lang) => {
    // console.log("Auth Language => ",lang)
    setLanguage(lang);
  };


  // ========================= Detect Internet =======================
  const [connectMode , setConnectMode] = useState(navigator.onLine);
  const handleStatusChange = () =>  setConnectMode(navigator.onLine);

  useEffect( () => {  
    //================= SET CONNECTION LOCAL =================
    // window.localStorage.setItem("onlineMode" , connectMode);
    
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);  
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };    
  }, [connectMode]);


  // Alert Message =================================================
  const [open, setOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [messageAlert, setMessageAlert] = useState("");

  const setAlert = (open, alert, message) => {
    setOpen(open);
    setAlertStatus(alert);
    setMessageAlert(message);
  };
  const alert = () => {
    return { open: open, status: alertStatus, message: messageAlert };
  };

  const value = {
    state,
    dispatch,
    noneUserAccesse,
    alert,
    setAlert,
    changeLanguage,
    language,
    connectMode
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export
export { AuthContext, AuthProvider };
