import React, { useContext, useState } from "react";
import "./previewexpense.scss";
import {
    // Button,
    Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography,
    Grid,
    Stack,
    Box,
    // TextField,
    Divider,
    // MenuItem,
    // FormControl,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    // Select,
    // InputAdornment,
    IconButton
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../Context/AuthContext";

export default function PreviewExpense({
    open,
    handleClosePreview,
    editRow,
    // t,
    language,
}) {
    // console.log(editRow)
    const amountUsd = (array) => {
        let amount = 0
        array?.map(e => {
            if (e.type_currency === "USD") {
                amount += e?.total_amount
            }
        })
        return amount?.toFixed(2)
    }
    const amountRiel = (array) => {
        let amount = 0
        array?.map(e => {
            if (e.type_currency === "Riel") {
                amount += e?.total_amount
            }
        })
        return amount
    }

    return (
        <Dialog open={open} onClose={handleClosePreview} className="preview-expense" fullWidth maxWidth='sm'>
            <DialogTitle className="dialog-title">
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                        Preview Expense
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClosePreview}>
                        <CloseIcon className="clear-icon" />
                    </IconButton>
                </Stack>
                <Divider />
            </DialogTitle>

            <DialogContent className="content">
                <DialogContentText>
                    {
                        editRow?.is_voided ? <Typography className="voided">Voided</Typography> : null
                    }

                    <Stack direction='row' justifyContent='space-between' mb={3}>
                        <Stack direction='row' spacing={1}>
                            <Typography className="title">Title : </Typography>
                            <Typography>{editRow?.titles}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            <Typography className="title">Date : </Typography>
                            <Typography>{moment(editRow?.date)?.format('YYYY/MM/DD')}</Typography>
                        </Stack>
                    </Stack>
                    <TableContainer >
                        <Table sx={{width:'100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="header-cell">Expense on</TableCell>
                                    <TableCell className="header-cell">Qty</TableCell>
                                    <TableCell className="header-cell">Unit Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    editRow?.items?.map((e, i) => (
                                        <TableRow key={i}>
                                            <TableCell className="body-cell">{e?.item_name}</TableCell>
                                            <TableCell className="body-cell">{e?.qty}</TableCell>
                                            <TableCell className="body-cell">$ {e?.unit_price}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow >
                                    <TableCell align="right" colSpan={3} className='footer'>
                                        <Divider />
                                        <Stack direction='row' spacing={2} justifyContent='end' mt={2}>
                                            <Typography>Expense Amount (USD) : </Typography>
                                            <Typography className="title">$ {amountUsd(editRow?.expense_amount)}</Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="right" colSpan={3} className='footer'>
                                        <Stack direction='row' spacing={2} justifyContent='end'>
                                            <Typography >Expense Amount (RIEL) : </Typography>
                                            <Typography className="title">៛ {amountRiel(editRow?.expense_amount)}</Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>

            <DialogActions>

            </DialogActions>
        </Dialog>
    );
}
