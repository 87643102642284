import * as React from "react";
// import "./customeraction.scss";
import { IconButton, Typography, Stack , Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom";

import VoidReceive from "./VoidReceive";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";

import { useQuery } from "@apollo/client"
import { GET_PAYMENT_BY_CARDID } from "../../../Schema/viewpos";
import moment from "moment"

export default function ViewPosAction({ editRow, setRefetch }) {

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  //===============GET PAYMENT BY CARD ID=================
  const [listPayment, setListPayment] = React.useState([]);
  const { refetch } = useQuery(GET_PAYMENT_BY_CARDID, {
    variables: {
      cardId: editRow?._id,
    },
    onCompleted: ({ getPaymentByCartId }) => {
      // console.log(getPaymentByCartId)
      setListPayment(getPaymentByCartId);
    },
    onError: (error) => {
      console.log(error.message);
    }
  });

  React.useEffect(() => {
    refetch()
  }, [editRow?._id])


  const navigate = useNavigate();
  const backToPOS = (cardId) => {
    navigate("/pos/update", { state: { cardId } });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openVoid, setOpenVoid] = React.useState(false);
  const handleOpenVoid = () => setOpenVoid(true);
  const handleCloseVoid = () => setOpenVoid(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(editRow);

  return (
    <div>

      {/* <IconButton
        onClick={handleClick}
        disabled={editRow?.is_voided ? true : false}
      >
        <MoreVertIcon className="three-point" />
      </IconButton> */}


      <Button
        onClick={handleOpenVoid}        
        disabled={editRow?.is_voided 
          // || moment(editRow?.created_at).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY") 
          ? true : false
        }
      >
          <Stack direction="row" spacing={1}  className="action">
              <DeleteForeverOutlinedIcon className="icon-menu-delete" />
              <Typography className="void-card">Void</Typography>
          </Stack>
      </Button>


      {/* <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {
            backToPOS(editRow?._id);
          }}         
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="icon-menu-update" />
            <Typography>
                Update
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenVoid();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="icon-menu-delete" />
            <Typography>Void</Typography>
          </Stack>
        </MenuItem>
      </Menu> */}

      <VoidReceive
        language={language}
        t={t}
        open={openVoid}
        editRow={editRow}
        setRefetch={setRefetch}
        handleCloseVoid={handleCloseVoid}
      />
    </div>
  );
}
