import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TableHead,
  Grid,
  Modal,
  IconButton,
} from "@mui/material";
import "./subtablesale.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GET_PRODUCT_SETUP_BY_PRODUCT_ID } from "../../../Schema/setting";
import { useQuery } from "@apollo/client";
import EmptyData from "../../../Component/Include/EmptyData";
import LoadingPage from "../../../Component/Include/LoadingPage";
import SaleAction from "./SaleAction";

export default function SubTableSale({ id , handleOpen }) {

  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState([]); 

  //MAIN PRODUCT
  const { data, refetch } = useQuery(GET_PRODUCT_SETUP_BY_PRODUCT_ID, {
    variables: { 
      productId: id ,
    },
    onCompleted: ({ getProductSetUpByProductId }) => {
      setRowData(getProductSetUpByProductId);
      // console.log(getProductSetUpByProductId);
      setLoading(false);
    },
    onError: (err) => {
      // console.log(err?.message);
      setLoading(true);
    },
  });

  useEffect(() => {
    refetch();
  }, [id]);
  // console.log("SubTableData", rowData);
  return (
    <Table className="table">
      {loading ? (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <LoadingPage />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <React.Fragment>
          {rowData?.lenght === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <EmptyData />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody className="body">                
                  <TableRow className="body-row">
                    <TableCell className="body-start" width="1%" onClick={handleOpen}></TableCell>
                    <TableCell className="body-title" sx={{color:"#0F81C2" , fontWeight: "bold"}} align="center"  colSpan={6} onClick={handleOpen}>
                        Add SetUp Here
                    </TableCell>
                    <TableCell className="body-title" width="1%" onClick={handleOpen}></TableCell>
                  </TableRow>
              </TableBody>

              {
                rowData?.map( (row, index) => (
                  <TableBody className="body" key={index}>                
                    <TableRow className="body-row">
                      <TableCell className="body-start" width="1%">
                        {index + 1}
                      </TableCell>
                      <TableCell className="body-title" width="21%">
                        {row?.product_Id?.name} {row?.descriptions}
                      </TableCell>
                      <TableCell className="body-title" width="10%">
                        {row?.product_Id?.category}
                      </TableCell>
                      <TableCell className="body-title" width="10%">
                        $ {row?.unit_Price} - 
                        {/* {row?.product_Id?.qty_inUnit === row?.saleQty*row?.product_Id?.qty_inUnit ? row?.saleQty : row?.saleQty*row?.product_Id?.qty_inUnit} */}
                        {row?.saleName}                    
                      </TableCell>
                      <TableCell className="body-title" width="10%">
                        {row?.saleType}
                      </TableCell>
                      <TableCell className="body-title" width="15%">
                        {row?.other}
                      </TableCell>
                      <TableCell className="body-title" width="5%" sx={{ fontWeight: "bold" , color: row?.isUse ? "green" : "red" }}>
                        {row?.isUse ? "is Using" : "Not use"}
                      </TableCell>
                      <TableCell className="body-title" align="right">
                        <SaleAction editRow={row} setRefetch={refetch} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              }
            </>
          )}
        </React.Fragment>
      )}
    </Table>
  );
}
