import { gql } from "@apollo/client";

export const SET_EXCHANGE_RATE = gql`
  mutation AddExchange($rate: Float!) {
    addExchange(rate: $rate)
  }
`;

export const GET_EXCHANGE_RATE = gql`
  query GetExchangeRate {
    getExchangeRate {
      _id
      rate
      created_At
    }
  }
`;

export const UPDATE_SALE_SETUP_PRODUCT = gql`
  mutation UpdateSaleSetUpProduct(
    $updateSaleSetUpProductId: ID!
    $input: inputSaleProduct
  ) {
    updateSaleSetUpProduct(id: $updateSaleSetUpProductId, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_SALE_SETUP_PRODUCT = gql`
  mutation DeleteSaleSetUpProduct($deleteSaleSetUpProductId: ID!) {
    deleteSaleSetUpProduct(id: $deleteSaleSetUpProductId) {
      message
      status
    }
  }
`;

export const CREATE_SETUP_SALE_PRODUCT = gql`
  mutation SetUpSaleProduct($input: inputSaleProduct!) {
    setUpSaleProduct(input: $input) {
      message
      status
    }
  }
`;


export const UPDATE_IS_USED_PRODOUCT = gql`
  mutation UpdateIsUse($status: Boolean, $updateIsUseId: ID) {
    updateIsUse(status: $status, id: $updateIsUseId) {
      message
      status
    }
  }
`;

export const GET_PRODUCT_SETUP_WITH_PAGINATION = gql`
  query GetProductSetUpWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $category: String
  ) {
    getProductSetUpWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      category: $category
    ) {
      data {
        _id
        name
        cost
        category
        image_src
        remark
        type
        feature
        unit
        qty_inUnit
        set_sale_status
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_PRODUCT_SETUP_BY_PRODUCT_ID = gql`
  query GetProductSetUpByProductId($productId: ID!) {
    getProductSetUpByProductId(productId: $productId) {
      _id
      saleName
      image
      saleQty
      saleType
      saleQR
      descriptions
      isUse
      other
      expireDate
      product_Id {
        _id
        name
        cost
        category
        image_src
        remark
        type
        feature
        unit
        qty_inUnit
        bar_Code
        created_At
      }
      unit_Price
      created_At
    }
  }
`;
export const GET_UNIT = gql`
  query GetUnit {
    getUnit {
      _id
      name
      created_At
    }
  }
`;

export const GET_ALL_CATEGORY = gql`
  query Query {
    getCategorise
  }
`;

export const GET_ALL_SHOPS = gql`
  query GetAllShops($keyword: String!) {
    getAllShops(keyword: $keyword) {
      _id
      name
      name_kh
      email
      phone
      address
      remark
      created_At
    }
  }
`;

export const GET_ALL_PRODUCT = gql`
  query GetAllProduct {
    getAllProduct {
      _id
      name
      cost
      category
      image_src
      remark
      type
      feature
      unit
      qty_inUnit
      unit_of_qty_inUnit
      bar_Code
      created_At
    }
  }
`;
