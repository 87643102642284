import React from "react";
import { Box, Button, Slider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Cropper from "react-easy-crop";
import "./cropimagefile.scss";
import getCroppedImg from "./Crop";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import axios from "axios";
import moment from "moment";

export default async function UploadImage(file) {
  //compress image in customer
  let uploadHook = "";

  if (!file) return;

  let formData = new FormData();
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const compressedFile = await imageCompression(file, options);
  let newName = `${uuidv4()}${file.name.split(".").pop()}`;
  var newFile = new File([compressedFile], `${newName}.png`, {
    type: "image/png",
  });

  formData.append("image", newFile);
  
  try {
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/loan-go-credit-Image/upload`,
        formData,
        config
      )
      .then(async function (response) {
        console.log(response?.data, "response");
        uploadHook = `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`;
      });
  } catch (error) {
    console.log("error", error);
  }

  return uploadHook;
}
