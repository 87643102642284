import React, { useState, useEffect, useRef } from "react";
import "./paymentmethod.scss";
import {
  Stack,
  Button,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
//ICON
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { NumericFormat } from 'react-number-format';


export default function Cash({
  closeCash,
  totalAmount,
  exChangeRate,
  setReceiveAmount,
  setReturnAmount,
  setAmountOwe,
  setPaidAmount,
  setRequireField
}) {


  let textInputUSD = useRef(null);
  let textInputKHR = useRef(null);

  //receive money state
  const [usd, setUsd] = useState(0);
  const [khr, setKhr] = useState(0);
  const [totalReceiveAmount, setTotalReceiveAmount] = useState(0);

  //change money state
  const [changeUsd, setChangeUsd] = useState(0);
  const [changeKhr, setChangeKhr] = useState(0);

  const updateUSD = (value) => {
    setUsd(value);
  };
  const updateKHR = (value) => {
    setKhr(value);
  };

  const autoSetUsd = () => {
    setUsd(parseFloat(totalAmount));
  };

  const autoSetKhr = () => {
    setKhr(parseFloat(totalAmount * exChangeRate));
  };

  const updateUsdChange = (value) => {
    let remain = parseFloat((totalReceiveAmount - totalAmount - value)?.toFixed(2) );
    setChangeUsd(parseFloat(value));
    setChangeKhr(parseFloat(remain * exChangeRate)?.toFixed(2));
  };

  const updateKhrChange = (value) => {
    let remain = parseFloat( (totalReceiveAmount - totalAmount - value / exChangeRate)?.toFixed(2));
    setChangeKhr(value);
    setChangeUsd(parseFloat(remain)?.toFixed(2));
  };

  // console.log(changeUsd, changeKhr);
  // ============================= get total Receive Amount ====================================
  const handleSetTotalReceiveAmount = () => {
    setTotalReceiveAmount(parseFloat(usd) + parseFloat(khr / exChangeRate));
  };

  useEffect(() => {
    handleSetTotalReceiveAmount();
    setChangeUsd(0);
    setChangeKhr(0);
  }, [usd, khr, exChangeRate]);



  // ================================================= check require field =====================
  useEffect( () => {
    if (totalAmount !== 0) {
        if(totalReceiveAmount === 0) {
            setRequireField(true);
        } else if(totalReceiveAmount - totalAmount > 0) {
            setRequireField(true);
        } else {
            setRequireField(false);
        }
    } else {
        setRequireField(true);
    }
  },[totalReceiveAmount])


  useEffect( () => {
    if (totalAmount !== 0) {
      if(changeUsd !== 0 || changeKhr !== 0 ) {
          setRequireField(false);
      } else {
          if(totalReceiveAmount - totalAmount > 0) {
              setRequireField(true);
          // } else if(totalReceiveAmount === 0){
          //     setRequireField(true);
          } else {
              setRequireField(false);
          }
      }
    } else {
      setRequireField(true);
    }
  },[changeUsd,changeKhr])
  


  // =============================================== =set value to create payment ===============
  useEffect(() => {
    setReceiveAmount([
      { amount: parseFloat(usd), from: "Cash", type_currency: "USD" },
      { amount: parseFloat(khr), from: "Cash", type_currency: "RIEL" },
    ]);
    setReturnAmount([
      { amount: parseFloat(changeUsd), from: "Cash", type_currency: "USD" },
      { amount: parseFloat(changeKhr), from: "Cash", type_currency: "RIEL" },
    ]);

    if (usd === 0 && khr === 0) {
      setAmountOwe([
        { amount: totalAmount, from: "Cash", type_currency: "USD" },
        { amount: 0, from: "Cash", type_currency: "RIEL" },
      ]);
      // paid amount
      setPaidAmount(0);
    } else if (usd !== 0 && khr === 0) {
      setAmountOwe([
        {
          amount: totalAmount - totalReceiveAmount > 0 ? totalAmount - totalReceiveAmount : 0,
          from: "Cash",
          type_currency: "USD",
        },
        { amount: 0, from: "Cash", type_currency: "RIEL" },
      ]);
      // paid amount
      setPaidAmount( totalAmount - totalReceiveAmount > 0 ? totalReceiveAmount : totalAmount );
    } else if (usd === 0 && khr !== 0) {
      setAmountOwe([
        { amount: 0, from: "Cash", type_currency: "USD" },
        { amount: totalAmount - totalReceiveAmount > 0 ? parseFloat( ((totalAmount - totalReceiveAmount) * exChangeRate)?.toFixed(2) ) : 0,
          from: "Cash",
          type_currency: "RIEL",
        },
      ]);
      // paid amount
      setPaidAmount( totalAmount - totalReceiveAmount > 0 ? totalReceiveAmount : totalAmount );
    } else if (usd !== 0 && khr !== 0) {
      setAmountOwe([
        {
          amount: totalAmount - totalReceiveAmount > 0 ? totalAmount - totalReceiveAmount : 0,
          from: "Cash",
          type_currency: "USD",
        },
        { amount: 0, from: "Cash", type_currency: "RIEL" },
      ]);
      // paid amount
      setPaidAmount( totalAmount - totalReceiveAmount > 0 ? totalReceiveAmount : totalAmount );
    } else {
      console.log("no payment process!");
    }
  }, [usd, khr, changeUsd, changeKhr, totalReceiveAmount]);

  return (
    <div className="cash">
      <Grid container spacing={4} >
        <Grid item xs={12}>
          <Stack direction="row" width="100%">
            <IconButton onClick={closeCash}>
              <ArrowCircleLeftOutlinedIcon />
            </IconButton>
            <Box width="80%">
              <Typography mt={1} ml={2} className="title" >
                Cash Pay
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item container xs={12} spacing={4}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="Center">
              <Typography className="amount">Amount</Typography>
            </Stack>
            <Stack direction="row" mb={2} justifyContent="Center" spacing={2} sx={{backgroundColor: "#0F81C2" , borderRadius: "5px" , padding: "10px" , color: "#fff"}}>
              <Typography></Typography>
              <h3>$</h3>
              <Typography className="money"> {(totalAmount)?.toFixed(2)}</Typography>
              <Typography className="money">=</Typography>
              <Typography className="money">              
                <NumericFormat 
                    value={(totalAmount * exChangeRate)?.toFixed(0)} 
                    thousandSeparator={true} 
                    displayType={'text'} 
                    renderText={(value, props) => <div {...props}> {value}</div>}
                /> 
              </Typography>
              <h3>៛</h3>
            </Stack>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={3}>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ height: "100%"}}
            >
              <Typography align="center" sx={{color:"#0F81C2" , fontWeight: 'bold'}}>Open Balance</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4.5}>
            <Stack direction="row">
              <TextField
                value={usd}
                onFocus={(e) => e.target.select()}
                inputRef={textInputUSD}
                InputProps={{
                  startAdornment: ( 
                      <IconButton 
                          className="btn-pay-usd-pos"   
                          onClick={() => { 
                            textInputUSD.current.focus(); 
                            autoSetUsd();
                          }}  
                      >
                            USD
                      </IconButton> 
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value !== undefined && e.target.value !== "") {
                    updateUSD(parseFloat(e.target.value));
                  } else {
                    updateUSD(0);
                  }
                }}
                placeholder="USD"
                size="small"
                type="number"
                fullWidth
              />
            </Stack>
          </Grid>
          <Grid item xs={4.5}>
            <Stack direction="row">
              <TextField
                onFocus={(e) => e.target.select()}
                value={khr}
                inputRef={textInputKHR}
                InputProps={{
                  startAdornment: ( 
                      <IconButton 
                          className="btn-pay-usd-pos"   
                          onClick={() => { 
                            textInputKHR.current.focus(); 
                            autoSetKhr();
                          }}  
                      >
                            KHR
                      </IconButton> 
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value !== undefined && e.target.value !== "") {
                    updateKHR(parseFloat(e.target.value));
                  } else {
                    updateKHR(0);
                  }
                }}
                placeholder="KHR"
                size="small"
                type="number"
                fullWidth
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end" mt={2}>
            <Grid container rowSpacing={3}>
              <Grid item xs={6}>
                <Stack direction="row" justifyContent="left" mt={0.6}>
                  <Typography>Exchange Rate : 1$ = {exChangeRate}៛</Typography>
                </Stack>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end" pr={2} mt={0.6}>
                    <Typography sx={{color:"#0F81C2"}}>Receive</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end">
                    <TextField
                      className={
                        totalReceiveAmount < totalAmount ? "less" : "more"
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      focused
                      aria-readonly
                      value={totalReceiveAmount?.toFixed(2)}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid item xs={6} />
              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end" pr={2} mt={0.6}>
                    <Typography sx={{color:"#0F81C2"}}>Change</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="end">
                    <TextField
                      // disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      focused
                      aria-readonly
                      value={ totalReceiveAmount - totalAmount < 0 ? 0.0 : (totalReceiveAmount - totalAmount)?.toFixed(2) }
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="end" mt={1} mb={2}>
                    <TextField
                      // disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">៛</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      focused
                      aria-readonly
                      value={ (totalReceiveAmount - totalAmount) * exChangeRate < 0 ? 0 : ((totalReceiveAmount-totalAmount)*exChangeRate)?.toFixed(0) }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>


        {/* change input  */}
        {totalReceiveAmount - totalAmount > 0 && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={2}>
              <Stack
                direction="column"
                justifyContent="center"
                sx={{ height: "100%" }}
              >
                <Typography sx={{color:"#0F81C2" , fontWeight: 'bold'}}>Change</Typography>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack direction="row">
                <TextField
                  onFocus={(e) => e.target.select()}
                  value={changeUsd}
                  onChange={(e) => {
                    if (e.target.value !== undefined && e.target.value !== "") {


                      if((totalReceiveAmount - totalAmount) > parseFloat(e.target.value)) {
                          updateUsdChange(parseFloat(e.target.value));
                      } else {
                          updateUsdChange(totalReceiveAmount - totalAmount);
                      } 


                    } else {
                      updateUsdChange(0);
                    }
                  }}
                  InputProps={{
                    startAdornment: ( 
                        <IconButton 
                            className="btn-pay-usd-pos"  
                            onClick={() => { 
                              if(totalReceiveAmount - totalAmount < 0){
                                updateUsdChange(0)
                              } else {
                                updateUsdChange(totalReceiveAmount - totalAmount)
                              }
                            }} 
                          >
                              USD 
                        </IconButton> 
                    ),
                  }}
                  placeholder="USD"
                  size="small"
                  type="number"
                  fullWidth
                />
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack direction="row">
                <TextField
                  onFocus={(e) => e.target.select()}
                  value={(changeKhr)?.toFixed(0)}
                  onChange={(e) => {
                    if (e.target.value !== undefined && e.target.value !== "") {
                        if((totalReceiveAmount - totalAmount)* exChangeRate > parseFloat(e.target.value)) {
                            updateKhrChange(parseFloat(e.target.value));
                        } else {
                            updateKhrChange((totalReceiveAmount - totalAmount)* exChangeRate);
                        } 
                    } else {
                      updateKhrChange(0);
                    }
                  }}
                  InputProps={{
                    startAdornment: ( 
                        <IconButton 
                            className="btn-pay-usd-pos"   
                            onClick={() => {
                              if((totalReceiveAmount - totalAmount) * exChangeRate < 0 ){
                                updateKhrChange(0)
                              } else {
                                updateKhrChange((totalReceiveAmount-totalAmount)*exChangeRate)
                              }
                            }}
                          >
                              KHR 
                        </IconButton> 
                    ),
                  }}
                  placeholder="KHR"
                  size="small"
                  type="number"
                  fullWidth
                />
              </Stack>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {/* <Stack direction="row" justifyContent="end" mt={1}>
            <Button className="process-btn">Process</Button>
          </Stack> */}
        </Grid>
      </Grid>
    </div>
  );
}
