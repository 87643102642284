import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// See: https://firebase.google.com/docs/web/learn-more#config-object

// for Deploy Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAooMkoEjP_mFyKGStB8plrKs-8KDsaFGA",
  authDomain: "inventory-mart-backend.firebaseapp.com",
  projectId: "inventory-mart-backend",
  storageBucket: "inventory-mart-backend.appspot.com",
  messagingSenderId: "278427384855",
  appId: "1:278427384855:web:dfd1c5a94ce9de795e3052"  
};


// for develop
// const firebaseConfig = {
//     apiKey: "AIzaSyCWwlmR7nr7az6PqT6r0C2hmKfw1MxHUDU",
//     authDomain: "test-inventory-mart.firebaseapp.com",
//     projectId: "test-inventory-mart",
//     storageBucket: "test-inventory-mart.appspot.com",
//     messagingSenderId: "576156604857",
//     appId: "1:576156604857:web:0fe5305244a99a8addbe68"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage();
// Initialize Firebase Authentication and get a reference to the service
export default app;
