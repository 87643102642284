import { gql } from "@apollo/client";

export const GET_CUSTOMER_PAGENATION = gql`
query GetCustomersWithPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
  getCustomersWithPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
    data {
      _id
      name
      tel
      address
      remark
      image_src
      image_name
      created_at
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: CustomerInput!, $updateCustomerId: ID) {
    updateCustomer(input: $input, id: $updateCustomerId) {
      message
      status
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($deleteCustomerId: ID) {
    deleteCustomer(id: $deleteCustomerId) {
      message
      status
    }
  }
`;
