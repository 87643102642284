import React, { useContext, useEffect, useState } from "react";
import "../Create/creatediscount.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Stack,
  Box,
  Button,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Checkbox,
} from "@mui/material";
//ICONS
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
//SRC
import { AuthContext } from "../../../../Context/AuthContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
//SCHEMA
import { useMutation } from "@apollo/client";
import { UPDATE_DISCOUNT } from "../../../../Schema/discount";

export default function UpdateDiscount({
  open,
  handleClose,
  setRefetch,
  editData,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [updateDiscount] = useMutation(UPDATE_DISCOUNT, {
    onCompleted: ({ updateDiscount }) => {
      if (updateDiscount?.status) {
        setAlert(true, "success", updateDiscount?.message);
        setLoading(false);
        resetForm();
        setRefetch();
        handleClose();
      } else {
        setAlert(true, "error", updateDiscount?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", updateDiscount?.message);
      setLoading(false);
    },
  });

  const [status, setStatus] = useState(false);
  const handleChangeStatus = (event) => setStatus(event.target.value);

  const [checkAmount, setCheckAmount] = useState(false);
  const changeCheckAmount = () => {
    setCheckAmount(true);
    setFieldValue("percentage", 0);
    setCheckPercent(false);
  };

  const [checkPercent, setCheckPercent] = useState(false);
  const changePercent = () => {
    setFieldValue("amount_discount", 0);
    setCheckPercent(true);
    setCheckAmount(false);
  };

  //fromik
  const AddCategory = Yup.object().shape({
    events: Yup.string().required("require!"),
    amount_discount: Yup.number().required("require!"),
    percentage: Yup.number().min(0).required("require!"),
    start_date: Yup.date().required("require!"),
    end_date: Yup.date().required("require!"),
    status: Yup.boolean().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      events: "",
      amount_discount: 0,
      percentage: 0,
      start_date: moment(),
      end_date: "",
      status: false,
      remark: "",
    },

    validationSchema: AddCategory,
    onSubmit: async (fieldValues) => {
      setLoading(true);
      // console.log("fieldValues::", fieldValues);
      updateDiscount({
        variables: {
          updateDiscountId: editData?._id,
          input: {
            events: fieldValues?.events,
            amount_discount: parseFloat(fieldValues?.amount_discount),
            percentage: parseFloat(fieldValues?.percentage),
            start_date: fieldValues?.start_date,
            end_date: fieldValues?.end_date,
            status: fieldValues?.status,
            remark: fieldValues?.remark,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (editData?.amount_discount > 0) {
      changeCheckAmount();
    } else {
      changePercent();
    }
    setFieldValue("events", editData?.events);
    setFieldValue("amount_discount", editData?.amount_discount);
    setFieldValue("percentage", editData?.percentage);
    setFieldValue("start_date", editData?.start_date);
    setFieldValue("end_date", editData?.end_date);
    setFieldValue("status", editData?.status);
    setFieldValue("remark", editData?.remark);
  }, [editData, open]);

  // console.log('editData',editData)

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      className="create-discount"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">Update Discount</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={6}>
                  <Typography className="field-title">Event</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    placeholder="Event"
                    {...getFieldProps("events")}
                    error={Boolean(touched.events && errors.events)}
                    helperText={touched.events && errors.events}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography className="field-title">Status</Typography>
                  <FormControl fullWidth size="small">
                    <Select
                      onChange={handleChangeStatus}
                      value={status}
                      {...getFieldProps("status")}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>no-active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="field-title">Start</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={values?.start_date}
                      onChange={(e) => setFieldValue("start_date", e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                          {...getFieldProps("start_date")}
                          error={Boolean(
                            touched.start_date && errors.start_date
                          )}
                          helperText={touched.start_date && errors.start_date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="field-title">End</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={values?.end_date}
                      onChange={(e) => setFieldValue("end_date", e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                          {...getFieldProps("end_date")}
                          error={Boolean(touched.end_date && errors.end_date)}
                          helperText={touched.end_date && errors.end_date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="field-title">Discount</Typography>
                  <Stack direction="row">
                    <Checkbox
                      checked={checkAmount}
                      onChange={changeCheckAmount}
                      icon={<PaidOutlinedIcon sx={{ color: "#b9b9b98a" }} />}
                      checkedIcon={<PaidOutlinedIcon />}
                    />
                    <Checkbox
                      checked={checkPercent}
                      onChange={changePercent}
                      icon={<PercentOutlinedIcon sx={{ color: "#b9b9b98a" }} />}
                      checkedIcon={<PercentOutlinedIcon />}
                    />
                    <TextField
                      onFocus={(e) => e.target.select()}
                      fullWidth
                      size="small"
                      placeholder={changePercent ? "%" : "$"}
                      {...getFieldProps(
                        checkAmount ? "amount_discount" : "percentage"
                      )}
                      error={Boolean(
                        checkAmount
                          ? touched.amount_discount && errors.amount_discount
                          : touched.percentage && errors.percentage
                      )}
                      helperText={
                        checkAmount
                          ? touched.amount_discount && errors.amount_discount
                          : touched.percentage && errors.percentage
                      }
                      InputProps={{
                        inputProps: {
                          type: "number",
                          min: 0,
                        },
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="field-title">Remark</Typography>
                  <Stack>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      placeholder="remark"
                      {...getFieldProps("remark")}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <Button fullWidth className="btn-action">
            Loading...
          </Button>
        ) : (
          <Button fullWidth className="btn-action" onClick={handleSubmit}>
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
