import React, { useState, useContext, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
// layouts
import Layout from "./Layout/Layout";
//Page
import Dashboard from "./Pages/Dashboard";
import PosPage from "./Pages/Pos";
import PosOfflinePage from "./Pages/PosOffline";
import PosUpdate from "./Pages/PosUpdate";
import ViewPos from "./Component/Pos/ViewPos/ViewPos";
import Refund from "./Pages/Refund";
import Users from "./Pages/Users";
import Customer from "./Pages/Customer";
import Expend from "./Pages/Expend";
import Setting from "./Pages/Setting";
import Report from "./Pages/Report";
import Login from "./Pages/Login";
import TableSale from "./Component/Setting/SetupSale/TableSale";
import ExchangeRate from "./Component/Setting/ExchangeRate/ExchangeRate";
import WholeSaleProductPage from "./Component/Setting/WholeSaleProduct/Table/WholeSaleProductPage";
import DiscountPage from "./Component/Setting/SetUpDiscount/Table/DiscountPage";
import Shop from "./Component/Setting/Shop/Shop";
import DefaultCustomer from "./Component/Setting/DefaultCustomer/DefaultCustomer";
import Page404 from "./Pages/Page404";
import ForgotPassword from "./Pages/ForgotPassword";
import TableViewQtyProduct from "./Component/Setting/AdjustQtyProduct/TableViewQtyProduct/TableViewQtyProduct"
import { GET_USER_LOGIN } from "./Schema/users";
import { AuthContext } from "./Context/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_EXCHANGE_RATE } from "./Schema/setting";
import OfflineSetup from "./Pages/OfflineSetup";
import AdvertiseSetup from "./Pages/AdvertiseSetup";



export default function Router({ prefersDarkMode, setPrefersDarkMode }) {

  //
  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      window.localStorage.getItem("userLogin", JSON.stringify(getUserLogin));
    },
  });


  //RUN THE EXCHANGE RATE QUERY
  const { refetch: refechRate } = useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangeRate }) => {
      window.localStorage.setItem(
        "exChangeRate",
        getExchangeRate?.rate ? getExchangeRate?.rate : null
      );
    },
    // onError: (error) => {
    // },
  });

  // check Route ==========================================================================
  const { state } = useContext(AuthContext);
  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem("users")));

  useEffect( () => {
    let userStorage = JSON.parse(window.localStorage.getItem("users"));
    if (userStorage) {
      setUser(userStorage);
      return;
    }

    if (state?.user) {
      setTimeout(() => {
        setUser(state?.user?.email);
        window.localStorage.setItem("users",JSON.stringify(state?.user?.email));
      }, 1200);
    } else {
      setUser(null);
    }
  }, [state?.user]);
 

  const [onlineMode,setOnlineMode] = useState(JSON.parse(window.localStorage.getItem("onlineMode")))
  useEffect( () => {
    let onlineModeStorage = JSON.parse(window.localStorage.getItem("onlineMode"));
    if (onlineModeStorage === false && onlineModeStorage !== undefined) {
      setOnlineMode(false);
    } else {
      setOnlineMode(true);
    }
  }, [window.localStorage.getItem("onlineMode")]);

  // End check Route ==========================================================================

  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const Content = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          // to="/dashboard"
          to="/pos"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        // { path: "/", element: <Navigate to="/dashboard" /> },
        // { path: "dashboard", element: <Dashboard /> },
        { path: "/", element: <Navigate to="/pos" /> },
        { path: "pos", element: <PosPage /> },
        { path: "pos/update", element: <PosUpdate /> },
        { path: "pos/view-pos", element: <ViewPos /> },
        { path: "refund", element: <Refund /> },
        { path: "user", element: <Users /> },
        { path: "customer", element: <Customer /> },
        { path: "expense", element: <Expend /> },
        { path: "report", element: <Report /> },

        { path: "setting", element: <Setting /> },
        { path: "setting/sale", element: <TableSale /> },
        { path: "setting/exchang-rate", element: <ExchangeRate refechRate={refechRate} />},
        { path: "/setting/wholesale", element: <WholeSaleProductPage /> },
        { path: "/setting/shop", element: <Shop /> },
        { path: "/setting/defalutcustomer", element: <DefaultCustomer /> },
        { path: "/setting/discount", element: <DiscountPage /> },
        { path: "/setting/adjustqty", element: <TableViewQtyProduct /> },
        { path: "/setting/offline_setup", element: <OfflineSetup />},
        { path: "/setting/advertise_setup", element: <AdvertiseSetup/>},

        { path: "*", element: <Page404 /> },       
      ],
    },
  ]);


  const ContentOffline = useRoutes([
    {
      path: "/",
      element: (
        <Layout 
          to="/pos"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/pos" /> },
        { path: "pos", element: <PosOfflinePage /> },
        { path: "pos/update", element: <PosUpdate /> },
        { path: "pos/view-pos", element: <ViewPos /> },  
        { path: "setting", element: <Setting /> },
        { path: "/setting/offline_setup", element: <OfflineSetup />},
        
        { path: "*", element: <Page404 /> },       
      ],
    },
  ]);




  if (user !== undefined && user !== null) {
    if(onlineMode) {
      return Content;
    } else {
      return ContentOffline;
    }
  } else {
    return LoginPage;
  }
}
