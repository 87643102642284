import React, { useState, useContext, useEffect } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  // Select,
  // MenuItem,
  // FormControl,
  // Autocomplete,
} from "@mui/material";

//ICONS
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
//SRC
import moment from "moment";
import EmptyData from "../../src/Component/Include/EmptyData";
import LoadingPage from "../../src/Component/Include/LoadingPage";
import TopRightBar from "../Menu/TopRightBar";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Dymanic/Translate";
import CreateRefund from "../Component/Refund/CreateRefund";
import RefundAction from "../Component/Refund/RefundAction";
import { GET_REFUND } from "../Schema/refund";
import { useQuery } from "@apollo/client";

function Refund() {
  const { language } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //modal preview
  const [row, setRow] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const handleClosePreview = () => setOpenPreview(false);
  const handleOpenPreview = (row) => {
    setOpenPreview(true);
    setRow(row);
  };

  const [rowData, setRowData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const { refetch } = useQuery(GET_REFUND, {
    variables: { keyword: keyword },
    onCompleted: ({ getReturnProduct }) => {
      console.log("getReturnProduct::::",getReturnProduct)
      setRowData(getReturnProduct);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  useEffect( () => {
    refetch();
  },[keyword])

  // console.log('keyword',keyword);

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Refund</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>
      <Grid container mt="20px" spacing={2}>
        <Grid item lg={3} md={3} sm={3} xs={11}>
          <TextField
            placeholder="search invoice N°"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={0} />
        <Grid item lg={2} md={2} sm={2} xs={11}>
          <Button
            className="btn-add"
            fullWidth
            size="small"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            onClick={handleOpen}
          >
            Create
          </Button>
          <CreateRefund
            handleClose={handleClose}
            open={open}
            setRefetch={refetch}
          />
        </Grid>
      </Grid>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  Invoice No
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Date
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Customer
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-end"
                      : "header-title-end"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <React.Fragment>
                {rowData?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4} className="body-cell">
                        <Stack
                          direction="column"
                          height={300}
                          justifyContent="center"
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {rowData?.map((row, index) => (
                      <TableRow
                        key={index}
                        // className={
                        //   (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                        // }
                        // className="body-row"
                        className={
                          row?.is_voided ? "body-row void" : "body-row"
                        }
                        // sx={{ bgcolor: row?.is_voided && "#ff000017" }}
                      >
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {row?.cart_Id?.numbering}
                        </TableCell>

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {moment(row?.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cell"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {row?.cart_Id?.customer_id?.name}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <RefundAction editRow={row} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>
        <CreateRefund
          handleClose={handleClosePreview}
          open={openPreview}
          setRefetch={refetch}
          type="view"
          data={row}
        />
      </Box>
    </div>
  );
}

export default Refund;
