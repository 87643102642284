import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, Link } from "react-router-dom";
//icons
import { AiOutlineDashboard, AiOutlineLogout } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import SendIcon from "@mui/icons-material/Send";
//firebase
import { auth } from "../firebase";
import { useQuery } from "@apollo/client";
import { GET_USER_LOGIN } from "../Schema/users";
import { signOut } from "firebase/auth";
import { RxDashboard } from "react-icons/rx"; //home
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';//expend
import { BiBookReader } from "react-icons/bi"; //report
import { HiOutlineUserGroup } from "react-icons/hi"; //customer
import { CiSettings } from "react-icons/ci"; //setting
import { BiNews } from "react-icons/bi"; // Pos,
import { RiRefund2Fill } from "react-icons/ri"; // refund,

//src
import logo from "../Assets/mart_logo.svg";
import "./menunavbar.scss";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose }) {
  let location = useLocation();

  const [iconReload, setIconReload] = useState(false);
  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
        window.localStorage.removeItem("users");
        window.location.reload();
      })

      .catch((error) => {
        // An error happened.
        // console.log(error);
      });
  };

  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      // console.log(getUserLogin?.role)
    },
    onError: (error) => {
      console.log("er", error?.message);
    },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const [openLogout, setOpenLogout] = React.useState(false);

  const handleOpenLogout = () => {
    setOpenLogout(true);
    setIconReload(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
    setIconReload(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={open} className="drawer-menu">
        <DrawerHeader
          onClick={() => navigate("/pos")}
          className="image-container"
        >
          <img src={logo} alt="logo" height="60%" width="60%" />
        </DrawerHeader>

        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          {/* <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <RxDashboard className="icon" fontSize={12} />
                </ListItemIcon>
                <Typography className="list-item-text">Home</Typography>
              </ListItemButton>
            </Link>
          </ListItem> */}

          {/* ============================== page POS ========================== */}
          <ListItem
            className={
              location.pathname === "/pos" ||
                location.pathname === "/pos/view-pos" ||
                location.pathname === "/pos/update"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
          >
            <Link to="/pos" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <BiNews className="icon" fontSize={12} />
                </ListItemIcon>
                <Typography className="list-item-text">POS</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          {/* ============================== page POS ========================== */}
          <ListItem
            className={location.pathname === "/refund" ? "list-item-active"
              : "list-item"}
            disablePadding
          >
            <Link to="/refund" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <RiRefund2Fill className="icon" fontSize={12} />
                </ListItemIcon>
                <Typography className="list-item-text">Refund</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          {/* ============================== page customer ========================== */}
          <ListItem
            className={
              location.pathname === "/customer"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/customer" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <HiOutlineUserGroup className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">Customer</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          {/* ============================== page user ========================== */}
          <ListItem
            className={
              location.pathname === "/user" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/user" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <HiOutlineUserCircle className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">User</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* ============================== page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/report" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <BiBookReader className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">Report</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          {/* ============================== page expend ========================== */}
          <ListItem
            className={
              location.pathname === "/expense" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/expense" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <NewReleasesOutlinedIcon className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">Expense</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* ============================== page setting ========================== */}
          <ListItem
            className={
              location.pathname === "/setting" ||
                location.pathname === "/setting/exchang-rate" ||
                location.pathname === "/setting/sale" ||
                location.pathname === "/setting/discount" ||
                location.pathname === "/setting/shop" ||
                location.pathname === "/setting/defalutcustomer" 
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/setting" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <CiSettings className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">Setting</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Box sx={{ flexGrow: 1 }}></Box>

        {/* ============================== logout ========================== */}
        <List className="list-menu">
          <ListItem
            className="list-item"
            disablePadding
            sx={{ display: "block" }}
          >
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={() => handleOpenLogout()}
            >
              <ListItemButton className="list-item-button">
                <ListItemIcon className="list-item-icon">
                  <AiOutlineLogout className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">Logout</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Drawer>

      <div>
        <Dialog open={openLogout} className="log-out-style">
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="right"
              sx={{ marginTop: "-5px" }}
            >
              <IconButton onClick={handleCloseLogout}>
                <DoDisturbOnOutlinedIcon
                  sx={{ color: "red", fontSize: "25px" }}
                />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stack direction="column" justifyContent="center" height="150px">
                <Stack
                  direction="row"
                  justifyContent="center"
                  width="400px"
                  marginTop="-10px"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    You are attemping to log out.
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" width="400px">
                  <Typography sx={{ color: "black", fontSize: "15px" }}>
                    Are you sure?
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  width="400px"
                  marginTop="30px"
                >
                  <Button
                    onClick={handleSignOut}
                    autoFocus
                    fullWidth
                    startIcon={iconReload ? <SendIcon /> : null}
                    sx={{
                      backgroundColor: "#0B82C4",
                      color: "white",
                      ":hover": { backgroundColor: "#0B82C4" },
                    }}
                  >
                    LOG OUT
                  </Button>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
