import React, { useContext, useState,useEffect } from "react";
import "./dialogadjustqtyproduct.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// date
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { AuthContext } from "../../../../Context/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { ADJUST_QTY_IN_SHOP } from "../../../../Schema/warehouse";
import moment from "moment";
import { GET_ALL_PRODUCT } from "../../../../Schema/setting";

export default function DialogCreateWarehouse({
  open,
  handleClose,
  setRefetch,
  t,
  language,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading,setLoading] = useState(false);

  let shop = JSON.parse(window.localStorage.getItem("shop")); 
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  //================== product ====================================
  const [allProduct, setAllProduct] = useState([]);
  const [productSelected,setProductSelected] = useState({id: "" , label: "" , unit: "" , unitPrice: 0 , qtyInUnit: 0 , unit_of_qty_inUnit: ""});
  
  const { data: dataAllProduct, refetch } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct);
      if (getAllProduct?.length !== 0) {
        let rows = [];
        getAllProduct?.map((element, index) => {
          const allrow = {
            label: index + 1 + " - " + element?.name + " Code: "+element?.bar_Code,
            id: element?._id,            
            unit: element?.unit,  
            unitPrice: element?.cost,      
            qtyInUnit: element?.qty_inUnit,
            unit_of_qty_inUnit: element?.unit_of_qty_inUnit
          };
          rows.push(allrow);
        });
        setAllProduct(rows);
      } else {
        setAllProduct([]);
      }
    },
  });

  const [adjustProductInShop] = useMutation(ADJUST_QTY_IN_SHOP, {
    onCompleted: ({ adjustProductInShop }) => {
      // console.log("user", adjustProductInShop);
      if (adjustProductInShop?.status) {
        setAlert(true, "success", adjustProductInShop?.message);
        setRefetch()
        setLoading(false);
        resetForm();
        handleClose();
        setProductSelected({id: "" , label: "" , unit: "" , unitPrice: 0 , qtyInUnit: 0 , unit_of_qty_inUnit: ""})
      } else {
        setAlert(true, "error", adjustProductInShop?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error, "mgs");
      setAlert(true, "error", adjustProductInShop?.message);
      setLoading(false);
    },
  });

  //fromik
  const AddLoanPlan = Yup.object().shape({       
    product_Id: Yup.string().required(),
    qty: Yup.number(), 
    instock_At: Yup.date().required(),
    expire_At: Yup.date().required(),
    mainQty: Yup.number(),  
    baseQty: Yup.number(), 
    shop_Id: Yup.string().required(),   
    unit_Price: Yup.number().required(),
  });

  const formik = useFormik({
    initialValues: {   
      product_Id: "",
      instock_At: new Date(),
      expire_At: new Date(),
      qty: 0, 
      mainQty: 0,
      baseQty: 0,    
      shop_Id: "",     
      unit_Price: "",
    },

    validationSchema: AddLoanPlan,
    onSubmit: async (values) => {
      
      setLoading(true);
    
      let newValue = {      
          product_Id: productSelected?.id,
          qty: values?.mainQty + values?.baseQty,       
          instock_At:  moment(values?.instock_At).format("YYYY-MM-DD"),
          expire_At: moment(values?.expire_At).format("YYYY-MM-DD"),
          shop_Id: shop?.id,        
          unit_Price: productSelected?.unitPrice,
          created_by: userLogin?._id,
      }

      // console.log(newValue);

      if(newValue?.shop_Id && newValue?.qty > 0 && productSelected?.id !== "" && productSelected?.id !== undefined) { 
          adjustProductInShop({
              variables: {
                  input: newValue,
              },
          }); 
      } else {
          setAlert(true, "error", "Cant find shop or qty." );
          setLoading(false);
      }
     
    },
  });

  const { errors, touched, resetForm, handleSubmit, getFieldProps,  setFieldValue,  values } = formik;

  useEffect(() =>{
    resetForm()
  },[open])


  useEffect( () => {
    if(shop?.id) {
      console.log(shop)
      setFieldValue("shop_Id" , shop?.id)
    }    
  },[shop?.id , open]) 

  
  useEffect( () => {
    setFieldValue("unit_Price" , productSelected?.unitPrice);
    setFieldValue("mainQty" , 0);
    setFieldValue("baseQty" , 0);
  },[productSelected?.id])


  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="create-adjust-qty-dialog" fullWidth maxWidth="sm">
        <DialogTitle className="dialog-title">
            <Stack direction="row" spacing={2} alignItems='center'>
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                Add product quantity to warehouse
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose}>
                  <CloseIcon className="clear-icon" />
                </IconButton>
            </Stack>
            <Divider />
        </DialogTitle>

        <DialogContent>

            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} className="grid-profile">   
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Product
                    </Typography>        
                    <Autocomplete
                        freeSolo
                        clearOnBlur
                        disablePortal
                        value={productSelected}
                        options={allProduct}
                        getOptionLabel={(option) => option?.label ? option?.label : "" }
                        isOptionEqualToValue={(option, value) => option.id === value.id || value === "" }
                        onChange={(e, newValue) => {
                          setFieldValue( "product_Id", newValue?.id ? newValue?.id : "" );
                          setProductSelected(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            placeholder="Product (name,barcode)"
                            {...params}
                            error={Boolean( touched.product_Id && errors.product_Id )}
                            helperText={touched.product_Id && errors.product_Id}
                          />
                        )}
                      />         
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Cost of product
                    </Typography>  
                    <TextField
                      fullWidth
                      size="small"
                      type="number"                    
                      placeholder="0"
                      onFocus={e => e.target.select() }   
                      value={values.unit_Price}                 
                      onChange={ (e) => {   
                          if(e.target.value) {                     
                            setFieldValue("unit_Price" , parseInt(e.target.value))     
                          } else {
                            setFieldValue("unit_Price" , 0);
                          }                   
                      }}
                      error={Boolean(touched.unit_Price && errors.unit_Price)}
                      helperText={touched.unit_Price && errors.unit_Price}
                      InputProps={{
                        inputProps: {                        
                          min: 0,
                        },
                        endAdornment: ( 
                            <Typography width="120px">
                               $ / {productSelected?.unit} 
                            </Typography>
                        ),
                      }}                      
                    />    
                  </Grid>

                  <Grid item xs={6}></Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Date Stock In
                    </Typography>  
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd-MM-yyyy"
                          mask="__-__-____"
                          value={values.instock_At}
                          disabled
                          onChange={(e) => {
                            setFieldValue( "instock_At" , e);
                          }}
                          error={Boolean(touched.instock_At && errors.instock_At)}
                          helperText={touched.instock_At && errors.instock_At}
                          className="search-field"
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                    </LocalizationProvider>                  
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Date Expire In
                    </Typography>  
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd-MM-yyyy"
                          mask="__-__-____"
                          value={values.expire_At}
                          onChange={(e) => {
                            setFieldValue( "expire_At" , e);
                          }}
                          error={Boolean(touched.expire_At && errors.expire_At)}
                          helperText={touched.expire_At && errors.expire_At}
                          className="search-field"
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                    </LocalizationProvider>                  
                  </Grid>


                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Qty ({productSelected?.unit})
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"                    
                      placeholder="0"
                      onFocus={e => e.target.select() }   
                      value={values.mainQty}                 
                      onChange={ (e) => {   
                          if(e.target.value) {                     
                            setFieldValue("mainQty" , parseInt(e.target.value))     
                          } else {
                            setFieldValue("mainQty" , 0);
                          }                   
                      }}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                      InputProps={{
                        inputProps: {                        
                          min: 1,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Qty ({productSelected?.unit_of_qty_inUnit})
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"                    
                      placeholder="0"    
                      value={parseFloat((values.baseQty*productSelected?.qtyInUnit)?.toFixed(1))}   
                      disabled={productSelected?.unit_of_qty_inUnit ? false : true}               
                      onFocus={e => e.target.select() }
                      onChange={ (e) => {    
                        if(e.target.value) {
                          setFieldValue("baseQty" , parseFloat(e.target.value) / productSelected?.qtyInUnit ) 
                        } else {
                          setFieldValue("baseQty" , 0 )
                        }               
                      }}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                      InputProps={{
                        inputProps: {                        
                          min: 0.1,
                        },
                      }}
                    />
                  </Grid>

                                    
                
                </Grid>
              </Form>
            </FormikProvider>
        
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
