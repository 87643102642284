import React, { useState, useContext } from "react";
import "../../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField } from "@mui/material";
import { UPDATE_IS_USED_PRODOUCT } from "../../../Schema/setting";
import { useMutation } from "@apollo/client";
//Schema
//Components
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";

export default function IsUseSale({
  open,
  handleClose,
  editRow,
  setRefetch,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [confirmVal, setConfirmVal] = useState("");
  const [loading, setLoading] = useState(false);
  

  const [updateIsUse] = useMutation(UPDATE_IS_USED_PRODOUCT , {
    onCompleted: ({ updateIsUse }) => {
      if (updateIsUse?.status) {
        setAlert(true, "success", updateIsUse?.message);
        setLoading(false);
        setRefetch();
        handleClose();
      } else {
        setLoading(false);
        setAlert(true, "error", updateIsUse?.message);
      }
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  console.log(editRow)

  const handleUpdateIsUse = () => {
    setLoading(true)
    updateIsUse({
        variables:{
            updateIsUseId: editRow?._id,
            status: !editRow?.isUse,
        }
    })    
  };

  return (
    <Dialog open={open} onClose={handleClose} className="delete-container">
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography  className={language === "kh" ? "titleKh" : "titleEn"}>កែប្រែផលិតផលលក់</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1"  className={language === "kh" ? "simple-textKh" : "simple-textEn"}>
            {t("do-you-want-to-update")}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle1"  className={language === "kh" ? "simple-textKh" : "simple-textEn"}>
              {t("please-input")}
            </Typography>
            <Typography className="body-text" variant="subtitle1">
              Product
            </Typography>
            <Typography variant="subtitle1"  className={language === "kh" ? "simple-textKh" : "simple-textEn"}>
              {t("to-update")}
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="month"
                fullWidth
                size="small"
                type="text"
                onChange={(e) => setConfirmVal(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              {confirmVal === "Product" ? (
                <Button
                  sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
                  className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                  variant="outlined"
                  fullWidth
                  size="small"
                  onClick={handleUpdateIsUse}
                  disabled={loading ? true : false}
                >
                  {loading ? "Loading..." : t("update-now")}
                </Button>
              ) : (
                <Button variant="outlined" size="small"  className={
                  language === "kh" ? "btn-deleteKh" : "btn-deleteEn"
                } fullWidth>
                  {t("update")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
