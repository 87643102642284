import React, { useContext, useState, useRef, useEffect } from "react";
import "./printproductitem.scss";
import {
  Stack,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useReactToPrint } from "react-to-print";
import { NumericFormat } from "react-number-format";

//Src
import LogoCompany from "../../../Assets/mart_logo.svg";
// import LogoCompanyPrint from "../../../Assets/GoGlobalMartBlack.svg";
import LogoCompanyPrint from "../../../Assets/martblack.png";
import { AuthContext } from "../../../Context/AuthContext";
import PaymentMethod from "./PaymentMethod";
// import VoidPayment from "./VoidPayment";
import { GET_PAYMENT_BY_CARDID } from "../../../Schema/viewpos";

// schema
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PAYMENT } from "../../../Schema/pos";

export default function PrintProductItem({
  open,
  handleClose,
  type,
  receipt,
  setRefetch,
}) { 
 
  const { setAlert } = useContext(AuthContext);
 
  //================= Local Storage ================
  const shop = JSON.parse(window.localStorage.getItem("shop"));
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  //================= Hook ====================
  const [loading, setLoading] = useState(false);
  const [textNote,setTextNote] = useState("");
  const [stateCreatePaymentSuccess, setStateCreatePaymentSuccess] =  useState(false);
  const [saleType,setSaleType] = useState("Retail"); 
  const [requireField,setRequireField] = useState(false);


  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleClose(),
  });


  //=============== GET PAYMENT BY CARD ID =================
  const [listPayment, setListPayment] = useState([]);
  const { refetch } = useQuery(GET_PAYMENT_BY_CARDID, {
    variables: {
      cardId: receipt?._id,
    },
    onCompleted: ({ getPaymentByCartId }) => { 
      setListPayment(getPaymentByCartId);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  // find list payment that is not void 
  const [findPayment,setFindPayment] = useState(1);
  const handleFindListPayment = () => {
    if(listPayment?.length !== 0) {
        let listPaymentFind = listPayment?.filter( (payList) => payList?.is_voided === false );        
        setFindPayment(listPaymentFind?.length)
    } else {
        setFindPayment(1);
    }
  }

  useEffect( () => {
    handleFindListPayment();
  },[open,listPayment])


  //====================== CRATE PAYMENT ======================
  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: ({ createPayment }) => {
      if (createPayment?.status) {
        setAlert(true, "succes", createPayment?.message);
        setLoading(false);
        refetch();
        setRefetch();
        setStateCreatePaymentSuccess(true);
      } else {
        setLoading(false);
        setAlert(true, "error", createPayment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [payment_method, setPayment_method] = useState("");
  const [receiveAmount, setReceiveAmount] = useState([]);
  const [returnAmount, setReturnAmount] = useState([]);
  const [amountOwe, setAmountOwe] = useState([]);
  const [paidAmount, setPaidAmount] = useState();
  const [repaymentStatus, setRepaymentStatus] = useState(false);


  const handleCreatePaymentReceipt = () => {
    setLoading(true);

    let amountUnpaid = [
      { amount: receipt?.total, from: "Cash", type_currency: "USD" },
      { amount: 0, from: "Cash", type_currency: "RIEL" },
    ];

    let recAmount = [
      { amount: 0, from: "Cash", type_currency: "USD" },
      { amount: 0, from: "Cash", type_currency: "RIEL" },
    ];

    let retAmount = [
      { amount: 0, from: "Cash", type_currency: "USD" },
      { amount: 0, from: "Cash", type_currency: "RIEL" },
    ];

    const newValue = {
      amount_owe: payment_method === "" ? amountUnpaid : amountOwe,
      card_id: receipt?._id,
      date: moment(),
      is_voided: false,
      payment_method: payment_method,
      repayment_status: repaymentStatus,
      receive_amount: payment_method === "" ? recAmount : receiveAmount,
      return_amount: payment_method === "" ?  retAmount : returnAmount,
      paid_amount: payment_method === "" ? 0 : paidAmount,
    };

    // console.log(newValue);

    createPayment({
      variables: {
        input: newValue,
      },
    });

  };

  //=================== CASH IN AND  EXCHANGE CASH ==================
  const cashInAndReturn = () => {
    let cashInUsd = 0;
    let cashInRiel = 0;

    let cashReturnUsd = 0;
    let cashReturnRiel = 0;

    listPayment?.map((e) => {
      // cash in
      if (e?.is_voided === false) {
        e?.receive_amount?.map((j) => {
          if (j?.type_currency === "USD") {
            cashInUsd += j?.amount;
          }
          if (j?.type_currency === "RIEL") {
            cashInRiel += j?.amount;
          }
        });

        // exchange cash
        e?.return_amount?.map((k) => {
          if (k?.type_currency === "USD") {
            cashReturnUsd += k?.amount;
          }
          if (k?.type_currency === "RIEL") {
            cashReturnRiel += k?.amount;
          }
        });
      }
    });

    return {
      cashInUsd: cashInUsd,
      cashInRiel: cashInRiel,
      cashReturnUsd: cashReturnUsd,
      cashReturnRiel: cashReturnRiel,
    };
  };

  //==================== OWN
  const own = () => {
    if(listPayment?.length!==0){
      let remain = listPayment?.filter(e =>e?.is_voided === false)
      console.log('remain',remain)
      
      if(remain?.length !== 0 ) {
        let totalAmountReceive = 0;

        remain?.forEach( (element) => {
            if(element?.receive_amount?.length !== 0) {
                totalAmountReceive += element?.receive_amount[0]?.amount + (element?.receive_amount[1]?.amount/receipt?.exchange_rate)
            }
        })
        
        if(receipt?.total-totalAmountReceive > 0) {
          return '$ '+ (receipt?.total-totalAmountReceive)?.toFixed(2);
        } else {
          return '$ '+ 0.00;
        }
       
      } else {
        return '$ '+ receipt?.total?.toFixed(2);
      }
      
    } else {
      return '$ '+ receipt?.total?.toFixed(2)
    }
  }

  useEffect(() =>{
    own()
  },[listPayment])


  //===================== CHECK LAYOUT PRINT FROM WHOLESALE OR RETAIL ================
  useEffect( () => {      
      if(receipt?.items && receipt?.items?.length > 0) { 
          const BreakException = {};
          try {
              receipt?.items?.forEach(function(elem) {                          
                  if(elem?.product_id_setup?.saleType === "Wholesale") {    
                      setSaleType("Wholesale");
                      throw BreakException;
                  } else {
                      setSaleType("Retail");
                  }
              });
          } catch (e) {
              if (e !== BreakException) throw e;
          }
      }
  },[receipt , open])

  useEffect( () => {
    setTextNote("");
  },[open])


  return (
    <Dialog
      open={open} 
      className="print-pos"
      fullScreen
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">Preview Receipt</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        {receipt?.is_voided ? (
          <Typography className="voided">Voided</Typography>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={6} className="grid-1">
            <PaymentMethod
                findPayment={findPayment}
                setReceiveAmount={setReceiveAmount}
                setReturnAmount={setReturnAmount}
                setPayment_method={setPayment_method}
                setAmountOwe={setAmountOwe}
                setPaidAmount={setPaidAmount}
                receipt={receipt}
                setRepaymentStatus={setRepaymentStatus}
                listPayment={listPayment}
                type={type}
                setStateCreatePaymentSuccess={setStateCreatePaymentSuccess}
                stateCreatePaymentSuccess={stateCreatePaymentSuccess}
                setRequireField={setRequireField}
            />
            {/* )} */}
          </Grid>

          <Grid item xs={6} className="grid-2">
          


      {/* ===================== Layout Preview ========================================== */}
          <div style={{ padding: "15px" }}>
              <Box className="print-receipt-container">
                <Stack
                  justifyContent="center"
                  direction="row"
                  sx={{ position: "relative" , height:"70px"}}
                >
                  <Box className="logo-invoice-print">
                      <img alt="Logo" src={LogoCompany} className="logo-com" />
                  </Box>
                  <Stack direction="row" justifyContent="center" width="100%">
                    <Stack direction="column" ml={4} height="100%">
                      <Typography className="receipt-title-kh">
                        {shop?.label_kh}
                      </Typography>
                      <Typography className="receipt-title">
                        {shop?.label}
                      </Typography>
                      <Typography className="invoice-header-top-print">
                          VATTIN: E116-2200003698
                        </Typography>
                      <Typography className="invoice-header-top-print">
                        {shop?.phone}
                      </Typography>
                      <Typography className="invoice-header-top-print">
                        {shop?.address}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack> 
                <br/>
                <table className="invoice-header">
                  <tbody>
                    <tr>
                      <td><b>លេខវិក្កយបត្រ</b></td>
                      <td>: <b>{receipt?.numbering} {moment(receipt?.created_at)?.utc(false)?.format("DD/MM/YYYY hh:mm a")}</b></td>
                    </tr>
                    <tr>
                      <td><b>អ្នកគិតលុយ</b></td>
                      <td>: <b>{userLogin?.firsName + " " + userLogin?.lastName}</b> </td>
                    </tr>
                    <tr>
                      <td><b>អតិថិជន</b></td>
                      <td>: <b>{receipt?.customer_id?.name}</b></td>
                    </tr>

              {
                saleType === "Wholesale" ?
                  <>
                    <tr>
                      <td><b>លេខទូរស័ព្ទ</b></td>
                      <td>: <b>{receipt?.customer_id?.tel}</b></td>
                    </tr>
                    <tr>
                      <td><b>អាសយដ្ឋាន</b></td>
                      <td>: <b>{receipt?.customer_id?.address}</b></td>
                    </tr>
                  </>
                : null
              }                    
                  </tbody>
                </table>

                <div style={{ width: "100%" , display: "flex" , justifyContent: "center" , marginTop: "6px" , marginBottom: "5px" }}>
                    <b style={{fontFamily: "Siemreap" , fontSize: "12px"}}>វិក្កយបត្រ</b>
                </div> 

                <div className="receipt-table-container">
                  <table className="receipt-table">
                    <thead>
                      <tr>
                        <th className="discription"><b style={{fontFamily: "Siemreap"}}>បរិយាយ</b> <br/>Description</th>
                        <th align="center"><b style={{fontFamily: "Siemreap"}}>តម្លៃ</b> <br/>Price</th>
                        <th align="center"><b style={{fontFamily: "Siemreap"}}>ប.តម្លៃ</b> <br/>Disc</th>
                        <th align="center"><b style={{fontFamily: "Siemreap"}}>ចំនួន</b> <br/>QTY</th>
                        <th className="end"><b style={{fontFamily: "Siemreap"}}>សរុប</b> <br/>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      { receipt?.items?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.name} {data?.product_id_setup?.descriptions}</td> 
                            <td align="center">
                              ${" "}{data?.price}
                            </td>
                            <td align="center">                              
                              {data?.percentage_discount}%
                            </td>
                            <td className="center"> 
                              {data?.qty +" "} 
                              {(data?.unit).split("១")[1] ? (data?.unit).split("១")[1] : null}
                              {(data?.unit).split("1")[1] ? (data?.unit).split("1")[1] : null}
                              {(data?.unit).split("០.៥")[1] ? (data?.unit).split("០.៥")[1] : null}
                              {(data?.unit).split("0.5")[1] ? (data?.unit).split("0.5")[1] : null}
                              {(data?.unit).split("១")[1] || (data?.unit).split("1")[1] || (data?.unit).split("០.៥")[1] || (data?.unit).split("0.5")[1]   ? null : data?.unit}
                            </td>
                            <td className="end">
                              ${" "} {(data?.qty * data?.price)?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
            
 
                      <tr> 
                        <td colSpan={2} className="start bold top-line">Items : {receipt?.items?.length}</td>
                        <td colSpan={1} className="bold top-line" align="center"></td>
                        <td colSpan={1} className="bold top-line" align="center"></td>
                        <td className="end bold top-line"></td>
                      </tr>

                      <tr> 
                        <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>សរុប</b> / Subtotal</td>
                        <td className="end bold"> $ {receipt?.sub_total?.toFixed(2)} </td>
                      </tr>

                      <tr> 
                        <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>បញ្ចុះតម្លៃ</b> / Discount (%)</td>
                        <td className="end bold"> $ {receipt?.discount_total?.toFixed(2)} </td>
                      </tr>
                         
                      <tr>                    
                        <td colSpan={3} className='start bold top-line'> 
                          <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (USD)
                        </td>

                        <td colSpan={2} className='end bold top-line'>
                          $ {receipt?.total?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                        
                        <td colSpan={3} className="start bold" style={{ borderBottom: "1px solid #000000"}}> 
                          <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (KHR)
                        </td>
                        <td colSpan={2} className="end bold" style={{ borderBottom: "1px solid #000000"}}>
                          ៛ {(receipt?.total * receipt?.exchange_rate)?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                       
                        <td colSpan={2} className="start bold" style={{ borderBottom: "1px solid #000000"}}> Exchange Rate </td>
                        <td colSpan={3} className="end bold" style={{ borderBottom: "1px solid #000000"}}> 1$ = {receipt?.exchange_rate}៛</td> 
                      </tr>

                      <tr>                       
                        <td colSpan={3} className="start bold">Receive in :</td>
                        <td colSpan={2} className="end bold"> </td>
                      </tr>

                      <tr>                        
                        <td colSpan={3} className="start bold" >Cash (KHR)</td>
                        <td colSpan={2} className="end bold">
                          ៛ {cashInAndReturn()?.cashInRiel?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                       
                        <td colSpan={3} className="start bold" >Cash (USD)</td>
                        <td colSpan={2} className="end bold" >
                          $ {cashInAndReturn()?.cashInUsd?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                       
                        <td colSpan={3} className="start bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                          Total Receive (USD)
                        </td>
                        <td colSpan={2} className="end bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                          $ {(cashInAndReturn()?.cashInUsd + (cashInAndReturn()?.cashInRiel/receipt?.exchange_rate))?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                       
                        <td colSpan={3} className="start bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                          <b style={{fontFamily: "Siemreap"}} >ប្រាក់ជំពាក់</b> / Balance
                        </td>
                        <td colSpan={2} className="end bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                          {own()}
                        </td>
                      </tr>

                      <tr>                    
                        <td colSpan={3} className="start bold">
                          <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (USD)
                        </td>
                        <td colSpan={2} className="end bold">
                          $ {cashInAndReturn()?.cashReturnUsd?.toFixed(2)}
                        </td>
                      </tr>

                      <tr>                       
                        <td colSpan={3} className="start bold">
                          <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (KHR)
                        </td>
                        <td colSpan={2} className="end bold">
                          ៛ {cashInAndReturn()?.cashReturnRiel?.toFixed(0)}
                        </td>
                      </tr> 

                    </tbody>
                  </table>
                </div> 

                <Stack direction='row'>
                  <Typography mt={'1px'}  className="confirm-person bold" style={{paddingRight:'10px'}}>Note:</Typography>
                  <textarea rows='3' onChange={(e) => setTextNote(e.target.value) }></textarea> 
                </Stack> 
                
                <Box marginTop="50px">
                  <Typography className="receipt-footer">
                    ទំនិញទិញហើយមិនអាចប្តូរវិញបានទេ
                  </Typography>
                  <Typography className="receipt-footer">
                    Goods sold not returnable
                  </Typography>
                  <Typography className="receipt-footer">
                    សូមអរគុណ!-Thank You!
                  </Typography>
                </Box>
              </Box>
            </div>
          {/* =============== end Preview =================== */}



          {/* ============== Layout for Print ======================== */}
          <div style={{display: "none"}}>
            <div ref={componentRef} style={{ padding: "15px" }}>

              { saleType === "Retail" ?  
                <Box className="print-receipt-container">
                  <Stack
                    justifyContent="center"
                    direction="row"
                    sx={{ position: "relative" , height:"70px"}}
                  >
                    <Box className="logo-invoice-print">
                        <img alt="Logo" src={LogoCompanyPrint} className="logo-com-print" />
                    </Box>
                    <Stack direction="row" justifyContent="center" width="100%">
                      <Stack direction="column" ml={4} height="100%">
                        <Typography className="receipt-title-kh">
                          {shop?.label_kh}
                        </Typography>
                        <Typography className="receipt-title">
                          {shop?.label}
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          VATTIN: E116-2200003698
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          {shop?.phone}
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          {shop?.address}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <br/>
                  <table className="invoice-header">
                    <tbody>
                      <tr>
                        <td className="color-black-text"><b>លេខវិក្កយបត្រ</b></td>
                        <td className="color-black-text">: <b>{receipt?.numbering} {moment(receipt?.created_at)?.utc(false)?.format("DD/MM/YYYY hh:mm a")}</b></td>
                      </tr>
                      <tr>
                        <td className="color-black-text"><b>អ្នកគិតលុយ</b></td>
                        <td className="color-black-text">: <b>{userLogin?.firsName + " " + userLogin?.lastName}</b> </td>
                      </tr>
                      <tr>
                        <td className="color-black-text"><b>អតិថិជន</b></td>
                        <td className="color-black-text">: <b>{receipt?.customer_id?.name}</b></td>
                      </tr>                    
                    </tbody>
                  </table>

                  <div style={{ width: "100%" , display: "flex" , justifyContent: "center" , marginTop: "6px" , marginBottom: "5px" }}>
                    <b style={{fontFamily: "Siemreap" , fontSize: "12px"}}>វិក្កយបត្រ</b>
                  </div>

                  <div className="receipt-table-container">
                    <table className="receipt-table">
                      <thead>
                        <tr>
                          <th className="discription"><b style={{fontFamily: "Siemreap"}}>បរិយាយ</b> <br/>Description</th>
                          <th align="center"><b style={{fontFamily: "Siemreap"}}>តម្លៃ</b> <br/>Price</th>
                          <th align="center"><b style={{fontFamily: "Siemreap"}}>ប.តម្លៃ</b> <br/>Disc</th>
                          <th align="center"><b style={{fontFamily: "Siemreap"}}>ចំនួន</b> <br/>QTY</th>
                          <th className="end"><b style={{fontFamily: "Siemreap"}}>សរុប</b> <br/>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {receipt?.items?.map((data, index) => {
                          return (
                            <tr key={index}> 
                              <td>
                                {data?.name} {data?.product_id_setup?.descriptions}
                              </td>                          
                              <td align="center">
                                {data?.price}
                              </td>
                              <td align="center">                              
                                {data?.percentage_discount}%
                              </td>
                              <td className="center">
                                {data?.qty +" "} 
                                {(data?.unit).split("១")[1] ? (data?.unit).split("១")[1] : null}
                                {(data?.unit).split("1")[1] ? (data?.unit).split("1")[1] : null}
                                {(data?.unit).split("០.៥")[1] ? (data?.unit).split("០.៥")[1] : null}
                                {(data?.unit).split("0.5")[1] ? (data?.unit).split("0.5")[1] : null}
                                {(data?.unit).split("១")[1] || (data?.unit).split("1")[1] || (data?.unit).split("០.៥")[1] || (data?.unit).split("0.5")[1]   ? null : data?.unit}
                              </td>
                              <td className="end">
                                ${" "}{(data?.qty * data?.price)?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}

                        <tr> 
                          <td colSpan={2} className="start bold top-line">Items : {receipt?.items?.length}</td>
                          <td colSpan={1} className="bold top-line" align="center"></td>
                          <td colSpan={1} className="bold top-line" align="center"></td>
                          <td className="end bold top-line"></td>
                        </tr>

                        <tr> 
                          <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>សរុប</b> / Subtotal</td>
                          <td className="end bold"> $ {receipt?.sub_total?.toFixed(2)} </td>
                        </tr>
                        <tr> 
                          <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>បញ្ចុះតម្លៃ</b> / Discount (%)</td>
                          <td className="end bold"> $ {receipt?.discount_total?.toFixed(2)} </td>
                        </tr>
                          
                        <tr>                    
                          <td colSpan={3} className='start bold top-line'> 
                            <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (USD)
                          </td>
                          <td colSpan={2} className='end bold top-line'>
                            $ {receipt?.total?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                        
                          <td colSpan={3} className="start bold" style={{ borderBottom: "1px solid #000000"}}> 
                            <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (KHR)
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderBottom: "1px solid #000000"}}>
                            ៛ {(receipt?.total * receipt?.exchange_rate)?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                       
                          <td colSpan={2} className="start bold" style={{ borderBottom: "1px solid #000000"}}> Exchange Rate </td>
                          <td colSpan={3} className="end bold" style={{ borderBottom: "1px solid #000000"}}> 1$ = {receipt?.exchange_rate}៛</td> 
                        </tr>

                        <tr>                       
                          <td colSpan={3} className="start bold">Receive in :</td>
                          <td colSpan={2} className="end bold"> </td>
                        </tr>

                        <tr>                        
                          <td colSpan={3} className="start bold" >Cash (KHR)</td>
                          <td colSpan={2} className="end bold">
                            ៛ {cashInAndReturn()?.cashInRiel?.toFixed(2)}
                          </td>
                        </tr>


                        <tr>                       
                          <td colSpan={3} className="start bold" >Cash (USD)</td>
                          <td colSpan={2} className="end bold" >
                            $ {cashInAndReturn()?.cashInUsd?.toFixed(2)}
                          </td>
                        </tr>


                        <tr>                       
                          <td colSpan={3} className="start bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                            Total Receive (USD)
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                            $ {(cashInAndReturn()?.cashInUsd + (cashInAndReturn()?.cashInRiel/receipt?.exchange_rate))?.toFixed(2)}
                          </td>
                        </tr>
  

                        <tr>                       
                          <td colSpan={3} className="start bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                            <b style={{fontFamily: "Siemreap"}} >ប្រាក់ជំពាក់</b> / Balance
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                            {own()}
                          </td>
                        </tr>

                        <tr>                    
                          <td colSpan={3} className="start bold">
                            <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (USD)
                          </td>
                          <td colSpan={2} className="end bold">
                            $ {cashInAndReturn()?.cashReturnUsd?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                       
                          <td colSpan={3} className="start bold">
                            <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (KHR)
                          </td>
                          <td colSpan={2} className="end bold">
                            ៛ {cashInAndReturn()?.cashReturnRiel?.toFixed(0)}
                          </td>
                        </tr> 
                        
                      </tbody>
                    </table>
                  </div>                
                  <Stack direction='row' mt={'3px'}>
                    <Typography className="confirm-person bold" style={{paddingRight:'10px'}}>Note:</Typography>
                    <Typography className="confirm-person" >{textNote}</Typography>
                  </Stack>
                  
                  
                  <Box marginTop="50px">
                    <Typography className="receipt-footer">
                      ទំនិញទិញហើយមិនអាចប្តូរវិញបានទេ
                    </Typography>
                    <Typography className="receipt-footer">
                      Goods sold not returnable
                    </Typography>
                    <Typography className="receipt-footer">
                      សូមអរគុណ!-Thank You!
                    </Typography>
                  </Box>
                </Box>
              : null }
              

              { saleType === "Wholesale" ?  
                <Box className="print-receipt-container">
                  <Stack
                    justifyContent="center"
                    direction="row"
                    sx={{ position: "relative" , height:"70px"}}
                  >
                    <Box className="logo-invoice-print">
                        <img alt="Logo" src={LogoCompanyPrint} className="logo-com-print" />
                    </Box>
                    <Stack direction="row" justifyContent="center" width="100%">
                      <Stack direction="column" ml={4} height="100%">
                        <Typography className="receipt-title-kh">
                          {shop?.label_kh}
                        </Typography>
                        <Typography className="receipt-title">
                          {shop?.label}
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          VATTIN: E116-2200003698
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          {shop?.phone}
                        </Typography>
                        <Typography className="invoice-header-top-print">
                          {shop?.address}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <br/>
                  <table className="invoice-header">
                    <tbody>
                      <tr>
                        <td className="color-black-text"><b>លេខវិក្កយបត្រ</b></td>
                        <td className="color-black-text">: <b>{receipt?.numbering} {moment(receipt?.created_at)?.utc(false)?.format("DD/MM/YYYY hh:mm a")}</b></td>
                      </tr>
                      <tr>
                        <td className="color-black-text"><b>អ្នកគិតលុយ</b></td>
                        <td className="color-black-text">: <b>{userLogin?.firsName + " " + userLogin?.lastName}</b> </td>
                      </tr>
                      <tr>
                        <td className="color-black-text"><b>អតិថិជន</b></td>
                        <td className="color-black-text">: <b>{receipt?.customer_id?.name}</b></td>
                      </tr>    
                      <tr>
                        <td className="color-black-text"><b>លេខទូរស័ព្ទ</b></td>
                        <td className="color-black-text">: <b>{receipt?.customer_id?.tel}</b></td>
                      </tr>
                      <tr>
                        <td className="color-black-text"><b>អាសយដ្ឋាន</b></td>
                        <td className="color-black-text">: <b>{receipt?.customer_id?.address}</b></td>
                      </tr>                 
                    </tbody>
                  </table>

                  <div style={{ width: "100%" , display: "flex" , justifyContent: "center" , marginTop: "6px" , marginBottom: "5px" }}>
                    <b style={{fontFamily: "Siemreap" , fontSize: "11px"}}>វិក្កយបត្រ</b>
                  </div>
                  
                  <div className="receipt-table-container">
                    <table className="receipt-table">
                      <thead>
                        <tr>
                          <th className="start"><b style={{fontFamily: "Siemreap"}}>លេខរៀង</b> <br/>N°</th>
                          <th className="discription"><b style={{fontFamily: "Siemreap"}}>បរិយាយ</b> <br/>Description</th>
                          <th align="center"><b style={{fontFamily: "Siemreap"}}>តម្លៃ</b> <br/>Price</th>
                          <th align="center"><b style={{fontFamily: "Siemreap"}}>ចំនួន</b> <br/>Qty</th>
                          <th className="end"><b style={{fontFamily: "Siemreap"}}>សរុប</b> <br/>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        { receipt?.items?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td className="start">{index + 1}.</td>
                              <td>
                                {data?.name} {data?.product_id_setup?.descriptions}
                              </td>                          
                              <td align="center">
                                {data?.price}
                              </td>
                              <td className="center"> 
                                {data?.qty +" "} 
                                {(data?.unit).split("១")[1] ? (data?.unit).split("១")[1] : null}
                                {(data?.unit).split("1")[1] ? (data?.unit).split("1")[1] : null}
                                {(data?.unit).split("០.៥")[1] ? (data?.unit).split("០.៥")[1] : null}
                                {(data?.unit).split("0.5")[1] ? (data?.unit).split("0.5")[1] : null}
                                {(data?.unit).split("១")[1] || (data?.unit).split("1")[1] || (data?.unit).split("០.៥")[1] || (data?.unit).split("0.5")[1]   ? null : data?.unit}
                              </td>
                              <td className="end">
                                ${" "} {(data?.qty * data?.price)?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}

                        <tr> 
                          <td colSpan={2} className="start bold top-line">Items : {receipt?.items?.length}</td>
                          <td colSpan={1} className="bold top-line" align="center"></td>
                          <td colSpan={1} className="bold top-line" align="center"></td>
                          <td className="end bold top-line"></td>
                        </tr>

                        <tr> 
                          <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>សរុប</b> / Subtotal</td>
                          <td className="end bold"> $ {receipt?.sub_total?.toFixed(2)} </td>
                        </tr>
                        <tr> 
                          <td colSpan={4} className="start bold"><b style={{fontFamily: "Siemreap"}}>បញ្ចុះតម្លៃ</b> / Discount (%)</td>
                          <td className="end bold"> $ {receipt?.discount_total?.toFixed(2)} </td>
                        </tr>
                          
                        <tr>                    
                          <td colSpan={3} className='start bold top-line'> 
                            <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (USD)
                          </td>
                          <td colSpan={2} className='end bold top-line'>
                            $ {receipt?.total?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                        
                          <td colSpan={3} className="start bold" style={{ borderBottom: "1px solid #000000"}}> 
                            <b style={{fontFamily: "Siemreap"}}>សរុបចុងក្រោយ</b> / Grand Total (KHR)
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderBottom: "1px solid #000000"}}>
                            ៛ {(receipt?.total * receipt?.exchange_rate)?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                       
                          <td colSpan={2} className="start bold" style={{ borderBottom: "1px solid #000000"}}> Exchange Rate </td>
                          <td colSpan={3} className="end bold" style={{ borderBottom: "1px solid #000000"}}> 1$ = {receipt?.exchange_rate}៛</td> 
                        </tr>

                        <tr>                       
                          <td colSpan={3} className="start bold">Receive in :</td>
                          <td colSpan={2} className="end bold"> </td>
                        </tr>

                        <tr>                        
                          <td colSpan={3} className="start bold" >Cash (KHR)</td>
                          <td colSpan={2} className="end bold">
                            ៛ {cashInAndReturn()?.cashInRiel?.toFixed(2)}
                          </td>
                        </tr>


                        <tr>                       
                          <td colSpan={3} className="start bold" >Cash (USD)</td>
                          <td colSpan={2} className="end bold" >
                            $ {cashInAndReturn()?.cashInUsd?.toFixed(2)}
                          </td>
                        </tr>


                        <tr>                       
                          <td colSpan={3} className="start bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                            Total Receive (USD)
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderTop: "1px dashed #000000" , paddingBottom: "5px", paddingTop: "5px"}}>
                            $ {(cashInAndReturn()?.cashInUsd + (cashInAndReturn()?.cashInRiel/receipt?.exchange_rate))?.toFixed(2)}
                          </td>
                        </tr>
  

                        <tr>                       
                          <td colSpan={3} className="start bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                            <b style={{fontFamily: "Siemreap"}} >ប្រាក់ជំពាក់</b> / Balance
                          </td>
                          <td colSpan={2} className="end bold" style={{ borderTop: "1px solid #000000" , paddingTop: "5px"}}>
                            {own()}
                          </td>
                        </tr>

                        <tr>                    
                          <td colSpan={3} className="start bold">
                            <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (USD)
                          </td>
                          <td colSpan={2} className="end bold">
                            $ {cashInAndReturn()?.cashReturnUsd?.toFixed(2)}
                          </td>
                        </tr>

                        <tr>                       
                          <td colSpan={3} className="start bold">
                            <b style={{fontFamily: "Siemreap"}}>ប្រាក់អាប់</b> / Change (KHR)
                          </td>
                          <td colSpan={2} className="end bold">
                            ៛ {cashInAndReturn()?.cashReturnRiel?.toFixed(0)}
                          </td>
                        </tr> 
                        
                      </tbody>
                    </table>
                  </div>                
                  
                  
                  <Stack direction='row' justifyContent='space-between' mb={6} mt={2} pl={1} pr={1}>
                    <Typography  className="confirm-person" ml={10}>អ្នកទិញ</Typography>
                    <Typography  className="confirm-person">អ្នករៀបចំទំនិញ</Typography>
                    <Typography  className="confirm-person" mr={10}>អ្នកលក់</Typography>
                  </Stack>
                  

                  <Stack direction='row' mt={'10px'}>
                    <Typography className="confirm-person bold" style={{paddingRight:'10px'}}>Note:</Typography>
                    <Typography className="confirm-person" >{textNote}</Typography>
                  </Stack>
                  <Typography  className="confirm-person" fontWeight={'bold'}>Payment Method:</Typography> 

                </Box>
              : null }
              

            </div>
          </div>
          {/* ===============  End print ======================== */}





          
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        

      {/* =====================  view card =========== */}
        {
          type === "viewPos" && payment_method === "" ?
              <>                
                  <Button
                      fullWidth
                      className="btn-action"
                      onClick={ () => handalePrint() }
                  >
                    Print Receipt
                  </Button>                  
              </>
          :  type === "viewPos" && payment_method !== "" ?
              <>       
                  {
                    loading ?
                      <Button fullWidth className="btn-action">
                        Loading...
                      </Button>
                    :      
                      <Button
                          fullWidth
                          className="btn-action"
                          disabled={requireField ? true : false}
                          onClick={ () =>  handleCreatePaymentReceipt() }
                      >                       
                        Completed Payment and Print Receipt                 
                      </Button>   
                  }                        
              </>
          : null
        } 


        {/* =====================  create and update card =========== */}
        { type === "createPos" && payment_method === "" ?
              <>
                  {
                    listPayment?.length !== 0 ?
                        <Button
                            fullWidth
                            className="btn-action"
                            onClick={() => handalePrint()}
                        >
                          Print Receipt
                        </Button>
                  :
                      <>
                        {
                          loading ?
                            <Button fullWidth className="btn-action">
                              Loading...
                            </Button>
                          : 
                            <Button
                                fullWidth
                                className="btn-action"
                                disabled={requireField ? true : false}
                                onClick={() =>  handleCreatePaymentReceipt() }
                            >                     
                                Unpaid and Print Receipt                  
                            </Button>
                        }
                      </>
                  } 
              </>
          : type === "createPos" && payment_method !== "" ? 
              <>   
                {
                  loading ?
                    <Button fullWidth className="btn-action">
                      Loading...
                    </Button>
                  :
                    <Button
                        fullWidth
                        className="btn-action"
                        disabled={requireField ? true : false}
                        onClick={() =>  handleCreatePaymentReceipt() }
                    >                     
                        Completed Payment and Print Receipt                 
                    </Button> 
                }                       
              </>
          : null
        }
        
               
      </DialogActions>
    </Dialog>
  );
}
