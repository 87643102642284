import React, { useContext } from "react";
import "./App.css";
import Router from "./routes";
//run build
// import { HashRouter as Routers } from 'react-router-dom';

//run domain
import { BrowserRouter as Routers } from "react-router-dom";
//
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AlertMessage from "./Component/AlertMessage/AlertMessage";
import { setContext } from "@apollo/client/link/context";
import { AuthContext } from "./Context/AuthContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";


function App() {
  
  const { state } = useContext(AuthContext);
  const { user } = state;

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
          background: {
            default: "#F8F8F8",
          },
        },

        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    []
  );
    
  

  const httpLink = createHttpLink({
    uri: process.env.React_APP_END_POINT, 
    // uri: "http://192.168.2.18:4000/api",
  });
  
  
  
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: user ? user?.token : "",
      },
    };
  });


  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routers>
          <Router />
        </Routers>
        <CssBaseline />
      </ThemeProvider>
      <AlertMessage />
    </ApolloProvider>
  );
}

export default App;
