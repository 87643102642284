import { gql } from "@apollo/client";

export const CREATE_REFUND = gql`
  mutation CreateReturnProduct($input: ReturnProductInput!) {
    createReturnProduct(input: $input) {
      status
      message
    }
  }
`;


export const VOID_REFUND = gql`
  mutation VoidReturnProduct($returnProductId: ID) {
    voidReturnProduct(ReturnProductId: $returnProductId) {
      status
      message
    }
  }
`;


export const GET_INVOICE = gql`
  query GetReturnProductByCart($fromDate: String, $toDate: String) {
    getReturnProductByCart(fromDate: $fromDate, toDate: $toDate) {
      _id
      numbering
    }
  }
`;

export const GET_REFUND = gql`
query GetReturnProduct($keyword: String) {
  getReturnProduct(keyword: $keyword) {
    _id
    cart_Id {
      _id
      customer_id {
        tel
        name
        image_src
        address
        _id
      }
      shop_Id {
        _id
        address
        email
        name
        phone
      }
      status
      numbering
      isDiscount
      is_voided
      date
      payment_status
      discount_total
      sub_total
      tax_total
      exchange_rate
      total
      items {
        _id
        product_id_setup {
          _id
          saleName
          saleQty
          saleType
          saleQR
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            qty_inUnit
            bar_Code
            created_At
          }
          unit_Price
          created_At
        }
        discount_id {
          _id
          amount_discount
          percentage
          events
          status
          start_date
          end_date
          remark
          created_At
        }
        price
        qty
        qty_in_product
        percentage_discount
        amount_discount
        qty_in_shop
        unit
        name
        image_src
      }
    }
    items {
      set_UpSale {
        _id
        saleName
        saleQty
        saleType
        saleQR
        product_Id {
          _id
          name
          cost
          category
          image_src
          remark
          type
          feature
          unit
          qty_inUnit
          bar_Code
          created_At
        }
        unit_Price
        created_At
      }
      discount_id {
        _id
        amount_discount
        percentage
      }
      qty
      price
      total_amount
      unit_sale
    }
    date
    is_voided
    created_At
  }
}
`;
