import React, { useState } from "react";
import {
  Grid,
  Stack,
  // Box,
  TextField,
  // InputAdornment,
  IconButton,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
function CreateListExpense(props) {
  const [touchedItemName, setTouchedItemName] = useState(false);
  const handleTouchItemName = () => setTouchedItemName(false);

  const [errorMessage] = useState([
    "Invalid Value",
    "Field is required!",
    "Duplicate item!",
  ]);

  return props?.items?.map((item) => (
    <Grid item container xs={12} spacing={2} key={item?.key}>
      <Grid item xs={6}>
        <TextField
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none !important",
                "-webkit-appearance": "none !important",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          size="small"
          placeholder="item name"
          value={item?.item_name}
          fullWidth
          multiline
          onFocus={handleTouchItemName}
          onChange={(e) => props?.updateItemName(item?.key, e.target.value)}
          error={props?.checkFieldRequired && item?.item_name === ""}
          helperText={
            props?.checkFieldRequired &&
            item?.item_name === "" &&
            errorMessage[1]
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none !important",
                "-webkit-appearance": "none !important",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          size="small"
          placeholder="Qty"
          fullWidth
          value={item?.qty}
          onFocus={(e) => e.target.select()}
          type={"number"}
          onChange={(e) => {
            if (e.target.value !== undefined && e.target.value !== "") {
              props?.updateItemQty(item?.key, e.target.value);
            } else {
              props?.updateItemQty(item?.key, 0);
            }
          }}
          inputProps={{
            min: 0.01,
          }}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          size="small"
          placeholder="unit price"
          fullWidth
          value={item?.unit_price ? item?.unit_price : 0.01}
          onClick={(e) => e.target.select()}
          onChange={(e) => props?.updateItemPrice(item?.key, e.target.value)}
          type={"number"}
          inputProps={{
            min: 0.01,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Stack direction="column" justifyContent="center" width="100%">
          <Stack direction="row" justifyContent="right" width="100%">
            <IconButton onClick={() => props.removeItem(item?.key)}>
              <DeleteRoundedIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  ));
}

export default CreateListExpense;
