import "./page404.scss";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import Preview from "../Assets/404-error-preview.png";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Box, IconButton, Typography } from "@mui/material";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // left: "50%",
        top: "35%",
        position: "relative",
        // position: "fixed",
        justifyContent: "center",
        display: "flex",
        height: "100vh",
        width: "100%",
        direction: "row",
      }}
    >
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Stack
          direction="column"
          justifyContent="right"
          // marginTop="15%"
          height="100vh"
        >
       
            <Typography variant="h2" className="marsk">
              Page not found!
            </Typography>

            <Typography>
              The page you request was not found, and have a fine guess why.
              <br />
              If you type URL directly please make sure the <br />
              spelling is correct. Have no fear, help is near.
            </Typography>

            <Stack direction="row" spacing={2} mt={5}>
              <Button
                className="btn-home"
                variant="contained"
                onClick={() => navigate("/")}
              >
                <Typography variant="h5">Go Home</Typography>
              </Button>
            </Stack>
    
        </Stack>

        <Box
          sx={{
            // backgroundColor: "pink",
            width: "50%",
            marginTop: "25%",
            height: "100vh",
          }}
        >
          <SmsFailedOutlinedIcon
            sx={{ height: "auto", width: "404px", color: "purple" }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
