import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
//graphql
// import { GET_CUSTOMERS_PAGINATION } from "../Schema/Customers";
// import { GET_LOANPLAN_WITH_PAGINATION } from "../Schema/Loanplan";
// import { GET_LOANTYPE_WITH_PAGINATION } from "../Schema/Loantype";

export function SelectCustomer({
  setIdValue,
  customerId,
  coBorrowerId,
  disabled,
  selectedValue,
}) {
  const [data, setData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [CoBorrowerOptions, setCoBorrowerOptions] = useState([]);
  const [guarantorOptions, setGuarantorOptions] = useState([]);
  // const { data: customerData } = useQuery(GET_CUSTOMERS_PAGINATION, {
  //   variables: {
  //     page: 1,
  //     keyword: "",
  //     pagination: false,
  //   },
  //   onCompleted: ({ getCustomerByPagination }) => {
  //     setData(getCustomerByPagination?.data);
  //   },
  //   onError: (error) => {
  //     console.log(error?.message);
  //   },
  // });

  // useEffect(() => {
  //   let rows = [];
  //   if (data?.length !== 0) {
  //     data?.map((d) => {
  //       const allRows = {
  //         id: d?._id,
  //         title: d?.first_name + " " + d?.last_name,
  //       };
  //       rows.push(allRows);
  //       setCustomerOptions(rows);
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   let rows = [];
  //   if (data?.length !== 0) {
  //     data
  //       ?.filter((d) => d?._id !== customerId)
  //       .map((d) => {
  //         const allRows = {
  //           id: d?._id,
  //           title: d?.first_name + " " + d?.last_name,
  //         };
  //         rows.push(allRows);
  //         setCoBorrowerOptions(rows);
  //       });
  //   }
  // }, [data, customerId]);

  // useEffect(() => {
  //   let rows = [];
  //   if (data?.length !== 0) {
  //     data
  //       ?.filter((d) => d?._id !== customerId && d?._id !== coBorrowerId)
  //       .map((d) => {
  //         const allRows = {
  //           id: d?._id,
  //           title: d?.first_name + " " + d?.last_name,
  //         };
  //         rows.push(allRows);
  //         setGuarantorOptions(rows);
  //       });
  //   }
  // }, [data, customerId, coBorrowerId]);

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  // console.log("selectedValue::", selectedValue)

  return (
    <Autocomplete
      value={selectedValue}
      options={
        customerId && coBorrowerId
          ? guarantorOptions
          : customerId
          ? CoBorrowerOptions
          : customerOptions
      }
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select value" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select value" />
      )}
    />
  );
}

export function SelectLoanType({ setIdValue, disabled, selectedValue }) {
  const [data, setData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  // const { data: loanTypeData } = useQuery(GET_LOANTYPE_WITH_PAGINATION, {
  //   variables: {
  //     page: 1,
  //     keyword: "",
  //     pagination: false,
  //   },
  //   onCompleted: ({ getLoanTypeByPagination }) => {
  //     setData(getLoanTypeByPagination?.data);
  //   },
  //   onError: (error) => {
  //     console.log(error.message);
  //   },
  // });

  // useEffect(() => {
  //   let rows = [];
  //   if (data?.length !== 0) {
  //     data?.map((d) => {
  //       const allRows = {
  //         id: d?._id,
  //         title: d?.loan_type_name,
  //       };
  //       rows.push(allRows);
  //       setSelectOptions(rows);
  //     });
  //   }
  // }, [data]);

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  // console.log("selectedValue::", selectedValue);

  return (
    <Autocomplete
      value={selectedValue}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select loanType" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select loanType" />
      )}
    />
  );
}

export function SelectLoanPlan({ setIdValue, disabled, selectedValue }) {
  const [data, setData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);


  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  return (
    <Autocomplete
      value={selectedValue}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select loanplan" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select loanplan" />
      )}
    />
  );
}



export  function SelectProduct({ setIdValue, disabled, selectedValue }) {


  const productData = [
    { label: "ដំណាប់ស្វាយ"},
    { label: "នំគ្រាប់ស្វាយ"},
    { label: "សូកូឡាគ្រប់រសជាតិ"},
   
  ];
  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };
  return (
    <Autocomplete
      value={selectedValue}
      options={productData}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.label === undefined ? "select" : option.label
      }
      disabled={disabled}
      renderInput={(params) => <TextField {...params} size="small"    placeholder="select"  />}
    />
  );
}


export function SelectUnit({ setIdValue, disabled, selectedValue }) {

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  const unitData = [
    { label: "កញ្ចប់"},
    { label: "ដប"},
    { label: "Kg"},
  ]
  
  return (
    <Autocomplete
      value={selectedValue}
      options={unitData}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.label === undefined ? "select unit" : option.label
      }
      disabled={disabled}
      renderInput={(params) => <TextField {...params} size="small"  placeholder="select unit" />}
    />
  )
}

