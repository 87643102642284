import React, { useState, useEffect, useContext } from "react";
import "../../../../Style/pagestyle.scss";
import { Link } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
//ICONS
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { TbPlaylistAdd } from "react-icons/tb";
//SRC
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Dymanic/Translate";
import EmptyData from "../../../Include/EmptyData";
import LoadingPage from "../../../Include/LoadingPage";
import TopRightBar from "../../../../Menu/TopRightBar";
import IconMenuResponsive from "../../../../Menu/IconMenuResponsive";
import moment from "moment";
import CreateDiscount from "../Create/CreateDiscount";
import DiscountAction from "../Action/DiscountAction";
import PreviewDiscount from "../Preview/PreviewDiscount";
import { useQuery } from "@apollo/client";
import { GET_DISCOUNT } from "../../../../Schema/discount";
import { useNavigate } from "react-router-dom";

export default function DiscountPage() {
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //open preview
  const [row, setRow] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const handleOpenPreview = (row) => {
    setOpenPreview(true);
    setRow(row);
  };
  const handleClosePreview = () => setOpenPreview(false);

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //paginator
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [paginator, setPaginator] = useState({});

  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_DISCOUNT, {
    variables: {
      keyword: keyword,
    },
    onCompleted: ({ getDiscount }) => {
      setTableData(getDiscount);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  useEffect(() => {
    setShowPage(page);
  }, [keyword, limit, page]);

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  // console.log("tableDiscount ::", tableData);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Typography
              className="page-title"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/setting")}
            >
              Setting
            </Typography>
            <Typography className="page-title">/</Typography>
            <Typography className="page-title">Discount</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>

      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={6} className="search-container">
          <Box className="box-search">
            <Stack direction="column" sx={{ width: "300px" }}>
              <TextField
                sx={{ bgcolor: "#fff !important" }}
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder="Search"
                size="small"
                onChange={(e) => setKeyword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={6} className="add-container">
          <Stack direction="row" spacing={2} justifyContent="end">
            <Button
              onClick={handleOpen}
              className="btn-add"
              startIcon={<TbPlaylistAdd className="icon-add" />}
            >
              Create
            </Button>
          </Stack>
          <CreateDiscount
            open={open}
            handleClose={handleClose}
            setRefetch={refetch}
          />
        </Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow className="header-row">
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  N°
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Event
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Start Date
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  End Date
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Status
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Discount
                </TableCell>
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-title"
                  }
                >
                  Remark
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-end"
                      : "header-title-end"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} className="body">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <React.Fragment>
                {tableData?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} className="body-cell">
                        <Stack
                          direction="column"
                          height={300}
                          justifyContent="center"
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {tableData?.map((row, index) => (
                      <TableRow
                        className={
                          (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                        }
                        key={index}
                      >
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {/* {index + paginator?.slNo} */}
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          <Typography>{row?.events}</Typography>
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {moment(row?.start_date).format("DD-MM-YY")}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {moment(row?.end_date).format("DD-MM-YY")}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {row?.status ? (
                            <Typography
                              sx={{ color: "green", fontWeight: "bold" }}
                            >
                              Active
                            </Typography>
                          ) : (
                            <Typography>no-active</Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {row?.amount_discount > 0 ? (
                            <Typography>
                              $ {row?.amount_discount?.toFixed(2)}
                            </Typography>
                          ) : (
                            <Typography>
                              % {row?.percentage?.toFixed(2)}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                        >
                          {row?.remark}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <DiscountAction editData={row} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>

        {/* <PreviewDiscount
          open={openPreview}
          handleClose={handleClosePreview}
          data={row}
        /> */}

        {/* <Stack direction="row" justifyContent="right" sx={{ mt: 2 }} alignItems="center" spacing={2}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            count={paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
          <Select
            sx={{ width: "105px" }}
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            defaultValue={limit}
          >
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={16}>16/Page</MenuItem>
            <MenuItem value={20}>20/Page</MenuItem>
          </Select>
        </Stack> */}
      </Box>
    </div>
  );
}
