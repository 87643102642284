import { gql } from "@apollo/client";

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($input: DiscountInput) {
    createDiscount(input: $input) {
      message
      status
    }
  }
`;

export const GET_DISCOUNT = gql`
  query GetDiscount($keyword: String) {
    getDiscount(keyword: $keyword) {
      _id
      amount_discount
      created_At
      end_date
      events
      percentage
      remark
      start_date
      status
    }
  }
`;

export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($updateDiscountId: ID, $input: DiscountInput) {
    updateDiscount(id: $updateDiscountId, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($deleteDiscountId: ID) {
    deleteDiscount(id: $deleteDiscountId) {
      message
      status
    }
  }
`;
