import * as React from "react";
import "../../Style/actionstyle.scss";
import { IconButton, Typography, Stack, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import VoidRefund from "./VoidRefund";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Dymanic/Translate";

export default function RefundAction({ editRow, setRefetch }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(editRow)

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleOpenDel} disabled={editRow?.is_voided}>
        <Stack direction="row" spacing={1} className="action">
          <DeleteForeverOutlinedIcon className="icon-menu-delete" />
          <Typography className="void-card">Void</Typography>
        </Stack>
      </Button>

      {/* <IconButton onClick={handleClick} disabled={editRow?.is_voided}>
        <MoreVertIcon className="three-point" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row">
            <DeleteForeverOutlinedIcon className="icon-menu-delete" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              Void
            </Typography>
          </Stack>
        </MenuItem>
      </Menu> */}

      <VoidRefund
        open={openDel}
        editRow={editRow}
        setRefetch={setRefetch}
        handleClose={handleCloseDel}
      />
    </div>
  );
}
