import React, { useEffect, useState, useContext } from "react";
import "../../../Pages/pos.scss";
import { Typography, Stack, Button, Grid, Box } from "@mui/material";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
export default function PosWholesale({
  tableData,
  handleAddProductItem,
  loading,
  handleOutStock,
  typeSell,
}) {
  // const { setAlert } = useContext(AuthContext);
  // console.log("tableDatawholesale", tableData);
  return (
    <>
      {loading ? (
        <Stack direction="column" justifyContent="center" height={300}>
          <LoadingPage />
        </Stack>
      ) : (
        <>
          {tableData?.filter((e) => e.type !== "Retail")?.length === 0 ? (
            <Stack direction="column" justifyContent="center" height={300}>
              <EmptyData />
            </Stack>
          ) : (
            <Grid container spacing={3}>
              {tableData
                ?.filter((e) => e.type !== "Retail")
                ?.map((data, index) => {
                  return (
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} key={index}>
                      <Button
                        fullWidth
                        className="btn-pos"
                        disabled={ data?.total_inShop === 0 || handleOutStock(data?._id) === true  ? true : false }
                        onClick={() => {
                          handleAddProductItem(data, data?.total_inShop);
                        }}
                      >
                        { data?.total_inShop === 0 && ( <Box className="stock-out">Out of stock</Box> ) }
                        { handleOutStock(data?._id) === true && ( <Box className="stock-out">Out of stock</Box> ) }

                        <Stack direction="column" width="100%">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            className="img-container"
                          >
                            { data?.image === "" || data?.image === null ? (
                              <HideImageOutlinedIcon
                                className="product-img"
                                sx={{ color: "#757575" }}
                              />
                            ) : (
                              <img
                                className="product-img"
                                alt="Image"
                                src={ data?.image ? data?.image : "" }
                              />
                            )}
                          </Stack>

                          <Stack
                            className="card-body"
                            direction="column"
                            justifyContent="space-between"
                          >
                            <Typography className="product-name">
                              {data?.name} { data?.descriptions ? data?.descriptions : "" }
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography className="product-price">
                                ${data?.unit_Price}
                              </Typography>
                              <Typography className="unit-name"> 
                                {data?.unit}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Button>
                    </Grid>
                  );
                })}
            </Grid>
            
            //       <Grid item xs={12} md={6} lg={3} key={index}>
            //         <Button
            //           disabled={data?.total_inShop === 0}
            //           fullWidth
            //           className="btn-pos"
            //           onClick={() => {
            //             handleAddProductItem(data, data?.total_inShop);
            //           }}
            //         >
            //           {data?.total_inShop === 0 && (
            //             <Box className="stock-out">Out of stock</Box>
            //           )}
            //           {handleOutStock(data?._id) === true && (
            //             <Box className="stock-out">Out of stock</Box>
            //           )}
            //           <Stack direction="column" width="100%">
            //             <Stack
            //               direction="row"
            //               justifyContent="center"
            //               className="img-container"
            //             >
            //               {data?.image_src === "" ? (
            //                 <HideImageOutlinedIcon
            //                   className="product-img"
            //                   sx={{ color: "#757575" }}
            //                 />
            //               ) : (
            //                 <img
            //                   className="product-img"
            //                   alt="Image"
            //                   src={data?.image_src}
            //                 />
            //               )}
            //             </Stack>
            //             <Stack
            //               className="card-body"
            //               direction="column"
            //               justifyContent="space-between"
            //             >
            //               <Typography className="product-name">
            //                 {data?.name}
            //               </Typography>
            //               <Stack direction="row" justifyContent="space-between">
            //                 <Typography className="product-price">
            //                   ${data?.unit_Price}
            //                 </Typography>
            //                 <Typography className="unit-name">
            //                   {data?.unit}
            //                 </Typography>
            //               </Stack>
            //             </Stack>
            //           </Stack>
            //         </Button>
            //       </Grid>
            //     );
            //   })}
            // </Grid>
          )}
        </>
      )}
    </>
  );
}
