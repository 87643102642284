import React, { useState, useEffect, useContext } from "react";
import "../../Style/pagestyle.scss";
import { Link } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./advertisesleepscreen.scss";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
//Icon
import IconMenuResponsive from "../../Menu/IconMenuResponsive";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";

//SRC
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Dymanic/Translate";
import { useNavigate } from "react-router-dom";
import TopRightBar from "../../Menu/TopRightBar";

//Component
import LoadingPage from "../../Component/Include/LoadingPage";
import EmptyData from "../../Component/Include/EmptyData";
import { FooterPagination } from "../../Dymanic/FooterPagination";
import EmptyImage from "../../Assets/empty-image.png";

import { useQuery } from "@apollo/client";
import { GET_ADVERTISE_WITH_PAGINATION } from "../../Schema/advertise";

export default function AdvertiseSleepScreen() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  // open create
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // get
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [paginationStatus, setPaginationStatus] = useState(true);

  const { refetch } = useQuery(GET_ADVERTISE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: paginationStatus,
    },
    onCompleted: ({ getAdvertisementWithPagination }) => {
      setRowData(getAdvertisementWithPagination?.data);
      setPagination(getAdvertisementWithPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      // console.log(error?.message);
      setLoading(true);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    if (e.target.value === 0) {
      setPaginationStatus(false);
    } else {
      setPaginationStatus(true);
      setPage(1);
    }
  };

  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, limit, keyword]);

  const navigate = useNavigate();
  return (
    <>
      <Box >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Navigation]}
        >
          {rowData?.map((item, index) => (
            <SwiperSlide>
              <img src={item?.src_image} alt={item?.title} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
}
