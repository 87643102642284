import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks"; 
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";

import { DB_WEB } from "../../Config/DB_Web";  
import { GET_DISCOUNT } from "../../Schema/discount";
// @ts-ignore
const { discount_collection } = DB_WEB;


export default function OfflineDiscount() { 

    const { setAlert } = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    //================== GET PRODUCT ====================
    const { data ,  refetch } = useQuery(GET_DISCOUNT, {
        variables: {
          keyword: "",
        },
        onCompleted: ({ getDiscount }) => {
          
        },
        onError: (error) => {
          console.log(error?.message);  
        },
      });

    
    useEffect( () => {
        refetch();
    },[loading])


    //================= CLEAR PRODUCT COLLECTION =================
    const handleClareDiscount = () => {
        discount_collection.clear();
    }
 

    //================= ADD PRODUCT COLLECTION =================
    const handleGetCustomer = () => { 

        if(data?.getDiscount?.length > 0) {
            //=============== CLEARE OLD DATA ==============
            handleClareDiscount();

            //=============== SET NEW DATA ================
            let rows = [];   
            data?.getDiscount?.map( (row) => {  
                let allRow = {
                    _id: row?._id,
                    amount_discount: row?.amount_discount,
                    end_date: row?.end_date,
                    events: row?.events,
                    percentage: row?.percentage,
                    remark: row?.remark,
                    start_date: row?.start_date,
                    status: row?.status,
                } 
                rows.push(allRow);
            })
            discount_collection.bulkAdd(rows);

            setTimeout( () => {
                setLoading(false);
                setAlert(true , "success" , "Discount is set.");
            },500)
            
        } else {
            setTimeout( () => {
                setLoading(false);
                setAlert(true , "error" , "No discount to set!");
            },500)
        }
        
    }
        
    //========================== GET FROM DB OFFLINE ==================
    const allItems = useLiveQuery( () => discount_collection.toArray(), []); 


    return(
        <Grid item xs={6}>
            <Stack
                direction="column" 
                sx={{
                    backgroundColor: "white", 
                    padding: "15px",
                    borderRadius: "10px",
                    height: "150px",
                }}
            >
                <Typography sx={{ fontWeight: "bold" }}>
                    Discount Offline
                </Typography>
                <Typography variant="body2">
                    Set and clear discount offline.
                </Typography>
                <Box flexGrow={1} />
                <Stack direction="row" justifyContent="center" spacing={2}>
                    
                    <Button 
                        sx={{
                            backgroundColor: 'green', 
                            color: "white", 
                            width: "150px",
                            ":hover": {
                                backgroundColor: 'green',
                            }
                        }} 
                        onClick={ () => { 
                            refetch(); 
                            setLoading(true);
                            setTimeout( () => {
                                handleGetCustomer();  
                            },2000) 
                        }}
                    >
                        { loading ? "...Loading" : "Set" }
                    </Button>
                    <Box flexGrow={1} />
                    <Stack direction="column" justifyContent="center" height="100%">
                        <Typography>
                            Items: {allItems ? allItems?.length : 0} record
                        </Typography>
                    </Stack> 
                </Stack> 
            </Stack>
            
        </Grid>
    )
}