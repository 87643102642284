import React, { useEffect, useState } from "react";
import "./report.scss";
import { Typography, Stack } from "@mui/material";
import moment from "moment";
import { getKhmerFullDate } from "../Function/khmerDate";
import { useQuery } from "@apollo/client";
import { REPORT_SHOP_RECIEVE_PRODUCT } from "../../Schema/report";

export default function RequestStock({ date }) {
  const shop = JSON.parse(window.localStorage.getItem("shop"));
  const [loading, setLoading] = useState(true);

  const { data, refetch } = useQuery(REPORT_SHOP_RECIEVE_PRODUCT, {
    variables: {
      shopId: shop ? shop?.id : "",
      fromDate: moment(date)?.format("YYYY-MM-DD"),
      toDate: moment(date)?.format("YYYY-MM-DD"),
    },
    onCompleted: ({ getShopStockReceiveReport }) => {
      console.log("getShopStockReceiveReport::", getShopStockReceiveReport);
      setLoading(false);
    },
  });

  useEffect(() => {
    refetch();
  }, [date]);

  return (
    <>
      <Stack container mt="30px" justifyContent="center" alignItems="center">
        <Typography className="report-title">
          តារាងស្នើសុំទំនិញចូលម៉ាត
        </Typography>
        <Typography className="report-date">
          {getKhmerFullDate(moment(date))}
        </Typography>
      </Stack>
      <Stack className="report-table-container">
        <table className="daily-report-print">
          <thead className="thead-sticky">
            <tr>
              <th className="thead-title-in-report">ល.រ</th>
              <th className="thead-title-in-report">ម៉ោងត្រូវការ</th>
              <th className="thead-title-in-report">បារកូដ</th>
              <th className="thead-title-in-report">ឈ្មោះទំនិញ</th>
              <th className="thead-title-in-report">ប្រភេទ</th>
              <th className="thead-title-in-report">ចំនួនទំនិញ</th>
              <th className="thead-title-in-report">ដោយ</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                <tr className="center">
                  <td colSpan={7}>loading...</td>
                </tr>
              </>
            ) : (
              <>
                {data?.getShopStockReceiveReport?.map((e, i) => (
                  <tr className="center" key={i}>
                    <td className="tbody-title-in-report">{i + 1}</td>
                    <td className="tbody-title-in-report">
                      {moment(e?.created_at)?.utc(false)?.format("DD/MMM/YYYY hh:mm a")}
                    </td> 
                    <td className="tbody-title-in-report">{e?.bar_code}</td>
                    <td className="tbody-title-in-report" align="left">
                      {e?.product_name}
                    </td>
                    <td className="tbody-title-in-report">{e?.category}</td>
                    <td className="tbody-title-in-report">
                      {(e?.qty * e?.qty_inUnit)?.toFixed(2)} {e?.unit_of_qty_inUnit}
                    </td>
                    <td className="tbody-title-in-report">{e?.created_by?.firsName+" "+e?.created_by?.lastName}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </Stack>
    </>
  );
}
