import React, { useContext, useState,useEffect } from "react";
import "./dialogadjustqtyproduct.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// date
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { AuthContext } from "../../../../Context/AuthContext";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_PRODUCT_IN_SHOP, REMOVE_QTY_PRODUCT } from "../../../../Schema/warehouse";
import moment from "moment";
import { GET_ALL_PRODUCT } from "../../../../Schema/setting";

export default function DialogAdjustQtyProduct({
  open,
  handleClose,
  setRefetch,
  t,
  language,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading,setLoading] = useState(false);

  let shop = JSON.parse(window.localStorage.getItem("shop"));
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  //================== product ====================================
  const [allProduct, setAllProduct] = useState([]);
  const [productSelected,setProductSelected] = useState({id: "" , label: "" , unit: "" , unitPrice: 0 , qtyInUnit: 0 , unit_of_qty_inUnit: ""});
  
  const { refetch } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct);
      if (getAllProduct?.length !== 0) {
        let rows = [];
        getAllProduct?.map((element, index) => {
          const allrow = {
            label: index + 1 + " - " + element?.name,
            id: element?._id,            
            unit: element?.unit,  
            unitPrice: element?.cost,      
            qtyInUnit: element?.qty_inUnit,
            unit_of_qty_inUnit: element?.unit_of_qty_inUnit
          };
          rows.push(allrow);
        });
        setAllProduct(rows);
      } else {
        setAllProduct([]);
      }
    },
  });

  useEffect( () => {
    refetch();
  },[open])


  //=============================
  const [productInShop,setProductInShop] = useState([]);
  const[getProductInShopByProductId] = useLazyQuery(GET_PRODUCT_IN_SHOP);

  useEffect( () => {  
    if(productSelected?.id) {
      getProductInShopByProductId({
        variables: {
          productId: productSelected?.id,
        },
        onCompleted: ({getProductInShopByProductId}) => {
          // console.log("getProductInShopByProductId:::",getProductInShopByProductId)
          if(getProductInShopByProductId?.length > 0) { 
            let rows = [];
            getProductInShopByProductId?.map( (row) => {
                const allRow = {
                  _id: row?._id,
                  qty: row?.qty,
                  product_Id: {
                    name: row?.product_Id?.name,
                    qty_inUnit: row?.product_Id?.qty_inUnit,
                    unit_of_qty_inUnit: row?.product_Id?.unit_of_qty_inUnit
                  },
                  decreased_qty: parseFloat((row?.qty*row?.product_Id?.qty_inUnit)?.toFixed(1)),
                  status: false,
                }
                rows.push(allRow)
            })
            setProductInShop([...rows])
          } else {
            setProductInShop([])
          }
        }
      })
    }
  },[productSelected?.id])


  const handleUpdateState = (value,i) => {
      let newArray = productInShop;     
      newArray?.map( (row,index) => {
          row.status = index === i ? value : false;  
      })
      setProductInShop([...newArray]);  
  }

  const handleUpdateDecreaseQty = (value) => {
      let newArray = productInShop;     
      newArray?.map( (row) => {      
          row.decreased_qty =  row.status === true ? value : parseFloat((row?.qty*row?.product_Id?.qty_inUnit)?.toFixed(1))
      })
      setProductInShop([...newArray]);  
  }


  //
  const [decreaseProductInShop] = useMutation(REMOVE_QTY_PRODUCT, {
    onCompleted: ({ decreaseProductInShop }) => { 
      if (decreaseProductInShop?.status) {
        setAlert(true, "success", decreaseProductInShop?.message);
        setRefetch()
        setLoading(false);
        resetForm();
        handleClose();
        setProductSelected({ id: "" , label: "" , unit: "" , unitPrice: 0 , qtyInUnit: 0 , unit_of_qty_inUnit: "" })
        setProductInShop([]);
      } else {
        setAlert(true, "error", decreaseProductInShop?.message);
        setLoading(false);
      }
    },
    onError: (error) => { 
      setAlert(true, "error", decreaseProductInShop?.message);
      setLoading(false);
    },
  });


  //fromik
  const AddLoanPlan = Yup.object().shape({       
    product_in_shop_id: Yup.string().required(),  
    adjust_type: Yup.string().required(),
  });


  const formik = useFormik({
    initialValues: {   
      product_in_shop_id: "",  
      adjust_type: "lost",
    },

    validationSchema: AddLoanPlan,
    onSubmit: async (values) => {
      setLoading(true);
      let datAdjust = productInShop?.filter(e => e.status === true )[0]; 

      let newValue = {      
          product_in_shop_id: values.product_in_shop_id,
          date: new Date(),
          decreased_qty: (datAdjust?.qty)-(datAdjust.decreased_qty/datAdjust?.product_Id?.qty_inUnit), 
          adjust_type: values?.adjust_type,
          created_by: userLogin?._id,
      }  
       

      if( newValue?.decreased_qty > 0 && newValue?.product_in_shop_id !== "" && newValue?.product_in_shop_id !== undefined) {  
        // console.log("newValue:::" , newValue);        
        decreaseProductInShop({
            variables: {
                input: newValue,
            },
        });
      } else {
        setLoading(false);
      }
     
    },
  });

  const { errors, touched, resetForm, handleSubmit, getFieldProps,  setFieldValue,  values } = formik;
 

  useEffect( () => {
    resetForm();
    setProductInShop([]);
    setFieldValue("product_in_shop_id" , ""); 
  },[open])


  useEffect( () => { 
    setProductInShop([]);
    setFieldValue("product_in_shop_id" , ""); 
  },[productSelected?.id])


  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="create-adjust-qty-dialog" fullWidth maxWidth="md">
        <DialogTitle className="dialog-title">
            <Stack direction="row" spacing={2} alignItems='center'>
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                Adjust product quantity
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose}>
                  <CloseIcon className="clear-icon" />
                </IconButton>
            </Stack>
            <Divider />
        </DialogTitle>

        <DialogContent>
        
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={5} className="grid-profile">   
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Product
                    </Typography>        
                    <Autocomplete
                        freeSolo
                        clearOnBlur
                        disablePortal
                        value={productSelected}
                        options={allProduct}
                        getOptionLabel={(option) => option?.label ? option?.label : "" }
                        isOptionEqualToValue={(option, value) => option.id === value.id || value === "" }
                        onChange={(e, newValue) => { 
                          setProductSelected(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            placeholder="Product"
                            {...params} 
                          />
                        )}
                      />         
                  </Grid>
                      
                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Adjust Type
                    </Typography>
                    <FormControl  fullWidth  className="text-field">
                      <Select
                        value={values?.adjust_type}
                        size="small"
                        onChange={ (event) => setFieldValue("adjust_type" , event.target.value) }
                      >
                        <MenuItem value={"lost"}>ទំនិញបាត់</MenuItem>
                        <MenuItem value={"spoiled"}>ទំនិញខូច</MenuItem>
                        <MenuItem value={"confuse"}>បញ្ចូលច្រលំ</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  {/* <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      Adjust Stock At
                    </Typography>  
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled
                          inputFormat="dd-MM-yyyy"
                          mask="__-__-____"
                          value={values.date}
                          onChange={(e) => {
                            setFieldValue( "date" , e);
                          }}
                          error={Boolean(touched.date && errors.date)}
                          helperText={touched.date && errors.date}
                          className="search-field"
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                    </LocalizationProvider>                  
                  </Grid> */}

                  <Grid item xs={12}></Grid>
                  <Grid container item xs={6} spacing={2} columnSpacing={2}>
                      <Grid container item xs={12} spacing={2}>
                          <Grid item xs={2}>
                            <Typography sx={{color: "#0F81C2" , fontWeight: "bold" , textAlign: "center"}}>
                                #
                            </Typography> 
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{color: "#0F81C2" , fontWeight: "bold"}}>
                              Producut Name 
                            </Typography>  
                          </Grid>
                          <Grid item xs={5}> 
                            <Typography sx={{color: "#0F81C2" , fontWeight: "bold" , textAlign:"right"}}>
                              Qty
                            </Typography> 
                          </Grid>
                      </Grid>

                {
                  productInShop?.map( (row,index) => (
                      <Grid container item xs={12} key={index} spacing={2}>
                          <Grid item xs={2}>
                            <Stack direction="row" justifyContent="center"> 
                              <Checkbox 
                                  checked={row?.status}
                                  onChange={() => {  
                                    handleUpdateState(!row?.status,index)
                                    setFieldValue("product_in_shop_id" , "") 
                                  }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={5}>
                            <Stack direction="column" justifyContent="center" height="100%"> 
                              {row?.product_Id?.name} 
                            </Stack>
                          </Grid>
                          <Grid item xs={5}> 
                            <Stack direction="column" justifyContent="center" height="100%" textAlign="right"> 
                              {parseFloat((row?.qty*row?.product_Id?.qty_inUnit)?.toFixed(1))} {row?.product_Id?.unit_of_qty_inUnit} 
                            </Stack>
                          </Grid>
                      </Grid>
                  ))
                }
                  </Grid>
                    
                  <Grid container item xs={1} spacing={2}></Grid>
                  <Grid container item xs={5} spacing={2}>
                      <Grid container item xs={12} spacing={2}> 
                          <Grid item xs={7}>
                            <Typography sx={{color: "#0F81C2" , fontWeight: "bold"}}>
                                Producut Name 
                            </Typography>  
                          </Grid>
                          <Grid item xs={5}> 
                            <Typography sx={{color: "#0F81C2" , fontWeight: "bold"}}>
                                Adjust Qty To
                            </Typography> 
                          </Grid>
                      </Grid>

                      {
                        productInShop?.filter(e => e.status === true )?.map( (row,index) => (
                          <Grid container item xs={12} spacing={2} key={index}> 
                              <Grid item xs={7}>
                                <Typography align="center">
                                  {row?.product_Id?.name} 
                                </Typography>  
                              </Grid>
                              <Grid item xs={5}> 
                                <TextField
                                  fullWidth
                                  size="small"
                                  type="number"                    
                                  placeholder="0"
                                  onFocus={e => e.target.select() }   
                                  value={row?.decreased_qty}                 
                                  onChange={ (e) => {    
                                      setFieldValue("product_in_shop_id" , row?._id );  
                                      if(e.target.value) {          
                                          if(parseFloat(e.target.value) < row?.qty*row?.product_Id?.qty_inUnit) { 
                                            handleUpdateDecreaseQty(parseFloat(parseFloat((e.target.value))?.toFixed(1)));
                                          } else {  
                                            handleUpdateDecreaseQty(parseFloat((row?.qty*row?.product_Id?.qty_inUnit)?.toFixed(1)));
                                          }
                                      } else { 
                                        handleUpdateDecreaseQty(0);
                                      }         
                                  }} 
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {row?.product_Id?.unit_of_qty_inUnit}
                                      </InputAdornment>
                                    ),
                                    inputProps: {                        
                                      min: 0,
                                    },
                                  }}
                                />
                              </Grid>
                          </Grid>
                        )) 
                      }
                      
                  </Grid>           
                
                </Grid>
              </Form>
            </FormikProvider>
         
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>

                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}

              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
