import React, { useEffect, useState, useContext } from "react";
import "./pos.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Pagination,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import IconMenuResponsive from "../Menu/IconMenuResponsive";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
import PrintProductItem from "../Component/Pos/PrintProductitem/PrintProductItem";
import TopRightBar from "../Menu/TopRightBar";
import ProductItemUpdate from "../Component/Pos/ProductItem/ProductItemUpdate";
import Suspend from "../Component/Pos/Suspend/Suspend";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import PosWholesale from "../Component/Pos/PosWholesale/PosWholesale";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PRODUCT_FOR_SALE_PAGINIATION, GET_CUSTOMER } from "../Schema/pos";
import { GET_CARD_BY_ID, UPDATE_CARD } from "../Schema/posupdate";
import { NumericFormat } from "react-number-format";

export default function PosUpdatePage() {

  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { setAlert } = useContext(AuthContext);
  const [loadingSuspended,setLoadingSuspended] = useState(false);
  const [suspended,setSuspended] = useState(false);

  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  const defaultCustomer = window.localStorage.getItem("defaultCustomer");
  let exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));
  let shop = JSON.parse(window.localStorage.getItem("shop"));

  //LINK TO VIEWPOS
  const navigate = useNavigate();
  const location = useLocation();
  const cardId = location?.state?.cardId;
  const viewPos = () => navigate("/pos/view-pos");
  // console.log(cardId)

  //SELECT CUSTOMER
  const [typeSell, setTypeSell] = useState("Retail");
  const [productQtyByIdData, setProductQtyByIdData] = useState(0);
  const [productItems, setProductItems] = useState([]);
  const [productIdScan, setProductIdScan] = useState("");
  const [category, setCategory] = useState({ title: "All menu" });
  const [totalDiscount, setTotalDiscount] = useState(0.0);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [customer, setCustomer] = useState([{ id: "", label: "" }]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: "",
    label: "",
  });

  //RECEIVE
  const [checkRequire, setCheckRequire] = useState(false);
  const [receiptDate, setReceiptDate] = useState(moment());
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [receiptNumber, setReceiptNumber] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [cardData, setCardData] = useState(null);

  //PAGINATOR
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [limit, setLimit] = useState(16);
  const [keyword, setKeyword] = useState("");
  const [saleQR,setSaleQR] = useState("");
  const [paginator, setPaginator] = useState({});
  const [tableData, setTableData] = useState([]);

  //QUERY CARD BY ID
  const { data } = useQuery(GET_CARD_BY_ID, {
    variables: {
      getCartByIdId: cardId,
    },
    onCompleted: ({ getCartById }) => {
      // console.log(getCartById);
      setCardData(getCartById);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // console.log("Recipe", cardData);

  //QUERY PRODUCT
  const [productForScan,setProductForScan] = useState([]);
  const { refetch: refetchProductScan } = useQuery(GET_PRODUCT_FOR_SALE_PAGINIATION, {
    variables: {
      category: "",
      type: typeSell,
      keyword: "",
      saleQr: "",
      pagination: false,
    },
    onCompleted: ({ getProductForSalePagination }) => {
      if(getProductForSalePagination?.data) {
        setProductForScan(getProductForSalePagination?.data)
      }       
    },
  });


  useEffect( () => {
    refetchProductScan();
  },[typeSell])


  const { refetch , loading: loadingProduct } = useQuery(GET_PRODUCT_FOR_SALE_PAGINIATION, {
    variables: {
      page: page,
      limit: limit,
      category: category?.title === "All menu" ? "" : category?.title,
      type: typeSell,
      keyword: keyword,
      saleQr: saleQR,
      pagination: true,
    },
    onCompleted: ({ getProductForSalePagination }) => {
      if(getProductForSalePagination?.data) {
        setPaginator(getProductForSalePagination?.paginator);
        setTableData(getProductForSalePagination?.data);
        setLoading(false);
      }      
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
    pollInterval: 1000,
  });

  
  useEffect( () => {
    refetch();
    setLoading(true);
  },[category?.title , page , category , typeSell , saleQR ])

  //QUERY CUSTOMER
  const { refetch: refectCustomer } = useQuery(GET_CUSTOMER, {
    variables: {
      keyword: "",
    },
    onCompleted: ({ getCustomers }) => {
      if (getCustomers?.data) {
        let rows = [];
        getCustomers?.data?.map((e) => {
          let row = { id: e?._id, label: e?.name };
          rows.push(row);
        });
        setCustomer(rows);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
    refectCustomer();
  }, []);

  //========== COUNT CATEGORY ITEMS==========================
  const countItem = (typeSell) => {
    let total = 0;
    if (typeSell === "Retail")
      total = tableData?.filter((e) => e.type === "Retail")?.length;
    else {
      total = tableData?.filter((e) => e.type !== "Retail")?.length;
    }
    return total;
  };

  //CLEAR BUTTON TOP RIGHT
  const handleClear = () => {
    setProductItems([]);
    setProductIdScan("");
    setSelectedCustomer(null);
    setOutStockProduct([]);
    navigate("/pos");
  };

  // ============= open dialog payment ==============
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    navigate("/pos");
    setOpen(false);
  };

  const [receiptData, setReceiptData] = useState(null);

  // ============= check out stock product =====================
  const [outStockProduct, setOutStockProduct] = useState([]);

  const handleAddOutStock = (newId) => {
    let newItem = [];
    const newOutStock = outStockProduct;
    if (newOutStock?.length !== 0) {
      if (newOutStock?.filter((e) => e === newId)?.length === 0) {
        setOutStockProduct([...outStockProduct, newId]);
      }
    } else {
      newItem.push(newId);
      setOutStockProduct([...newItem]);
      // setAlert(true,"error","Out of stock!");
    }
  };

  const handleDeleteOutStock = (id) => {
    let filteredItems = outStockProduct?.filter((e) => e !== id);
    setOutStockProduct(filteredItems);
  };

  const handleOutStock = (idProduct) => {
    let outStockState = false;
    let newOutStock = outStockProduct;
    if (newOutStock?.length !== 0) {
      if (newOutStock?.filter((e) => e === idProduct)?.length !== 0) {
        outStockState = true;
      } else {
        outStockState = false;
      }
    }
    return outStockState;
  };

  // ================== function create cart ============
  const [updateCart] = useMutation(UPDATE_CARD, {
    onCompleted: ({ updateCart }) => {
      console.log("updateCart?.data",updateCart?.data);
      if (updateCart?.status) {
        setAlert(true, "success", updateCart?.message);        
        setLoadingUpdate(false);
        setLoadingSuspended(false);
        if(suspended === false) {
          handleOpen();
          setReceiptData(updateCart?.data);
        } else {
          handleClear();
        }

      } else {
        setAlert(true, "error", updateCart?.message);
        setLoadingUpdate(false);
        setLoadingSuspended(false);
      }
    },
    onError: (error) => {
      console.log(error.message);
      setLoadingUpdate(true);
    },
  });

  //CHANGE TYPESELL
  const handleChangeTypeSell = (e) => {
    // setProductItems([]);
    setPage(1);
    setKeyword("");
    setSaleQR("");
    setLimit(8);
    setTypeSell(e.target.value);
  };

  //DELETE ITEM
  const handleDeleteItem = (product_id_setup) => {
    let filteredItems = productItems?.filter( (t) => t.product_id_setup !== product_id_setup );
    handleDeleteOutStock(product_id_setup);
    setProductItems(filteredItems);
  };

  //UPDATE PRICE DISCOUNT
  const handleTotalDiscount = () => {
    let amount = 0;
    productItems?.map((e) => {
      if ( e?.percentage_discount !== 0 && e?.percentage_discount !== undefined ) {
          amount += e?.percentage_discount * ((e?.price * e?.qty) / 100);
      }

      if (e?.amount_discount !== 0 && e?.amount_discount !== undefined) {
          amount += e?.amount_discount;
      }
    });
    setTotalDiscount(amount?.toFixed(2));
  };

  //UPDATE ITEM QTY
  const handleUpdateAddQtyProductCard = (arrayItem, itemid) => {
    const items = arrayItem;   
    const product = productItems?.filter( (item) => item?.product_id_setup === itemid );

    // =============== check qty in stock ===============
    // if ( product[0]?.qty_in_shop - parseFloat(((product[0]?.qty + 1) * product[0]?.qty_in_product)?.toFixed(2)) < 0 ) {
    //   handleAddOutStock(itemid);
    //   setProductItems([...items]);
    // } else {
    //   items.map((i) => {
    //     if (i.product_id_setup === itemid) {
    //       i.qty += 1;
    //     }
    //   });
    //   setProductItems([...items]);
    // }
    
    // =============== Not check qty in stock ===============
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty += 1;
      }
    });
    setProductItems([...items]);

    handleTotalDiscount();
  };

  const handleUpdateAddQtyByInputNumber = (arrayItem, itemid , newQty ) => {
    // console.log("arrayItem", arrayItem);
    const items = arrayItem;      
    const product = productItems?.filter((item) => item?.product_id_setup === itemid);
    const originalProduct = tableData?.filter( (e) => e?._id === itemid );

    // =============== check qty in stock ===============      
    // if ((product[0]?.qty_in_shop) - (newQty * product[0]?.qty_in_product) < 0) {
    //   handleAddOutStock(itemid);
    //   items.map((i) => {
    //     if (i.product_id_setup === itemid) {
    //       i.qty = parseFloat(product[0]?.qty_in_shop * originalProduct[0]?.product_Id?.qty_inUnit);
    //     }
    //   });
    //   setProductItems([...items]);
    // } else {
    //   handleDeleteOutStock(itemid);
    //   items.map((i) => {
    //     if (i.product_id_setup === itemid) {
    //       i.qty = newQty;
    //     }
    //   });
    //   setProductItems([...items]);
    // }    

    // =============== Not check qty in stock =============== 
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty = newQty;
      }
    });
    setProductItems([...items]);


    handleTotalDiscount();
  };

  //UPDATE REMOVE QTY
  const handleUpdateRemoveQtyProductCard = (arrayItem, itemid) => {
    const items = arrayItem;
    items.map((i) => {
      if (i.product_id_setup === itemid) {
        i.qty -= 1;
      }
    });
    setProductItems([...items]);
    handleTotalDiscount();
  };

  //CLICK ON CART TO ADD
  const handleAddProductItem = (e, qty_in_shop) => {
    // console.log('e',e)
    setKeyword("");
    setSaleQR("");

    let newObject = {
        product_id_setup: e?._id,
        image_src: e?.image,
        name: e?.name,
        unit: e?.unit,
        qty: 1,
        qty_in_product: e?.qty,
        qty_in_shop: qty_in_shop,
        qty_in_product_unit: e?.product_Id?.qty_inUnit,
        price: e?.unit_Price,
        discount_id: null,
        percentage_discount: 0,
        amount_discount: 0,
        descriptions: e?.descriptions,
    }; 

    // console.log("newObject", newObject);

    const newItem = productItems;
    if (newItem?.length !== 0) {
      let addArray = false;
      const BreakException = {};
      try {
        if (typeSell === "Retail") {
          newItem?.map((elem) => {
            if (elem?.product_id_setup !== newObject?.product_id_setup) {
              addArray = true;
            } else {
              addArray = false;
              handleUpdateAddQtyProductCard(
                newItem,
                newObject?.product_id_setup
              );
              throw BreakException;
            }
          });
        } else {
          newItem?.map((elem) => {
            if (elem?.product_id_setup !== newObject?.product_id_setup) {
              addArray = true;
            } else {
              addArray = false;
              handleUpdateAddQtyProductCard(
                newItem,
                newObject?.product_id_setup
              );
              throw BreakException;
            }
          });
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      if (addArray) {
        newItem.push(newObject);
      }
    } else {
      newItem.push(newObject);
    }
    setProductItems([...newItem]);
    handleTotalDiscount();
  };

  //UPDATE DISCOUNT
  const updateDiscount = (itemId, valueDiscount) => {
    const items = productItems;
    items?.map((i) => {
      if (i.product_id_setup == itemId) {
        if (valueDiscount?.percent !== 0) {
          i.percentage_discount = valueDiscount?.percent;
          i.discount_id = valueDiscount?.id;
          i.amount_discount = 0;
          i.discount_label = valueDiscount?.label;
        } else {
          i.amount_discount = valueDiscount?.amount;
          i.discount_id = valueDiscount?.id;
          i.percentage_discount = 0;
          i.discount_label = valueDiscount?.label;
        }
      }
    });
    setProductItems([...items]);
    handleTotalDiscount();
  };

  // ============================== TOTAL AMOUNT ============================
  let subTotal = productItems?.reduce(function (a, b) {
    return a + b.price * b.qty;
  }, 0);

  useEffect(() => {
    if (totalDiscount !== 0) {
      setTotalAmount(subTotal - totalDiscount);
    } else {
      setTotalAmount(subTotal);
    }
  }, [subTotal, totalDiscount]);

  useEffect(() => {
    if (typeSell !== "Retail") {
      if (customerVal?.id === null || customerVal?.id === undefined) {
        setCheckRequire(true);
      } else {
        setCheckRequire(false);
      }
    }
  }, [customerVal?.id]);

  useEffect(() => {
    setShowPage(page);
    refectCustomer();
  }, [typeSell, page, keyword, limit, category?.title]);

  //SCAN
  const handleAddProScan = () => {
      if (productForScan?.filter( (e) => e?.saleQR === productIdScan)?.length > 0) {
          let productScan = productForScan?.filter((e) => e?.saleQR === productIdScan);

          //======================= Check Qty In Stock =======================
          // if ( productScan[0]?.total_inShop > 0) {
          //     handleAddProductItem( productScan[0], productScan[0]?.total_inShop );
          //     setProductIdScan("");
          // } else {
          //     setAlert(true, "error", "Out of stock!");
          //     setProductIdScan("");
          //     setSaleQR("");
          // }

          //======================== Check Qty In Stock =======================
          handleAddProductItem( productScan[0], productScan[0]?.total_inShop );
          setProductIdScan("");
      }
  };


  useEffect(() => {
    if(productIdScan !== "" && productIdScan !== undefined) {
      handleAddProScan();
    }
  }, [productIdScan,loadingProduct]);


  // ================== function update card =================================
  const handleUpdateCard = () => {    
      setReceiptData(null)
      const newValue = {     
        date: receiptDate,
        customer_id: selectedCustomer?.id,
        items: productItems,
        payment_status: cardData?.payment_status,
        sub_total: parseFloat(subTotal?.toFixed(2)),
        discount_total: parseFloat(totalDiscount),
        tax_total: 0,
        total: parseFloat(totalAmount?.toFixed(2)),
        exchange_rate: parseFloat(exChangeRate),
      };

      updateCart({
        variables: {
          updateCartId: cardId,
          input: newValue,
        },
      });
  };

  
  //===================== get total_inShop by id =====================
  const getQtyInshop = (id) => {
    if(tableData?.length > 0) {
      const filterItem = tableData?.filter((e) => e?._id === id);
      return filterItem[0]?.total_inShop;
    } else {
      return 0;
    }
  };

  // ======================== set Data Update ===========================
  useEffect(() => {
    if (cardData) {
      setSelectedCustomer({
        id: cardData?.customer_id?._id,
        label: cardData?.customer_id?.name,
      });
      // 
      
      let newObject = []; 
      cardData?.items?.map((e) => {  
        let converObj = {
          product_id_setup: e?.product_id_setup?._id,
          image_src: e?.product_id_setup?.image,
          name: e?.name,
          unit: e?.unit,
          qty: e?.qty,
          qty_in_product: e?.qty_in_product,
          qty_in_product_unit: e?.product_id_setup?.product_Id?.qty_inUnit, 
          qty_in_shop: getQtyInshop(e?.product_id_setup?._id) + (e?.qty*e?.qty_in_product),
          price: e?.price,
          discount_id: e?.discount_id?._id ? e?.discount_id?._id : null,
          discount_label:
            e?.discount_id && e?.discount_id?.percentage !== 0
              ? e?.discount_id?.percentage + "% - " + e?.discount_id?.events
              : e?.discount_id && e?.discount_id?.amount_discount !== 0
              ? "$" +
                e?.discount_id?.amount_discount +
                " - " +
                e?.discount_id?.events
              : "",
          percentage_discount: e?.discount_id?.percentage
            ? e?.discount_id?.percentage
            : 0,
          amount_discount: e?.discount_id?.amount_discount
            ? e?.discount_id?.amount_discount
            : 0,
          descriptions: e?.product_id_setup?.descriptions,
        };
        newObject.push(converObj);
      });
      setProductItems([...newObject]);
      
    }
  }, [cardData]);

  useEffect(() => {
    //START AND SET TOTAL DISCOUNT
    handleTotalDiscount();
  }, [productItems?.length]);

  useEffect( () => {
    setPage(1);
  },[keyword])

  console.log(productItems, "productItems");
  // console.log("cardData", cardData);
  // console.log("tableData", tableData)

  return (
    <div className="pos-page">
      <Grid container spacing={4}>
        {/* LEFT SIDE */}
        <Grid item xs={12} sm={12} md={6} lg={5} xl={7}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="column" justifyContent="center">
              <IconMenuResponsive />
            </Stack>

            <Stack direction="column" justifyContent="center">
              <Box className="slash" />
            </Stack>

            <Stack direction="column" justifyContent="center">
              <Typography
                className="page-title"
                onClick={() => navigate("/pos")}
                sx={{ cursor: "pointer" }}
              >
                POS
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <TopRightBar />
          </Stack>

          {/* MENU BAR */}
          <Grid container spacing={3} sx={{ marginTop: "10px"}}>
            <Grid item xs={12} className="search-container">
              <Grid container spacing={2}>
                
                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search">
                    <FormControl fullWidth className="text-field">
                      <Select
                        value={typeSell}
                        size="small"
                        onChange={handleChangeTypeSell}
                      >
                        <MenuItem value={"Retail"}>Retail</MenuItem>
                        <MenuItem value={"Wholesale"}>Wholesale</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search">
                    <Stack direction="column" width="100%">
                      <TextField
                        value={keyword}
                        className="text-field"                        
                        id="input-with-sx"
                        placeholder="Name"
                        size="small"
                        onChange={ (e) => {
                          setKeyword(e.target.value)
                          setCategory({ title: "All menu" })
                          setPage(1);
                          setSaleQR("");
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 1 }}>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                  <Box className="box-search">
                    <Stack direction="column" width="100%">
                      <TextField
                        value={saleQR}
                        className="text-field"           
                        id="input-with-sx"
                        placeholder="Barcode"
                        size="small"
                        onChange={ (e) => {
                          setSaleQR(e.target.value);
                          setKeyword("")
                          setCategory({ title: "All menu" })
                          setPage(1);                          
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 1 }}>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={3} className="add-container" >
                  <Button
                    onClick={viewPos}
                    fullWidth
                    className="btn-add"
                    startIcon={<VisibilityOutlinedIcon className="icon-add" />}
                  >
                    ViewPos
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={3} className="add-container"  >
                  <Suspend />
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box className="category">Category</Box>
            <Box className="category-style">
              <Stack className="scroll-container">
                {listCategory?.map((e, index) => (
                  <Button
                    key={index}
                    onClick={() => setCategory({ title: e?.label })}
                    className="item-box"
                  >
                    <Stack direction="column" width="100%">
                      <Stack direction="row" justifyContent="center">
                        <Typography className="menu-icon">{e?.icon}</Typography>
                      </Stack>
                      <Stack
                        className={
                          category?.title === e?.label ? "active" : "no-active"
                        }
                      >
                        <Typography variant="body2" mt={"3px"}>{e?.label}</Typography>
                        {/* {category?.title === e?.label ? (
                          <Typography>{countItem(typeSell)} Items</Typography>
                        ) : null} */}
                      </Stack>
                    </Stack>
                  </Button>
                ))}
              </Stack>
            </Box>

            <Box className="category">Product</Box>

            <Box className="tabs-container">
              {typeSell === "Wholesale" ? (
                <>
                  <PosWholesale
                    handleAddProductItem={handleAddProductItem}
                    loading={loading}
                    tableData={tableData}
                    page={page}
                    limit={limit}
                    keyword={keyword}
                    setShowPage={setShowPage}
                    typeSell={typeSell}
                    setPaginator={setPaginator}
                    handleOutStock={handleOutStock}
                  />
                </>
              ) : (
                <>
                  {loading ? (
                    <Stack
                      direction="column"
                      justifyContent="center"
                      height={400}
                    >
                      <LoadingPage />
                    </Stack>
                  ) : (
                    <>
                      {tableData?.filter((e) => e?.type === "Retail")?.length === 0 ? (
                        <Stack
                          direction="column"
                          justifyContent="center"
                          height={300}
                        >
                          <EmptyData />
                        </Stack>
                      ) : (
                        <Grid container spacing={2}>
                          { tableData?.filter((e) => e?.type === "Retail")?.map((data, index) => {
                              return (
                                <Grid item  xs={3} sm={3} md={3} lg={3} xl={2} key={index} >
                                  <Button
                                    fullWidth
                                    className="btn-pos"
                                    // disabled={ data?.total_inShop === 0 || handleOutStock(data?._id) === true ? true : false }
                                    onClick={() => {
                                      handleAddProductItem( data, data?.total_inShop );
                                    }}
                                  >
                                    
                                    {/* { data?.total_inShop === 0 || handleOutStock(data?._id) === true ? (
                                      <Box className="stock-out">
                                        Out of stock
                                      </Box>
                                    ) : null} */}


                                    <Stack direction="column" width="100%">
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                        className="img-container"
                                      >
                                          {data?.image === "" || data?.image === null ? (
                                            <HideImageOutlinedIcon
                                              className="product-img"
                                              sx={{ color: "#757575" }}
                                            />
                                          ) : (
                                            <img
                                              className="product-img"
                                              alt="Image"
                                              src={data?.image}
                                            />
                                          )}
                                      </Stack>

                                      <Stack
                                        className="card-body"
                                        direction="column"
                                        justifyContent="space-between"
                                      >
                                        <Typography className="product-name">
                                          {data?.name}
                                        </Typography>

                                        { data?.descriptions ?
                                          <Typography className="product-name">
                                            <span style={{ color:"white" , backgroundColor: "red" , padding: 3 , borderRadius: "5px"}}>
                                              {data?.descriptions}
                                            </span>
                                          </Typography>
                                        : null }


                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <Typography className="product-price">
                                            ${data?.unit_Price}
                                          </Typography>
                                          <Typography className="unit-name">
                                            {data?.unit}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  </Button>
                                </Grid>
                              );
                            })}
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>

            <Stack
              mt={2}
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction="row"
                sx={{ bgcolor: "#fff", padding: "5px", borderRadius: "10px" }}
                alignItems="center"
                spacing={2}
              >
                <Pagination
                  page={showPage}
                  hideNextButton={false}
                  hidePrevButton={false}
                  count={paginator?.totalPages}
                  color="primary"
                  variant="outlined"
                  onChange={(event, pageNumber) => {
                    setPage(parseInt(pageNumber));
                  }}
                />

                <Select
                  sx={{ width: "105px" }}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  size="small"
                  value={limit}
                  defaultValue={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <MenuItem value={6}>6/Page</MenuItem>
                  <MenuItem value={8}>8/Page</MenuItem>
                  <MenuItem value={16}>16/Page</MenuItem>
                  <MenuItem value={20}>20/Page</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          {/* END LEFT GRID */}
        </Grid>

        {/* RIGHT SIDE 2 */}
        <Grid item xs={12} sm={12} md={6} lg={7} xl={5}>
          <Box className="order-container">
            <Button
              onClick={handleClear}
              className="clear-btn"
              // sx={{ display: productItems?.length === 0 ? "none" : "block" }}
            >
              Clear
            </Button>
            <Stack direction="column">
              <Typography className="order-title">Current Order</Typography>
              <Box mb={2}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={6} xl={6}>
                    <Typography className="order-subtitle">
                      Receipt Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={receiptDate}
                        onChange={(e) => setReceiptDate(e)}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="text-field"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="order-subtitle">
                      Select Customer
                    </Typography>
                    <Autocomplete
                      freeSolo
                      clearOnBlur
                      disablePortal
                      size="small"
                      options={customer}
                      value={selectedCustomer}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || value === ""
                      }
                      getOptionLabel={(option) =>
                        option?.label ? option?.label : ""
                      }
                      onChange={(event, value) => {
                        // setSelectCustomer(value?.id);
                        setSelectedCustomer(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Customer"
                          error={selectedCustomer === null}
                          helperText={selectedCustomer === null && "require"}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="order-subtitle">
                      Enter product:
                    </Typography>
                    <TextField
                      autoFocus={true}
                      autoComplete="off"
                      placeholder="cursor here to scan"
                      fullWidth
                      size="small"
                      value={productIdScan}
                      onChange={ (e) => {
                        setSaleQR(e?.target?.value)
                        setProductIdScan(e?.target?.value) 
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* =============== product item card ============ */}
              <ProductItemUpdate
                  handleDeleteOutStock={handleDeleteOutStock}
                  productItems={productItems}
                  handleUpdateAddQtyProductCard={handleUpdateAddQtyProductCard}
                  handleDeleteItem={handleDeleteItem}
                  productQtyByIdData={productQtyByIdData}
                  handleUpdateRemoveQtyProductCard={handleUpdateRemoveQtyProductCard}
                  handleUpdateAddQtyByInputNumber={handleUpdateAddQtyByInputNumber}
                  typeSell={typeSell}
                  updateDiscount={updateDiscount}
              />

              <Stack direction="column" spacing={1} className="total-container">
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="sub-total">Subtotal</Typography>
                  <Typography className="sub-total">
                    $ {subTotal?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="sub-total">Discount</Typography>
                  </Stack>
                  <Typography className="sub-total">
                    $ {totalDiscount}
                  </Typography>
                </Stack>
                <Box className="underline" />
                <Stack direction="row" spacing={1}>
                  <Typography className="total">Total:</Typography>
                  <Typography className="total">{productItems?.length} item</Typography>
                  <Box flexGrow={1}/>
                  <Typography className="total">
                    $ {totalAmount?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="right">
                  <Typography className="total-kh">
                    <NumericFormat 
                        value={(totalAmount * exChangeRate)?.toFixed(0)} 
                        thousandSeparator={true} 
                        displayType={'text'} 
                        renderText={(value, props) => <div {...props}>៛ {value}</div>}
                    /> 
                  </Typography>
                </Stack>
              </Stack>


             

              <Stack direction="row" justifyContent="center" spacing={2} mt={1}>

                  <Box sx={{width: "40%"}}>
                    {
                      loadingSuspended ?
                        <Button 
                          fullWidth 
                          className="btn-payment"
                          startIcon={<AddShoppingCartOutlinedIcon />}
                        >
                          Loading...
                        </Button>
                      :
                        <Button
                          className="btn-payment"
                          fullWidth
                          disabled={productItems?.length === 0 || selectedCustomer === null ? true : false}
                          onClick={() => {
                            handleUpdateCard();
                            setSuspended(true);
                            setLoadingSuspended(true)
                          }}
                          startIcon={<AddShoppingCartOutlinedIcon />}
                        >
                            Suspended
                        </Button>
                    } 
                  </Box>

                  { loadingUpdate ? (
                    <Button 
                      fullWidth 
                      className="btn-payment"
                      startIcon={<PaidOutlinedIcon />}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      className="btn-payment"
                      disabled={productItems?.length === 0 || selectedCustomer === null ? true : false}
                      onClick={() => {
                        handleUpdateCard();
                        setLoadingUpdate(true);
                        setSuspended(false);
                      }}
                      startIcon={<PaidOutlinedIcon />}
                    >
                      Continue to Payment
                    </Button>
                  )}

              </Stack> 

              <PrintProductItem
                open={open}
                receipt={receiptData}
                handleClose={handleClose}
                type={"createPos"}
                setRefetch={refetch}      
              />

            </Stack>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
//category list
const listCategory = [
  { label: "All menu", icon: "📦" },
  { label: "Food", icon: "🍔" },
  { label: "Meat", icon: "🍖" },
  { label: "Drink", icon: "🍸" },
  { label: "Vegetables", icon: "🥒" },
  { label: "Fruit", icon: "🍎" },
  { label: "Cosmetic", icon: "🎒" },
  { label: "Snacks", icon: "🍱" },
  { label: "Spices", icon: "🔥" },
  { label: "Soap", icon: "🍵" },
  { label: "Ice Cream", icon: "🍦" },
  { label: "Preserved Food", icon: "🍣" },
  { label: "Daily Use", icon: "🛍" },
  { label: "Rice", icon: "🍚" },
  { label: "Other", icon: "..." },
];
