import React, { useState, useContext } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Avatar,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import { translateLauguage } from "../Dymanic/Translate";
import { AuthContext } from "../Context/AuthContext";
import TopRightBar from "../Menu/TopRightBar";
import IconMenuResponsive from "../Menu/IconMenuResponsive";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CreateExpense from "../Component/Expense/CreateExpense";
import ExpenseAction from "../Component/Expense/ExpenseAction";
import PreviewExpense from "../Component/Expense/PreviewExpense";
import { GET_EXPENSE } from "../Schema/expense";
import { useQuery } from "@apollo/client";
export default function Expend() {
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const [editRow, seteditRow] = useState(null);
  const [openPreview, setOpenPriview] = useState(false);
  const handleClosePreview = () => setOpenPriview(false);
  const handleOpenPreview = (row) => {
    seteditRow(row);
    setOpenPriview(true);
  };

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rowData, setRowData] = useState([]);

  const { refetch } = useQuery(GET_EXPENSE, {
    variables: {
      startDate: from ? moment(from)?.format("YYYY-MM-DD") : "",
      endDate: to ? moment(to)?.format("YYYY-MM-DD") : "",
    },
    onCompleted: ({ getExpenses }) => {
      setRowData(getExpenses);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(true);
    },
  });

  const amountUsd = (array) => {
    let amount = 0;
    array.map((e) => {
      if (e.type_currency === "USD") {
        amount += e?.total_amount;
      }
    });
    return amount?.toFixed(2);
  };
  const amountRiel = (array) => {
    let amount = 0;
    array.map((e) => {
      if (e.type_currency === "Riel") {
        amount += e?.total_amount;
      }
    });
    return amount;
  };

  // console.log(rowData)
  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Expense</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <TopRightBar />
      </Stack>
      <Grid container mt={"20px"} spacing={2}>
        <Grid item lg={3} md={3} sm={6} xs={10}>
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={from}
                onChange={(e) => setFrom(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={10}>
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={to}
                onChange={(e) => setTo(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={10} />
        <Grid item lg={2} md={2} sm={6} xs={10}>
          <Button
            className="btn-add"
            fullWidth
            size="small"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            onClick={handleOpen}
          >
            Create
          </Button>
          <CreateExpense
            open={open}
            handleClose={handleClose}
            // t={t}
            // language={language}
            setRefetch={refetch}
          />
        </Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer
        //  sx={{ maxWidth: `${width}px` }}
        >
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  Date
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  Title
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  Expense Amount (USD)
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                >
                  Expense Amount (RIEL)
                </TableCell>
                <TableCell
                  className={
                    language === "kh"
                      ? "header-titleKh-start"
                      : "header-title-start"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <React.Fragment>
                {rowData?.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={7} className="body-cell">
                        <Stack
                          direction="column"
                          height={300}
                          justifyContent="center"
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {rowData?.map((row, index) => (
                      <TableRow
                        // className="body-row"
                        className={ row?.is_voided ? "body-row void" : 'body-row'}
                        key={index}
                        // sx={{ bgcolor: row?.is_voided && "#ff000017" }}
                      >
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {moment(row?.date)?.format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          {row?.titles}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          $ {amountUsd(row?.expense_amount)}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cellKh-start"
                              : "body-cell-start"
                          }
                          onClick={() => handleOpenPreview(row)}
                        >
                          ៛​ {amountRiel(row?.expense_amount)}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <ExpenseAction editRow={row} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>

        <PreviewExpense
          editRow={editRow}
          open={openPreview}
          handleClosePreview={handleClosePreview}
        />
      </Box>
    </div>
  );
}
