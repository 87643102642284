import * as React from "react";
import "../../../Style/actionstyle.scss";
import { IconButton, Typography, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import { FaBarcode } from "react-icons/fa";
import UpdateSale from "./UpdateSale";
import DelectSale from "./DeleteSale";
import IsUseSale from "./IsUseSale";
import ViewQrCode from "./ViewQrCode";
import ViewBarcode from "./ViewBarcode";

import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Dymanic/Translate";

export default function SaleAction({ editRow, setRefetch }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal delete
  const [openIsUse, setOpenIsUse] = React.useState(false);
  const handleOpenIsUse = () => setOpenIsUse(true);
  const handleCloseIsUse = () => setOpenIsUse(false);
  
  
  //Modal qrcode
  const [openQrCode, setOpenQrCode] = React.useState(false);
  const handleOpenQrCode = () => setOpenQrCode(true);
  const handleCloseQrCode = () => setOpenQrCode(false);

  //Modal barcode
  const [openBarcode, setOpenBarcode] = React.useState(false);
  const handleOpenBarcode = () => setOpenBarcode(true);
  const handleCloseBarcode = () => setOpenBarcode(false);

  //open menu
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="three-point" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="icon-menu-update" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {t("update")}
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenIsUse();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <EventAvailableIcon className="icon-menu-update" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              Is Used
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="icon-menu-delete" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {t("delete")}{" "}
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenQrCode();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <QrCode2OutlinedIcon sx={{ color: "#ecbd1d" }} />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {/* {t("update")} */}
              QrCode
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenBarcode();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <FaBarcode />
            <Typography
              sx={{ marginLeft: "10px !important" }}
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {/* {t("update")} */}
              BarCode
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <UpdateSale
        t={t}
        open={openEdit}
        handleClose={handleCloseEdit}
        editRow={editRow}
        language={language}
        setRefetch={setRefetch}
      />

      <IsUseSale 
          t={t}
          open={openIsUse}
          handleClose={handleCloseIsUse}
          editRow={editRow}
          language={language}
          setRefetch={setRefetch}
      />


      <DelectSale
        open={openDel}
        handleClose={handleCloseDel}
        editRow={editRow}
        t={t}
        setRefetch={setRefetch}
      />

      <ViewQrCode
        open={openQrCode}
        handleClose={handleCloseQrCode}
        editRow={editRow}
      />

      <ViewBarcode
        open={openBarcode}
        handleClose={handleCloseBarcode}
        editRow={editRow}
      />
    </div>
  );
}
