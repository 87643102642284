import React, { useState } from "react";
import { Grid, Stack, TextField, IconButton, Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

export default function CreateListItem(props) {
  const totalAmount = (item) => {
    let amount = 0;
    if (item?.discount === null) {
      amount = item?.unit_Price * item?.returnQty;
    } else if (item?.discount?.amount_discount !== 0) {
      if (item?.returnQty !== 0) {
        amount =
          item?.returnQty * item?.unit_Price - item?.discount?.amount_discount;
      }
    } else {
      if (item?.returnQty !== 0) {
        amount =
          item?.unit_Price * item?.returnQty -
          item?.unit_Price *
            item?.returnQty *
            (item?.discount?.percentage / 100);
      }
    }
    return amount?.toFixed(2);
  };

  return props?.items?.map((item, index) => (
    <Grid item container xs={12} spacing={1} key={index}>
      <Grid item xs={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            style={{ width: "50px" }}
            className="image"
            src={item?.image_src}
            alt={item?.name}
          />
          <Typography>
            {item?.name} {item?.unit_sale}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={props?.type}
          size="small"
          fullWidth
          value={item?.returnQty}
          onFocus={(e) => e.target.select()}
          type={"number"}
          onChange={(e) => {
            if (e.target.value !== undefined && e.target.value !== "") {
              if (e.target.value === 0)
                props?.updateItemQty(item?.product_id_setup, 1);
              else props?.updateItemQty(item?.product_id_setup, e.target.value);
            } else {
              props?.updateItemQty(item?.product_id_setup, 1);
            }
          }}
          inputProps={{
            min: 1,
            max: item?.qty,
          }}
          error={
            (props?.checkFieldRequried && item?.returnQty === 0) ||
            item?.returnQty === 0
          }
          helperText={
            (props?.checkFieldRequried &&
              item?.returnQty === 0 &&
              "more than 0") ||
            (item?.returnQty === 0 && "more than 0")
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextField size="small" fullWidth value={item?.unit_Price} disabled />
      </Grid>
      <Grid item xs={3}>
        <TextField size="small" fullWidth value={totalAmount(item)} disabled />
      </Grid>
      <Grid item xs={1}>
        <Stack
          direction="column"
          justifyContent="center"
          width="100%"
          sx={{ display: props?.type && "none" }}
        >
          <Stack direction="row" justifyContent="right" width="100%">
            <IconButton
              onClick={() => props.removeItem(item?.product_id_setup)}
            >
              <DeleteRoundedIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  ));
}
