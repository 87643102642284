import { useState, useEffect } from "react";
import { Stack, Pagination, Select, MenuItem } from "@mui/material";
//graphql

export function FooterPagination({
  totalPages,
  limit,
  setPage,
  handleLimit,
  showPage,
  componentName
}) {
  // console.log("selectedValue::", selectedValue)

  return (
    <Stack
      direction="row"
      justifyContent="right"
      spacing={2}
      sx={{ margin: "20px 30px 0px 0px" }}
    >
      <Stack direction="column" justifyContent="center">
        <Pagination
          page={showPage}
          hideNextButton={false}
          hidePrevButton={false}
          count={totalPages}
          color="primary"
          variant="outlined"
          onChange={(event, pageNum) => setPage(parseInt(pageNum))}
        />
      </Stack>

      <Select
        size="small"
        value={limit}
        onChange={handleLimit}
      >
        <MenuItem value={6}>6/Page</MenuItem>
        <MenuItem value={8}>8/Page</MenuItem>
        <MenuItem value={10}>10/Page</MenuItem>
        <MenuItem value={15}>15/Page</MenuItem>
      {
        componentName === "tableSetupSale" ? null :
        <MenuItem value={0}>All/Page</MenuItem>
      }        
      </Select>
    </Stack>
  );
}
