import React, { useContext, useEffect, useState } from "react";
import "./createcustomer.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, Avatar, TextField, Divider } from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";
import CropImageFile from "../CropImage/CropImageFile";
import { UPDATE_CUSTOMER } from "../../Schema/customer";
import { useMutation } from "@apollo/client";

export default function UpdateCustomer({
  editRow,
  t,
  open,
  language,
  handleClose,
  setRefetch,
  view
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  //mutation update data
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: ({ updateCustomer }) => {
      if (updateCustomer?.status) {
        setAlert(true, "success", updateCustomer?.message);
        setLoading(false);
        setRefetch();
        handleClose();
      } else {
        setAlert(true, "error", updateCustomer?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log("err", error?.message);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  //fromik
  const UpdateCustomer = Yup.object().shape({
    name: Yup.string().required("require!"),
    address: Yup.string().required("required address!"),
    tel: Yup.string(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      address: "",
      name: "",
      tel: "",
      remark: "",
    },

    validationSchema: UpdateCustomer,
    onSubmit: async (values) => {
      setLoading(true);
        updateCustomer({
          variables: {
            updateCustomerId:editRow?._id,
            input: {
              ...values,
              // image_src: "",
              // image_name: "",
            },
          },
        });
      
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (editRow) {
      setFieldValue("name", editRow?.name);
      setFieldValue("tel", editRow?.tel);
      setFieldValue("address", editRow?.address);
      setFieldValue("remark", editRow?.remark);
    }
  }, [editRow,open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="setup-dialog">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2} alignItems='center'>
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {view? 'Preview Customer' : "Update Customer"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon className="clear-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} className="grid-profile">
                    {!openCrop ? (
                      <Box>
                        <Stack
                          className="profile"
                          direction="row"
                          justifycontent="center"
                        >
                          <IconButton component="label" disabled={view}>
                            <TextField
                              type="file"
                              id="image"
                              sx={{ display: "none" }}
                              onChange={handleUploadImage}
                            />
                            <Avatar
                              className="avater-image"
                              src={
                                imageFile
                                  ? URL.createObjectURL(imageFile)
                                  : null
                              }
                            />
                            <input type="file" hidden />
                          </IconButton>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          sx={{ mt: 1 }}
                        >
                          <Typography
                            className={
                              language === "kh" ? "text-Kh" : "text-En"
                            }
                          >
                            {t("profile")}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : (
                      <CropImageFile
                        setImageFile={setImageFile}
                        photoURL={photoURL}
                        setOpenCrop={setOpenCrop}
                        setPhotoURL={setPhotoURL}
                        setUploadHook={setProfileHook}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("customer")}
                    </Typography>
                    <TextField
                      disabled={view}
                      fullWidth
                      size="small"
                      multiline
                      placeholder="name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("phone")}
                    </Typography>
                    <Stack>
                      <TextField
                        disabled={view}
                        fullWidth
                        size="small"
                        placeholder="tel"
                        {...getFieldProps("tel")}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("address")}
                    </Typography>
                    <Stack>
                      <TextField
                        disabled={view}
                        fullWidth
                        size="small"
                        placeholder="address"
                        {...getFieldProps("address")}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={language === "kh" ? "subKh" : "subEn"}
                    >
                      {t("remark")}
                    </Typography>
                    <Stack>
                      <TextField
                        disabled={view}
                        fullWidth
                        size="small"
                        placeholder="remark"
                        {...getFieldProps("remark")}
             
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box className="action" sx={{display: view && 'none'}}>
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={ language === "kh" ? "btn-actionKh" : "btn-actionEn"}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("update")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
