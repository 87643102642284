import React, { useRef } from "react";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import html2canvas from "html2canvas";
import BarCode from "./BarCode";
import { useReactToPrint } from "react-to-print";
import moment from "moment/moment";
import { NumericFormat } from "react-number-format";
export default function ViewBarcode({ open, handleClose, editRow }) {
  // console.log(editRow);
  const exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));

  const handleDownloadCard = async () => {
    const element = document.getElementById("card"),
      canvas = await html2canvas(element, {
        logging: true,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
      }),
      data = canvas.toDataURL("image/tif"),
      link = document.createElement("a");
    link.href = data;
    link.download = `card-${editRow?.saleQR}.tif`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //PRINT
  const compnentRef = useRef();
  const handleToPrint = useReactToPrint({
    content: () => compnentRef.current,
  });


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="view-qrcode"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">
            {/* Preview Qrcode {editRow?.product_id?.name} */}
            Preview Barcode {editRow?.product_Id?.name}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" justifyContent="center"  spacing={3} className="body-container">
              {/* { editRow?.saleQty*editRow?.product_Id?.qty_inUnit } */}
              {/* {editRow?.saleName}  */}

          <Box id="card" paddingTop="0px" paddingLeft="5px" paddingRight="5px" paddingBottom="5px" ref={compnentRef}>
            
              <Stack  direction="row" justifyContent="center" sx={{color: "#000" , fontWeight: "bold" , fontSize: "16px"}}> 
                  <NumericFormat 
                    value={(editRow?.unit_Price*exChangeRate)?.toFixed(0)} 
                    thousandSeparator={true} 
                    displayType={'text'} 
                    renderText={(value, props) => <div {...props}>  
                    { editRow?.product_Id?.name } {" "} $ { editRow?.unit_Price }{" "}-{" "} ៛ {value}
                    </div>}
                  /> 
              </Stack>

              {/* <Stack  direction="row" justifyContent="center" sx={{color: "#000" , fontWeight: "bold" , fontSize: "15px"}}>
                  $ { editRow?.unit_Price } - { editRow?.unit_Price*exChangeRate } ៛
              </Stack> */}
              
              <Stack  direction="row" spacing={1} justifyContent="center" sx={{color: "#000" , fontWeight: "bold" , fontSize: "15px"}}>
                <span>
                  Pack: {moment()?.format("DD-MMM-YY")} 
                </span>
              </Stack>

              <Stack  direction="row" spacing={1} justifyContent="center" sx={{color: "#000" , fontWeight: "bold" , fontSize: "15px"}}>
                {
                  editRow?.expireDate ?
                  <span>
                    Expire: {moment(editRow?.expireDate)?.format("DD-MMM-YY")}
                  </span>
                : null
                } 
              </Stack>

            {/* {
              editRow?.expireDate ?
                <Stack  direction="row" justifyContent="center" sx={{color: "#000" , fontWeight: "bold" , fontSize: "14px"}}>
                  Expire: {moment(editRow?.expireDate)?.format("DD-MM-YYYY")}
                </Stack>
              : null
            } */}
              
              <Stack
                direction="row" 
                justifyContent="center"   
                className="qrcode-container" 
                mt={0}
              >
                <BarCode productId={editRow?.saleQR} />              
              </Stack>
              
          </Box>

          <Box sx={{flexGrow:1}} />

          <Stack direction="row" justifyContent="center" spacing={4}>
              <Button
                startIcon={<DownloadOutlinedIcon />}
                onClick={handleDownloadCard}
              >
                Download
              </Button>

              <Button
                startIcon={<DownloadOutlinedIcon />}
                onClick={handleToPrint}
              >
                Print
              </Button>
          </Stack>

        </Stack>
      </DialogContent>
    </Dialog>
  );
}
